import _ from "lodash";
import React, { useMemo, useState, useEffect, useContext } from "react";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { connect } from "react-redux";
import {
  TextField,
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Grid,
  Chip,
  Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import update from "immutability-helper";
import appRdxFns from "fitbud/redux/app";
import Dialog from "fitbud/components/Dialog";
import { FormTextField } from "fitbud/components/form-fields";
import { ExpandMore as KeyDown } from "@material-ui/icons/";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DeleteIcon } from "fitbud/icons/delete";
import { useSnackbar } from "notistack";
import NumUnitInput from "fitbud/components/numUnitInput";
import { CHECKIN } from "./default";
import {reorder} from "fitbud/utils/helpers";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { bffCheckinResetDefault } from 'fitbud/api';

const USER_DESC = 'Reset to match with your global settings.'
const COMPANY_DESC = 'Reset all clients to check-in as per above settings.'
const DAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const DAYS_MAP = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};
const weeksList = [
  {value: "sunday", label: "S"},
  {value: "monday", label: "M"},
  {value: "tuesday", label: "T"},
  {value: "wednesday", label: "W"},
  {value: "thursday", label: "T"},
  {value: "friday", label: "F"},
  {value: "saturday", label: "S"},
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginRight: "25px",
      borderRadius: "24px",
      width: "48px",
      height: "48px",
      border: "solid 1px #d1d1d1",
    },
    marginBottom: "20px"
  },
  description: {
    lineHeight: "22px",
  },
  checkinsError: {
    fontWeight: 400,
    marginBottom: "12px",
    color: "#f44336", 
    fontSize: "0.75rem"
  }
}));

const Measurables = props => {
  const { measurables, state, setState } = props;
  const { measurements: input } = state;
  
  const recalculatePriority = (skip = null) => {
    let priority = 0;
    _.sortBy(Object.entries(input), ['1.priority']).forEach((entry) => {
      const [key, val] = entry;
      if(skip === key) return;
      input[key] = { ...val, priority }
      priority++;
    });
    return {
      measurements: {
        $merge: input
      }
    }
  }
  const measurements = useMemo(() => {
    const out = [];
    if (!measurables) return out;
    if (!input) return out;
    _.each(measurables, (y, key) => {
      const x = input[key]
      if (!x) return;
      out.push({key, ...x, ...y});
    });
    if (input.select)
      out.push({...input.select, key: 'select', value: 'select'});
    return _.sortBy(out, ['priority']);
  }, [input, measurables]);
  const options = useMemo(() => {
    if (!measurables) return [];
    const availableKeys=_.omit(measurables,Object.keys(input));
    return _.map(availableKeys, (v, k) => ({ value: k, label: v.value }))
  }, [input, measurables]);
  const onAddMore = (e) => {
    const key = e.target.value;
    setState(state => {
      if (!measurables[key]) return state;
      const obj = {...measurables[key]};
      obj.priority = (state && state.measurements && state.measurements.length) || 0;
      obj.tracking = true;
      obj.optional = true;
      return update(state, {measurements: {$merge: {[key]: obj}}});
    });
  };

  const onRemove = e => {
    const key = e.currentTarget.name;
    if (!key) return;
    const out = recalculatePriority(key);
    out.measurements = { ...out.measurements, $unset: [key] }
    setState(update(state, out));
  };

  const handleChange = e => {
    if (!e || !e.target) return;
    const {name, checked, value} = e.target;
    if (!name) return;
    const [target, key] = name.split('-');
    if (!key) return;
    if (target === 'opt') {
      setState(update(state, {measurements: {
        [key]: {optional: {$set: !checked}}
      }}));
    } else if (target === 'type') {
      const y = measurables[value];
      const x = input[key];
      if (!y) return;
      setState(update(state, {measurements: {
        $unset: [key],
        $merge: { [value]: { ...x, ...y } }
      }}));
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const _out = reorder(measurements, result.source.index, result.destination.index).reduce((acc, curr, idx) => {
      acc[curr.key] = { ...curr, priority: idx }
      delete acc[curr.key].key;
      return acc;
    }, {})
    setState(update(state, {
      measurements: {
        $merge: _out
      }
    }));
    // setState({ measurements: _out });
  };
  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {measurements.map(({key, value, optional }, index) => {
                  return (
                    <Draggable draggableId={`__dragId${index}`} key={`__dragId${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} className="bg-white">
                          <Grid key={key} className="fp-0 fpb-20" container >
                            <Grid item xs={6} className="fp-0 fpr-30">
                              <TextField variant='outlined' disabled
                                name={key} value={value} className='w-100'
                                InputProps={{classes: { root: 'medium', input: 'size_15_500 medium text-black' }}}>
                              </TextField>
                            </Grid>
                            <Grid item xs={6} className="fp-0 ">
                              <div className="d-flex justify-content-between align-items-center">
                                <FormControlLabel
                                  name={`opt-${key}`}
                                  className="fm-0 fmln-10"
                                  checked={!optional}
                                  onChange={handleChange}
                                  control={<Checkbox color="primary" />}
                                  label={<Typography variant="body1">Required</Typography>} />
                                <div className="d-flex align-items-center">
                                  <IconButton name={key} onClick={onRemove}>
                                    <DeleteIcon />
                                  </IconButton>
                                  <div {...provided.dragHandleProps}>
                                    <DragIndicatorIcon />
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      {options.length > 0 && <div className='mb-20'>
        <TextField select variant='outlined' className='w-100' value='select' onChange={onAddMore}
          SelectProps={{ IconComponent: KeyDown, inputProps: { classes: { icon: 'fpl-5  text-black' } }}}
          InputProps={{classes: { root: 'medium', input: 'size_15_500 medium select-medium' }}}>
          <MenuItem key='select' value='select'>Select an option to add to the list above</MenuItem>
          {options.map(({ label, value }) => (<MenuItem key={value} value={value}>{label}</MenuItem>))}
        </TextField>
      </div>}
    </div>
  );
};

const Photos = props => {
  const { photosData, photos, poses, setState } = props;
  const [options, setOptions] = useState(() => {
    if (!photosData) return [];
    return _.chain(photosData).toPairs()
      .map(([key, obj]) => ({key, ...obj}))
      .sortBy(['default_priority']).value();
  });
  const [sortedPoses, onChange] = useState(() => {
    let tmp = poses || {};
    if (!poses && photosData && photos) {
      Object.keys(photos).forEach(x => {
        const key = x.toLowerCase();
        tmp[key] = {...photosData[key]};
        tmp[key].optional = photos[x];
      });
    }
    return _.chain(tmp).toPairs()
      .map(([key, obj]) => ({key, ...obj, priority: obj.priority || obj.default_priority}))
      .sortBy(['priority', 'default_priority']).value();
  });
  useEffect(() => {
    if (!photosData) return;
    const out1 = {}, out2 = {}, remaining = [];
    sortedPoses.forEach(x => {
      const {key, default_priority, ...rest} = x;
      out1[key] = rest;
      out2[key] = rest.optional || false;
    });
    if (setState) setState(state => ({...state, poses: out1, photos: out2}));
    Object.entries(photosData).forEach(([key, obj]) => {
      if (out1[key]) return;
      remaining.push({key, ...obj});
    });
    setOptions(_.sortBy(remaining, ['default_priority']));
  }, [sortedPoses, photosData, setState]);
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    onChange(poses => reorder(poses, result.source.index, result.destination.index)
      .map((x, n) => ({...x, priority: 10.0 * (n + 1)})));
  };
  const handleSelect = (e) => {
    const key = e.target.value;
    onChange(poses => {
      if (!photosData[key]) return poses;
      const out = [...(poses || [])];
      const obj = {...photosData[key]};
      if (!obj) return out;
      obj.optional = false;
      obj.key = key;
      if (!out.length) obj.priority = 10;
      else {
        const {priority} = out[out.length - 1];
        obj.priority = priority + 10;
      }
      out.push(obj);
      return out;
    });
  };
  const handleDelete = (e) => {
    const key = e.currentTarget.name;
    onChange(poses => {
      const out = [];
      let n = 10;
      poses.forEach(x => {
        if (x.key === key) return;
        out.push({...x, priority: n});
        n += 10;
      });
      return out;
    });
  };
  const toggleRequired = (e) => {
    const { name: key, checked } = e.target;
    onChange(poses => {
      const out = [];
      poses.forEach(x => {
        if (x.key === key) x.optional = !checked;
        out.push(x);
      });
      return out;
    });
  };
  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedPoses.map(({key, value, optional}, index) => {
                  return (
                    <Draggable draggableId={`__dragId${index}`} key={`__dragId${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} className='bg-white'>
                          <Grid key={key} className='p-0 pb-20' container >
                            <Grid item xs={6} className='p-0 pr-30'>
                              <TextField variant='outlined' disabled
                                name={key} value={value} className='w-100'
                                InputProps={{classes: { root: 'medium', input: 'size_15_500 medium text-black' }}}>
                              </TextField>
                            </Grid>
                            <Grid item xs={6} className='p-0'>
                              <div className='d-flex justify-content-between align-items-center'>
                                <FormControlLabel name={key} className='m-0 fmln-10'
                                  checked={optional === false} onChange={toggleRequired}
                                  control={<Checkbox color='primary' />}
                                  label={<Typography variant='body1'>Required</Typography>} />
                                <div className='d-flex align-items-center'>
                                  <IconButton name={key} onClick={handleDelete}>
                                    <DeleteIcon />
                                  </IconButton>
                                  <div {...provided.dragHandleProps}>
                                    <DragIndicatorIcon />
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      {options.length > 0 && <div className='mb-20'>
        <TextField select variant='outlined' className='w-100' value='select' onChange={handleSelect}
          SelectProps={{ IconComponent: KeyDown, inputProps: { classes: { icon: 'fpl-5  text-black' } }}}
          InputProps={{classes: { root: 'medium', input: 'size_15_500 medium select-medium' }}}>
          <MenuItem key='select' value='select'>Select an option to add to the list above</MenuItem>
          {options.map(({ key, value }) => (<MenuItem key={key} value={key}>{value}</MenuItem>))}
        </TextField>
      </div>}
    </div>
  );
};

const CheckinForm = props => {
  const {
    photosData,
    measurables,
    config,
    onSubmit,
    onCancel,
    disableSpecificDayCheckin = false,
  } = props;
  const classes = useStyles();
  const { cid } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({...CHECKIN, ...(config || {})});
  const [errors, setErrors] = useState({});
  const [selectedDays, setSelectedDays] = useState(() => {
    const out = {...DAYS_MAP};
    const _days = _.get(config, 'specific_day_checkins');
    if (_days && _days.length)
      out[_days[0]] = true;
    return out;
  });
  const {
    photos, measurements,
    checkin_frequency = 7,
    checkin_due_count,
    checkin_overdue_count,
    enable_specific_day_checkins: daysEnabled,
  } = state;

  const valid = () => {
    let ok = true;
    let errors = {};
    if (!checkin_frequency) {
      errors.checkin_frequency = "Please provide valid value";
      ok = false;
    }

    const sum = (checkin_due_count || 0) + (checkin_overdue_count || 0);

    if (sum >= checkin_frequency) {
      enqueueSnackbar('Checkin window should be less than the checkin frequency', { variant: 'error' });
      ok = false;
    }
    const daysSelected = DAYS.filter(d => !!selectedDays[d]);
    if(!!daysEnabled && (!daysSelected || !daysSelected.length)) {
      errors.specific_day_checkins = "Please specify the preferred day for check-in";
      ok = false;
    }
    if(_.isEmpty(photos)) {
      enqueueSnackbar("Please add at least one photo to proceed", { variant: "error" });
      ok = false;
    }
    if(_.isEmpty(measurements)) {
      enqueueSnackbar("Please add at least one measurement to proceed", { variant: "error" });
      ok = false;
    }
    if (measurements && measurements.select) {
      errors.measurements = "Please select the required measurement";
      ok = false;
    }
    setErrors(errors);
    return ok;
  };

  const save = (e) => {
    e.preventDefault();
    if (!valid()) return;
    const specific_day_checkins = [];
    _.each(selectedDays, (v, k) => {
      if (selectedDays.length) return;
      if (v) specific_day_checkins.push(k);
    });
    const out = {...state};
    if (specific_day_checkins.length) out.specific_day_checkins = specific_day_checkins;
    onSubmit(out);
  };

  const handleChange = e => {
    if (!e || !e.target) return;
    const {id, name, checked, value} = e.target;
    let key = name || id;
    if (key) {
      if (key === 'enable_specific_day_checkins')
        setState(s => ({...s, [key]: checked}));
      else
        setState(s => ({...s, [key]: Number(value)}));
    }
  };

  const handleWeek = (e) => {
    let value = e.currentTarget.dataset.value;
    setSelectedDays({[value]: true});
  }
  const handleCompayReset = async (e) => {
    save(e);
    await bffCheckinResetDefault(cid)
  }
  const checkInWindowText = useMemo(() => {
    if (!checkin_due_count && !checkin_overdue_count)
      return 'Clients will be able to check-in only on the scheduled date'
    if (!checkin_due_count)
      return `Clients will be able to check-in from the scheduled date to ${checkin_overdue_count} day${checkin_overdue_count > 1 ? 's' : ''} overdue`
    if (!checkin_overdue_count)
      return `Clients will be able to check-in from ${checkin_due_count} day${checkin_due_count > 1 ? 's' : ''} early to the scheduled date`
    return `Clients will be able to check-in from ${checkin_due_count} day${checkin_due_count > 1 ? 's' : ''} early to ${checkin_overdue_count} day${checkin_due_count > 1 ? 's' : ''} overdue from the scheduled date`;
  }, [checkin_due_count, checkin_overdue_count]);
  
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={onCancel}
      onSave={save}
      title={"Check In Settings"}
      titleFont="h3"
      paperClass="width-600"
    >
      <div className="fmt-30 fmx-20 fmb-20">
        <Typography variant="h6" className="fpb-10 fmt-5">
          Check-In Frequency
        </Typography>
        <FormTextField fullWidth classes={{control: "fmb-10"}}>
          <TextField name="checkin_frequency" select required
            value={checkin_frequency}
            error={!!errors.checkin_frequency}
            helperText={errors.checkin_frequency}
            variant="outlined"
            onChange={handleChange}
            SelectProps={{
              IconComponent: KeyDown,
              inputProps: { classes: { icon: "fpl-5" } }
            }}
            InputProps={{
              classes: {
                root: " medium", input: "size_15_500 medium select-medium"
              }
            }}>
            <MenuItem key="key_7" value={7}>
              Once a week
            </MenuItem>
            <MenuItem key="key_14" value={14}>
              Once every 2 weeks
            </MenuItem>
            {cid === 'aggam' && <MenuItem key="key_35" value={35}>
              Once every 5 weeks
            </MenuItem>}
            <MenuItem key="key_30" value={30}>
              Once a month
            </MenuItem>
            <MenuItem key="key_42" value={42}>
              Once every 6 weeks
            </MenuItem>
          </TextField>
        </FormTextField>
        <Typography className="font_13_500 text-grey">
            Clients' scheduled check-in date will be aligned to this frequency
        </Typography>
        <Typography variant="h6" className="fmb-10 fmt-20">
          Check-In Window
        </Typography>
        <div className='d-flex flex-row align-items-baseline'>
          <NumUnitInput
            fullWidth noFloat
            placeholder="0"
            id="checkin_due_count"
            unit={`day${checkin_due_count > 1 ? 's' : ''} early`}
            onChange={handleChange}
            value={checkin_due_count}
            error={!!errors.checkin_due_count}
            helperText={errors.checkin_due_count}
            variant="outlined"
            required
            InputProps={{
              inputProps: { min: 0, max: checkin_frequency - 1 },
              classes: { root: "medium", input: "size_16_500" }
            }} />
          <Typography className="font_13_500 text-grey px-20">
            to
          </Typography>
          <NumUnitInput
            fullWidth noFloat
            placeholder="0"
            id="checkin_overdue_count"
            unit={`day${checkin_overdue_count > 1 ? 's' : ''} overdue`}
            onChange={handleChange}
            value={checkin_overdue_count}
            error={!!errors.checkin_overdue_count}
            helperText={errors.checkin_overdue_count}
            variant="outlined"
            required
            InputProps={{
              inputProps: { min: 0, max: checkin_frequency - 1 },
              classes: { root: "medium", input: "size_16_500" }
            }}/>
        </div>
        <Typography className="font_13_500 text-grey fmb-8 fmtn-10">
            {checkInWindowText}
        </Typography>
        {!disableSpecificDayCheckin && (<div className="d-flex align-items-center">
          <FormControlLabel name="enable_specific_day_checkins"
            onChange={handleChange}
            checked={daysEnabled || false}
            className="mb-0 fmr-0"
            control={<Checkbox color="primary"/>}
            label={<Typography caption="h6" component="div">
              Enable Specific Day Check-ins</Typography>}/>
        </div>)}
        {!disableSpecificDayCheckin && daysEnabled &&  (
          <div className="fmb-20">
            <div className={classes.root}>
              {weeksList.map(({value, label}) => {
                return(
                  <Chip 
                    onClick={handleWeek} 
                    data-value={value} 
                    key={value} 
                    label={ <Typography caption="h6">{label}</Typography>} 
                    color={selectedDays[value] ? "primary" : "default"}
                    variant={selectedDays[value] ? "default" : "outlined"}
                  />
                )
              })}
            </div>
            <Typography className={classes.checkinsError} caption="caption">
              {errors.specific_day_checkins}
            </Typography>
            <Typography className={classes.description} variant="body2" color="textSecondary">
              You can select a specific day(s) for all your clients to check-in. Based on the start date of a client, they will be mapped to the closest check-in day.
            </Typography>
          </div>
        )}
        <Divider className="fmt-8 fmb-15 fmln-20 fmrn-20" />
        <Typography caption="h6" component="div" className="fpb-10 fmt-5">
          Photos
        </Typography>
        <Photos photosData={photosData} photos={config.photos} poses={config.poses} setState={setState} />
        <Divider className="fmt-8 fmb-15 fmln-20 fmrn-20" />
        <Typography caption="h6" component="div" className="fmy-20">
          Measurements
        </Typography>
        <Measurables
          measurables={measurables}
          errors={errors.measurements}
          state={state} setState={setState} 
        />
        {!props.hideReset &&
          <>
            <Divider className="fmb-20 fmln-20 fmrn-20" />
            <Typography className="font_15_600 fmb-15 text-dark-grey" variant="body1">
              {props.user ? USER_DESC : COMPANY_DESC}
            </Typography>
            <Button variant="outlined" color="primary" className="f-xmedium" onClick={props.user ? props.handleUserCheckinReset : handleCompayReset}>
              {props.user ? 'Reset' : 'Reset For All Clients'}
            </Button>
          </>
        }
      </div>
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  return { showLoader, hideLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinForm);
