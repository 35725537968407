import React, { useCallback, useMemo, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  OutlinedInput,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { FormTextField } from "fitbud/components/form-fields";
import { MediaUploader } from "fitbud/components/media-uploader/mediaUploader";
import AutoSuggest from "fitbud/components/autoSuggest";
import Dialog from "fitbud/components/Dialog";
import Confirmation from "fitbud/components/confirmationDialog";
import { exTypeHelper } from "fitbud/utils/helpers";
import {mapIdToObject, mediaObjExist} from "fitbud/views/workouts/helperfn";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import * as Sentry from '@sentry/browser';
import {TUT_VIDEO_CIDS} from "./exercisesDetail";

const bps2taz = require("./bps2taz");
export const MAIN_VIDEO='media_bgon';
export const TUT_VIDEO='media_tutorial';

const CreateEditForm = (props) => {
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const [dirty, setDirty] = useState(false);
  const {
    doc,
    errors,
    loading,
    isNew,
    onSubmit,
    onCancel,
    sidesData,
    typesData,
    handleMedia,
    muscleGroups,
    equipmentsData,
    handleChange:_handleChange,
    handleChips,
    targetAreasData,
    removeMedia,
    handleVideoFeature,
    handleDraft,
    handlePublish,
    videoStatusRefresh,
    videoUploadError={},
    draftFeature,
    isDraftAvailable,
    updateMediaMeta,
    cid
  } = props;
  const isSecVideoSupported=useMemo(()=>TUT_VIDEO_CIDS.includes(cid),[cid]);
  const [isTutVidAvlbl,setTutVid]=useState(mediaObjExist(doc.media_tutorial));
  const {
    title,
    ref_name,
    description,
    side,
    type,
    thumbnail,
    media,
    media_tutorial,
    category
  } = doc;
  let equipments = mapIdToObject(equipmentsData,doc.equipments);
  let muscleGroupsData = mapIdToObject(muscleGroups,doc.muscle_groups);
  let bodyParts = mapIdToObject(targetAreasData,doc.body_parts,bps2taz);
  //---functions----
  const handleChange=(e)=>{
    setDirty(true);
    const {id,name,value}=e.target;
    const _name=id||name;
    if(_name==='equipments'||_name==='muscle_groups'||_name==='body_parts'){
      const newValues=value.map(val => val.value);
      _handleChange({target:{
        value:newValues,
        name:_name
      }})
    }
    else if(_name==='is_mute_video'||_name==='is_crop_square'){
      _handleChange({target:{
        value:e.target.checked,
        name:_name
      }})
    }
    else{
      _handleChange(e);
    }
  }
  const openConfirmationDialog = () => {
    dirty ? showConfirmation('cancel') : onCancel();
  };
  const handleMediaWrapper = (args) => {
    setDirty(true);
    handleMedia(...args);
  }
  const removeMediaWrapper=(args)=>{
    setDirty(true);
    removeMedia(args);
  }
  const handleSubmit=()=>{
    if(!isNew && !dirty && !isDraftAvailable) {
      onCancel();
      return;
    }
    if(isNew|| !draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }
  const setDuration=(secs,mediaType)=>{
    const value = Math.round(secs);
    updateMediaMeta(mediaType,{duration:value});
  }
  const handleError=(err)=>{
    console.log("onError",err);
    Sentry.captureException(err);
  }
  const whenPlayerReady=useCallback((player)=>{
    try{
      const id=player.props.id===MAIN_VIDEO?MAIN_VIDEO:TUT_VIDEO;
      const videoHeight=player.getInternalPlayer().videoHeight;
      if(!!videoHeight){
        updateMediaMeta(id,{height:`${videoHeight}p`});
      }
    }
    catch(err){
      console.log("onReady err",err);
      Sentry.captureException(err);
    }
    
  },[doc.media,doc.media_tutorial]);
  const CheckTutVideo=(e)=>{
    const v=e.target.checked;
    setTutVid(v);
    if(!v){
      setDirty(true);
      removeMediaWrapper(TUT_VIDEO);
    }
  }
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={openConfirmationDialog}
      onSave={handleSubmit}
      title={isNew ? "Add Exercise " : "Edit Exercise"}
      titleFont="h3"
      actionText={isNew && "Add to My Library"}
      paperClass="width-640"
      maxWidth={"md"}
    >
      {isNew && doc.id && <Alert severity='info'>
        This will create a copy of this exercise in your own library.
      </Alert>}
      <div className="fp-20">
        <div className="d-flex fmb-20">
          <div className="flex-1 d-flex flex-column justify-content-between">
            <FormTextField fullWidth label="Reference Name" required>
              <TextField
                id="ref_name"
                autoComplete="off"
                value={ref_name}
                onChange={handleChange}
                error={!!errors.ref_name}
                helperText={errors.ref_name}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium",
                  },
                }}
              />
            </FormTextField>
            <FormTextField fullWidth label="Display Name" required classes={{control:"mb-0"}}>
              <TextField
                id="title"
                autoComplete="off"
                value={title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
                variant="outlined"
                disabled={loading}
                required
                type="text"
                InputProps={{
                  classes: {
                    root: "medium",
                    input: "size_16_500 medium",
                  },
                }}
              />
            </FormTextField>
          </div>
        </div>
        <FormTextField fullWidth label="Type">
            <Select
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: "medium",
                      input: "size_16_500 select-medium",
                    }}
                    name="category"
                  />
                }
                value={category || ""}
                onChange={handleChange}
                disabled={loading}
                required
                displayEmpty
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {typesData.map((item, i) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormTextField>
        <div>
        </div>
        <div className="d-flex">
          <div className="w-50 fpr-10">
            <FormTextField fullWidth label="Tracking Fields">
              <Select
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: "medium",
                      input: "size_16_500 select-medium",
                    }}
                    name="type"
                  />
                }
                value={type}
                onChange={handleChange}
                disabled={loading}
                required
              >
                {exTypeHelper.types.map(type => (<MenuItem key={type} value={type}>
                  {exTypeHelper.typeToText(type)}</MenuItem>))}
              </Select>
            </FormTextField>
          </div>
          <div className="w-50 fpl-10">
            <FormTextField fullWidth label="Per Side Info">
              <Select
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: "medium",
                      input: "size_16_500 select-medium",
                    }}
                    name="side"
                  />
                }
                value={side || ""}
                onChange={handleChange}
                disabled={loading}
                required
                displayEmpty
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {sidesData.map((item, i) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormTextField>
          </div>
        </div>
         <FormTextField fullWidth label="Equipment">
          <AutoSuggest
            placeholder="Add Equipment …"
            onChange={value=>handleChange({target:{name:"equipments",value:value||[]}})}
            options={equipmentsData}
            value={equipments}
          />
        </FormTextField>
        <FormTextField fullWidth label="Muscle Groups">
          <AutoSuggest
            placeholder="Add muscle groups …"
            onChange={value=>handleChange({target:{name:"muscle_groups",value:value||[]}})}
            options={muscleGroups}
            value={muscleGroupsData}
          />
        </FormTextField>
        <FormTextField fullWidth label="Target Area">
          <AutoSuggest
            placeholder="Add target areas …"
            onChange={value=>handleChange({target:{name:"body_parts",value:value||[]}})}
            options={targetAreasData}
            value={bodyParts}
          />
        </FormTextField>
        <FormTextField fullWidth label="Description">
          <TextField
            multiline
            rows="2"
            rowsMax="10"
            id="description"
            fullWidth
            onChange={handleChange}
            value={description}
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            disabled={loading}
            required
            InputProps={{
              classes: {
                root: "large",
                input: "size_16_500",
              },
            }}
          />
        </FormTextField>
        <Divider className="position-relative fmb-20" style={{left:-20,width:'calc(100% + 40px)'}}/>
        <MediaUploader
          label={"Media"}
          media={media}
          errors={errors}
          handleChange={handleChange}
          handleMedia={(...args) => handleMediaWrapper([...args, MAIN_VIDEO])}
          removeMedia={() => removeMediaWrapper(MAIN_VIDEO)}
          allowGifs={true} 
          doc={doc} 
          featureType="exercise"
          showFeature={true}
          videoStatusRefresh={()=>videoStatusRefresh(MAIN_VIDEO)}
          videoUploadError={videoUploadError[MAIN_VIDEO]}
          playerProps={{
            onDuration:v=>setDuration(v,MAIN_VIDEO),
            onReady: whenPlayerReady,
            onError:handleError,
            id: MAIN_VIDEO
        }}
          withThumbnail
          thumbnail={thumbnail}
          isNew={isNew}
        />
        {!!isSecVideoSupported && <FormControlLabel className='mt-20 fmb-15'
          control={<Checkbox color="primary" checked={isTutVidAvlbl} onClick={CheckTutVideo} />}
          label={<Typography>Upload Tutorial Video</Typography>}/>}
        {isSecVideoSupported && isTutVidAvlbl && <MediaUploader
          label={"Tutorial Video"}
          errors={{mediaUrl:errors['tutMediaUrl']}}
          media={[media_tutorial||{}]}
          handleMedia={(...args) => handleMediaWrapper([...args, TUT_VIDEO])}
          removeMedia={() => removeMediaWrapper(TUT_VIDEO)}
          doc={doc} 
          featureType="exercise"
          showFeature={false}
          videoStatusRefresh={()=>videoStatusRefresh(TUT_VIDEO)}
          videoUploadError={videoUploadError[TUT_VIDEO]}//TEst pending
          playerProps={{
            onDuration: v=>setDuration(v,TUT_VIDEO),
            onReady: whenPlayerReady,
            onError:handleError,
            id:TUT_VIDEO
          }}
          isNew={isNew}
        />}
      </div>
      {isConfirmationOpen && (
        <>
          {(isNew||!draftFeature)?
            <Confirmation
            open
            handleClose={() => showConfirmation(null)}
            handleChange={() => onCancel()}
            handleCancel={() => showConfirmation(null)}
          />:
          <CustomConfirmation
            handleClose={() => showConfirmation(null)}
            handleDiscard={onCancel}
            handleDraft={handleDraft}
            handlePublish={handlePublish}
            dirty={dirty}
            showDiscard={dirty}
          />
          }
        </>
      )}
    </Dialog>
  );
};

export default CreateEditForm;
