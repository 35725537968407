import React from 'react';
import { useLiveSessions } from 'fitbud/providers/liveSessionsProvider';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';

export const JoinButton = ({ session, className }) => {
  const { handleOpenVideoScreen } = useLiveSessions();

  function handleClick(e) {
    e.preventDefault();
    handleOpenVideoScreen(session);
  }
  return (
    <ClrdButton
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleClick}
      className={clsx('f-large flex-grow-1', className)}>
      Join Appointment
    </ClrdButton>
  );
};
