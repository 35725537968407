import React,{useState,useEffect,useContext,useMemo} from 'react';
import { SimpleNumberInput } from 'fitbud/components/numberInput';
import { Typography,InputAdornment } from '@material-ui/core';
import _ from 'lodash';
import {MenuList} from "fitbud/components/menuList";
import Arrow from "@material-ui/icons/ExpandMoreRounded";
import {DUMB_UNITS, roundNumber} from "fitbud/utils/helpers";
import { Conversion } from 'fitbud/providers/conversion';
import { countDecimals } from "fitbud/utils/helpers";
import { GMML_SERVINGS } from 'fitbud/utils/helpers';

export const InputDropdown=({
    name,options=[],unit,value,handleOptionChange,data,
    onValueChange,disabled,...rest})=>{
    const handleChange=(e)=>{
      let value =e.target.value;
      if (countDecimals(value) > 2) return;
      onValueChange(e);
    }
    const _handleOptionChange=(e,v)=>{
      if(v===unit) return;
      handleOptionChange(e,v,value);
    }
    return(
      <SimpleNumberInput
          name={name}
          type="number"
          value={roundNumber(value)}
          onChange={handleChange}
          min={1}
          disabled={disabled}
          autoComplete="off"
          variant="outlined"
          required
          InputProps={{
            classes: {
              root: 'medium pr-2',
              input: 'size_16_600 medium pl-10',
            },
            endAdornment:<Dropdown 
            options={options} 
            handleOptionChange={_handleOptionChange}
            unit={unit}
            disabled={disabled}
            value={value}
            />
          }}
          {...rest}
        />
    )
  }



//-------ONLY USED FOR FOOD FORM-----
export const InputDropdownWConversion=(props)=>{
    const {name,value,unit,options=[],onValueChange,handleOptionChange,disabled,unit_name,...rest}=props;
    const {unitFactors}=useContext(Conversion);
    const [_value,updateValue]=useState();
    useEffect(()=>{
      updateValue(getDisplayValue(unitFactors,value,unit));
    },[unit])
    const handleChange=(e)=>{
        const v=e.target.value;
        updateValue(v);
        const target=_.merge(e.target,{value:getDbValue(unitFactors,v,unit)})
        onValueChange({...e,target});
    }
    const _handleOptionChange=(e,v)=>{
      handleOptionChange(e,v);
    }
    return(
        <SimpleNumberInput
        name={name}
        type="number"
        value={roundNumber(_value)}
        onChange={handleChange}
        min={1}
        disabled={disabled}
        autoComplete="off"
        variant="outlined"
        required
        inputProps={{
          min: 1,
          step: '0.01',
        }}
        InputProps={{
          classes: {
            root: 'medium pr-2',
            input: 'size_16_600 medium pl-10',
          },
          endAdornment:options.length?<Dropdown 
          options={options} 
          handleOptionChange={_handleOptionChange}
          unit={unit}
          disabled={disabled}
          value={value}
          {...rest}
          />:(
            <InputAdornment position="end">
              {_.lowerCase(unit_name)}
            </InputAdornment>
          )
        }}
        {...rest}
      />
    )
}
const Dropdown=({options,handleOptionChange,unit,disabled,value,...rest})=>{
  const [anchorEl,setAnchorEl]=useState(null);
  const mapping = useMemo(() => _.chain(options).keyBy('value').mapValues('label').value(), [options]);
  const openMenu=(e)=>{
    if(!hasMultipleOptions) return;
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }
  const closeMenu=()=>setAnchorEl(null);
  const onSelect=(e,v)=>{
    handleOptionChange(e,v)
    closeMenu();
  }
  const maxWidth=useMemo(()=>{
    if(value){
      if(String(roundNumber(value)).length>5) return "40%";
      return "fit-content";
    }
    return "fit-content";
  },[value])
  
  const hasMultipleOptions=options.length>1;
  return(
    <>
      <Typography onClick={disabled?null:openMenu} className='h-100 d-flex align-items-center cursor-pointer text-nowrap justify-content-end no-select' style={{width:maxWidth}}>
        <span className='text-truncate' >{unit==="" ? "":_.lowerCase(mapping[unit])}</span>
        {hasMultipleOptions && <Arrow/>}
      </Typography>
      {(hasMultipleOptions) && <MenuList
        anchorEl={anchorEl}
        onClose={closeMenu}
        options={options}
        handleClick={onSelect}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        showDividerAtValue={[""]}
      />}
    </>
  )
}

export function getDbValue(unitFactors,v,u){
  //converts unit-value to gm/ml
  switch(u){
    case DUMB_UNITS[0]:return v;
    case DUMB_UNITS[1]:return v;
    case DUMB_UNITS[2]:return (1/unitFactors['meal_weight']['imperial']['conversion'])*v;
    case DUMB_UNITS[3]:return (1/unitFactors['meal_volume']['imperial']['conversion'])*v;
    case DUMB_UNITS[4]:return (1/unitFactors['meal_volume']['imperial_us']['conversion'])*v;
  }
}
export function getDisplayValue(unitFactors,v,u){
  //converts gm/ml to unit-value
  switch(u){
      case DUMB_UNITS[0]:return v;
      case DUMB_UNITS[1]:return v;
      case DUMB_UNITS[2]:return roundNumber(unitFactors['meal_weight']['imperial']['conversion']*v);
      case DUMB_UNITS[3]:return roundNumber(unitFactors['meal_volume']['imperial']['conversion']*v);
      case DUMB_UNITS[4]:return roundNumber(unitFactors['meal_volume']['imperial_us']['conversion']*v);
  }
}
export function getPreferredUnit(doc){
  if(doc.preferred_unit) return doc.preferred_unit;
  return doc.serving_type==="meal_weight"?GMML_SERVINGS[0]:GMML_SERVINGS[1];
}
export function findStandardUnit(serving_type,_sortServing){
  if(serving_type==="meal_weight"){
    return _sortServing.find(i=>i.value==="gm");
  }
  else if(serving_type==="meal_volume"){
    return _sortServing.find(i=>i.value==="ml");
  }
}