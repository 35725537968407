import React, {useContext, useMemo} from "react";
import { makeStyles } from "@material-ui/core/styles";
import CopyAction from "fitbud/components/copyAction";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  IconButton,
  Divider
} from "@material-ui/core";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import DeleteAction from "fitbud/components/deleteAction";
import { commafyNumber, roundMacros, roundNumber } from "fitbud/utils/helpers";
import { RoleContext } from "fitbud/providers/roleProvider";
import {DetailPgStyling} from "fitbud/hooks/useDetailPgStyle";
import {Avatar} from "fitbud/components/avatar";
import DetailEditIcon from "fitbud/icons/detailEdit";
import {GetDetailSecLine} from "fitbud/components/catalog/detailSecondLine";
import clsx from 'clsx';
import _ from 'lodash';
import {DraftBanner} from "fitbud/components/draftBanner";
import {DRAFT} from "fitbud/utils/constants";
import { Conversion } from "fitbud/providers/conversion";
import { secondsToDuration } from "fitbud/components/durationInput";

const useStyles = makeStyles(theme => ({
  stepAvatar: {
    backgroundColor: props => props.isProgress ? "#f14e3f" : theme.palette.primary.main,
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 5
  },
  stepDivider: {
    border: `solid 2px ${theme.palette.background.default}`,
    flex: 1,
    marginBottom: "-5px"
  },
  stepTextStyle: {
    fontWeight: 600,
    fontSize: "11px"
  },
  boxShadowStyle: {
    boxShadow: "none"
  },
  stepsRoot:{
    "& .stepTitle":{
      fontSize: '14px',
      fontWeight: 500,
      paddingBottom: '5px',
    },
    "& .stepValue":{
      fontSize: '16px',
      fontWeight: 600,
    },
  }
}));

const handleV2vals = obj => {
  if (!obj) return false;
  const { duration, durationv2 } = obj;
  let x = duration || 0;
  if (durationv2 || durationv2 === 0)
    x = durationv2;
  return Math.round(x / 60) || false;
};

function Details(props) {
  const { convertor } = useContext(Conversion);
  const { catalogAdmin } = useContext(RoleContext);
  const classes = useStyles(props);
  const {
    doc,
    handleEditMode,
    isPreview,
    copyItem,
    disableActions,
    deleteItem,
    hideHeader,
    draftBannerProps = {},
    showOnlyEdit,
    createNew,
  } = props;
  
  const [ hour, min, sec ] = useMemo(() => {
    const parsedDuration=secondsToDuration(doc.duration || 0);
    return parsedDuration.split(" : ").map(i=>Number(i));
  },[doc.duration]);
  const warmUpVal = handleV2vals(doc.warm_up);
  const coolDnVal = handleV2vals(doc.cool_down);
  const secondLine=GetDetailSecLine('cardio',doc);
  const _description = _.get(doc,"desc",'').trim();
  const _distance = convertor.getDisplayValue("distance", _.get(doc, "distance", 0));
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  const [minTargetHR, maxTargetHR] = doc.target_hr || [];
  const [minHIHR, maxHIHR] = (doc.high_intensity && doc.high_intensity.target_hr) || [];
  const [minSSHR, maxSSHR] = (doc.steady_state && doc.steady_state.target_hr) || [];
  return (
    <DetailPgStyling>
      {!hideHeader && <>
        {draftBannerProps.isDraftAvailable && !isPreview && <DraftBanner  doc={doc}  {...draftBannerProps}/>}
        <Card square elevation={0} className={clsx(!_description && "pb-0")}>
          <CardHeader
            title={doc.title}
            subheader={secondLine}
            avatar={
              <Avatar
                src={doc.thumbnail}
                name={doc.title}
                size="large"
                alt={doc.title || " "}
              />
            }
            action={isPreview ? null :
              <>
                <IconButton onClick={handleEditMode}>
                  <DetailEditIcon />
                </IconButton>
                {(!showOnlyEdit && !isDraft && !createNew) && <CopyAction title="Cardio" onCopy={copyItem} isDisabled={disableActions} />}
                {catalogAdmin && !showOnlyEdit && !isDraft && 
                  <DeleteAction title="Cardio" onDelete={deleteItem} disabled={disableActions} />
                }
              </>
            }
            classes={{ root: _description ? "main-header-root" : "", title: "main-title", subheader: !secondLine && "d-none" }}
          />
          <CardContent>
            {_description && <MoreLessTextBtn text={_description} className="desc" btnClassName="viewMore" />}
          </CardContent>
        </Card>
      </>
      }
       {!hideHeader && doc.desc && <Divider className="dense"/>}
       <Card square elevation={0}>
         <CardContent classes={{ root: classes.stepsRoot }}>
          <div>
            {(!!min || !!sec) && (
              <div className="d-flex">
                <div className="d-flex flex-column justify-content-start align-items-center">
                  <div className={classes.stepAvatar}></div>
                  {(doc.sub_type !== "liss" || (!!_distance || (!!doc.target_hr[0] || !!doc.target_hr[1])) ) && 
                    <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-30">
                  <Typography className="stepTitle">
                    Duration
                  </Typography>
                  <div className="d-flex align-items-baseline">
                    {hour > 0 && <Typography className="stepValue mr-5"> {hour} hr </Typography>}
                    {min > 0 && <Typography className="stepValue mr-5"> {min} min </Typography>}
                    {sec > 0 && (
                      <Typography className="stepValue">
                        {(sec < 10 ? "0" : "") + sec} sec
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!!_distance && doc.sub_type === "liss" && (
              <div className="d-flex">
                <div className="d-flex flex-column justify-content-start align-items-center">
                  <div className={classes.stepAvatar}></div>
                  {(!!minTargetHR || !!maxTargetHR) &&  <div className={`${classes.stepDivider} light-bg-grey`} /> }
                </div>
                <div className="fpl-20 pb-30">
                  <Typography className="stepTitle">
                    Distance
                  </Typography>
                  <div className="d-flex align-items-baseline stepValue">
                    <Typography className="stepValue"> {_distance}   {convertor.getDisplayUnit("distance")} </Typography>
                  </div>
                </div>
              </div>
            )}
            {!!doc.target_cals && Number.isFinite(doc.target_cals) && (
              <div className="d-flex">
                <div className="d-flex flex-column justify-content-start align-items-center">
                  <div className={classes.stepAvatar}></div>
                  {(doc.sub_type !== "liss" || (!!min || !!sec) || !!_distance || (!!doc.target_hr[0] || !!doc.target_hr[1])) && 
                    <div className={`${classes.stepDivider} light-bg-grey`} /> }
                </div>
                <div className="fpl-20 fpb-30">
                  <Typography className="stepTitle">
                    Target Calories
                  </Typography>
                  <Typography className="stepValue">
                    {commafyNumber(roundMacros(doc.target_cals))} cals
                  </Typography>
                </div>
              </div>
            )}
        </div>
        {doc.sub_type === "liss" ? (!!doc.target_hr[0] || !!doc.target_hr[1]) && (
          <div className="d-flex">
            <div className="d-flex flex-column justify-content-start align-items-center">
              <div className={classes.stepAvatar}></div>
            </div>
            <div className="fpl-20 fpb-30">
              <Typography className="stepTitle">
                Heart Rate
              </Typography>
              <Typography className="stepValue">
                {!!minTargetHR && !!maxTargetHR ? minTargetHR + " - " + maxTargetHR :
                  (!!minTargetHR && minTargetHR) || (!!maxTargetHR && maxTargetHR)} bpm
              </Typography>
            </div>
          </div>
        ) : (
            <>
              <div className="d-flex">
                <div className="d-flex flex-column justify-content-start align-items-center">
                  <div className={classes.stepAvatar}></div>
                  <div className={`${classes.stepDivider} light-bg-grey`} />
                </div>
                <div className="fpl-20 fpb-30">
                  <Typography className="stepTitle">
                    Rounds
                  </Typography>
                  <Typography className="stepValue">{doc.rounds}</Typography>
                </div>
              </div>
              {warmUpVal && 
               <div className="d-flex">
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                    <div className={`${classes.stepDivider} light-bg-grey`} />
                  </div>
                <div className="fpl-20 fpb-30">
                  <Typography className="stepTitle">
                    Warm Up
                </Typography>
                <div className="d-flex align-items-baseline">
                    <Typography className="stepValue">
                      {warmUpVal} min
                    </Typography>
                 </div> 
                </div>
             </div>
              }
              <div className="d-flex">
                <div className="d-flex flex-column justify-content-start align-items-center">
                  <div className={classes.stepAvatar}></div>
                  <div className={`${classes.stepDivider} light-bg-grey`} />
                </div>
                <div className="fpl-20 fpb-30">
                  <Typography className="stepTitle">
                    High Intensity
                </Typography>
                  <div className="d-flex align-items-baseline">
                    <Typography className="stepValue">
                      {doc.high_intensity.duration} sec
                    </Typography>
                    {(!!minHIHR || !!maxHIHR) && 
                      <>
                        <Typography className="stepValue">
                          <span className="mx-5">/</span> {minHIHR && maxHIHR ? minHIHR + " - " + maxHIHR : (!!minHIHR && minHIHR) || (!!maxHIHR && maxHIHR)} bpm
                        </Typography>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-column justify-content-start align-items-center">
                  <div className={classes.stepAvatar}></div>
                  {coolDnVal && <div className={`${classes.stepDivider} light-bg-grey`} />}
                </div>
                <div className="fpl-20 fpb-30">
                  <Typography className="stepTitle">
                    Steady State
                </Typography>
                  <div className="d-flex align-items-baseline">
                    <Typography className="stepValue">
                      {doc.steady_state.duration} sec
                    </Typography>
                    {(!!minSSHR || !!maxSSHR) &&
                      <>
                        <Typography className="stepValue">
                          <span className="mx-5"> / </span> {minSSHR && maxSSHR ? minSSHR + " - " + maxSSHR : (!!minSSHR && minSSHR) || (!!maxSSHR && maxSSHR)} bpm
                        </Typography>
                      </>
                    }
                  </div>
                </div>
              </div>
              {coolDnVal && 
               <div className="d-flex">
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={classes.stepAvatar}></div>
                  </div>
                <div className="fpl-20">
                  <Typography className="stepTitle">
                    Cool Down
                </Typography>
                <div className="d-flex align-items-baseline">
                    <Typography className="stepValue">
                      {coolDnVal} min
                    </Typography>
                 </div> 
                </div>
             </div>
              }
            </>
          )}
         </CardContent>
       </Card>
    </DetailPgStyling>
  );
}

export default Details;
