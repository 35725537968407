import React, { useState,useCallback, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  List,
  Typography,
  LinearProgress,
  InputBase,
  IconButton,
  InputAdornment,
  Divider,
} from '@material-ui/core';
import Clear from '@material-ui/icons/CloseRounded';
import { NavIcon } from 'fitbud/partials/appBar';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_ERROR, HUBSPOT_PROPS, NETWORK_ERROR } from 'fitbud/utils/constants';
import { bffUpdateHubspotProp, fetchFDC } from 'fitbud/api';
import useDebounce from 'fitbud/hooks/useDebounce';
import NoMeal from "fitbud/images/catalog/food.png";
import { useSnackbar } from "notistack";
import { DumbList,Placeholder } from 'fitbud/components/catalog';
import { get, find,debounce } from 'lodash';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { roundNumber } from 'fitbud/utils/helpers';
import NoResultFound from "fitbud/components/catalog/NoResultFound";
import clsx from 'clsx';
import searchIcon from 'fitbud/images/searchIcon.svg';

const ListFDCItemsInner = props => {
  const {
    list,
    query,
    loading,
    nextPage,
    onClick,
    fetchMore,
    ...listProps
  } = props;
  const listRef = useRef();
  const { fetchTags } = useContext(TagsContext);


  

  // added layoutEffect to reset scroll to avoid multiple calls
  useLayoutEffect(() => {
    listRef.current.scrollTo(0,0)
  },[query])



  const getServingType = (serving) =>{
    if(!serving) return null;
    const servingData = fetchTags('serving');
    const _serving = find(servingData, (d) => d[0] === serving);
    if(!!_serving)  return _serving[1].value;

  }

  const parseListData = (l) => {
    const _servingType = getServingType(get(l,"serving"));
    const _servingSize = get(l, "serving_size");
    const _servingUnit = get(l, "serving_unit");
    const _calories = get(l, "macros.calories");
    let  _secondLine = "";
    if(_servingType && _servingSize && _servingUnit){
      _secondLine = `1 ${_servingType} • ${roundNumber(_servingSize,2)} ${_servingUnit}`
    }

   if(_secondLine && _calories){
    _secondLine = _secondLine + " • "
   }

   if(_calories){
    _secondLine = _secondLine + `${roundNumber(_calories, 0).toLocaleString()} cals`
   }


    const obj = {
      primaryText: get(l,'title') || get(l,"ref_name") || "",
      secondaryText: _secondLine,
      src: get(l, 'thumbnail'),
    };
    return { ...obj };
  };

  return (
    <>
    {loading && <LinearProgress className='position-absolute w-100' style={{top:0,left:0,zIndex:1}}/>}
      <List id="scrollableDiv" disablePadding className="overflow-auto w-100" ref={listRef}>
        <InfiniteScroll
          dataLength={list.length}
          next={fetchMore}
          hasMore={nextPage}
          scrollableTarget="scrollableDiv"
        >
        <>
          <DumbList
            docs={list}
            parseListData={parseListData}
            onClick={(e,doc)=>onClick(doc)}
            {...listProps}
          />
        </>
        </InfiniteScroll>
      </List>
    </>
  );
};

const styles = makeStyles((t) => ({
  header:{
      borderBottom:'1px solid #D8DCE0',
  },
  input:{
      borderRadius: "20px",
      height: 40,
      border: "1px solid #D3D9DB",
      background:'#fff',
      padding:'0px 10px',
    }
}));

const ListFDCItems = (props) => {
  const classes = styles();
  const { 
    searchOff,
    query:propsQuery="",
    noResultFoundAction, 
    ...restProps 
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [list, updateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, updateNextPage] = useState(null);
  const [q, setQ] = useState(propsQuery);
  //---analytics-----
  useEffect(() => {
    bffUpdateHubspotProp(HUBSPOT_PROPS.FOOD_DB_BROWSED);
  }, []);
  //------xx---------
  useEffect(()=>{
    //query coming as props
    setQ(propsQuery)
  },[propsQuery])
  useEffect(()=>{
    if(!!q) {
      debouncedFetch();
    }
    else{
      updateList([]);
      updateNextPage(null);
    }
    // else fetch();
  }, [q]);
  const handleSearchChange = e => {
    setQ(e.target.value);
  };
  const fetch=async()=>{
    let latestQ;
    setQ(o=>latestQ=o);
    if(!latestQ){return;}
    try{
      setLoading(true);
      const {data} = await fetchFDC(latestQ);
      const { data: result, next } = data;
      if (!!result) {
        updateList(result)
        updateNextPage(next);
        setLoading(false);
      }
    } catch(e) {
      const {message} = e.response || {};
      enqueueSnackbar(message || DEFAULT_ERROR, { variant: "error" });
      setLoading(false);
    }
  }
  const debouncedFetch=useCallback(debounce(fetch,500),[]);
  const fetchMore = async () => {
      try {
        setLoading(true);
        const { data } = await fetchFDC(q, nextPage);
        const { data: result, next } = data;
        const newList = [...list, ...result];
        if (!!result) updateList(newList);
        updateNextPage(next);
        setLoading(false);
      }catch(e) {
        const {success, message} = e.response
        if(!success) enqueueSnackbar(message, { variant: "error" });
        setLoading(false);
      }
    }
  return (
    <div className="flex-1 w-100 d-flex flex-column">
      {!searchOff && <div className={clsx("bg-light-grey fpb-10 fpx-20",classes.header)}>
        <InputBase
            value={q}
            onChange={handleSearchChange}
            fullWidth
            autoFocus
            startAdornment={
                <InputAdornment position="start">
                    <img alt="search-icon" src={searchIcon} />
                </InputAdornment>
            }
            className={classes.input}
          />
          
        </div>
      }
    <div className="position-relative flex-1 d-flex w-100">
      {!loading && !list.length && <Placeholder noResultFoundAction={noResultFoundAction} keyName={'food'} initialSearchLabel="Search for a Food Item in the FitBudd Database" isSearch={!!q} initialSearch={!q} className="w-100"/>}
      <ListFDCItemsInner
        list={list}
        nextPage={nextPage}
        loading={loading}
        fetchMore={fetchMore}
        query={q}
        {...restProps}
      />
      </div>
    </div>
  );
};

export default ListFDCItems;
