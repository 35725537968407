import React, { useContext, useMemo, useState, useEffect } from "react";
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  InputAdornment,
  Menu,
  MenuItem,
  Divider,
  Select,
  OutlinedInput,
  Button
} from "@material-ui/core";
import { Line } from 'rc-progress';
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/CloseRounded";
import clsx from "clsx";
import MediumWorkoutSvg from 'fitbud/images/mediumWorkout.svg';
import SadWorkoutSvg from 'fitbud/images/sadWorkout.svg';
import EasyWorkoutSvg from 'fitbud/images/easyWorkout.svg';
import { ClrdButton } from "fitbud/components/form-fields";
import NumberInput from "fitbud/components/numberInput";
import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import {Conversion} from "fitbud/providers/conversion";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { countDecimals, getActivityUnitText, transform } from "fitbud/utils/helpers";

import firebase from "fitbud/firebase";
import {
  multiplier,
  durationInTypes
} from "fitbud/views/schedulePlanner/helper";
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import momentDate from "moment";
import moment from "moment-timezone";
import {
  oprtnlDateFormat,
  SCHEDULE_MIN_VALID_DAYS,
  SCHEDULE_MAX_VALID_DAYS,
  SCHED_MIN_DAY_ERR,
  SCHED_MAX_DAY_ERR,
  RandomColors,
  MealColors,
  ACTIVITY_NAME_CONSTANT
} from "fitbud/utils/constants";
import{roundNumber} from "fitbud/utils/helpers";
import { hasLength } from "fitbud/utils/helpers";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import CircularLoader from "fitbud/components/CircularLoader";
import TimeIcon from "fitbud/images/time.svg";
import MediumIcon from "fitbud/images/medium.svg";
import LowIcon from "fitbud/images/low.svg";
import HighIcon from "fitbud/images/high.svg";
import TargetIcon from "fitbud/images/target.svg";
import FoodRecipe from "fitbud/views/meals/foodRecipe";
import { LowLevelIcon } from "fitbud/icons/lowLevel";
import { ModerateLevelIcon } from "fitbud/icons/moderateLevel";
import { HighLevelIcon } from "fitbud/icons/highLevel";
import { CopyIcon } from "fitbud/icons/copy2";
import CheckInPlaceHolderImg from "./checkIns/checkinPlaceHolder";
import { useSnackbar } from 'notistack';
import {durationToColonString} from "fitbud/views/workouts/helperfn";
import {durationToHMString} from "fitbud/views/workouts/helperfn";

export const Feedback = ({feedback, feedbackV2, notes, className = '', style = undefined}) => {
  const { comp } = useContext(FirebaseAuthContext);
  const [txt, SVG] = useMemo(() => {
    if (!comp || !comp.exists) return [];
    const data = comp.data();
    const showRPE = data && data.workout_config && (data.workout_config.rpe !== false);
    if (feedbackV2 && showRPE) {
      switch (feedbackV2) {
        case 1: return ["Extremely Light: Barely any effort or movement"];
        case 2: return ["Very Easy: Minimal effort or movement"];
        case 3: return ["Easy: Slight effort, easy and comfortable"];
        case 4: return ["Moderate: Moderate effort, didn’t run out of breath"];
        case 5: return ["Challenging: Noticeable effort, still manageable"];
        case 6: return ["Somewhat Hard: Getting difficult, some reserve remaining"];
        case 7: return ["Hard: Hard, continuously pushing and focusing on effort"];
        case 8: return ["Very Hard: Significant effort, feeling challenged and fatigued"];
        case 9: return ["Extremely Hard: Maximum effort, close to exhaustion"];
        case 10: return ["Maximum Exertion: Complete exhaustion, unable to continue"];
        default: break;
      }
    }
    switch (feedback) {
      case 1: return ['Too Easy', EasyWorkoutSvg];
      case 2: return ['Perfect', MediumWorkoutSvg];
      case 3: return ['Too Hard', SadWorkoutSvg];
      default: return [];
    }
  }, [feedback, feedbackV2, comp]);
  if (!txt) return null;
  if (SVG) {
    return (
      <Tooltip title={notes || txt} classes={{tooltip: 'white-space-preline'}}>
        <img className={className} style={style} alt={txt} src={SVG} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={notes || txt} classes={{tooltip: 'white-space-preline'}}>
        <p className={`rounded border-2 px-1 text-black bg-light ${className}`}>RPE&nbsp;-&nbsp;<span className='text-danger'>{feedbackV2}</span></p>
      </Tooltip>
    );
  }
};
export const getWorkoutIntensityIcon = (intensity) =>{
  if (!intensity) return null;
  return intensity === "low" ? (
    <LowLevelIcon
    className="fmt-10"
    classes={{
        root: "x-small",
      }}
    />
  ) : intensity === "high" ? (
    <HighLevelIcon
      className="fmt-10"
      classes={{
        root: "x-small",
      }}
    />
  ) : (
    <ModerateLevelIcon
    className="fmt-10"
    classes={{
        root: "x-small",
      }}
    />
  );                
  
}


const ratingTrackerStyle = makeStyles(()=>({
  ratingTracker: {
    width: "auto",
    height: "auto",
    borderRadius: "11.2px",
    backgroundColor: "#f8f8f8",
    position:"relative"
  },
  viewMore: {
    backgroundColor: "#f8f8f8 !important",
    marginBottom: "0px !important"
  },
  feedback:{
    position:"absolute",
    fontSize: 14,
    fontWeight: 600,
    top:"-12px",
    right:10
  },
  feedbackText: {
    marginLeft: "20px",
    borderTop: "2px solid rgba(127, 139, 155, 0.2)",
    marginRight: "20px",
    paddingTop: "15px",
    paddingBottom: "20px",
  },
  secondRow : {
    borderTop: "2px solid rgba(127, 139, 155, 0.2)",
  }
}))

const switchButtonStyle = makeStyles(()=>({
  base: {
    width: "auto",
    height: "44px",
    borderRadius: "23.5px",
    backgroundColor: "#f0f0f0"
  },
  root: {
    width: props => props.isStats ? "240px" : "430px",
    height: "48px",
    position: "relative"
  },
  button: {
    width: props => props.isStats ? "120px!important" : "230px!important",
    height: "40px!important",
    borderRadius: "20px!important",
    boxShadow: "0 3px 7px 0 rgba(0, 0, 0, 0.12)!important",
    backgroundColor: "#ffffff!important",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    position: "relative",
    transform: "translate3d(0, 0, 0)",
    "&[aria-checked=true]": {
      transform: props => props.isStats ? "translate3d(115px, 0, 0)" : "translate3d(197px, 0, 0)",
    }
  },
  backgroundText: {
    position: "absolute",
    color: "#8c8c8c",
  },
  leftMargin: {
    marginLeft: props => props.isStats ? "100px" : "200px",
  }
}))

const NutritionTrackerStyle = makeStyles(()=>({
  bottomBorder: {
    width: "500px",
    height: "1px",
    backgroundColor: "#e1e1e1"
  },
  span: {
    fontWeight: "100",
    fontSize: "20px"
  },
  letterSpacing: {
    letterSpacing: "1px"
  },
  flexBasis: {
    flexBasis: "8em"
  },
  fontWeight: {
    fontWeight: "100"
  },
  textAlign: {
    textAlign: "center",
    color: "#70829b"
  }
}))

const ActivitySectionStyle = makeStyles((theme) => ({
  activityError: {
    fontWeight: 400,
    marginBottom: "10px",
    color: "#f44336",
    fontSize: "0.75rem",
    textAlign: "left"
  }
}));

export const Images = ({
  userId,
  identifier,
  aspect,
  img = {},
  className,
  error,
  onError,
  position,
  new_identifier,
  imageStyles={},
  side,
}) => {
  let identity = _.split(identifier, "_")[1];
  if (!error && img && img.image_data)
    return (
      <ImgAspectFill
        completed={img.completed}
        aspect={aspect || 1}
        imageData={img.image_data}
        storageFolderRef={`user_profiles/${userId}/checkins`}
        bucketName="checkins"
        ext="jpg"
        identifier={new_identifier || `front_${identity}%side_${identity}%back_${identity}`}
        urlIdentifier = {identifier}
        className={className + ' cursor-pointer'}
        rounded
        position={position}
        imageStyles={{...imageStyles}}
      />
    );
    return <CheckInPlaceHolderImg side={side} className={className} imageStyles={imageStyles} aspect={aspect || 1} />
};
export function calculateValues({
  value = null,
  unitSystem,
  unitDetails,
  measurableUnits,
  unit,
  showNothingWhenEmpty = false,
  returnValues = false,
  roundNumberPref = 1,
}) {
  if(!value) return;
  unitDetails = unitDetails || {}
  let newVal = Number(value);
  let valInFt, newUnit;
  if (!hasLength(measurableUnits)) return;
  if (newVal !== undefined && unit && unitSystem) {
    let conversionUnit = _.get(measurableUnits[unit],unitDetails[unit] || unitSystem);
    if(!!conversionUnit){
      newVal = newVal * Number(conversionUnit.conversion || 1);
      newUnit =  conversionUnit.unit;
    }
    if (newUnit === "ft") {
      let ft = parseInt(newVal);
      let inch = (newVal % 1) * 12;
      if(!returnValues){
        ft = ft + "'";
        inch = roundNumber(inch, roundNumberPref) + '"';
        valInFt = ft + inch;
      };
      if(!!returnValues) return ({ unit: newUnit, value: [ft, roundNumber(inch, roundNumberPref)] });
    }
  }
  if(!!returnValues) return ({ unit: newUnit, value: roundNumber(newVal, roundNumberPref) });
  return (
    <>
      {newVal
        ? newUnit !== "ft"
          ? roundNumber(newVal, roundNumberPref)
          : valInFt
        : !showNothingWhenEmpty && "-"}
      {newVal && newUnit !== "ft" ? (
        <span className="font_14_500 fml-5">{newUnit}</span>
      ) : (
        undefined
      )}
    </>
  );
}
export const DisplayMeasurements = ({
  label,
  value,
  unitSystem,
  unitDetails,
  measurableUnits,
  unit_type
}) => {
  return (
    <div>
      <Typography variant="body1" color="textSecondary">
        {label}
      </Typography>
      <Typography className="heading fmt-10">
        {calculateValues({
          value,
          unitSystem,
          unitDetails,
          measurableUnits,
          unit: unit_type,
          roundNumberPref: 1,
        }) || "-"}
      </Typography>
    </div>
  );
};

export const RestDay = ({ classes = null, sizeClasses }) => {
  const restCard = {
    border: "solid 1px #99e7ff",
    height: "80px",
    background:
      "repeating-linear-gradient(45deg,#eefbff,#eefbff 10px,#eaeaeb 10px,#eaeaeb 11px)"
  };
  return (
    <div
      style={restCard}
      className={clsx(
        "fmx-20 rounded d-flex justify-content-center align-items-center",
        !!sizeClasses ? sizeClasses : classes.card
      )}
    >
      <Typography variant="body1">Rest</Typography>
    </div>
  );
};

export const DrawerHeaderInScheduleTracker = ({closeDrawer,title, date, daywiseNutrition, docId, btnTitle, btnClick}) => {
  if (docId === 'bmr') return null;
  const parsedDate = momentDate(date);
  return (
    <Toolbar
      className={
        "d-flex justify-content-between align-item-center fpx-20 position-relative"
      }
    >
      <IconButton edge="start" color="inherit" onClick={closeDrawer} className="position-absolute">
        <CloseIcon />
      </IconButton>
      {parsedDate.isValid() && <Typography align="center" variant="h5" className={clsx("flex-grow-1", btnTitle && "text-left fml-40")}>{parsedDate.format("ddd, D MMM")}</Typography>}
      {btnTitle && <Button onClick={btnClick} className="mr-n12" classes={{ label: "font_15_600 text-white" }}>{btnTitle}</Button>}
    </Toolbar>
  )
}

export const DrawerSubHeaderInScheduleTracker = props => {
  const { scheduleDoc, targetAreasData } = props;
  const _duration = useMemo(()=>{
    return durationToHMString(scheduleDoc.duration);
  },[scheduleDoc.duration]);
  return (
    <div className={clsx("fp-0 fm-0 fpb-20")}>
      <div className="d-flex flex-wrap fmt-10 fpb-20">
        <Typography variant="h2" className={"heading"}>{scheduleDoc.title}</Typography>
      </div>
      {scheduleDoc && scheduleDoc.type === "workout" && (
        <div className="d-flex flex-wrap fmt-10">
          <div className="d-flex flex-column fmr-100 fpb-20">
            {!!_duration && (
              <Typography variant="h5"><img className="fmr-10" height="18" width="18" alt="Time" src={TimeIcon} />{_duration}</Typography>
            )}
          </div>
          <div className="d-flex flex-column fmr-100 fpb-20">
            <Typography variant="h5">
              <img className="fmr-10" height="18" width="18" alt="Medium" src={scheduleDoc.intensity === "low" ? LowIcon : scheduleDoc.intensity === "high"?HighIcon :MediumIcon} /> {_.capitalize(scheduleDoc.intensity)}
            </Typography>
          </div>
          {
            !!scheduleDoc.target_area && !!scheduleDoc.target_area.length  && <div className="d-flex flex-column fmr-100 fpb-20">
            <Typography variant="h5">
              <img className="fmr-10" height="18" width="18" alt="Target" src={TargetIcon} />
              {scheduleDoc.target_area.map((option, index) => {
                const target = _.find(targetAreasData, (d) => d.value === option);
                return <span className="fmy-10 ">{target && target.label}{index === scheduleDoc.target_area.length - 1 ? "" : `, `}</span>
              })}
            </Typography>
          </div>
          }
          
        </div>
      )}
      <MoreLessTextBtn text={scheduleDoc.desc} />
    </div>
  )
}

export const heartRateProgressText = (aggregate= {}) =>{
  let heart_rate,
    min_hr = Math.max(0, Number(_.get(aggregate, "min_hr", 0))),
    max_hr = Math.max(0, Number(_.get(aggregate, "max_hr", 0))),
    avg_hr = Math.max(0, Number(_.get(aggregate, "avg_hr", 0)));

  if(!!min_hr && !!max_hr){
    heart_rate = min_hr + "-" + max_hr;
  }else{
    heart_rate = avg_hr || min_hr ||  max_hr || "-" ;
  }
  return heart_rate;
}

export const RatingInScheduleTracker = props => {
  const classes = ratingTrackerStyle();
  const { convertor } = useContext(Conversion);
  const { aggregateDoc, dayWiseData } = props
  let duration = _.get(aggregateDoc,"duration");
   //if old user day wise notes note not available then notes is fetching from aggregateDoc
  let notes = (_.get(dayWiseData, "notes", "") || _.get(aggregateDoc, "notes", ""));
  const distance = convertor.getDisplayValue("distance", _.get(aggregateDoc, "distance", 0));
  const distanceText = distance ? `${distance} ${convertor.getDisplayUnit("distance")}` : '-';
  let heart_rate = heartRateProgressText(aggregateDoc)
  let calories = _.get(aggregateDoc, "cals");
  const speed = distance ? `${roundNumber(distance / (duration / 3600), 1)} ${convertor.getDisplayUnit("distance")}/h` : '-';
  const pace = distance ?  `${durationToColonString(Math.floor(duration / distance))} min/${convertor.getDisplayUnit("distance")}` : '-';
  const isLissCardio = (_.get(dayWiseData, 'meta.type', '') === 'cardio') && (_.get(dayWiseData, 'meta.sub_type', '') === 'liss');
  if(!!calories) calories = Math.round(calories);
  const startTime = !!aggregateDoc && !!aggregateDoc.start_time && momentDate(_.get(aggregateDoc, "start_time.seconds")*1000).format('h:mm a');
  return(
    <>
      <div className="d-flex flex-wrap fmt-20 fpb-10">
        <Typography variant="body2">{startTime ? `${startTime}, ` : null} {!!aggregateDoc && aggregateDoc.start_time ? momentDate(aggregateDoc.start_time.seconds * 1000).format("ddd D MMM") : null}</Typography>
      </div>
      <div className={clsx(classes.ratingTracker)}>
        <Feedback feedback={_.get(aggregateDoc, "feedback")} feedbackV2={_.get(aggregateDoc, "feedbackV2")} notes={_.get(aggregateDoc, "notes")}
          style={{height:29, width:29}} className={classes.feedback}/>
        <div className="d-flex flex-row justify-content-between align-items-center px-10 py-20">
          <div className="flex-1 text-center">
            <Typography color="primary" variant="h1" className="fpb-10">{durationToColonString(duration)}</Typography>
            <Typography variant="caption" className="font_14_500" >Duration</Typography>
          </div>
          <div className="flex-1 text-center">
            <Typography color="primary" variant="h1" className="fpb-10">{calories || '-'} {!!calories && <span className="font_13_500">Cals</span>}</Typography>
            <Typography variant="caption" className="font_14_500">Energy</Typography>
          </div>
          <div className="flex-1 text-center">
            <Typography color="primary" variant="h1" className="fpb-10">{heart_rate || 0}</Typography>
            <Typography variant="caption" className="font_14_500">Heart Rate</Typography>
          </div>
        </div>
        {isLissCardio && !!distance && <div className={clsx("d-flex justify-content-between align-items-center px-10 py-15", classes.secondRow)}>
          <div className="flex-1 text-center">
            <Typography color="primary" variant="h4" className="fpb-10">{distanceText}</Typography>
            <Typography variant="caption" className="font_14_500" >Distance</Typography>
          </div>
          <div className="flex-1 text-center">
            <Typography color="primary" variant="h4" className="fpb-10">{speed}</Typography>
            <Typography variant="caption" className="font_14_500">Speed</Typography>
          </div>
          <div className="flex-1 text-center">
            <Typography color="primary" variant="h4" className="fpb-10">{pace}</Typography>
            <Typography variant="caption" className="font_14_500">Pace</Typography>
          </div>
        </div>}
        {notes && (
          <div className={classes.feedbackText}>
            <Typography variant="caption" className="font_14_500">
              <MoreLessTextBtn text={notes} className="desc" btnClassName={clsx("viewMore", classes.viewMore)} />
            </Typography>
          </div>
        )}
      </div>
    </>
  )
}

export const SwitchbuttonInScheduleTracker = props => {
  const classes = switchButtonStyle(props);
  const { hanldeSwitchChange, title, isStats } = props;
  const [isChecked, setChecked] = useState(false);

  function handleSwitch() {
    hanldeSwitchChange(!isChecked)
    setChecked(!isChecked);
  }
  
  return(
    <div className={isStats ? "fp-0 fm-0" : "fp-0 fm-0 fpt-20 fpb-20"}>
      <div className={clsx(classes.base, "d-flex flex-row justify-content-between align-items-center fpt-20 fpb-20")}>
        <div className={clsx(classes.backgroundText, "d-flex flex-column fpx-60")}>
          <Typography variant="subtitle2">{title[0]}</Typography>
        </div>
        <div className={clsx(classes.backgroundText, classes.leftMargin, "d-flex flex-column fpx-60")}>
          <Typography variant="subtitle2">{title[1]}</Typography>
        </div>
        <label>
            <input className="switch" type="checkbox" onChange={handleSwitch}  />
            <div className={classes.root}>
              <div className={clsx(classes.button)} aria-checked={isChecked}>
                <Typography variant="subtitle2">{!!isChecked ? title[1] : title[0]}</Typography>
              </div>
            </div>
        </label>
      </div>
    </div>
  )
}

export const DrawerSubHeaderInNutritionTracker = ({nutritionDoc, daywiseNutrition}) => {
  const classes = NutritionTrackerStyle();
  const { macros = {} } = nutritionDoc;
  const { calories } = macros;
  const isMealAssigned = !!Object.keys(nutritionDoc).length;
  const renderCalories = () => {
    return (
      <>
        {!!daywiseNutrition
          ? _.round(_.get(daywiseNutrition, "macros.calories"), 2)
          : "0"}{" "}
        <span className={clsx(classes.span)}>
          {!!isMealAssigned && <span>/ {_.round(calories, 2)}</span>} 
          &nbsp;Cal
        </span>
      </>
    );
  };

  const renderMacros = (item) => {
    return (
      <>
        {(daywiseNutrition && daywiseNutrition.macros && daywiseNutrition.macros[item] && _.round(daywiseNutrition.macros[item], 2)) || '0'}{' '}
        <span className={clsx(classes.fontWeight)}>
          {!!isMealAssigned && <span>/ {_.round(macros[item], 2)}</span>} g
        </span>
      </>
    );
  };

  return (
    <div className={"fp-0 fm-0 fpb-20"}>
      <div className="d-flex flex-wrap fmt-10 fpb-20">
        <Typography className={clsx(classes.letterSpacing)} variant="h1">
          {renderCalories()}
        </Typography>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center fpt-10 fpb-10">
        {["protein", "fat", "carbs"].map((item, index) => {
          const calculateMacrosInPercent = (isMealAssigned && daywiseNutrition && daywiseNutrition.macros 
            && daywiseNutrition.macros[item] && (daywiseNutrition.macros[item]/macros[item])*100) || 0;
          return (
            <div className={clsx(classes.flexBasis, "d-flex flex-column")}>
              <Typography
                variant="h6"
                className={clsx(classes.fontWeight, "fpb-10")}
              >
                {_.capitalize(item)}
              </Typography>
              <Line
                percent={calculateMacrosInPercent}
                strokeWidth={!!isMealAssigned ? "6":"0.5"} //To hide circle when meal not assign
                trailWidth="2"
                trailColor="#e2e7ee"
                strokeColor="#ffffff"
              />
              <Typography variant="h6" className="fpt-10">
                {renderMacros(item)}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const NutritionTracker = props => {
  const { isUser, daywiseNutrition, mealCategories = [], servingData, meal, index } = props;
  const classes = NutritionTrackerStyle();
  const [foodRecipes, setFoodRecipes] = useState({});
  const [loader, setLoader] = useState(true);
  const mealData = useMemo(() => _.find(meal.groups, ['type', mealCategories[0]]), [meal.groups, mealCategories]);
  const dayMeal = useMemo(() => _.find(
    !!daywiseNutrition && daywiseNutrition.groups,
    ['type', mealCategories[0]]),
    [daywiseNutrition, mealCategories]
  );
  const isMealAssigned = useMemo(() => !!Object.keys(meal).length, [meal]);

  useEffect(() => {
    let foodItems = {};
    dayMeal &&
      dayMeal.food_items.forEach((item) => {
        item.ref.get().then((data) => {
          foodItems[data.id]={
            ...data.data(),
          }
          setFoodRecipes({...foodItems});
        });
      });
    setInterval(function () {
      setLoader(false);
    }, 1000);
  }, [dayMeal]);

  if(!loader && !_.get(dayMeal, 'food_items')) return null;
  return (
    <div>
      {loader && index === 0 && <CircularLoader />}
      {!loader && (
        <>
          <Toolbar
            className="fpl-30 fpr-20 d-flex justify-content-between align-items-center fpt-20 fpb-20"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <div className="d-flex flex-column">
              <Typography variant="body1">
                {mealCategories && mealCategories[1].value}
              </Typography>
            </div>
            <div className="d-flex flex-column">
              <Typography variant="h6">
                {!!dayMeal ? Math.round(_.get(dayMeal, "calories",0)) : "0"}{" "}
                <span className={clsx(classes.fontWeight)}>
                  {" "}
                  {!!isMealAssigned && mealData && <span>
                    / {!!mealData ? Math.round(_.get(mealData, "calories",0)) : ""}
                    </span>}
                  &nbsp;Cal
                </span>
              </Typography>
            </div>
          </Toolbar>
          <div className="fpl-30 fpr-20 fpt-20">
            <FoodRecipesGroup
              classes={classes}
              servingData={servingData}
              foodRecipes={foodRecipes}
              foodItems={dayMeal && dayMeal.food_items}
              isUser={isUser}
            />
          </div>
        </>
      )}
    </div>
  );
}

export const FoodRecipesGroup = (props) => {
  const { foodItems, classes, foodRecipes, isUser } = props;
  return (
    <>
      {foodItems && foodItems.length > 0 ? (
        foodItems.map((food, index) => {
          if(!_.get(food,'ref.id',null)) return null;
          let foodRecipeData = foodRecipes[food.ref.id] || {};
          foodRecipeData.macros = { ...food.macros };
          foodRecipeData.selected_type = food.selected_type;
          foodRecipeData.serving = food.serving;
          foodRecipeData.serving_id = food.serving_id;
          foodRecipeData.serving_size = food.serving_size;
          foodRecipeData.quantity = food.quantity;
          let calories = _.get(food, "macros.calories")
          return (
            <>
            <FoodRecipe
              meal={{ quantity: food.quantity }}
              foodRecipeData={foodRecipeData}
              calories={calories}
              isUser={isUser}
            />
            {index !== foodItems.length - 1 && <Divider className="fmx-20 fmb-20" />}  
            </>
          );
        })
      ) : (
        <div className={clsx(classes.textAlign, "fpt-35 fpb-35")}>
          <Typography variant="body1">Food not added yet</Typography>
        </div>
      )}
    </>
  );

  // return(
  //   <>
  //     {foodItems && foodItems.length > 0 ? (
  //       foodItems.map((food, index) => {
  //         let foodRecipeData= foodRecipes[index];
  //         foodRecipeData.macros={...food.macros};
  //         return <foodRecipe meal={{quantity:1}} foodRecipeData={foodRecipeData} />
  //        )
  //       })
  //     ): (
  //       <div className={clsx(classes.textAlign, "fpt-35 fpb-35")}>
  //         <Typography variant="body1">Food not added yet</Typography>
  //       </div>
  //     )}
  //   </>
  // )
};

//----NOTE: DEPRECATED: Use DialogAppBar instead.--------
export const DrawerHeaderInScheduleEditor = ({ closeDrawer, title }) => {
  return (
    <AppBar
      position="sticky"
      classes={{
        root: "height-70"
      }}
    >
      <Toolbar
        className={
          "d-flex flex-row justify-content-between align-items-center fpx-20"
        }
      >
        <Typography variant="h3" className="text-capitalize">{title}</Typography>
        <IconButton edge="end" color="inherit" onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
export const fetchTags = async () => {
  const snapshot = await firebase
    .firestore()
    .collection("tags")
    .doc("measurables")
    .get();

  return await snapshot.data();
};

export const ScheduleActivitySwitchSection = (props) => {
  const {
    doc,
    switchRecord,
    selectedWeek,
    toggleFn,
    className, 
    disableComponent = false,
    handleActivityAdd
  } = props;
  const classes = ActivitySectionStyle()
  const {convertor} = useContext(Conversion);
  const [value, setValue] = useState({});
  let distance_unit = convertor.getDisplayUnit("distance");
  useEffect(()=>{
    let activity_volume = _.get(doc, `week${selectedWeek + 1}.value`, {});
    const distance_volume = convertor.getDisplayValue("distance",activity_volume.distance)
    setValue({...activity_volume, ...{"distance": distance_volume}});
  },[doc, selectedWeek]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) =>{
    let chng;
    chng = { [e.target.id || e.target.name]: (Number(e.target.value) || 0)};
    setValue({...value, ...chng});
  }

  const handleOnBlur = () =>{
    const distanceValue = value.distance
    const distance = convertor.getDBValue("distance", distanceValue) || 0;
    let _v = {...value, distance}
    let e = {
      target: {value : _v }
    }
    handleActivityAdd(e, selectedWeek);
  }
  const error = _.get((doc || {}), `week${selectedWeek + 1}.error`, null);
  return(
    <section className={className}>
      <div className="d-flex align-items-center fmb-10">
        <Switch
          color="primary"
          checked={switchRecord[`week${selectedWeek + 1}`]}
          onChange={(e) => toggleFn({ e, week: selectedWeek })}
          className={disableComponent && "d-none"}
        />
        <Typography className={`font_18_500 text-capitalize ${disableComponent && "d-none"}`}>Activity</Typography>
      </div>
      <Typography className={classes.activityError} caption="caption">
        {error}
      </Typography>
      {switchRecord[`week${selectedWeek + 1}`] && (
        ACTIVITY_NAME_CONSTANT.map((item, index) => (
          <NumberInput
            key={index}
            name={item}
            error={error}
            className="w-100"
            disabled={disableComponent}
            onChange = {handleChange}
            onBlur ={handleOnBlur}
            decimals={item === "distance" ? 2 : 0}
            value={value[item] || 0}
            variant="outlined"
            InputProps={{
              classes: { input: "size_16_500 medium" },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography className="text-right" color="textSecondary" variant="body1" style={{ width: 120 }}>
                  {getActivityUnitText(item, distance_unit)}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        ))
      )}
    </section>
  )
}

export const ScheduleWaterSwitchSection = (props) =>{
  const {
    doc,
    switchRecord,
    selectedWeek,
    toggleFn,
    className,
    handleTextAdd,
    disableComponent = false,
  } = props;
  const {convertor} = useContext(Conversion);
  const [value, setValue] = useState(0);
  let water_unit = convertor.getDisplayUnit("water");
  useEffect(()=>{
    let water_volume = _.get(doc, `week${selectedWeek + 1}.value`, 0);
    water_volume = convertor.getDisplayValue("water",water_volume )
    if(water_unit === "ml") water_volume = Math.round(water_volume)
    setValue(water_volume);
  },[doc, selectedWeek]) // eslint-disable-line react-hooks/exhaustive-deps

  const error = _.get((doc || {}), `week${selectedWeek + 1}.error`, null);
  const handleChange = (e) =>{
    let value = e.target.value;
    if (countDecimals(value) > 2) return;
    setValue(value);
  }
   
  const handleOnBlur = () =>{
    let _v = convertor.getDBValue("water", value) || 0;
    let e = {
      target: {value : _v }
    }
    handleTextAdd(e, selectedWeek);
  } 
  
  return (
    <section className={className}>
      <div className="d-flex align-items-center fmb-20 ">
        {Object.keys(switchRecord).length && (
          <Switch
            color="primary"
            checked={switchRecord[`week${selectedWeek + 1}`]}
            onChange={(e) => toggleFn({ e, week: selectedWeek })}
            className={disableComponent && "d-none"}
          />
        )}
        <Typography className="font_18_500 text-capitalize">Water</Typography>
      </div>
      {switchRecord[`week${selectedWeek + 1}`] && (
          <NumberInput
            error={error}
            key={`selected_week_${selectedWeek}`}
            helperText={error}
            className="w-100"
            disabled={disableComponent}
            onChange = {handleChange}
            onBlur ={handleOnBlur}
            decimals={water_unit === 'ml' ? 0 : 1}
            value={value}
            variant="outlined"
            InputProps={{
              classes: { input: "size_16_500 medium" },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography className="text-right" color="textSecondary" variant="body1" style={{ width: 120 }}>
                    {`${water_unit || "ml"}/day`}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
      )}
    </section>
  );
}



export const ScheduleSwitchSections = ({
  MealPicker,
  doc,
  fbUser,
  switchRecord,
  selectedWeek,
  toggleFn,
  handleFrequency,
  toggleAdd,
  handleOptions,
  className,
  picker = true,
  moduleName,
  textValue,
  handleTextAdd,
  disableComponent = false,
  showToggle = true,
  handleCopy
}) => {
  const canBMR = fbUser && fbUser.bmr && fbUser.bmr.canBMR;
  const bmrEnabled = fbUser && fbUser.bmr && fbUser.bmr.bmrEnabled;
  const entity = picker
    ? moduleName === "nutrition"
      ? _.get(doc[`week${selectedWeek + 1}`], "meals")
      : _.get(doc[`week${selectedWeek + 1}`], "supplement")
    : null;
  const frequency = _.get(((!!picker && doc) || {}), `week${selectedWeek + 1}.frequency`, null);

  useEffect(() => { // when a week changes see if we can make it BMR
    if (moduleName !== 'nutrition' || !bmrEnabled || frequency === 'bmr') return;
    if (frequency === 'per_day') return;
    let meals = _.get(doc, `week${selectedWeek + 1}.meals`, []);
    if (!meals.length || !meals[0])
      toggleFn({ e: {target: {checked: true}}, week: selectedWeek, fakeBMR: true });
  }, [selectedWeek]); // eslint-disable-line react-hooks/exhaustive-deps

  const error = _.get((doc || {}), `week${selectedWeek + 1}.error`, null);
  let card_entity = moduleName === "nutrition" ? "Meal" : "Supplement";
  const switchedChecked = bmrEnabled || switchRecord[`week${selectedWeek + 1}`];

  const isCopyDisabled = () =>{
    if(moduleName === "nutrition") {
      const weekKey = `week${selectedWeek + 1}`;
      const mealType = _.get(doc[weekKey], "frequency", "");
      if (mealType === 'bmr') return true;
      let meals = _.get(doc[weekKey], "meals", []);
      meals = meals.filter(Boolean);
      if(mealType === "on_off_day" && meals.length !== 2){
        return true;
      } else if (mealType === "single" && meals.length !== 1){
       return true;
      }
    }
    return false;
  }

  return (
    <section className={className}>
      <div className="d-flex align-items-center fmb-20 ">
        {Object.keys(switchRecord).length && showToggle && (
          <Switch disabled={bmrEnabled}
            color="primary"
            checked={switchedChecked}
            onChange={e => toggleFn({ e, week: selectedWeek })}
            className={disableComponent && "d-none"}
          />
        )}
        <Typography className="font_18_500 text-capitalize">
          {moduleName}
        </Typography> 
        {moduleName === "nutrition" && switchedChecked &&  !isCopyDisabled() &&
         <>
           <div className="flex-grow-1" />
           <Tooltip title="Copy Nutrition">
           <IconButton  onClick={()=>handleCopy(selectedWeek)}>
             <CopyIcon />
           </IconButton>
           </Tooltip>
         </>
        }
      </div>
      {picker && (
        <>
          {switchedChecked && (
            <div className="fpx-20 fpt-25 fpb-20 border">
              <Select
                fullWidth
                IconComponent={KeyDown}
                className={clsx(
                  "flex-row justify-content-between fmb-25 ",
                  disableComponent ? "d-none" : "d-flex"
                )}
                style={{textAlign: "left"}}
                input={
                  <OutlinedInput
                    classes={{
                      root: "medium",
                      input: "size_16_500 select-medium"
                    }}
                  />
                }
                name="frequency"
                value={frequency}
                onChange={handleFrequency}
                required
              >
                <MenuItem key="single" value="single">
                  Single
                </MenuItem>
                <MenuItem key="on_off_day" value="on_off_day">
                  ON/OFF Day
                </MenuItem>
                {card_entity === "Meal" && 
                  <MenuItem key="per_day" value="per_day">
                    Per Day
                  </MenuItem>
                }
                {canBMR && <MenuItem
                  value="bmr"
                  key ="bmr"
                >
                  BMR Based
                </MenuItem> }
                
              </Select>
              {(() => {
                if (frequency === "on_off_day") {
                  return (
                    <MealPicker
                      onClick={toggleAdd}
                      selectedWeek={selectedWeek}
                      iteration={2}
                      entity={entity}
                      moduleName={moduleName}
                      error={error}
                      handleOptions={handleOptions}
                      disabled={disableComponent}
                    />
                  );
                } else if(frequency === "single" || frequency === "bmr") {
                  return(
                    <MealPicker
                      bmr={frequency === "bmr" ? fbUser.bmr : null}
                      onClick={toggleAdd}
                      selectedWeek={selectedWeek}
                      iteration={1}
                      error={error}
                      entity={entity}
                      moduleName={moduleName}
                      handleOptions={handleOptions}
                      disabled={disableComponent}
                    />
                  );
                }
              })()}
            </div>
          )}
          {!switchRecord[`week${selectedWeek + 1}`] && disableComponent && (
            <div className="fpx-20 fpt-25 fpb-20 border">
              <div
                className="bg-light-grey text-black d-flex align-items-center justify-content-center border fpy-15 rounded"
                style={{
                  maxWidth: "320px",
                  minHeight: "80px"
                }}
              >
                No {card_entity} selected
              </div>
            </div>
          )}
        </>
      ) }
    </section>
  );
};
export const DurationDropdown = ({
  duration,
  durationType,
  handleChange,
  handleClickAway = null,
  style,
}) => {
  let durationGridStyles = {
    display: "grid",
    gridTemplateColumns: "calc((100% - 10px)/2.5) auto",
    gridColumnGap: "10px",
    alignItems: "center"
  };
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [params, handleParams] = React.useState({
    duration: duration,
    durationType: durationType
  });

  function handleClick(e) {
    if (handleClickAway) handleClickAway();
    setAnchorEl(e.currentTarget);
    handleParams({
      duration,
      durationType
    });
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function changeDuration(e) {
    let name = e.target.id || e.target.name;
    let value = e.target.value;
    if (name === "duration") {
      value = parseInt(value);
    }
    let currentState = params;
    currentState[name] = value;
    handleParams({ ...currentState });
  }
  function validate() {
    const { duration } = params;
    let durationInDays = params.duration * multiplier(params.durationType);
    if (durationInDays < SCHEDULE_MIN_VALID_DAYS) {
      enqueueSnackbar(SCHED_MIN_DAY_ERR(), { variant: "error" });
      return false;
    } else if (durationInDays > SCHEDULE_MAX_VALID_DAYS) {
      enqueueSnackbar(SCHED_MAX_DAY_ERR(), { variant: "error" });
      return false;
    }
    if (duration < 1) {
      enqueueSnackbar("Minimum 1 day must be selected.", {
        variant: "error"
      });
      return false;
    }
    return true;
  }
  function saveChanges() {
    if (!validate()) return;
    handleChange(null, {
      ...params,
      duration: params.duration * multiplier(params.durationType)
    });
    handleClose();
  }
  return (
    <React.Fragment>
      <ClrdButton
        className={`f-h40w160 d-flex bg-white justify-content-between align-items-center fpx-15`}
        onClick={handleClick}
        style={{ border: '1px solid #D3D9DB' }}
        disableRipple
      >
        <Typography className="font_16_500">
          <span className="fmr-5 font_16_700">{duration}</span>
          {transform(durationType,{ capitalize: false, pluralize: duration })}
        </Typography>
        <KeyDown className={`fp-0`} />
      </ClrdButton>
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: `fb-border small`,
          list: "fp-0"
        }}
      >
        <div className="fp-20">
          <Typography color="textSecondary" className="fmb-10">
            Schedule Duration
          </Typography>
          <div className={`fmb-10 `} style={durationGridStyles}>
            <TextField
              // placeholder="Schedule Duration"
              value={params.duration}
              autoComplete="off"
              variant="outlined"
              onChange={changeDuration}
              name="duration"
              id="duration"
              type="number"
              InputProps={{
                classes: {
                  root: "small",
                  input: "size_14_600 small"
                }
              }}
            />
            <TextField
              name="durationType"
              id="durationType"
              select
              onChange={changeDuration}
              value={params.durationType}
              variant="outlined"
              required
              SelectProps={{
                IconComponent: KeyDown,
                // inputProps: {
                //   classes: {
                //     icon: "text-black"
                //   }
                // }
              }}
              InputProps={{
                classes: {
                  root: "small",
                  input: "size_14_600 small select-small"
                }
              }}
            >
              <MenuItem value="months"> Months</MenuItem>
              <MenuItem value="weeks"> Weeks</MenuItem>
              <MenuItem value="days">Days</MenuItem>
            </TextField>
          </div>
          <ClrdButton
            className="w-100 f-xlarge"
            color="main"
            onClick={saveChanges}
          >
            Apply
          </ClrdButton>
        </div>
      </Menu>
    </React.Fragment>
  );
};

export const ScheduleHeader = ({
  editMode,
  ref_name,
  handleClickAway = null,
  handleChange,
  loading = false,
  duration,
  durationType,
  enqueueSnackbar,
  moduleName
}) => {
  return (
    <div className={editMode ? "d-flex justify-content-center" : "w-75"}>
      {editMode ? (
        <React.Fragment>
          <TextField
            style={{ minWidth: "300px" }}
            className="fmr-10"
            placeholder="Schedule Name"
            autoComplete="off"
            defaultValue={ref_name}
            onClick={!!handleClickAway ? handleClickAway : null}
            onBlur={handleChange}
            variant="outlined"
            name="ref_name"
            disabled={!!loading}
            required
            type="text"
            InputProps={{
              classes: {
                root: "medium",
                input: "bg-white size_16_600 medium"
              }
            }}
          />
          <DurationDropdown
            handleChange={handleChange}
            duration={durationInTypes(duration, durationType)}
            durationType={durationType}
            enqueueSnackbar={enqueueSnackbar}
            handleClickAway={handleClickAway}
            moduleName={moduleName}
          />
        </React.Fragment>
      ) : (
        <Typography className={!editMode ? "text-truncate" : undefined}>
          {_.upperFirst(ref_name)}
        </Typography>
      )}
    </div>
  );
};
export const woAggregate = ({ isOld, woAggregate = null, dayId, woid }) => {
  let woAggregateVal = !!woAggregate
    ? !woAggregate[`${dayId}:${woid}`]
      ? isOld
        ? "- / "
        : ""
      : "duration" in woAggregate[`${dayId}:${woid}`]
      ? convertWoAggregateUnits(woAggregate,dayId,woid)
      : "-"
    : null;
  return woAggregateVal;
};

export const convertWoAggregateUnits = ( woAggregate, dayId, woid ) => {
  const durationInSeconds = parseInt(woAggregate[`${dayId}:${woid}`].duration)
  const duration = {
              m: parseInt(durationInSeconds / 60),
              s: parseInt(durationInSeconds % 60),
            };
  const mins= duration.m === 0? "":duration.m + " m";
  const comma =duration.m !== 0 && duration.s? ", ":""
  const sec =  duration.s ? `${duration.s} s` : "";
  const woAggregateVal = mins+ comma + sec + " / "; 
  return woAggregateVal;
};


export const dateAtWeekStartFn = ({
  startDate,
  week,
  dateFormat = "DD MMM, YYYY"
}) => {
  if (!startDate || week < 0) return null;
  return moment(startDate)
    .add(week * 7, "days")
    .format(dateFormat);
};
export const dateAtWeekEndFn = ({
  startDate,
  week,
  dateFormat = "DD MMM, YYYY",
  durationInDays
}) => {
  if (!startDate || week < 0) return null;
  let no_of_weeks = durationInDays / 7;
  if (week <= no_of_weeks) {
    return moment(startDate)
      .add((week * 7) - 1, "days")
      .format(dateFormat);
  } else {
    return moment(startDate)
      .add(durationInDays - 1, "days")
      .format(dateFormat);
  }
};
export const dayDate = ({
  dateofWeekStart = null,
  startDate,
  week,
  dateFormat = "DD MMM, YYYY",
  day
}) => {
  let weekStartDate =
    dateofWeekStart || dateAtWeekStartFn({ startDate, week, dateFormat });
  return moment(weekStartDate, dateFormat).add(day, "days");
};
export const isOld = (date, time_zone) => {
  if (!time_zone) return;
  let dateInFormat = moment(date, oprtnlDateFormat, time_zone)
    .startOf("day")
    .format(oprtnlDateFormat);
  let current_time = moment
    .tz(time_zone)
    .startOf("day")
    .format(oprtnlDateFormat);
  return moment(dateInFormat).isBefore(current_time);
};
export const isOldIncludesSameDate = (date, time_zone) => {
  if (!time_zone) return;
  let dateInFormat = moment(date, oprtnlDateFormat, time_zone)
    .startOf("day")
    .format(oprtnlDateFormat);
  let current_time = moment
    .tz(time_zone)
    .startOf("day")
    .format(oprtnlDateFormat);
  return moment(dateInFormat).isSameOrBefore(current_time);
};
export const getStatus = ({ userDoc, planDoc }) => {
  if (Object.keys(userDoc).length) {
    if (!userDoc.onboarding_complete) {
      return {
        msg: "Pending Questionnaire",
        step: 1
      };
    } else {
      if (((userDoc && userDoc["current_plan_status"] === "expired") ||
        (userDoc.aplan && planDoc && planDoc["plan_state"] === "expired")
      )) {
        return {
          msg: "Plan Expired",
          step: 4
        };
      } else if (
        !userDoc.aplan ||
        (userDoc.aplan && planDoc && (!planDoc["plan_state"] || (planDoc["plan_state"] === "inactive")))
      ) {
        return {
          msg: "Pending Activation",
          step: 2
        };
      } else if (
        userDoc.aplan &&
        planDoc &&
        planDoc["plan_state"] === "activated"
      ) {
        return {
          msg: "Active",
          step: 3
        };
      }
    }
  }
  return "";
};
export const checkEntityExistence = (obj, moduleName = "nutrition") => {
  let entity = moduleName === "nutrition" ? "meal" : "supplement";
  if (!obj || !hasLength(obj)) return false;
  if (obj["frequency"] === "single") return !!obj[entity];
  else if (obj["frequency"] === "on_off_day")
    return !!(obj[`off_day_${entity}`] && obj[`on_day_${entity}`]);
};
export function findTimeSpan({ startDate, dateToday, duration, dateDiff }) {
  let isInFuture = moment(startDate).isAfter(dateToday);
  if (!!isInFuture)
    return {
      timeSpan: "future",
      week: 0
    };
  else {
    let endDate = moment(startDate).add(duration - 1, "days");
    if (endDate.isBefore(dateToday, "days"))
      return {
        timeSpan: "expired",
        week: duration % 7 === 0 ? Math.floor(duration / 7) - 1 : Math.floor(duration / 7),
      };
    else
      return {
        timeSpan: "present",
        week: parseInt(dateDiff / 7)
      };
  }
}
export const orderScheduleWeeks = (weekOrder, weekDoc) => {
  if (!weekOrder) return {};
  let weeks = weekOrder.map(i => {
    let week = weekDoc.docs.find(j => j.id === i);
    if (!!week) {
      return { id: week.id, ...week.data() };
    } else return null;
  });
  weeks = weeks.filter(Boolean);
  return weeks;
};
const MLCLRSV2 = [
  [0x05, 0xB7, 0x15],
  [0x43, 0xA0, 0x47],
  [0x33, 0x69, 0x1E],
  [0x38, 0x8E, 0x3C],
  [0x68, 0x9F, 0x38],
];
const WOCLRSV2 = [
  [0xFF, 0x52, 0x52],
  [0xFF, 0x40, 0x81],
  [0xE0, 0x40, 0xFB],
  [0x7C, 0x4D, 0xFF],
  [0x53, 0x6D, 0xFE],
  [0x44, 0x8A, 0xFF],
  [0xD5, 0x00, 0x00],
  [0xC5, 0x11, 0x62],
  [0xAA, 0x00, 0xFF],
  [0x62, 0x00, 0xEA],
  [0x29, 0x62, 0xFF],
  /* [0x31, 0x1B, 0x92],
  [0x62, 0x00, 0xEA],
  [0x1A, 0x23, 0x7E],
  [0x30, 0x4F, 0xFE],
  [0x0D, 0x47, 0xA1],
  [0x29, 0x62, 0xFF],*/
];
export const cardClrCSS = (id, meal = false) => {
  let clr1 = [0x00, 0x00, 0x00];
  let clr2 = [0xE5, 0xE5, 0xE5];
  const SRC = meal ? MLCLRSV2 : WOCLRSV2;
  const X = SRC.length;
  if (id && X) {
    const hash = id.replace(/\W+/g, ' ').split(' ').map(x => x.charCodeAt(0)).reduce((h, c) => (c + ((h << 5) - h)), 0);
    clr1 = SRC[Math.abs(hash) % X];
    clr2 = clr1.map(x => (0xE5 + 0.1 * x)); // 10% opacity over a white BG (0.9 * 0xFF = 0xE5)
  }
  return {
    backgroundColor: `rgb(${clr2[0]},${clr2[1]},${clr2[2]})`,
    borderLeft: `4px solid rgb(${clr1[0]},${clr1[1]},${clr1[2]})`,
  };
};
export function genHash(str) {
  if(!str) return ;
  str = str.slice(-5);
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return RandomColors[hash % RandomColors.length];
}
export function genMlHash(str) {
  if(!str) return ;
  str = str.slice(-5);
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return MealColors[hash % MealColors.length];
}
