import React from "react";
import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";

const CSS =
  " shadow-sm justify-content-center align-items-center position-fixed";

const useStyles = makeStyles(theme => ({
  div: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    right: "10px",
    color: "#ffffff",
    zIndex: "11",
    bottom: "100px",
    cursor: "pointer",
    backgroundColor: "#4089f7",
    transform: "scaleX(1) scaleY(1)"
  },
  icon: {
    width: "40px",
    height: "40px"
  }
}));
const ScrollToBottom = ({ unread = 0, show = false, id="chatArea" }) => {
  const classes = useStyles();
  const chatarea = document.getElementById(id);

  const scroll = () => {
    chatarea.scrollTo({ top: (0, chatarea.scrollHeight), behavior: "smooth" });
  };
  return (
    <div
      className={`${CSS} ${classes.div} ${!!show ? "d-flex" : "d-none"}`}
      onClick={scroll}
    >
      <Badge
        badgeContent={unread}
        color="secondary"
        children={""}
        invisible={!unread}
      >
        <KeyDown className={classes.icon} />
      </Badge>
    </div>
  );
};
export default ScrollToBottom;
