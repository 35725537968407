import React, { useState } from 'react';
import Dialog from 'fitbud/components/Dialog';
import SchList from 'fitbud/views/schedulePlanner/list';
import SelectorCatalogList from 'fitbud/components/catalog/selectorCatalogList';
import ArrowIcon from '@material-ui/icons/ArrowForward';


const ScheduleSelector = (props) => {
  const { importType, handleAdd, onClose, mealDoc, selectedMeal, open, ...other } = props;
  function save() {}
  function handleClose() {
    onClose();
  }

  const onSelect = (doc) => {
    handleAdd([doc]);
  };

  return (
    <Dialog
      open={open}
      {...other}
      title={`Select Schedule `}
      buttonColor="main"
      paperClass="width-600 height-70"
      toolbarClass="height-60"
      titleFont="h3"
      fullWidth
      onSave={save}
      onClose={handleClose}
      hideHeaderDivider={true}
      dialogContentClassName="d-flex flex-column"
      disableAction={true}
    >
      <div className="position-relative d-flex flex-column h-100">
        <SelectorCatalogList
          isFilter={false}
          keyName={importType === 'woImport' ? 'schedules' : 'mlSchedules'}
          onClick={onSelect}
          showDraftInd={true}
          listProps={{
            ListItemSecondaryAction: () => (
              <span style={{ marginRight: 8 }}>
                <ArrowIcon />
              </span>
            ),
          }}
        />
      </div>
    </Dialog>
  );
};
export default ScheduleSelector;
