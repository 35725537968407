import { FormHelperText, makeStyles, Typography } from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";
import { date2hhmm, hhmm2mins, mins2hhm } from "fitbud/utils/scheduling";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

/* * * * * SLOT - PICKER * * * * *
 * - Renders two MUI TimePickers to set a time range
 * - Data Model --- {time:"HH:MM" <string>, duration: difference between the range <number> }
 */
function SlotPicker(props) {
  let { disabled, slot, onChange, index, error } = props;
  const classes = useStyles();
  const [startTime, setStartTime] = useState(slot && new Date(`2021-01-01T${slot.time}:00`)); //Make new date from time of the slot
  const [endTime, setEndTime] = useState(slot && new Date(`2021-01-01T${mins2hhm(hhmm2mins(slot.time) + slot.duration)}:00`));

  function handleStartTime(time) {
    const start = moment(time);
    if (!start.hour() && !start.minutes())
      start.minute(15); // midnight becomes quarter past midnight
    let diff = moment(endTime).diff(start, 'minutes'); // find difference b/w start and end time
    // Pass the change up to parent and setState
    onChange({ time: date2hhmm(start), duration: diff }, index);
    setStartTime(start.toDate());
  }

  function handleEndTime(time) {
    const end = moment(time);
    if (!end.hour() && !end.minutes())
      end.hour(23) && end.minute(45); // midnight becomes quarter to midnight
    let diff = end.diff(moment(startTime), 'minutes'); // find difference b/w start and end time
    // Pass the change up to parent and setState
    onChange({ time: date2hhmm(startTime), duration: diff }, index);
    setEndTime(end.toDate());
  }
  
  useEffect(()=> {
    // Pass the change up to parent and setState
    setEndTime(slot && new Date(`2021-01-01T${mins2hhm(hhmm2mins(slot.time) + slot.duration)}:00`))
  }, [slot])

  return (
    <div>
      <div className='d-flex align-items-center'>
        <div>
          <TimePick 
            inputClasses={classes.maxWidth}
            disabled={disabled} 
            value={startTime} 
            onChange={handleStartTime} 
            error={error} 
          />
        </div>
        <div className='mx-2'>
          <Typography variant="caption" color="textPrimary">
            -
          </Typography>
        </div>
        <div>
          <TimePick 
            inputClasses={classes.maxWidth}
            disabled={disabled}
            value={endTime}
            onChange={handleEndTime} 
            error={error} 
          />
        </div>
      </div>
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </div>
  );
}

export function TimePick(props) {
  const {minutesStep = 15} = props;
  const classes = useStyles();
  return (
    <TimePicker
      {...props}
      minutesStep={minutesStep}
      inputVariant="outlined"
      InputProps={{
        className: `${classes.timeInput} ${props.inputClasses}`,
        ...props.InputProps
      }}
    />
  );
}
const useStyles = makeStyles((theme) => ({
  timeInput: {
    minHeight: "48px",
    fontSize: "15px",
    fontWeight: 500
  },
  maxWidth: {
    maxWidth:'120px',
  }
}));

export default withSnackbar(SlotPicker);
