import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import update from "immutability-helper";
import * as Sentry from '@sentry/browser';
import { DEFAULT_ERROR, HUBSPOT_PROPS,DRAFT_STATES } from "fitbud/utils/constants";
import foodRecipesRepo from "fitbud/repo/foodRecipes";
import frsRdxFns from "fitbud/redux/foodRecipesSupps";
import suppsRdxFns from "fitbud/redux/supps";
import fileUploadRdxFns from "fitbud/redux/fileUpload";
import { uploadFile, getTags } from "fitbud/utils/services";
import SupplementForm from "./supplementForm";
import appRdxFns from "fitbud/redux/app";
import SupplementDetails from "./foodSupplementDetails";
import _ from 'lodash';
import {
  ERROR_TITLE,
  ERROR_REF_NAME,
  ERROR_DESCRIPTION,
  ERROR_URL,
  FOODRECIPES_STORAGE_FILE_PATH,
  DRAFT,
  PUBLISHED,
  OFFLINE_ERROR,
  FETCH_ERROR
} from "fitbud/utils/constants";
import { isValidURL,isOnline } from "fitbud/utils/helpers";
import CircularLoader from "fitbud/components/CircularLoader";
import PageNotFound from "fitbud/views/pageNotFound";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { bffUpdateHubspotProp } from "fitbud/api";
import {calculatePublishStatus} from "fitbud/utils/catalog";

class SupplementInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      isValidId: true,
      doc: props.doc,
      oldDoc: props.doc,
      draftDoc:{},
      publishedDoc:{},
      errors: {},
      editMode: false,
      servingData: [],
      showingDoc: PUBLISHED,
    };
  }
  static contextType = FirebaseAuthContext;

  get authUser() {
    return this.context.authUser;
  }

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get draftFeature() {
    const { comp } = this.context;
    return !!comp.data().features.draft;
  }
  get isDraftAvailable() {
    return !!_.get(
      this.state,
      "publishedDoc.publish_status",
      PUBLISHED
    ).includes("draft");
  }

  handleChange = (e) => {
    let newState;
    const key = e.target.id || e.target.name;
    if (e.target.name === "serving" && e.target.value === "select") return;
    let value = e.target.value;
    if (e.target.id === "description") value = value.trim();
    if(key === "serving_size") value = Number(value);
    newState = update(this.state, {
      doc: {
        [key]: {
          $set:value
        }
      }
    });

    this.setState({ ...newState, errors: {} });
  };

  handleServingUnit = serving_type => {
    const unit = serving_type === "meal_volume" ? "ml" : "g";  
    const newState = update(this.state, {
      doc: {
        serving_unit: {
          $set: unit
        },
        serving_type: {
          $set: serving_type
        }
      }
    });
    this.setState({ ...newState, errors: {} });
  };

  handleEditMode = () => {
    this.setState({ editMode: true });
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };

  handleCancel = () => {
    const newState = update(this.state, {
      doc: {
        $set: { ...this.state.oldDoc },
      },
      editMode: {
        $set: false,
      },
      dirty: {
        $set: false,
      },
      errors: {
        $set: {},
      },
    });
    this.setState(newState);
  };

  valid = () => {
    let out = true;
    let errors = {};
    const {
      description,
      ref_name="",
      title="",
      serving_size,
      link,
      serving,
      serving_type,
    } = this.state.doc;
    let type = this.state.doc.type;
    if (this.isNew) {
      type = this.props.type;
    }

    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }

    if (!title.trim()) {
      errors.title = ERROR_TITLE("display name");
      out = false;
    }

    if (!serving) {
      errors.serving = "Please provide a serving item";
      out = false;
    }

    if (type === "supplement" && !!link && !isValidURL(link)) {
      errors.link = ERROR_URL;
      out = false;
    }

    this.setState({ errors });
    return out;
  };

  copyItem = () => {
    const {
      hideLoader,
      showLoader,
      insert,
      onSave,
      enqueueSnackbar,
    } = this.props;
    const { doc } = this.state;
    showLoader();
    let copyDoc = update(doc, {
      ref_name: {
        $set: `Copy of ${doc.ref_name}`,
      },
    });
    foodRecipesRepo(this.context.cid,this.draftFeature)
      .create(copyDoc)
      .then((doc) => {
        hideLoader();
        if (doc) {
          const newDoc = {
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: doc.data().ref_name,
              type: doc.data().type,
              has_link: !!doc.data().link,
              serving_size: doc.data().serving_size,
              serving : doc.data().serving,
              thumbnail:doc.data().thumbnail,
              publish_status:doc.data().publish_status
            },
          };
          insert(newDoc);
          this.props.insertSupps(newDoc);
          if (!!onSave) onSave(doc);
          enqueueSnackbar("Supplement copy successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }

  deleteItem = () => {
    const { doc } = this.state;
    const {
      onDelete,
      enqueueSnackbar,
      hideLoader,
      showLoader,
    } = this.props;
    showLoader();

    let updatedDoc = { ...doc };

    updatedDoc = update(updatedDoc, {
      archive: {
        $set: true
      }
    });

    foodRecipesRepo(this.context.cid,this.draftFeature)
      .update(this.docId, updatedDoc)
      .then((doc) => {
        hideLoader();
        if (doc) {
          this.props.deleteSupps(doc.id);
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Supplement deleted successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
  
  handlePublish = async() => {
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      await this.toggleDoc(DRAFT);
    }
    this.onSubmit(PUBLISHED);
  };
  handleDraft = () => {
    this.onSubmit(DRAFT);
  };
  onSubmit = async (docType = PUBLISHED) => {
    const {
      hideLoader,
      showLoader,
      insert,
      onSave,
      enqueueSnackbar
    } = this.props;
    const { doc } = this.state;
    const { thumbnail } = doc;

    if (!this.valid()) return;
    showLoader();

    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED'];

    let updatedDoc = { ...doc };
    if (thumbnail && thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: FOODRECIPES_STORAGE_FILE_PATH
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original", "240")
        }
      });
    }

    if (!updatedDoc.serving_unit) {
      updatedDoc = update(updatedDoc, {
        serving_unit: {
          $set: "g"
        },
        serving_type: {
          $set: "meal_weight"
        }
      });
    }
    const _rdxState = {
      cid: this.context.cid,
      ref_name: updatedDoc.ref_name,
      dtype: updatedDoc.type,
      has_link: !!updatedDoc.link,
      serving_size: updatedDoc.serving_size,
      serving : updatedDoc.serving,
      thumbnail :(calculatedDocType===DRAFT_STATES['DRAFT_ONLY'] || DRAFT_STATES["PUBLISHED"])
          ? updatedDoc.thumbnail
          : _.get(this.state.publishedDoc, "thumbnail"),
      publish_status:calculatedDocType
    };

    if (this.isNew) {
      const { type } = this.props;
      updatedDoc = update(updatedDoc, {
        type: {
          $set: type
        }
      });

      foodRecipesRepo(this.context.cid,this.draftFeature)
        .create(updatedDoc)
        .then(doc => {
          bffUpdateHubspotProp(HUBSPOT_PROPS.SUPPLEMENT_CREATED);
          hideLoader();
          if (doc) {
            const newDoc = {
              _id: doc.id,
              data: _rdxState
            };
            insert(newDoc);
            this.props.insertSupps(newDoc);
            if (!!onSave) onSave(doc);
          }
        })
        .catch(err => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    } else {
      foodRecipesRepo(this.context.cid,this.draftFeature)
        .update(this.docId, updatedDoc, null, docType)
        .then(doc => {
          hideLoader();
          if (doc) {
            const latestDoc = {
              _id: doc.id,
              data: _rdxState
            };
            this.props.updateSupps(latestDoc);
            const to_publish = doc.data().to_publish;
            //---TODO ⬇︎ :replace docType with calculatedDocType ?? 
            this.setState((o) => ({
              editMode: false,
              showingDoc: to_publish ? DRAFT : docType,
              publishedDoc:(docType===PUBLISHED && !to_publish)?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
              draftDoc:docType===DRAFT?doc.data():{...(o.draftDoc||{}),to_publish},
              oldDoc: doc.data(),
              doc: doc.data(),
            }));
            //-------
          }
        })
        .catch((err) => {
          console.log(">>>err", err);
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    }
  };

  handleImage = file => {
    let newState;
    if (file) {
      newState = update(this.state, {
        doc: {
          thumbnail: {
            $set: {
              file: file,
              url: URL.createObjectURL(file)
            }
          }
        }
      });
    } else {
      newState = update(this.state, {
        doc: {
          thumbnail: {
            $set: ""
          }
        }
      });
    }
    this.setState({ ...newState, errors: {} });
  };
  toggleDoc = (docType) => {
    const { draftDoc, publishedDoc } = this.state;
    const doc = docType === DRAFT ? { ...draftDoc } : { ...publishedDoc };
    if (!Object.keys(doc).length) return;
    this.setState({ showingDoc: docType, doc: { ...doc }, oldDoc: { ...doc } });
  };
  discardDraft = () => {
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    foodRecipesRepo(this.context.cid,this.draftFeature)
      .deleteDraft(this.docId, null, this.state.doc.publish_status)
      .then((doc) => {
        hideLoader();
        if (!doc) return;
        const updatedDoc = doc.data();
        if (
          _.get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]
        ) {
          this.props.deleteSupps(doc.id);
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
        } else if (
          _.get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]
        ) {
          this.setState((o) => ({
            oldDoc: { ...updatedDoc },
            doc: { ...updatedDoc },
            publishedDoc: { ...o.publishedDoc, publish_status: PUBLISHED },
            showingDoc:PUBLISHED
          }));
          this.props.updateSupps({
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: updatedDoc.ref_name,
              dtype: updatedDoc.type,
              has_link: !!updatedDoc.link,
              serving_size: updatedDoc.serving_size,
              serving: updatedDoc.serving,
              thumbnail: updatedDoc.thumbnail,
              publish_status:updatedDoc.publish_status
            },
          });
        }
        enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };

  render() {
    const {
      doc,
      oldDoc,
      loader,
      errors,
      editMode,
      servingData,
      isValidId,
      showingDoc,
    } = this.state;
    
    const { type } = this.props;

    if (!isValidId) return <PageNotFound keyName="supplement" />;
    if (this.isNew)
      return (
        <SupplementForm
          doc={doc}
          errors={errors}
          type={type}
          handleMedia={this.handleImage}
          handleChange={this.handleChange}
          servingData={servingData}
          onCancel={this.props.onCancel}
          onSubmit={this.onSubmit}
          isNew
          handleServingUnit={this.handleServingUnit}
        />
      );
    return (
      <div className={`overflow-x-hidden ${doc ? "" : "h-100"} position-relative`}>
        {loader && <CircularLoader className="position-absolute" />}
        {doc && (
          <>
            {!editMode && (
              <SupplementDetails
                id={this.props.id}
                doc={oldDoc}
                handleEditMode={this.handleEditMode}
                servingData={servingData}
                copyItem={this.copyItem}
                deleteItem={this.deleteItem}
                isPreview={this.props.isPreview}
                draftBannerProps={{
                  toggleDoc: this.toggleDoc,
                  isDraftAvailable: this.isDraftAvailable,
                  handlePublish: this.handlePublish,
                  discardDraft: this.discardDraft,
                  showingDoc: showingDoc,
                }}
              />
            )}
            {editMode && (
              <SupplementForm
                doc={doc}
                loading={false}
                errors={errors}
                type={doc.type}
                handleMedia={this.handleImage}
                handleChange={this.handleChange}
                servingData={servingData}
                onCancel={this.handleCancel}
                onSubmit={this.onSubmit}
                handleServingUnit={this.handleServingUnit}
                handleDraft={this.handleDraft}
                handlePublish={this.handlePublish}
                draftFeature={this.draftFeature}
                isDraftAvailable={this.isDraftAvailable}
              />
            )}
          </>
        )}
      </div>
    );
  }
  getSuppDoc = async (servingData) => {
    try{
      // if (this.isNew && !this.props.fdcData) return;
      this.setState({ loader: true });
      let doc,
        oldDoc = {},
        publishedDoc = {},
        draftDoc = {},
        showingDoc;
      if(this.isNew){
        if(this.props.fdcData){
          doc = { ...this.props.fdcData };
          oldDoc = { ...this.props.fdcData };
        }
        else{
          doc={...this.state.doc};
          oldDoc={...this.state.oldDoc};
        }
      }
      else if (!this.isNew) {
        //get published doc
        const suppDoc = await this.fetch();
        if (!suppDoc.exists) {
          this.setState({
            isValidId: false,
            loader: false,
          });
          return;
        }
        publishedDoc = suppDoc.data ? suppDoc.data() : suppDoc;
        //if draft feature is off, treat main collection doc as published
        if (!this.draftFeature) {
          publishedDoc["publish_status"] = DRAFT_STATES['PUBLISHED'];
        }
        const publish_status = _.get(publishedDoc, "publish_status", PUBLISHED);
        if (publish_status.includes("draft")) {
          //get draft doc
          const draftWo = await this.fetch(true);
          draftDoc = !!draftWo.data ? draftWo.data() : draftWo;
        }
        if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
          doc = { ...draftDoc };
          showingDoc = DRAFT;
          oldDoc = { ...draftDoc };
        } else {
          doc = { ...publishedDoc };
          showingDoc = PUBLISHED;
          oldDoc = { ...publishedDoc };
        }
      }
      if(!doc.serving){
        doc = { ...doc, serving: servingData[2][0] };
      }
      this.setState({
        doc,
        oldDoc,
        publishedDoc,
        draftDoc,
        loader: false,
        showingDoc,
      });
    }
    catch(err){
      const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
        this.setState({loader:false})
    }
  };
  componentDidMount() {
    this.setState({ loader: true });
    const fetchServing = () => getTags("serving");
    Promise.all([fetchServing()])
      .then(([serving]) => {
        let servingData = [];
        if (!!serving) {
          servingData = Object.entries(serving);
        }
        this.setState({
          servingData: servingData,
        });
        this.getSuppDoc(servingData);
      })
      .catch(err => {
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
      });
  }

  fetch = (isDraft = false) => {
    return foodRecipesRepo(this.context.cid,this.draftFeature).doc(this.docId, null, isDraft);
  };
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === "new") {
    const type = op.type;
    let _defaultDoc = {
      type,
      title: "",
      ref_name: "",
      link:"",
      serving_type: "meal_weight"
    };

    return {
      doc: { ..._defaultDoc },
      loading: s.foodRecipes.loading,
    };
  } else
    return { doc: undefined, loading: s.foodRecipes.loading };
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const { insert: insertSupps, update: updateSupps, delete: deleteSupps } = suppsRdxFns(d);
  const { uploadFile } = fileUploadRdxFns(d);

  return {
    ...frsRdxFns(d),
    insertSupps,
    updateSupps,
    deleteSupps,
    showLoader,
    hideLoader,
    uploadFile,
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(SupplementInfo)
);
