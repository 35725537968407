import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, Drawer, Typography } from '@material-ui/core';
import SelectorContextProvider, {
  SelectorContext,
  SELECTOR_LIB,
} from 'fitbud/components/catalogSelector/selectorContext';
import SelectorHeader from 'fitbud/components/catalogSelector/selectorHeader';
import SelectedList from 'fitbud/components/catalogSelector/selectedList';
import { ClrdButton } from 'fitbud/components/form-fields';
import { DialogAppBar } from 'fitbud/components/Dialog';
import { SelectorList } from './listWrapper';
import SupplementInfo from 'fitbud/views/supplement/info';
import CatalogFilter from 'fitbud/components/catalog-filter';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {AddMenus as AddResourcesMenu} from "fitbud/views/resources/subComponents";
import AddResourceDialog from "fitbud/views/resources/addDialog";

const useClasses = makeStyles(()=>({
  addBtn:{
    padding: "11px 30px",
    backgroundColor:"#E0ECFE",
    cursor: "pointer",
    borderRadius:5
  }
}))



const getViewComponent = (keyName) => {
  if (keyName === 'supps') return SupplementInfo;
  return null;
};

const getViewTitle = (keyName) => {
  if (keyName === 'supps') return 'Supplement';
  return '';
};

const CatalogMultiSelectorContainer = (props) => {
  const { keyName = 'meals',noSelectedPlaceHolder, defaultFilter, hidePreSelected, dataType,showDraftInd} = props;
  const {
    query,
    addSelectedValues,
    selectedLib,
    filterOpen,
    filterState,
    closeFilter,
    setFilterState,
  } = useContext(SelectorContext);
  const [viewItem, setViewItem] = useState(null);
  const classes = useClasses();
  const [anchorEl, setAnchorEl] = useState(null);
  const [resourceAddOption, setResourceAddOption] = useState('');

  const handleClickOnSelectedItem = (e, l) => {
    setViewItem(l);
  };
  const onDrawerClose = () => {
    setViewItem(null);
  };
  const _VIEWComponent = getViewComponent(keyName);
  const _title = getViewTitle(keyName);

  const handleAddResource = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleResourceOption = (e, option) => {
    e.stopPropagation();
    setAnchorEl(null);
    setResourceAddOption(option);
  };

  const closeResourceAddOption = () => {
    setAnchorEl(null);
  };

  const onSaveResources = (doc) =>{
    const _id = doc.id;
    const data = doc.data;
    addSelectedValues({ _id: _id, type:dataType, data: data }); // ON Add resources must add type  to follow structure : [{_id, type, data}] 
  }

  const renderNoResultFoundAction = (noResultFound = true) =>{
    /* in case of no result found in resources support option to add */
    if (keyName !== 'resources') return null;
    const _label = noResultFound ? "We can't find what you are looking for. Use the button below to add your own resources." : 
    "No resources items are added , please add resources.";
    return (
      <div style={{ width: 350 }} className="d-flex flex-column justify-content-center align-items-center fmt-10">
        <Typography className="font_13_400 text-dark-grey fmb-20">
          {_label}
        </Typography>
        <div className={classes.addBtn} onClick={handleAddResource}>
          <Typography color="primary" className="font_15_600">
            Add Resource
          </Typography>
        </div>
        <AddResourcesMenu anchorEl={anchorEl} onClose={closeResourceAddOption} handleOption={handleResourceOption} />
      </div>
    );
  }

  const noDataFoundAction = () =>{
    return renderNoResultFoundAction(false);
  }


  return (
    <DialogContent className="d-flex fp-0">
      <Grid container className="flex-grow-1">
        <Grid
          item
          xs={7}
          className="h-100 d-flex position-relative"
          style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          {selectedLib === SELECTOR_LIB.MY_LIB && (
            <SelectorList 
              keyName={keyName} 
              filterState={filterState} 
              noDataFoundAction={noDataFoundAction} 
              noResultFoundAction={renderNoResultFoundAction}  
              query={query} 
              onClick={addSelectedValues} 
              dataType={dataType} 
              defaultFilter={defaultFilter} 
              showDraftInd={showDraftInd}
              />
          )}
        </Grid>
        <Grid className="h-100 d-flex" item xs={5}>
          <SelectedList
            onClick={handleClickOnSelectedItem}
            keyName={keyName}
            noSelectedPlaceholder={noSelectedPlaceHolder}
            hidePreSelected={hidePreSelected}
          />
        </Grid>
      </Grid>
      {!!viewItem && !!_VIEWComponent && (
        <Drawer anchor="right" onClose={onDrawerClose} open={true}>
          <DialogAppBar title={_title} onClose={onDrawerClose} titleFont="h3" />
          <_VIEWComponent id={viewItem._id} key={viewItem._id} isPreview={true} />
        </Drawer>
      )}
      {filterOpen && (
        <CatalogFilter
          filters={filterState}
          keyName={keyName}
          open={true}
          onSave={setFilterState}
          handleClose={closeFilter}
        />
      )}
      {resourceAddOption && <AddResourceDialog  N={0} onSave={onSaveResources} type={resourceAddOption} handleClose={()=>setResourceAddOption("")} />}
    </DialogContent>
  );
};

const Header = (props) => {
  const { handleAdd, selected } = props;
  const { selectedValues } = useContext(SelectorContext);
  const { addAllSelectedValues } = useContext(SelectorContext);
  const totalSelectedValues = (selectedValues || []).length;

  useEffect(() => {
    if (!!selected && !!selected.length) {
      addAllSelectedValues(selected);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSave = () => {
    handleAdd && handleAdd(selectedValues);
  };

  const getHeaderActions = () => {
    return (
      <ClrdButton
        disabled={!totalSelectedValues}
        variant="contained"
        color="primary"
        className="f-medium"
        onClick={handleSave}
      >
        Save
      </ClrdButton>
    );
  };
  return <SelectorHeader hideLibrarySwitch={true} keyName="Meals" headerActions={getHeaderActions} {...props} />;
};

const CatalogMultiSelector = (props) => {
  const { open, item, hidePreSelected = 0,keyName } = props;
  return (
    <Dialog
      open={open}
      id="exercise_selector"
      scroll="paper"
      classes={{
        paper: 'width-800 height-70',
      }}
    >
      <SelectorContextProvider hidePreSelected={hidePreSelected} item={item} keyName={keyName}>
        <Header {...props} />
        <CatalogMultiSelectorContainer {...props} />
      </SelectorContextProvider>
    </Dialog>
  );
};

export default CatalogMultiSelector;
