import React, { useContext, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { formatNo } from 'fitbud/utils/helpers';
import { useToggle } from 'fitbud/hooks/form';
import { UserSchContext } from '../provider';
import Editor from './editors';

const BMRCard = ({ className }) => {
  const { bmrlocal } = useContext(UserSchContext);
  const [edit, toggleEdit] = useToggle();
  const [l1, l2, ready] = useMemo(() => {
    if (!bmrlocal) return ['', '', false];
    const { p, f, c, meal } = bmrlocal;
    const l1 = `P ${p}% • F ${f}% • C ${c}%`;
    if (!meal) return [l1, 'Click to edit', false];
    let l2 = '';
    const {macros} = meal;
    if (macros && macros.calories)
      l2 = formatNo(Math.round(macros.calories), {}, '');
    return [l1, l2, true];
  }, [bmrlocal]);
  if (!l1) return null;
  return (
    <div>
      <div className={`d-flex flex-column justify-content-between pl-10 py-8 pr-30 cursor-pointer ${className || ''}`} style={style} onClick={toggleEdit} >
        <Typography className='font_14_600 overflow-hidden twoLineEllipsis'>{l1}</Typography>
        <div className='d-flex flex-row align-items-center text-dark-grey'>
          {l2 && <Typography className='font_13_600 text-0d0d0d' color='inherit'>{l2}</Typography>}
          {l2 && <Typography className='font_13_500 text' color='inherit'>&nbsp;{ready ? 'cals' : ''}</Typography>}
        </div>
      </div>
      {edit && <Editor onClose={toggleEdit}/>}
    </div>
  );
};

const style = { height: 80, backgroundColor: '#E6F7E7', border: '1px solid #D3D9DB', borderLeft: '4px solid #05B715', borderRadius: 3 };

export default BMRCard;
