import React from 'react';
import {makeStyles,Typography} from '@material-ui/core';
import fitnessEditorPlchldr from "fitbud/images/fitnessEditorPlchldr.svg"
import scheduleEditorPlchldr from "fitbud/images/scheduleEditorPlaceholder.svg"
import SupplementImg from "fitbud/images/catalog/supplements.png";
import NoMeals from "fitbud/images/noMeal2.svg";
import ExStepsPlchldr from "fitbud/images/exerciseStepsPlaceholder.svg";
import clsx from 'clsx';
const styles=makeStyles({
    centerAlign:{
        position:'absolute',
        top:"50%",
        left:"50%",
        transform:'translate(-50%,-50%)'
    }
})

export const EditorPlaceholder=({keyName,centerAlign=false,cta, className})=>{
    const classes=styles();
    const doc={
        centerHeading:"",
        centerImg:fitnessEditorPlchldr
    };
    switch(keyName){
        case "exercise-step-placeholder":{
            doc['centerImg']=ExStepsPlchldr;
            doc['centerHeading']="Add Instructions"
            break;
        }
        case "exercise-alternate-placeholder":{
            doc['centerHeading']="Add Alternate Exercises"
            break;
        }
        case "workout-editor-placeholder":{
            doc['centerHeading']="No Exercises Added";
            doc['centerSubheading']="Click on ‘+’ button above to add exercises to this workout";
            break;
        }
        case "workout-video-placeholder":{
            doc['centerHeading']="No Videos Added";
            doc['centerSubheading']="Click on ‘+’ button above to add Videos to this workout";
            break;
        }
        case 'supplement':{
            doc['centerHeading']="No Supplements Added";
            doc['centerSubheading']="Click on ‘+’ button above to add supplements to this plan";
            doc['centerImg']=SupplementImg;
            break;
        }
        case "schedule-editor-placeholder":{
            doc['centerHeading']="No Schedule Added";
            doc['centerSubheading']="Click on ‘+’ button above to add Schedule";
            doc['centerImg']=scheduleEditorPlchldr;
            break;
        }
        case "meal-macro-detail-placeholder":{
            doc['centerHeading']="No Meals Added";
            doc['centerSubheading']="Meals added in this meal plan will show here";
            doc['centerImg']=NoMeals;
            break;
        }
        default:{}
    }
    const {centerHeading,centerImg, centerSubheading}=doc;
    return(
        <>
            <div className={clsx("d-flex flex-column align-items-center justify-content-center",centerAlign && classes.centerAlign, className)}>
                <img src={centerImg} alt="placeholder" className="fmt-15 fmb-25"/>
                <Typography className="font_16_600 font-weight-700 fmb-10">{centerHeading}</Typography>
                {!!centerSubheading && <Typography className="font_14_500 fmt-5 text-dark-grey">{centerSubheading}</Typography>}
                {cta && cta()}
            </div>
        </>
  )
}