import React from "react";
import PaymentIcon from "@material-ui/icons/Payment";
import { ActiveClientsIcon } from "fitbud/icons/activeClients";
import { TrainerIcon } from "fitbud/icons/trainerIcon";
import { ReportsNStatsIcon } from "fitbud/icons/reportsNStatsIcon";
import { CompanyIcon } from "fitbud/icons/companyIcon";
import { StaffIcon } from "fitbud/icons/staffIcon";
import {
  CheckinsIcon,
  ClientsIcon,
  FitnessIcon,
  LiveSessionsIcon,
  MsgsIcon,
  NutritionIcon,
  PaymentsIcon,
  SettingsIcon,
  HomeIcon,
  SetupGuideIcon,
  StoreFrontIcon,
  BrandingIcon,
  ExploreIcon,
  HelpIcon,
  MoreIcon
} from "fitbud/icons/sideMenu";
import { UserStateIndicator } from "fitbud/views/users/list";
import { ClientAppIcon } from "fitbud/icons/sideMenu/clientAppIcon";

const Keys = {
  O1:"/quick-help",
  O2:"/get-started",
  D:"/home",
  S: "/staff",
  Co: "/companies",
  F: "/fitness",
  F1: "/fitness/exercises",
  F2: "/fitness/workouts",
  F3: "/fitness/cardio",
  F4: "/fitness/schedules",
  N: "/nutrition",
  N1: "/nutrition/foods",
  N2: "/nutrition/daily-meals",
  N3: "/nutrition/schedules",
  N4: "/nutrition/daily-supplements",
  N5: "/nutrition/supplements",
  N6: "/nutrition/recipes",
  TR:'/trainers',
  SF:'/store-front',
  U: "/clients",
  // U1: "/clients/pendingSignIn",
  U2: "/clients/active",
  U3: "/clients/inactive",
  U4: "/clients/browse",
  U5: "/clients/leads",
  U6: "/clients/plansToAssign",
  // M: "/clients/chats",
  M: "/messages",
  M1: "/messages/chats",
  M2: "/messages/group-chats",
  M3: "/messages/broadcasts",
  M4: "/messages/in-app-notifications",
  CI: "/clients/checkins",
  C: "/config",
  C1: "/config/plans",
  C2: "/config/questionnaire",
  C3: "/config/app-pages",
  C4: "/config/general",
  C5: "/config/billing",
  R: "/reports",
  P: "/plans",
  LS:"/video-calls",
  TP: "/config/profile",
  W: "/website",
  E: "/explore",
  E0:"/explore/configuration",
  E3: "/explore/resources",
  E3: "/explore/resources",
  L:"/live",
  L1 : "/live/calendar",
  L2:"/live/group-classes",
  L3:"/live/configuration",
  D0: "/curated/for-you",
  H: "https://help.fitbudd.com/en/",
  MR: "/more",
  CA: "/client-app/theme",
};
export default {
   [Keys.O1]:{
    label: "Quick Help",
    icon: <SetupGuideIcon />
  },
  [Keys.O2]:{
    label: "Get Started",
    icon: <SetupGuideIcon />
  },
  [Keys.D]:{
    label: "Home",
    icon: <HomeIcon />
  },
  // [Keys.O]:{
  //   label: "Get Started",
  //   icon: <OverviewIcon />
  // },
  [Keys.S]: {
    label: "Staff",
    icon: <StaffIcon />
  },
  [Keys.Co]: {
    label: "Companies",
    icon: <CompanyIcon />
  },
  [Keys.F]: {
    label: "Fitness",
    icon: <FitnessIcon />,
    children: [Keys.F1, Keys.F2, Keys.F3]
  },
  [Keys.F1]: {
    label: "Exercises",
  },
  [Keys.F2]: {
    label: "Workouts",
  },
  [Keys.F3]: {
    label: "Cardio",
  },
  [Keys.F4]: {
    label: "Schedules",
  },
  [Keys.N]: {
    label: "Nutrition",
    icon: <NutritionIcon />,
    children: [Keys.N1,Keys.N6 ,Keys.N2, Keys.N3, Keys.N4]
  },
  [Keys.N1]: {
    label: "Food Items",
  },
  [Keys.N2]: {
    label: "Meal Plans",
  },
  [Keys.N3]: {
    label: "Meal Schedules",
  },
  [Keys.N4]: {
    label: "Supplement Plans",
  },
  [Keys.N5]: {
    label: "Supplements",
  },
  [Keys.N6]: {
    label: "Recipes",
  },

  [Keys.TR]: {
    label: "Trainers",
    icon: <TrainerIcon />,
  },
  [Keys.SF]: {
    label: "Store-Front",
    icon: <StoreFrontIcon />,
  },
  [Keys.U]: {
    label: "Clients",
    icon: <ClientsIcon />,
    children: [Keys.U1, Keys.U2, Keys.U3]
  },
  [Keys.U1]: {
    label: "Pending Sign In", 
    icon:  <div className="fpx-12"><UserStateIndicator state="pendingSignIn"/></div>,
    show: true
  },
  [Keys.U2]: {
    label: "Active Clients", 
    icon:  <div className="fpx-12"><UserStateIndicator state="active"/></div>,
    show: true
  },
  [Keys.U3]: {
    label: "Inactive Clients", 
    icon:  <div className="fpx-12"><UserStateIndicator state="inactive"/></div>,
    show: true
  },
  [Keys.U5]: {
    label: "Leads", 
    icon:  <div className="fpx-12"><UserStateIndicator state="leads"/></div>,
    show: true
  },
  [Keys.U6]: {
    label: "Plans To Assign", 
    icon:  <div className="fpx-12"><UserStateIndicator state="plansToAssign"/></div>,
    show: true
  },
  [Keys.M]: {
    label: "Messages",
    icon: <MsgsIcon />
  },
  [Keys.M1]: {
    label: "Direct Messages",
  },
  [Keys.M2]: {
    label: "Group Chat",
  },
  [Keys.M3]: {
    label: "Broadcasts"
  },
  [Keys.M4]: {
    label: "In-App Notifications"
  },
  [Keys.CI]: {
    label: "Check-ins",
    icon: <CheckinsIcon />
  },
  // [Keys.ST]: {
  //   label: "Settings",
  //   icon: <SettingsIcon />
  // },
  [Keys.C]: {
    label: "Settings",
    icon: <SettingsIcon />,
    children: [Keys.C1, Keys.C2, Keys.C3, Keys.C4,Keys.C5]
  },
  // [Keys.C]: {
  //   label: "Configuration",
  //   icon: <SettingsIcon />,
  //   children: [Keys.C1, Keys.C2, Keys.C3, Keys.C4]
  // },
  [Keys.C1]: {
    label: "Plans",
    icon: "fas fa-cog"
  },
  [Keys.C2]: {
    label: "Questionnaire",
  },
  [Keys.C3]: {
    label: "My App",
  },
  [Keys.C4]: {
    label: "General",
  },
  [Keys.C5]: {
    label: "Billing",
    icon: <PaymentIcon />
  },
  [Keys.R]: {
    label: "Reports & Stats",
    icon: <ReportsNStatsIcon />
  },
  [Keys.P]: {
    label: "Payment Plans",
    icon: <PaymentsIcon />
  },
  [Keys.TP]: {
    label: "Profile",
    icon: <ActiveClientsIcon />
  },
  [Keys.L] : {
    label:"Live",
    icon:<LiveSessionsIcon />,
    children: [Keys.L1, Keys.L2, Keys.L3]
  },
  [Keys.L1]:{
    label:"Calendar"
  },
  [Keys.L2]:{
    label:"Group Classes"
  },
  [Keys.L3]:{
    label:"Configuration"
  },
  // [Keys.LS]: {
  //   label: "Video Calls",
  //   icon: <LiveSessionsIcon />
  // },
  [Keys.W]: {
    label: "Website",
    icon: <BrandingIcon />
  },
  [Keys.E]: {
    label: "Explore",
    icon: <ExploreIcon />,
    children: [Keys.E0]
  },
  [Keys.E0]: {
    label: "Configuration",
  },
  [Keys.E3]: {
    label: "Resources",
    icon: <ExploreIcon />,
  },
  [Keys.D0]: {
    label: "Curated › For You",
    icon: <ExploreIcon />,
  },
  [Keys.H]: {
    label: "Help",
    icon: <HelpIcon />,
  },
  [Keys.MR]: {
    label: "More",
    icon: <MoreIcon />,
  },
  [Keys.CA]: {
    label: "Client App",
    icon: <ClientAppIcon />
  },
  [Keys.AT]: {
    label: "App Theming",
  }
  // [Keys.FQ]: {
  //   label: "FAQs",
  //   icon: <i className="fa fa-question" aria-hidden="true"></i>
  // },
  // [Keys.B]: {
  //   label: "Billing",
  //   icon: <BillingIcon />
  // }
};
