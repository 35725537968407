import _cap from 'lodash/capitalize';
import _empty from 'lodash/isEmpty';
import React, { useMemo, useContext } from 'react';
import {
  Typography, Tooltip,
} from '@material-ui/core';
import { cardClrCSS, Feedback } from 'fitbud/views/users/subComponents';
import { formatNo } from 'fitbud/utils/helpers';
import { Conversion } from 'fitbud/providers/conversion';
import { UserSchContext } from './provider';
import {durationToUserSchCardFormat} from "fitbud/views/workouts/helperfn";

const MajorCount = ({todo, done, convertor, unit, precision = 0}) => {
  const userVal = done ? (convertor ? convertor.getDisplayValue(unit, done) : formatNo(done, {precision}, '-')) : '-';
  return (
    <div className='d-flex flex-row align-items-baseline'>
      <Typography className='font_13_600 text-0d0d0d'>{userVal}</Typography>
      {Boolean(todo) && <Typography className='font_13_500 text-0d0d0d'>
        &nbsp;/&nbsp;{convertor ? convertor.getDisplayValue(unit, todo) : formatNo(todo, {precision}, '-')}
      </Typography>}
      <Typography className='font_13_500 text-0d0d0d'>&nbsp;{convertor ? convertor.getDisplayUnit(unit) : unit}</Typography>
    </div>
  );
};
const MinorCount = ({todo, done, convertor, unit, precision = 0, abbr = false, className = '', format=true}) => {
  const userVal = done ? (convertor ? convertor.getDisplayValue(unit, done) : (format? formatNo(done, {precision, abbr}, '-'): done)) : '-';
  return (
    <div className={`d-flex flex-column ${className}`}>
      <Typography noWrap className='font_10_500 text-grey mb-3px'>{convertor ? `${_cap(unit)} (${convertor.getDisplayUnit(unit)})` : unit}</Typography>
      <div className='d-flex flex-row align-items-baseline'>
        <Typography className='font_12_600 text-0d0d0d'>{userVal}</Typography>
        {Boolean(todo) && <Typography className='font_10_500 text-0d0d0d'>
          &nbsp;/&nbsp;{convertor ? convertor.getDisplayValue(unit, todo) : (format? formatNo(todo, {precision, abbr}, '-'):todo)}
        </Typography>}
      </div>
    </div>
  );
};

const WtrActCard = ({ data, convertor, className }) => {
  const {todo, done} = data;
  if (_empty(done) && (!todo || !todo.required)) return null;
  const {water: w0, energy: e0, flights: f0, steps: s0, distance_travelled: d0} = done || {};
  const {water: w1, energy: e1, flights: f1, steps: s1, distance: d1} = todo || {};
  return (
    <div className={`d-flex flex-column justify-content-between mr-15 p-10 ${className || ''}`} style={CSSS.wa}>
      <Typography className='font_14_500 text-0d0d0d'>Water &amp; Activity</Typography>
      <div className='d-flex flex-row justify-content-between'>
        <MinorCount done={w0} todo={w1} convertor={convertor} unit='water' className='pr-10'/>
        <MinorCount done={s0} todo={s1} unit='Steps' className='px-10 border-left' abbr/>
        <MinorCount done={d0} todo={d1} convertor={convertor} unit='distance' className='px-10 border-left'/>
        <MinorCount done={f0} todo={f1} unit='Flights' className='px-10 border-left'/>
        <MinorCount done={e0} todo={e1} unit='Energy' className='pl-10 border-left' abbr/>
      </div>
    </div>
  );
};

const MLCard = ({ data, className, onClick, date, past }) => {
  const [done, todo] = useMemo(() => {
    if (!data) return [];
    const { done, todo } = data;
    const doneOut = done && done.calories ? done : null;
    const todoOut = (todo && todo.macros) || null;
    return [doneOut, todoOut];
  }, [data]);

  if (!done && !todo) return null;
  const {calories: e0, protein: p0, fat: f0, carbs: c0} = done || {};
  const {calories: e1, protein: p1, fat: f1, carbs: c1} = todo || {};
  const _click = () => {
    if (!onClick) return;
    onClick({ ...data, date, past});
  };
  return (
    <div className={`d-flex flex-column justify-content-between mr-15 p-10 cursor-pointer ${className || ''}`} style={CSSS.ml} onClick={_click}>
      <MajorCount done={e0} todo={e1} unit='cals'/>
      <div className='d-flex flex-row justify-content-between'>
        <MinorCount done={p0} todo={p1} unit='Protein'/>
        <MinorCount done={f0} todo={f1} unit='Fat'/>
        <MinorCount done={c0} todo={c1} unit='Carbs'/>
      </div>
    </div>
  );
};

const WOCard = ({ data, className, onClick, date, past }) => {
  const {log, meta, id} = data || {};
  const style = useMemo(() => CSSRAND(id), [id]);
  const ref_name = (meta && (meta.ref_name || meta.title)) || '-';
  const noLogs = !log || !log.is_complete
  const [_done, cals, bpmh, bpmavg, feedback, feedbackV2, notes] = useMemo(() => {
    if (noLogs) return [];
    const { duration, cals, avg_hr, min_hr, max_hr, feedback, feedbackV2, notes } = log;
    const bpmh = (min_hr && max_hr && min_hr < max_hr) ? `${min_hr}-${max_hr} BPM` : 'BPM';
    return [duration, cals, bpmh, avg_hr && avg_hr > 0 ? avg_hr : 0, feedback, feedbackV2, notes];
  }, [log, noLogs]);
  const {done,todo,heading}=useMemo(()=>durationToUserSchCardFormat(_done, meta ? meta.duration : 0),[_done, meta])
  const _click = () => {
    if (!onClick) return;
    onClick({ ...data, date, past, log: noLogs ? null : data.log });
  };
  if (_empty(meta)) return null;
  return (
    <div className={`d-flex flex-column justify-content-between p-10 mr-15 cursor-pointer  ${className || ''}`} style={style} onClick={_click}>
      <div className='d-flex flex-row justify-content-between align-middle'>
        <Tooltip title={ref_name}>
          <Typography className='font_14_500 text-0d0d0d text-truncate'>{ref_name}</Typography>
        </Tooltip>
        <Feedback feedback={feedback} feedbackV2={feedbackV2} notes={notes} style={CSSS.ficon} className='ml-5 font_10_600 '/>
      </div>
      <div className='d-flex flex-row justify-content-between'>
        <MinorCount done={done} todo={todo} unit={heading}/>
        <MinorCount done={cals} unit='Cals'/>
        <div className='d-flex flex-column'>
          <Typography className='font_10_500 text-grey mb-3px'>{bpmh || 'BPM'}</Typography>
          <div className='d-flex flex-row align-items-baseline'>
            <Typography className='font_12_600 text-0d0d0d'>{bpmavg || '-'}</Typography>
            {Boolean(bpmavg) && <Typography className='font_10_500 text-0d0d0d'>&nbsp;Avg</Typography>}
          </div>
        </div>
      </div>
    </div>
  );
};

const noshow = data => (!data || (!data.done && !data.todo));
const ItemCard = ({ index, week, day, dayCopy, data = null, wo = false, ml = false, wa = false, rest = false, className, date }) => {
  const { objCache, weekReady, dayToday, weekToday, controller, bmrremote } = useContext(UserSchContext);
  const { convertor } = useContext(Conversion);
  const [parsed, past] = useMemo(() => {
    const past = (week * 7 + day) < dayToday; // we're considering today as past here
    if (!weekReady) return [null];
    if (rest) return ['rest'];
    if (wa) {
      const { water, activity } = data || {};
      if (noshow(water) && noshow(activity)) return [null];
      let todo = {required: false};
      let done = {};
      if (water) {
        let {todo: a, done: b} = water;
        if (a && a.required && a.value) {
          todo.required = true;
          todo.water = water.todo.value;
        }
        if (b && b.total) done.water = b.total;
      }
      if (activity) {
        const {todo: a, done: b} = activity;
        if (a && a.required && a.value) {
          todo = {...todo, required: true, ...a.value};
        }
        if (b && b.date) done = {...done, ...b};
      }
      return [{todo, done}, past];
    }
    if (ml) {
      if (noshow(data)) return [null];
      let meta = undefined;
      const id = data.todo;
      if (id) {
        if (id === 'bmr' && week >= weekToday && !past) { // active BMR
          if (bmrremote && bmrremote.meal)
            meta = bmrremote.meal;
        } else {
          meta = (dayCopy && dayCopy[id]);
          if (_empty(meta))
            meta = (objCache && objCache[id]);
        }
        if (!meta) return [null];
        if (!meta && !data.done) return [null];
        meta.id = id;
      }
      return [{...data, todo: meta}, past];
    }
    if (wo) {
      if (!data || !data.id) return [null];
      if (!_empty(data.meta)) return [data, past]; // meta already present
      let meta = (dayCopy && dayCopy[data.id]);
      if (_empty(meta))
        meta = (objCache && objCache[data.id]);
      return [{...data, meta}, past];
    }
    return [null];
  }, [bmrremote, weekToday, objCache, dayToday, week, day, dayCopy, weekReady, data, rest, wo, ml, wa]);

  if (!parsed) return null;

  if (rest) {
    return (
      <div className={`d-flex justify-content-center align-items-center mr-15 ${className || ''}`} style={CSSS.rest}>
        <Typography className='font_13_600 text-0d0d0d'>Rest</Typography>
      </div>
    );
  }
  
  if (wo) return <WOCard past={past} data={parsed} onClick={controller.showDetail} date={date}/>;
  if (ml) return <MLCard past={past} data={parsed} onClick={controller.showDetail} date={date}/>;
  if (wa) return <WtrActCard past={past} data={parsed} convertor={convertor}/>;
  return null;
};

const FakeCard = () => <div style={CSSS.fake}/>;

const CSSS = {
  ml: { height: 80, width: 210, backgroundColor: '#E6F7E7', border: '1px solid #D3D9DB', borderLeft: '4px solid #05B715', borderRadius: 3 },
  wa: { height: 80, minWidth: 210, backgroundColor: '#EBEEEF', border: '1px solid #D3D9DB', borderLeft: '4px solid #455A64', borderRadius: 3 },
  water: { height: 80, width: 210, backgroundColor: '#ECF8FD', border: '1px solid #D3D9DB', borderLeft: '4px solid #4FC3F7', borderRadius: 3 },
  activity: { height: 80, width: 210, backgroundColor: '#FEF2ED', border: '1px solid #D3D9DB', borderLeft: '4px solid #FF8259', borderRadius: 3 },
  rest: { height: 80, width: 210, backgroundColor: '#F0F3F5', border: '1px solid #F0F3F5', borderRadius: 3 },
  addwo: { height: 80, width: 210, backgroundColor: '#FEFAEB', border: '1px solid #FFEA9F', borderRadius: 3 },
  fake: { height: 80, width: 0 },
  ficon: { width: 20, height: 'auto' },
};
const CSSRAND = (id, meal = false) => {
  const clrs = cardClrCSS(id, meal);
  return { height: 80, width: 210, borderRadius: 3, border: '1px solid #D3D9DB', ...clrs };
};

export {
  ItemCard, FakeCard,
};
