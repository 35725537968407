import { Card, CircularProgress, makeStyles, Typography, IconButton, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { CameraIcon } from "fitbud/icons/cameraIcon";
import { ColumnIcon } from "fitbud/icons/columnIcon";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import MeasurementStats from "./checkinStatsView";
import CheckinView from "./checkinView";
import CompareProgress from "./compareProgress";
import _ from "lodash";
import { replaceAllSafe, roundNumber, secondsToMinuteFormatted } from "fitbud/utils/helpers";
import { useToggle } from 'fitbud/hooks/form';
import EditIcon from 'fitbud/icons/detailEdit';
import Dialog from 'fitbud/components/Dialog';
import { useProgressTrendsContext } from "../progTrendsProvider";
import { useUserContext } from "../detail";
import { bffUpdateHubspotProp } from "fitbud/api";
import { HUBSPOT_PROPS } from "fitbud/utils/constants";
import { ArrowColors } from "fitbud/utils/constants";
// import { ArrowDownwardSharp, ArrowUpwardSharp } from "@material-ui/icons";
// import { DoubleSideArrow } from "fitbud/icons/doubleSideArrowIcon";
import ExerciseProgress from "./exerciseProgress";
import {UpBlueArrowIcon} from "fitbud/icons/upBlueArrow";
import {DownBlueArrowIcon} from "fitbud/icons/downBlueArrow";
import {SameBlueArrowIcon} from "fitbud/icons/sameBlueArrow";

const styles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  leftPanel:{
    width:'100%',
    [theme.breakpoints.up('md')]:{
      width: '40%',
    },
    overflow:'auto',
  },
  rightPanel:{
    width:'100%',
    [theme.breakpoints.up('md')]:{
      width:'60%', 
    },
    overflow:'auto',
    borderLeft:'1px solid #eee'
  },
}));

const CheckIns = props => {
  const {  measurableTags, isCheckinsLoading, docId } = props;
  const classes = styles();
  const { measurementsAggregate, loadAggMeasurementsData, loadExerciseData, exerciseData, compChkinConf } = useProgressTrendsContext();
  const { aplan } = useUserContext();

  const [isLoading, setLoading] = useState(false);
  const [opts, toggleOpts, setOpts] = useToggle();
  const [view, setView] = useState('check_ins');
  const [selectedTitle, setSelectedTitle] = useState('check_ins')
  const handleViewSelect = (view, title='') => () => {
    if(title) setSelectedTitle(title);
    if(view)setView(view);
    setOpts(false);
  };

  useEffect(() => {
    let unMount = false;
    if(!measurementsAggregate || !exerciseData) setLoading(true);
    Promise.all([loadAggMeasurementsData(), loadExerciseData()]).then((res) => {
      if(unMount) return;
      setLoading(false);
    });
    return () => unMount = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aplan, docId, loadAggMeasurementsData, loadExerciseData]);

  useEffect(()=> {
    if(view === 'check_ins' || view === 'compare_progress' ) return;
    const container  = document.querySelector('#checkin-right-container');
    if(!!container){
      container.scrollTop = 0;
    }
  }, [view]);
  
    useEffect(() => {
      bffUpdateHubspotProp(HUBSPOT_PROPS.CHECKIN_VIEWED);
    },[]);

  if(isLoading || isCheckinsLoading) return (
    <div className={`${classes.root} d-flex h-100 position-relative align-items-center justify-content-center`}>
      <CircularProgress />
    </div>
  );
  return (
    <div className={`${classes.root} d-flex flex-column flex-md-row h-100 overflow-hidden`}>
      <Hidden smDown>
        <Options className={classes.leftPanel} measurementsAggregate={measurementsAggregate}
        measurableTags={measurableTags} handleViewSelect={handleViewSelect}
        exerciseData={exerciseData} view={view}/>
      </Hidden>
      <div className={`${classes.rightPanel} bg-white`} id='checkin-right-container'>
        <div className='d-flex flex-row fpy-25 fpx-20 border-bottom align-items-center'>
          <Typography variant="h3" className="text-capitalize">
            {replaceAllSafe(selectedTitle, '_', ' ')}
          </Typography>
          <div className='flex-grow-1'/>
          <Hidden mdUp>
            <IconButton onClick={toggleOpts} className='m-n8'><EditIcon/></IconButton>
          </Hidden>
        </div>
        {view ==='check_ins' ? <CheckinView {...props} scrollContainerId={'checkin-right-container'} />
          : view ==='compare_progress' ? <CompareProgress {...props} compChkinConf={compChkinConf} />
            : <MeasurementStats
                type={view.includes('ex_') ? 'ex' : 'mea'}
                data={view.includes('ex_') ? exerciseData : measurementsAggregate}
                view={view.includes('ex_') ? view.replace('ex_', '')  : view.replace('mea_', '')}
                id='measurement-stats'
              />
        }
        {opts && <Dialog open title='Options' onClose={toggleOpts}>
          <Options measurementsAggregate={measurementsAggregate}
          measurableTags={measurableTags} handleViewSelect={handleViewSelect}
          exerciseData={exerciseData} view={view}/>
        </Dialog>}
      </div>
    </div>
  );
};

const Options = ({asSelect = false, className, view, handleViewSelect, measurementsAggregate, measurableTags, exerciseData}) => {
  const mCount = _.keys(measurementsAggregate).length;
  return (
    <div id="progress_options" className={`${className || ''} fpy-15 fpx-20 bg-light`}>
      {[{title: 'check_ins', icon: CameraIcon}, {title: 'compare_progress', icon:ColumnIcon}].map(({title ,icon}, index) => 
        <CardText noLog
          key={title+index}
          title={title}
          className={index > 0 && 'fmt-15'}
          view={view}
          handleViewSelect={handleViewSelect} Icon={icon}
          keyword={title}
        />)}
      {!!measurementsAggregate && Object.keys(measurementsAggregate).length > 0 && (
        <Typography className='fmt-25 fmb-15'>
          Measurements
        </Typography>
      )}
      {!!measurementsAggregate && measurableTags && Object.keys(measurableTags).length > 0 &&  Object.keys(measurableTags).map((title, index) => 
        measurementsAggregate[title.toLowerCase()] ? (
          <CardText 
            type={'mea'}
            data={measurementsAggregate[title.toLowerCase()]}
            key={title+index}
            title={measurableTags[title].value}
            className={index > 0 && 'fmt-15'} 
            view={view}
            handleViewSelect={handleViewSelect}
            keyword={title}
          />
        ) : null
      )}
      <ExerciseProgress mCount={mCount} data={exerciseData} handleViewSelect={handleViewSelect} view={view} />
    </div>
  );
};

export const CardText = ({ 
  type='', data=false, title='', view='', handleViewSelect=null, Icon=false, className, keyword, noLog=false, ...rest 
}) => {
  const logs = !!data.views && !!data.primary ? _.get((data || {}), `${data.primary}.logs`, []) : _.get((data || {}), `logs`, []);
  const unit = !!data.views && !!data.primary ? _.get((data || {}), `${data.primary}.unit`, '') : _.get((data || {}), `unit`, '');
  const firstLogged = (logs && logs.length && logs[logs.length - 1]) || false;
  const lastLogged = (logs && logs.length && logs[0]) || false;
  return (
    <Card 
      elevation={1} 
      onClick={handleViewSelect(!!type ?  type + '_' + keyword.toLowerCase() : keyword.toLowerCase(), title)} 
      style={{maxWidth: '380px'}}
      className={clsx('p-20 cursor-pointer w-100',
        (!!type ? view === type + '_' + keyword.toLowerCase() 
          : view === keyword.toLowerCase()) && 'border border-dark', className)}
      {...rest}
      >
        {/*----- TITLE -----*/}
        <div className='d-flex align-items-center'>
          {Icon && <Icon className='fmr-15'/>}
          <Typography variant='h6' className='text-capitalize' noWrap>
            {replaceAllSafe(title, '_', ' ')}
          </Typography>
        </div>
        {/*----- LAST LOGGED VALUE -----*/}
        <div className={`justify-content-between mt-15 ${noLog ? 'd-none' : 'd-flex'}`}>
          <div className='d-flex align-items-center'>
            <Typography className='font_16_700' noWrap>
              {!lastLogged.value?"-"
              :<>
              {unit === 'duration' ? secondsToMinuteFormatted(lastLogged.value, { toSting: true, inSeconds: lastLogged.value <= 60 } ) 
                : roundNumber(lastLogged.value, 1)} {''}
              </>}
              {!!(unit) && unit !== 'duration' && (
                <span className='font_13_500'>
                  {unit}
                </span>
              )}
            </Typography>
          </div>
          {/*----- DIFFERENCE VALUE -----*/}
          {!!firstLogged && !!lastLogged && !!(logs.length>1) && (
            <div className='d-flex align-items-center h-100'>
              <DifferenceIcon
                difference={lastLogged.value - firstLogged.value}
                type={type}
                initialValue={firstLogged.value}
                lastValue={lastLogged.value} 
              />
              <Typography className="font_13_600 d-flex ml-6">
                {unit==="duration"?
                secondsToMinuteFormatted(roundNumber(Math.abs(lastLogged.value - firstLogged.value)),{ toSting: true, inSeconds: lastLogged.value <= 60 })
                :roundNumber(Math.abs(lastLogged.value - firstLogged.value))
                }
              {unit!=="duration" && <span className="font_13_500 ml-3px">{" "}{unit}</span>}
              </Typography>
              {!!data.target && <span className='font_13_500'>
               {' '} / {Math.abs(roundNumber(data.target - firstLogged.value, 1))}
               {' '} {unit}
              </span>}
            </div>
          )}
        </div>
    </Card>
  );
};
export const DifferenceIcon = ({ difference, target, initialValue, type='default', lastValue }) => {
  if(difference === 0) return (
    <SameBlueArrowIcon/> 
  );
  if(!difference) return null;
  if(type ==='default' || !target){
    if(!difference) return null;
    if(difference > 0){
      return (
        <UpBlueArrowIcon/>
      );
    };
    if(difference < 0){
      return (
        <DownBlueArrowIcon/>
      );
    };
    return null;
  };
  if(type === 'mea' && !!target){
    if(lastValue > initialValue){
      if(lastValue <= target) return <UpBlueArrowIcon style={{color: ArrowColors.green, width: '20px', height: '20px' }}  />
      if(lastValue > target) return <UpBlueArrowIcon style={{color: ArrowColors.red, width: '20px', height: '20px' }}  />
    };
    if(lastValue < initialValue){
      if(lastValue > target) return <DownBlueArrowIcon style={{color: ArrowColors.green, width: '20px', height: '20px' }}  />
      if(lastValue < target) return <DownBlueArrowIcon style={{color: ArrowColors.red, width: '20px', height: '20px' }}  />
    };
    return null;
  };
  return null;
}
export default withSnackbar(CheckIns);
