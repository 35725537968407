import {
  FormControl, FormHelperText,
  FormLabel,
  Typography,TextField,
  MenuItem,
  OutlinedInput,
  RadioGroup, FormControlLabel, Radio, Divider
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { RoleContext } from "fitbud/providers/roleProvider";
import { diff15 } from "fitbud/utils/scheduling";
import moment from "moment";
import { default as React, useContext, useEffect, useState } from 'react';
import { FormTextField } from "fitbud/components/form-fields";
import UserData from "./userData";
import { TimePick } from "fitbud/views/liveSessions/masterSettings/SlotPicker"
import { Select } from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import TrainerSelector from "fitbud/views/liveSessions/booking-dialog/trainerSelector";
import _ from "lodash";

export const DatePickerField = props => {
  const InputProps = {
    classes: {
      root: "medium",
      input: "size_16_500 medium"
    },
    ...props.InputProps,
  };
  return (
    <FormTextField fullWidth label= {!props.hideLabel && "Date"} >
      <TextField {...props} InputProps={InputProps} />
    </FormTextField>
  );
};

const skipTo15Mins = (time) => {
  // Returns time with minutes as 15 multiples
  const t = moment(time);
  const remainder = 15 - (t.minute() % 15);
  const newTime = moment(t)
    .add(remainder, "minutes");
  return newTime.toDate();
}

const BookingInfo =  (props) => {
  const {rescheduleMode, data,
    user, onChange, setErrors, errors= {}, goBack, isVideoEnabled, 
    userBookingsCount, setUserBookCount, directBooking  } = props;
  const { cid, authUser: { uid } = {} } = useContext(FirebaseAuthContext);
  const { isOwner, tEnabled } = useContext(RoleContext);

  const [date, setDate] = useState(rescheduleMode ? new Date(data.startDate) : moment().toDate());
  const [time, setTime] = useState(rescheduleMode ? new Date(data.startDate) : skipTo15Mins(moment()));
  const [duration, setDuration] = useState(rescheduleMode ? data.duration : 15);
  const [isTrainerBooking, setTrainerBooking] = useState(rescheduleMode ? data.isTrainerBooking : true);
  const [isInstant, setIsInstant] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(_.get(user, "trainer_id", uid));
  async function handleDateChange(date){
    setErrors({});
    let newDate = moment(date, 'MMMM Do YYYY')
    setDate(newDate);
  }
  
  function handleSlotChange(time) {
    setErrors({});
    setTime(moment(time, 'hh:mm a').toDate());
  }

  function handleDurationChange(e) {
    const { name, value } = e.target;
    if (name === "duration") {
      setDuration(value);
    }
  }

  function checkTrainerBooking() {
    setTrainerBooking(!isTrainerBooking);
  }
 
  function mergeTime(date, time){
    // Merge date & time variable to make utc slot
    date = moment(date)
    time = moment(time)
    let newTime = moment(date.format('YYYYMMDD'), 'YYYYMMDD').set({
      hours: time.hours(),
      minutes: time.minutes(),
      seconds: 0,
      milliseconds: 0,
    })
    return newTime.toDate();
  }
  const handleInstantChange = (e) => {
    if(e.target.value === 'instant'){
       setIsInstant(true);
       setDate(moment().toDate());
       setTime(moment().add(1, 'minutes'));
    }else{
      setIsInstant(false);
      setDate(moment().toDate());
      setTime(skipTo15Mins(moment()));
    }
  }

  useEffect(()=>{
    if(!isOwner) setTrainerBooking(false);
  },[isOwner])

  const handleSelectedTrainer = (value)=>{
    setSelectedTrainer(value);
  };

  useEffect(()=> {
    // Pass the change up to parent each time anything changes
    onChange({time: mergeTime(date, time).toISOString(), duration, isTrainerBooking, selectedTrainer})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, date, duration, isTrainerBooking, selectedTrainer])

  return (<>
    <UserData 
      cid={cid}
      date={date}
      user={rescheduleMode ? data.user : user}
      rescheduleMode={rescheduleMode} 
      onNameBoxClick={directBooking ? null : goBack}
      disableEdit={directBooking || rescheduleMode}
      isTrainerBooking={isTrainerBooking}
      checkTrainerBooking={checkTrainerBooking}
      isVideoEnabled={isVideoEnabled}
      userBookingsCount={userBookingsCount}
      setUserBookCount={setUserBookCount}
      isInstant={isInstant}
      handleInstantChange={handleInstantChange}
    />
    <Divider className='fmy-10'/>
    {/* INSTANT / LATER */}
    <div className='fmt-20'>
      <FormControl component="fieldset">
        <RadioGroup value={isInstant ? 'instant' : 'later'} onChange={handleInstantChange}>
          <div className='d-flex'>
            <FormControlLabel value="instant" control={<Radio color="primary" />} label="Instant Call" className='fmr-30'/>
            <FormControlLabel value="later" control={<Radio color="primary" />} label="Schedule Later" />
          </div>
        </RadioGroup>
      </FormControl>
    </div>
    {!!isOwner && !!tEnabled && <TrainerSelector value={selectedTrainer} onChange={handleSelectedTrainer} />}
    <div className='d-flex'>
      {/* DATE AND TIME INPUT */}
      {!isInstant && <FormControl fullWidth className="w-40 pr-3">
        <DatePicker
          allowKeyboardControl
          animateYearScrolling={false}
          disablePast
          onChange={handleDateChange}
          value={date}
          name="date"
          format='MMM D, YYYY'
          inputVariant="outlined"
          TextFieldComponent={DatePickerField}
          KeyboardButtonProps={{
            "aria-label": "date"
          }}
        />
      </FormControl>}
      {/* SLOT SELECTOR */}
      {!isInstant && <FormControl stylefullWidth className="w-30 pr-3" error={!!errors.time}>
        <FormLabel>
          <Typography variant={"body2"} component="label" color="textSecondary">
            Time{" "}
          </Typography>
        </FormLabel>
        <TimePick
          error={Boolean(errors.time)}
          value={time} 
          format="hh:mm a"
          onChange={handleSlotChange}
          name="slot"
          minTime={moment().toDate()}
        />
        {!!errors.time ? <FormHelperText>{errors.time}</FormHelperText> : null}
      </FormControl>}
      {/* Duration INPUT */}
      <FormControl fullWidth className={isInstant ? "" : "w-30"} error={!!errors['duration']}>
        <FormLabel>
          <Typography variant={"body2"} component="label" color="textSecondary">
            {'Duration'}
          </Typography>
        </FormLabel>
        <Select
          fullWidth
          value={duration ? duration : ""}
          onChange={handleDurationChange}
          IconComponent={DropDownIcon}
          input={<OutlinedInput
            classes={{ root: "medium", input: "size_16_500 select-medium" }}
            name={'duration'}/>}>
          {diff15 &&
            diff15.map((item, index) => {
              if (item.hasOwnProperty('label') && item.hasOwnProperty('value')) {
                return <MenuItem value={item.value} key={item.value + index}>{item.label}</MenuItem>;
              } else {
                return <MenuItem value={item} key={item + index}>{item} mins</MenuItem>;
              }
            })}
        </Select>
        {errors['duration'] ? <FormHelperText>{errors['duration']}</FormHelperText> : null}
      </FormControl>
    </div>
  </>);
};


export default BookingInfo