import firebase from "fitbud/firebase";
import uuidv4 from "uuid/v4";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { get } from "lodash";
import { getVimeoThumbnail, getYTThumbnail, parseVimeoVideoId } from "fitbud/views/workouts/helperfn";

const storageRef = () => firebase.storage().ref();

export const uploadFile = async ({ file, filePath }) => {
  
  return new Promise((resolve, reject) => {
    if (!file) reject("File not found");
    let reader = new FileReader();
    reader.onloadend = async e => {
      let blob = new Blob([file], { type:file.type });
      const fileId = uuidv4() + "." + (file.name || "").split(".").pop();
      const path = filePath + fileId ;
      try{
        const uploadTask = await storageRef().child(path).put(blob);
        const fileSource = await uploadTask.ref.getDownloadURL();
        resolve(fileSource);
      }catch(err){
        console.log(err);
        reject(err);
      }
      // return [fileSource, fileId];
    };
    reader.onerror = e => {
      reject(e);
    };
    reader.readAsDataURL(file);
    return;
  });
};

export const getTags = async (name, source = '', compDoc = null) => {
  if (!name) return;
  let out = null;

  if (compDoc && compDoc.exists) {
    let { app_config } = compDoc.data();
    out = (app_config && app_config.tags && app_config.tags[name]) || null;
  }

  if (!out) {
    const snapshot = await firebase.firestore().collection(`tags`).doc(name).get();
    out = snapshot.data();
  }

  const cid = window.localStorage.getItem('cid');
  const keys = Object.keys(out);
  keys.forEach(key => {
    const { only, modules } = out[key];
    if (only && only.length && !only.includes(cid))
      delete out[key];
    if (!!source && modules && modules.length && !modules.includes(source))
      delete out[key];
  });
  return out;
};
export const uploadDataUrl = (uri, path) => {
  return new Promise((resolve, reject) => {
    if (!uri) reject("URI not found");
    const uploadTask = storageRef().child(path).putString(uri, "data_url");
    uploadTask.on(
      "state_changed",
      function progress(snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      function error(err) {
        reject(err);
      },
      function complete() {
        resolve();
      }
    );
  });
};
export const imageResizer = async (file, type, maxWidth, maxHeight, outputType) => {
  let compressFormat ;
  if(type ==='JPG')
    compressFormat='JPEG';
  else
    compressFormat=type;
  const {aspect, width, height} = await getImageMeta(file);
  if(aspect >= 1) {
    if(width <= maxWidth) return fileToBase64(file);
    else {
      return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          Math.round(maxWidth/aspect),
          compressFormat,
          100,
          0,
          uri => {
            if (!uri) reject(null);
            else resolve(uri);
          },
          outputType
        );
      });
    }
  }else {
    if(height <= maxHeight) return fileToBase64(file);
    else {
      return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          Math.round(aspect * maxHeight),
          maxHeight,
          compressFormat,
          100,
          0,
          uri => {
            if (!uri) reject(null);
            else resolve(uri);
          },
          outputType
        );
      });
    }
  }
};


export const fileToBase64 =  (file) => {
  return new Promise((resolve, reject) => {
    if (!file) reject("File not found");
    let reader = new FileReader();
    reader.onloadend =  e => {
      resolve(reader.result);
    };
    reader.onerror = e => {
      reject(e);
    };
    reader.readAsDataURL(file);
    return;
  });
};
export const getVideoMeta = (file) => {
  let aspect, width, height, duration;
  return new Promise((resolve, reject) => {
    if (!file) reject("File not found");
    const video = document.createElement('video');
    video.onerror = () => {
      alert ("Error!");
    };
    video.onloadedmetadata = (e) => {
      const { videoWidth=300, videoHeight=300, duration: _duration=1  } = video || {};
      duration = _duration;
      width = !Number.isNaN(videoWidth) && !!videoWidth ? videoWidth : 300;
      height = !Number.isNaN(videoHeight) && !!videoHeight ? videoHeight : 300;
      aspect = Number((width / height).toFixed(2));
      resolve({ aspect, width, height, duration });
    }
    video.src = URL.createObjectURL(file);
  })
}
export const getImageMeta = (file) => {
  let aspect, width, height;
  return new Promise((resolve,reject)=> {
    if (!file) reject("File not found");
    const img = new Image();
      img.onload = function() {
        aspect = this.width / this.height;
        width = this.width;
        height = this.height;
        // URL.revokeObjectURL(img.src)
        resolve({aspect, width, height});
      };
      img.onerror = function(e) {
        reject(e);
      }
      img.src = URL.createObjectURL(file);
  })
};

export const  sendMessage  = async (userToken, docId) => {
  const config = {
    method: 'POST',
    url: `https://bff.fitbudd.com/rpc/sendMessage?fb-project=${process.env.REACT_APP_FB_PROJECT}&fb-token=${userToken}`,
    data : {user_id: docId}
  };
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error.message);
  });
};

export const captureFrame = async(isFrameSelector=false) => {
  try{
    let element = isFrameSelector ? document.getElementById("frame-selector") : document.getElementById('media_bgon');
    if(!element) return;
    let video = element.getElementsByTagName('video');
    video=get(video,'0',null);
    if(!video) return;
    const format='jpeg'
    let canvas = document.createElement("CANVAS");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    let thumbnail = canvas.toDataURL('image/' + format,1);
    if(!thumbnail) {
      throw new Error();
    }
    return thumbnail;
  }
  catch(err){
    //something went wrong;
    console.log(err, ">>");
  }
}

export const fetchVideoThumbnail = async (media) => {
  let out = null;
  const { type, url } = get(media, "0", {});
  switch (type) {
    case "youtube": {
      let thumbnail = await getYTThumbnail(url);
      if (!thumbnail) throw new Error("no thumbnail")
      out = thumbnail;
      break;
    }
    case "vimeo": {
        const thumbnail = await getVimeoThumbnail(parseVimeoVideoId(url));
        if (!thumbnail) throw new Error("no thumbnail")
        out = thumbnail;
        break;
    }
    default: {
        const thumbnail = await captureFrame();
        if (!thumbnail) throw new Error("no thumbnail");
        out = thumbnail;
        break;
    }
  }
  return out;
}
