import _ from "lodash";
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'
import moment from "moment";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import {
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Hidden,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  downloadFile } from "fitbud/utils/helpers";
import { ShowDate, getDownloadUrl, getImageDownloadUrl } from 'fitbud/views/chats/chatView';
import ScrollToBottom from "fitbud/views/chats/scrollToBottom";
import { GroupChatMessagePlaceholder } from 'fitbud/views/groupChat/placeholder';
import firebase from 'firebase';
import { ArrowBackIos, Close } from '@material-ui/icons';
import Confirmation from 'fitbud/components/confirmationDialog';
import Users from "fitbud/views/users";
import { NavContext } from "fitbud/providers/appNav";
import { DEFAULT_ERROR, DrawerWidth, DrawerWidthMinified } from "fitbud/utils/constants";
import { LEFT } from "fitbud/hooks/useSplit";
import { useSnackbar } from "notistack";
import * as Sentry from '@sentry/browser';
import GrpMessage from './grpMessage';

const useStyles = makeStyles(theme => ({
  chatArea: {
    background: "#ffffff",
    flex: 1,
    padding: '0 20px',
    overflow: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 187px)"
    }
  },
  rightMsg: {
    maxWidth: "max-content",
    background: "#FFF",
    border: '2px solid #F0F3F5',
    wordWrap: "break-word",
    marginLeft: "auto",
    borderRadius: "10px 10px 0px 10px",
  },
  mediaRight: {
    padding: 0,
    marginLeft: "auto",
    border: "none"
  },
  leftMsg: {
    maxWidth: "max-content",
    background: "#F0F3F5",
    wordWrap: "break-word",
    border: '2px solid #F0F3F5',
    position: "relative",
    borderRadius: "10px 10px 10px 0px"
  },
  mediaLeft: {
    padding: 0,
    marginRight: "auto",
    border: "none"
  },
  messageAvatar:{
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  menu:{
    padding: 0
  },
  negMargin:{
    marginTop: '-1px',
  },
  normalMargin:{
    marginTop: "5px",
  },
  inChatLoader:{
    position: 'absolute',
    top: 0,
    left: "50%",
    transform: "translate(-50%)",
  },
  ltContainer:{
    whiteSpace: "pre-wrap",
    paddingRight: "30%",
    maxWidth: '100%',
  },
  rtContainer:{
    whiteSpace: "pre-wrap",
    paddingLeft: "30%",
    maxWidth: '100%',
  },
  toggleButton : {
    position: "absolute",
    left: "-50px",
    top: "10px",
    zIndex: theme.zIndex.drawer + 2,
  },
  drawerPaper : {
    overflow: "visible",
    maxWidth: "100%",
    width: ({ isExpanded = false, isMobile }) => isMobile ? "100%" :`calc(100% - ${isExpanded ? DrawerWidth + LEFT : DrawerWidthMinified + LEFT}px)`,
  },
  drawerRoot: {
    overflow: "visible",
    zIndex: `${theme.zIndex.drawer} !important`,
    width: ({ isExpanded = false, isMobile }) => isMobile ? "100%" :`calc(100% - ${isExpanded ? DrawerWidth + LEFT : DrawerWidthMinified + LEFT}px)`,
    maxWidth: ({ isExpanded = false, isMobile }) => isMobile ? "100%" :`calc(100% - ${isExpanded ? DrawerWidth + LEFT : DrawerWidthMinified + LEFT}px)`,
  },
}));

function GroupChatMessageListView(props) {
  const { messages, fetchMore, lastFetchedMsg, fetching, groupId, profileCache, showLoader, hideLoader, scrollToId } = props;
  const { cid, authUser, comp } = useContext(FirebaseAuthContext);
  const { drawerState } = useContext(NavContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [view_width, setViewWidth] = useState(0);
  const [userDrawer, setUserDrawer] = useState(false);
  const [downloadResource, setDownloadResource] = useState({type: '', data: {}, id: ''});
  const closeUserDrawer = () => setUserDrawer(false);
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles({ isExpanded: drawerState.expanded, isMobile });
  const isScrolled = useRef(false);

  async function beginDownload() {
    showLoader();
    try {
      const { type, data, id } = downloadResource;
      let downloadUrl;
      if (type === 'image') {
        downloadUrl = await getImageDownloadUrl(data);
      } else {
        downloadUrl = await getDownloadUrl(props.id, data);
      }
      if (!downloadUrl) throw new Error('invalid url');
      await downloadFile(downloadUrl, type === 'other' ? data.body : id);
      hideLoader();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  }

  const decideToShowUnread = useCallback(
    _.debounce(() => {
      const chatarea = document.getElementById("groupChatArea");
      const last_msg = document.getElementById(`groupMessagesEnd`);
      if (!chatarea || !last_msg) return;
      if (last_msg.getBoundingClientRect().bottom - chatarea.getBoundingClientRect().bottom <= 100) {
        setShow(false);
      } else {
        setShow((chatarea.getBoundingClientRect().bottom < last_msg.getBoundingClientRect().bottom))
      }
    }, 100),
    [],
  );

  useEffect(() => {
    if (document.getElementById("groupMessagesEnd") && !show) {
      document.getElementById("groupMessagesEnd").scrollIntoView({ behaviour: "smooth", block: "end" });
    }
    setViewWidth(!!document.getElementById("groupChatArea") 
    && document.getElementById("groupChatArea").getBoundingClientRect().width)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  useEffect(() => {
    const chatarea = document.getElementById("groupChatArea");
    chatarea.addEventListener("scroll", decideToShowUnread);
    return () => {
      chatarea && chatarea.removeEventListener("scroll", decideToShowUnread);
    };

  }, [decideToShowUnread]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleOpenMessageOption = (e, id, authorId, resource) => {
    setDownloadResource(resource)
    e.preventDefault();
    e.stopPropagation();
    const ele = e.target;
    setAnchorEl({element: ele, id, authorId });
  }

  const handleDeleteMessage = (e) => {
    e.preventDefault();
    if(deleteConfirm){
      showLoader();
      firebase.firestore()
        .doc(`groupChats/${groupId}/messages/${deleteConfirm}`)
        .update( { deleted: true, _uat: firebase.firestore.FieldValue.serverTimestamp() }).then(() => {
          setDeleteConfirm(false);
          hideLoader();
        });
    }
  }

  const handlePrivateReply = () => {
    setUserDrawer(`${cid}:${anchorEl.authorId}`);
  };

  let show_date = false;
  let date1;
  if (!!messages  && !!messages.length) {
    show_date = true;
    let timestamp_current = (messages[0].data().timestamp && messages[0].data().timestamp.toMillis())|| new Date();
    date1 = moment(timestamp_current).local();
  }

  const handleScroll = (e) => {
    isScrolled.current = e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight;
    if(e.target.scrollTop > 0) return;
    //scroll previous messages
    if (fetching) return;
    fetchMore();
  };

   const scrollToItem = () => {
    if (!!scrollToId) {
      document.getElementById(scrollToId).scrollIntoView({ behaviour: "smooth", block: "end"});
    }
  };

  useEffect(scrollToItem, [messages.length]);
  return (
    <div className={classes.chatArea} id="groupChatArea" onScroll={handleScroll}>
      <div className={clsx(
        "mx-auto text-center position-absolute", 
        lastFetchedMsg && fetching ? 'visible' : 'd-none',
        classes.inChatLoader,
      )}>
        <CircularProgress size={22} />
      </div>
      {show_date && <ShowDate date={date1} />}
      {!!messages && !!messages.length ? messages.map((msg, index) => {
        return(
          <GrpMessage msg={msg} key={msg.id} index={index} messages={messages}
            groupId={groupId} view_width={view_width} classes={classes}
            profileCache={profileCache} id={props.id} onOptionClick={handleOpenMessageOption}/>
        )
      }) : <GroupChatMessagePlaceholder /> }
      <div id="groupMessagesEnd" />
      <ScrollToBottom show={show} id="groupChatArea" />
      <Confirmation
        title="Please confirm"
        confirmOption="Yes, Delete"
        msg="Are you sure you want to delete the message?"
        open={!!deleteConfirm}
        handleCancel={() => setDeleteConfirm(false)}
        handleChange={handleDeleteMessage} 
      />
      <Menu
        anchorEl={anchorEl && anchorEl.element}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        classes={{
          paper: `fb-border`,
          list: "p-0"
        }}
        id="delete_pop_up"
        onClick={handleCloseMenu}
      >
        {anchorEl && anchorEl.authorId !== cid &&
          <MenuItem onClick={handlePrivateReply}>
            Reply Privately
          </MenuItem>}
        {downloadResource.type !== 'text' && (
          <MenuItem onClick={beginDownload}>
            Download
          </MenuItem>
        )}
        <MenuItem onClick={() => setDeleteConfirm(anchorEl.id)}>
          Delete
        </MenuItem>
      </Menu>
      {!!userDrawer &&
          <Drawer variant="temporary" open={!!userDrawer} anchor="right" classes={{ root: classes.drawerRoot, paper: classes.drawerPaper}}
            ModalProps={{ BackdropProps: { onClick: closeUserDrawer } }}>
            <Hidden smDown>
              <IconButton onClick={closeUserDrawer} className={clsx(classes.toggleButton, "bg-grey-new")}>
                <Close />
              </IconButton>
            </Hidden>
            <Hidden mdUp>
                <AppBar position="sticky" color="primary">
                  <Toolbar classes={{ root: "height-60" }} className={"d-flex flex-row bg-white justify-content-between align-items-center border-bottom fpx-10"}>
                    <div className='d-flex align-items-center'>
                      <IconButton edge="end" color="inherit" onClick={closeUserDrawer}>
                        <ArrowBackIos />
                      </IconButton>
                      <Typography variant="h3" className='fml-20'>{"Back to Group Chat"}</Typography>
                    </div>
                  </Toolbar>
                </AppBar>
              </Hidden>
            {!!userDrawer && <Users isDrawerMode page={"chats"} scope={"chats"} uid={userDrawer} />}
          </Drawer>
        }
    </div>
  )
};

export default GroupChatMessageListView;
