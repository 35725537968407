import React from "react";
import {
  Typography,
} from "@material-ui/core";

import Megaphone from "fitbud/images/megaphone.svg";

// export const BroadcastPlaceholder = () => {
//   return (<div className=" h-100 text-center d-flex align-items-center justify-content-center flex-column px-5 ">
//     <img alt="noitem" src={Megaphone} />
//     <Typography variant="h5" className="fmt-20">
//       Send messages to multiple clients at once.
//     </Typography>
//     <Typography variant="h5" className="fmt-20">
//       To start sending, select the audience from the left panel.
//     </Typography>
//   </div>)
// }

export const BroadcastMessageViewPlaceholder = () => {
  return (<div className=" h-100 text-center d-flex align-items-center justify-content-center flex-column px-5 ">
    <img alt="noitem" src={Megaphone} />
    <Typography variant="h3" className="fmt-20">
      Send a new Broadcast
    </Typography>
    <Typography variant="subtitle1" className="fmt-15 text-muted mx-5">
      Your broadcast will be shared as a private 1-1 message with all the clients in this group. The clients can follow up on the shared broadcast via DM individually.
    </Typography>
  </div>)
}

