import React, { useState, useEffect } from 'react';
import { Chip, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import {isObject} from 'lodash';

export const useStyles = makeStyles(() => ({
  chip: {
    // margin: '0 7px 15px 7px',
    marginRight:15,
    maxWidth:250,
    marginBottom:15,
    height: 32,
    backgroundColor: '#F2F4F7',
    border: ( {noBorder = false } ) => !noBorder && '1px solid #D3D9DB',
  },
  selectedChip: {
    border: '1px solid #317FF5',
    backgroundColor: 'white',
  },
  chipLabel: {
    paddingLeft:14,
    paddingRight:14,
    lineHeight:"normal",
    fontWeight: 500,
    fontSize: 13,
    color: '#37404D',
  },
  selectedChipLabel: {
    paddingLeft:14,
    paddingRight:14,
    fontWeight: 500,
    fontSize: 13,
    color: '#317FF5',
    lineHeight:"normal",
  },
  selectedClickable: {
    cursor: 'pointer',
    color: '#317FF5',
    border: '1px solid #317FF5',
    '&:focus , &:hover': {
      backgroundColor: '#FFF',
    },
  },
  unselectedClickable: {
    cursor: 'pointer',
    '&:focus, &:hover': {
      backgroundColor: '#F2F4F7',
      color: '#65768C',
    },
  },
}));

const ChipGroups = (props) => {
  const { fields, componentState, handleChange, prefix = null, label, className, labelClass,classes:propClasses={}, noBorder, clickable= true, addMore=null } = props;
  const [state, setState] = useState(componentState || []); // state : string[]

  useEffect(() => {
    setState(componentState);
  }, [componentState]);

  const classes = useStyles( { noBorder });
  const handleChipClick = (e, f) => {
    handleChange &&
      handleChange({
        target: {
          name: f.value,
        },
      });
  };

  const _fields = (fields || []).map((f) => {
    if (isObject(f)) {
      return { ...f, checked: state && state.includes(f.value) };
    } else {
      return { label: `${prefix || ''}${f}`, value: f, checked: state && state.includes(f), id: f.id || f };
    }
  });

  return (
    <div className={clsx(className, propClasses.root)}>
      {!!label && <Typography className="fmb-15 font_18_600">{label}</Typography>}
      <div className={clsx('d-flex flex-wrap', propClasses.container)}>
        {_fields.map((f, i) => {
          return (
            <Chip
              label={f.label}
              id={f.id}
              onDelete={f.onDelete || null}
              clickable={clickable}
              size="medium"
              className="rounded-pill border-none"
              onClick={(e) => (f.onClick && f.onClick(e)) || handleChipClick(e, f)}
              classes={{
                root: clsx(classes.chip, f.checked && classes.selectedChip),
                label: clsx(f.checked ? classes.selectedChipLabel : clsx(classes.chipLabel,labelClass, propClasses.label)),
                clickable: clsx(f.checked ? classes.selectedClickable : classes.unselectedClickable ),
              }}
            />
          );
        })}
        {!!addMore && <Chip 
          label={addMore.label}
          size="medium"
          className="rounded-pill border-none"
          onClick={() => addMore.onClick({target:{name:addMore.value}})}
          classes={{
            root: clsx(classes.chip),
            label: clsx(classes.chipLabel,'text-primary font-weight-600',propClasses.label),
            clickable: clsx(classes.unselectedClickable )
          }}
          disabled={addMore.disabled}
        />}
      </div>
    </div>
  );
};

export default ChipGroups;
