import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import update from "immutability-helper";

import * as Sentry from '@sentry/browser';
import {DEFAULT_ERROR,PUBLISHED,DRAFT_STATES,DRAFT,VIEWS_CONSTANTS,OFFLINE_ERROR,FETCH_ERROR} from "fitbud/utils/constants";
import { isOnline } from "fitbud/utils/helpers";
import CreateEditForm from "./createEditForm";
import firebase from "fitbud/firebase";
import { IconButton, Tooltip } from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import mealRepo from "fitbud/repo/meals";
import suppRdxFns from "fitbud/redux/supplements";
import foodRecipesRepo from "fitbud/repo/foodRecipes";
import appRdxFns from "fitbud/redux/app";
import { getTags, uploadFile } from "fitbud/utils/services.js";
import Details from "./details";
import SupplementsForm from "./supplementsForm";
import Confirmation from "fitbud/components/confirmationDialog";
import {
  ERROR_TITLE,
  ERROR_REF_NAME,
  ERROR_DESCRIPTION,
  MEAL_STORAGE_FILE_PATH
} from "fitbud/utils/constants";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import PageNotFound from "fitbud/views/pageNotFound";
import ImportIcon from "fitbud/icons/importIcon";
import ImportSupplementDialog from "./importSupplementDialog";
import CircularLoader from "fitbud/components/CircularLoader";
import uuidv4 from "uuid/v4";
import {Title} from "fitbud/views/workouts/form";
import {PublishedDrawer} from "fitbud/views/workouts/publishdedDrawer";
import { CustomConfirmation } from "fitbud/components/customConfirmation";
import clsx from 'clsx';
import { ClrdButton } from "fitbud/components/form-fields";
import {SaveIcon} from "fitbud/icons/saveIcon";
import {calculatePublishStatus} from "fitbud/utils/catalog";

const DEFAULT = {
  ref_name: '', title: '', thumbnail: '', description: '',
};

class Info extends React.Component {
  constructor(props) {
    super(props);
    let defaultDoc = props.scheduleDoc
      ? _.cloneDeep({ ...props.doc, ...props.scheduleDoc })
      : props.doc;
    this.state = {
      loader: true,
      isValidId: true,
      doc: defaultDoc,
      oldDoc: props.doc,
      draftDoc: {},
      publishedDoc: {},
      foodRecipes: [],
      errors: {},
      editMode: false,
      supplementEditorMode: false,
      mediaFiles: (props.doc && props.doc.media) || [],
      servingData: [],
      dirty: false,
      isConfirmationOpen: false,
      displayEditOption: props.edit,
      showingDoc: PUBLISHED,
      viewOrigDoc: false,
    };
  }

  static contextType = FirebaseAuthContext;

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get draftFeature(){
    const {comp} = this.context;
    if(_.isUndefined(this.props.draftFeature)){
      return !!comp.data().features.draft;
    }
    return this.props.draftFeature;
  }
  get isDraftAvailable() {
    return !!_.get(this.state, "publishedDoc.publish_status",PUBLISHED).includes('draft');
  }

  handleChange = e => {
    const groupIndex = e.target.dataset
      ? parseInt(e.target.dataset.group)
      : null;
    const mealItemIndex = e.target.dataset
      ? parseInt(e.target.dataset.itemindex)
      : null;

    let chng;
    if (e.target.type === "number") {
      e.target.value = Number(e.target.value);
    }
    chng = { [e.target.id || e.target.name]: e.target.value };
    if (
      groupIndex !== null &&
      !isNaN(groupIndex) &&
      mealItemIndex !== null &&
      !isNaN(mealItemIndex)
    ) {
      chng = { [e.target.id || e.target.name]: Number(e.target.value) };
      const newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              supplements: {
                [mealItemIndex]: {
                  $merge: chng
                }
              }
            }
          }
        }
      });
      this.setState({ ...newState, errors: {}, dirty: true });
    } else {
      this.setState(s => ({
        doc: { ...s.doc, ...chng },
        errors: {},
        dirty: true
      }));
    }
  };

  updateParent = params => {
    this.setState(s => ({ doc: { ...s.doc, ...params } }));
  };

  handleReorder = ({ grpIndex, supplements }) => {
    const newState = update(this.state, {
      doc: {
        groups: {
          [grpIndex]: {
            supplements: {
              $set: supplements
            }
          }
        }
      }
    });
    this.setState({ ...newState, errors: {}, dirty: true });
  };

  closeMetaForm = () => {
    const newState = update(this.state, {
      doc: {
        $set: _.cloneDeep({ ...this.state.oldDoc, ...this.props.scheduleDoc })
      },
      editMode: {
        $set: false
      },
      errors:{
        $set:{}
      },
      dirty:{
        $set:false,
      },
      isConfirmationOpen:{
        $set:false
      }
    });
    this.setState({ ...newState });
  };
  closeEditor=(action)=>{
    if(action==='close' && this.state.dirty){
      this.setState({isConfirmationOpen:true});
      return;
    }
    if(action==="done"){
      if(!this.state.dirty && !this.props.isEditorMode && !this.isDraftAvailable){
        //close
      }
      else if(this.draftFeature){
        this.setState({ isConfirmationOpen: true });
        return;
      }
      else{
        this.handlePublish();
        return;
      }
    }
    const newState = update(this.state, {
      doc: {
        $set: _.cloneDeep({ ...this.state.oldDoc, ...this.props.scheduleDoc })
      },
      supplementEditorMode: {
        $set: false
      },
      errors: { $set: {} },
      isConfirmationOpen: { $set: false },
      dirty: { $set: false },
    });
    this.setState({ ...newState});
  }

  handleEditCancel = () => {
    const newState = update(this.state, {
      doc: {
        $set: _.cloneDeep({ ...this.state.oldDoc, ...this.props.scheduleDoc })
      },
      supplementEditorMode: {
        $set: false
      },
      errors: { $set: {} },
      isConfirmationOpen: { $set: false },
      dirty: { $set: false },
    });
    this.setState({ ...newState});
  };

  handleMealType = (grpIndex, value) => {
    const { doc, mealCategories } = this.state;
    if (doc.groups[grpIndex].type === value || value === "select") return;
    let newState = { ...this.state };

    const existCatIndex = doc.groups.findIndex(grp => grp.type === value);
    if (existCatIndex > -1) {
      let newFoodItems = newState.doc.groups[grpIndex];

      const existedItems = newState.doc.groups[existCatIndex].supplements;
      newFoodItems = newState.doc.groups[grpIndex].supplements.filter(
        newItem =>
          !existedItems.find(existItem => {
            return existItem.ref.id === newItem.ref.id;
          })
      );

      newState = update(newState, {
        doc: {
          groups: {
            [existCatIndex]: {
              supplements: {
                $push: [...newFoodItems]
              }
            }
          }
        }
      });
      newState = update(newState, {
        doc: {
          groups: {
            $splice: [[grpIndex, 1]]
          }
        }
      });
    } else {
      let order = mealCategories.findIndex(cat => cat[0] === value);
      order = order === -1 ? 1 : order + 1;
      newState = update(newState, {
        doc: {
          groups: {
            [grpIndex]: {
              type: {
                $set: value
              },
              order: {
                $set: order
              }
            }
          }
        }
      });
    }
    // ordering of the meal categories

    const newGroups = newState.doc.groups.sort((a, b) => a.order - b.order);

    newState = update(newState, {
      doc: {
        groups: {
          $set: newGroups
        }
      }
    });
    this.setState({ ...newState, errors: {}, dirty: true });
  };

  handleDelete = payload => {
    const { grpIndex, itemIndex, type } = payload;
    let newState;

    if (
      this.state.doc.groups[grpIndex].supplements.length === 1 ||
      type === "group"
    ) {
      newState = update(this.state, {
        doc: {
          groups: {
            $splice: [[grpIndex, 1]]
          }
        }
      });
    } else {
      newState = update(this.state, {
        doc: {
          groups: {
            [grpIndex]: {
              supplements: {
                $splice: [[itemIndex, 1]]
              }
            }
          }
        }
      });
    }

    this.setState({ ...newState, errors: {}, dirty: true });
  };

  handleSupplementsAdd = async payload => {
    const groupIndex = payload.grpIndex;
    let newState;
    let recpIds = [];
    //if inside a group
    if (groupIndex !== null) {
      const newItem = payload.data.map(d => {
        recpIds.push(d._id);
        const supplement = {
          ref: firebase
            .firestore()
            .doc(`companies/${this.context.cid}/foodRecipes/${d._id}`),
          quantity: 1
        };
        return supplement;
      });
      newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              supplements: {
                $push: [...newItem]
              }
            }
          }
        }
      });
    }

    const foodRecipesData = await this.fetchRecipes(recpIds);
    newState = update(newState, {
      foodRecipes: {
        $push: [...foodRecipesData]
      }
    });
    this.setState({ ...newState, errors: {}, dirty: true });
    return;
  };

  handleEditMode = () => {
    this.setState({ editMode: true});
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };

  addMealCategory = () => {
    const newState = update(this.state, {
      doc: {
        groups: {
          $push: [
            {
              supplements: []
            }
          ]
        }
      }
    });
    this.setState({ ...newState, errors: {}, dirty: true });
  };

  handleMedia = file => {
    let newState;
    if (file) {
      newState = update(this.state, {
        doc: {
          thumbnail: {
            $set: {
              file: file,
              url: URL.createObjectURL(file)
            }
          }
        }
      });
    } else {
      newState = update(this.state, {
        doc: {
          thumbnail: {
            $set: ""
          }
        }
      });
    }
    this.setState({ ...newState, errors: {}, dirty: true });
  };

  valid = (isPublish) => {
    let out = true;
    let errors = {};
    const { description, title="", ref_name="", groups } = this.state.doc;

    if (!title.trim()) {
      errors.title = ERROR_TITLE('display name');
      out = false;
    }

    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }
    if(!groups.length && isPublish){
      if(this.isNew){
        //do nothing
      }
      else if(this.draftFeature || (!this.draftFeature && this.state.supplementEditorMode)){
        this.props.enqueueSnackbar(
          "Please add category to complete supplement plans",{
            variant:"error"
          }
        );
        errors.category = `Please add category to complete the supplement plan`;
        out=false;
      }
    }
    errors.groups = [];
    groups.forEach((cat, i) => {
      errors.groups[i] = {};
      if (!cat.type) {
        errors.groups[i].type = `Please select a valid category for each supplement`;
        out = false;
      }

      if (!!cat.supplements && !cat.supplements.length) {
        errors.groups[i].supplement = `Please add supplements in each category to complete the plan`;
        out = false;
      }

      errors.groups[i].supplements = [];
      cat.supplements.forEach((supplement, suppIndex) => {
        errors.groups[i].supplements[suppIndex] = {};
        if (!supplement.quantity) {
          errors.groups[i].supplements[suppIndex].quantity =
            "Please provide a valid value";
          out = false;
        }
      });
    });

    this.setState({ errors });
    return out;
  };
  discardDraft = () => {
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    mealRepo(this.context.cid,this.draftFeature)
      .deleteDraft(this.docId, null, this.state.doc.publish_status)
      .then((doc) => {
        hideLoader();
        if (!doc) return;
        const updatedDoc = doc.data();
        this.props.update({
          _id: doc.id,
          data: {
            cid: this.context.cid,
            ref_name: updatedDoc.ref_name,
            type: updatedDoc.type,
            thumbnail: updatedDoc.thumbnail,
            publish_status:updatedDoc.publish_status
          }
        });
        if (_.get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]) {
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
        } else if (_.get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]) {
          this.setState(o=>({
            oldDoc: { ...updatedDoc },
            doc: { ...updatedDoc },
            publishedDoc:{...o.publishedDoc,publish_status:PUBLISHED},
            showingDoc:PUBLISHED,
          }));
        }
        enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
  deleteItem = () => {
    const {
      onDelete,
      enqueueSnackbar,
      hideLoader,
      showLoader,
    } = this.props;
    showLoader();
    mealRepo(this.context.cid,this.draftFeature)
      .delete(this.docId)
      .then((doc) => {
        hideLoader();
        if (doc) {
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Supplement delete successfully.", { variant: "success" });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }

  copyItem = () =>{
    const {
      hideLoader,
      showLoader,
      insert,
      onSave,
      enqueueSnackbar,
    } = this.props;
    const { doc } = this.state;
    let copyDoc = update(doc, {
      ref_name: {
        $set: `Copy of ${doc.ref_name}`,
      },
    });
    showLoader();
    mealRepo(this.context.cid,this.draftFeature)
      .create(copyDoc)
      .then((doc) => {
        hideLoader();
        if (doc) {
          insert({
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: doc.data().ref_name,
              type: doc.data().type,
              thumbnail : doc.data().thumbnail,
              publish_status:doc.data().publish_status
            },
          });
          if (!!onSave) onSave(doc);
          enqueueSnackbar("Supplement copy successfully.", { variant: "success" });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }
  toggleDoc = async(docType) => {
    const { draftDoc, publishedDoc } = this.state;
    const doc = docType === DRAFT ? { ...draftDoc } : { ...publishedDoc };
    if (!Object.keys(doc).length) return;
    await this.parseCurrentDoc(doc);
    this.setState({ showingDoc: docType });
  };
  
  handlePublish=async()=>{
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      await this.toggleDoc(DRAFT);
    }
    this.onSubmit(PUBLISHED, true);
  }
  handleDraft=(close=false)=>{
    this.onSubmit(DRAFT, close);
  }
  onSubmit = async (docType = PUBLISHED,closeOnSaving=false) => {
    const {
      hideLoader,
      showLoader,
      insert,
      onSave,
      enqueueSnackbar
    } = this.props;
    const { doc } = this.state;
    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED'];
    if (!this.valid(isPublish)) {
      this.setState({isConfirmationOpen:false});
      return;
    }
    showLoader();
    const { thumbnail } = doc;
    let updatedDoc = { ...DEFAULT, ...doc };

    if (thumbnail && thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: MEAL_STORAGE_FILE_PATH
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original","240")
        }
      });
    }
    if (this.props.scheduleSaveSupplementEdit) {
      this.props.scheduleSaveSupplementEdit(updatedDoc);
      this.setState({ editMode: false, supplementEditorMode: false });
      hideLoader();
    } else {
      if (this.isNew) {
        mealRepo(this.context.cid,this.draftFeature)
          .create(updatedDoc)
          .then(doc => {
            hideLoader();
            if (doc) {
              insert({
                _id: doc.id,
                data: {
                  cid: this.context.cid,
                  ref_name: doc.data().ref_name,
                  type: doc.data().type,
                  thumbnail: doc.data().thumbnail,
                  publish_status:calculatedDocType
                }
              });
              if (!!onSave) onSave(doc);
            }
          })
          .catch(err => {
            hideLoader();
            enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
            Sentry.captureException(err);
          });
      } else {
        let request = () => mealRepo(this.context.cid,this.draftFeature).update(this.docId, updatedDoc,null,docType);
        if (this.props.createNew) {
          updatedDoc.srcId = this.docId;
          updatedDoc.archive = true;
          updatedDoc.userProfileId = this.props.userProfileId;
          const docRef = await firebase.firestore().collection(`companies/${this.context.cid}/meals`).doc(`modified_${uuidv4()}`);
          await docRef.set(updatedDoc)
          request = () => docRef.get();
        }
        request()
          .then(doc => {
            if (doc) {
              this.props.update({
                _id: doc.id,
                data: {
                  cid: this.context.cid,
                  ref_name: doc.data().ref_name,
                  type: doc.data().type,
                  thumbnail: (calculatedDocType===DRAFT_STATES['DRAFT_ONLY']||calculatedDocType===DRAFT_STATES["PUBLISHED"])?updatedDoc.thumbnail:_.get(this.state.publishedDoc,'thumbnail'),
                  publish_status:calculatedDocType
                }
              });
              //---TODO ⬇︎ :replace docType with calculatedDocType ?? 
              this.setState(o=>({
                editMode: false,
                supplementEditorMode: !closeOnSaving,
                doc: doc.data(),
                oldDoc: doc.data(),
                dirty: false,
                showingDoc:docType,
                publishedDoc:docType===PUBLISHED?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
                draftDoc:docType===DRAFT?doc.data():{...(o.draftDoc||{})},
                isConfirmationOpen: false,
                dirty:false
              }));
              //-------
              if(this.props.scheduleSaveSuppEdit){
                this.props.scheduleSaveSuppEdit(updatedDoc, doc.id);
              };
            }
            hideLoader();
          })
          .catch(err => {
            hideLoader();
            enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
            Sentry.captureException(err);
          });
      }
    }
  };

  handleImportSupplementDialog = (value) =>{
    this.setState({openImportSupplementDialog : value })
  }

  getHeaderActions = ()=>{
    return (
      <>
      <Tooltip title="Import from other Supplement Plans">
        <IconButton className="fmr-20" onClick={()=>this.handleImportSupplementDialog(true)}>
          <ImportIcon />
        </IconButton>
      </Tooltip>
      <ClrdButton
        className={clsx(
          "f-medium ",
          "border-primary",
          // !!exExist && "border-primary",
          !this.draftFeature && 'd-none'
        )}
        onClick={() => this.handleDraft(false)}
        disableElevation
        color={"invert"}
        variant="contained"
        >
        <SaveIcon
          className={"mr-5"}
          color={"primary"}
          // color={!exExist ? "inherit" : "primary"}
        />
        Save
      </ClrdButton>
      
      </>
    );
  }

  handleSupplementImport = async (suppGroups, cb) =>{
    const { doc } = this.state;
    const { groups } = doc || {};
    let newState = {...this.state};
    let recpIds = [];
    suppGroups.forEach((_sg) => {
      const _gIndex = groups.findIndex((g) => g.type === _sg.type);
      if (_gIndex === -1) {
        newState = update(newState, {
          doc: {
            groups: {
              $push: [_sg],
            },
          },
        });
      } else {
        newState = update(newState, {
          doc: {
            groups: {
              $splice: [[_gIndex, 1, _sg]],
            },
          },
        });
      }
    });
    //TODO ::need to do sort
    //fetch newly added food & recipes::


    //sort the groups
    const newGroups = newState.doc.groups.sort((a, b) => a.order - b.order);
    newState = update(newState, {
      doc: {
        groups: {
          $set: newGroups
        }
      }
    });


    _.forEach(newState.doc.groups, (g) => {
      let SItems = g.supplements || [];
      _.forEach(SItems, (f) => {
        recpIds.push(f.ref.id);
      });
    });

    const foodRecipesData = await this.fetchRecipes(recpIds);
    newState = update(newState, {
      foodRecipes: {
        $push: [...foodRecipesData],
      },
    });

    this.setState({ ...newState, errors: {}, dirty: true });
    cb && cb();
  }
  openEditor=()=>{
    this.setState({supplementEditorMode:true});
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  }
  toggleOrigDocPopup = () => {
    this.setState((o) => ({
      viewOrigDoc: !o.viewOrigDoc,
    }));
  };

  render() {
    const {
      doc,
      errors,
      editMode,
      serving,
      foodRecipes,
      mealCategories,
      isConfirmationOpen,
      loader,
      isValidId,
      displayEditOption = true,
      showingDoc,
      viewOrigDoc,
      dirty
    } = this.state;
    const { loading, type, scheduleDoc = {}, isImport = false, checkImportSupplementGroup, onSelectImportSupplements,isPreview=false } = this.props;
    if (!isValidId) return <PageNotFound keyName="supplement" />;
    if (this.isNew)
      return (
        <CreateEditForm
          loading={loading}
          isNew
          type={type}
          doc={doc}
          errors={errors}
          handleChange={this.handleChange}
          onCancel={this.props.onCancel}
          onSubmit={this.handlePublish}
          handleMedia={this.handleMedia}
        />
      );
    return (
      <>
        {loader && <CircularLoader centerAlign={true} style={{zIndex:2}}/>}
        {!loader && <Details
          oldDoc={this.state.oldDoc}
          scheduleDoc={scheduleDoc}
          displayEditOption={displayEditOption}
          doc={doc}
          foodRecipes={foodRecipes}
          mealCategories={mealCategories}
          serving={serving}
          isImport={isImport}
          checkImportSupplementGroup={checkImportSupplementGroup}
          onSelectImportSupplements={onSelectImportSupplements}
          supplementEditorMode={this.state.supplementEditorMode}
          handleEditMode={this.handleEditMode}
          onDelete={this.deleteItem}
          onCopy={this.copyItem}
          disableActions={this.props.disableActions}
          setSupplementEditorMode={this.openEditor}
          loader={loader}
          isPreview={isPreview}
          draftBannerProps={{
            toggleDoc:this.toggleDoc,
            isDraftAvailable:this.isDraftAvailable,
            handlePublish:this.handlePublish,
            discardDraft:this.discardDraft,
            showingDoc:showingDoc,
            // disablePublishBtnFn:this.disablePublishBtnFn
          }}
        />}
          {editMode && (
            <CreateEditForm
              loading={loading}
              doc={doc}
              type={doc.type}
              errors={errors}
              handleChange={this.handleChange}
              onCancel={this.closeMetaForm}
              onSubmit={() => this.onSubmit(PUBLISHED, true)}
              handleDraft={()=>this.handleDraft(true)}
              handlePublish={()=>this.handlePublish()}
              handleMedia={this.handleMedia}
              draftFeature={this.draftFeature}
              isDraftAvailable={this.isDraftAvailable}
            />
          )}
           
        {this.state.supplementEditorMode && (
          <Dialog
            fullScreen
            open={true}
            onSave={() => this.closeEditor("done")} //Close Button on UI
            onClose={()=>this.closeEditor('close')}
            paperClass="bg-light-grey"
            actionText ="Save"
            toolbarClass="height-40"
            additionalActions = {this.getHeaderActions()}
            title={
              <Title
                doc={doc}
                toggleOrigDocPopup={this.toggleOrigDocPopup}
                viewOrigDoc={viewOrigDoc}
                isDraftAvailable={this.isDraftAvailable}
                />
            }
          >
            <SupplementsForm
              editorMode
              doc={doc}
              handleChange={this.handleChange}
              errors={errors}
              loading={loading}
              onCancel={() =>
                this.setState({
                  supplementEditorMode: false,
                  doc: this.state.olddDoc
                })
              }
              storedSupplements={foodRecipes}
              onSubmit={this.onSubmit}
              handleSupplementsAdd={this.handleSupplementsAdd}
              addMealCategory={this.addMealCategory}
              handleMealType={this.handleMealType}
              handleDelete={this.handleDelete}
              handleReorder={this.handleReorder}
              mealCategories={mealCategories}
              serving={serving}
            />
            {viewOrigDoc && (
              <PublishedDrawer onClose={this.toggleOrigDocPopup} keyName={VIEWS_CONSTANTS.SUPPLEMENTS}/>
            )}
          </Dialog>
        )}
        {this.state.openImportSupplementDialog &&
          <ImportSupplementDialog docId={this.docId} open={true} handleClose={()=>this.handleImportSupplementDialog(false)} onSave={this.handleSupplementImport} />
        }

        {/* {isConfirmationOpen && (
          <Confirmation
            open
            handleClose={() => this.setState({ isConfirmationOpen: false })}
            handleChange={this.handleEditCancel}
            handleCancel={() => this.setState({ isConfirmationOpen: false })}
          />
        )} */}
        {isConfirmationOpen && (
          <CustomConfirmation
            dirty={dirty}
            draftFeature={this.draftFeature}
            handleDraft={() => this.handleDraft(true)}
            handlePublish={this.handlePublish}
            handleClose={() => this.setState({ isConfirmationOpen: false })}
            handleDiscard={() => this.closeEditor()}
          />
        )}
       </>
    );
  }
  parseCurrentDoc=async(doc)=>{
    let recipesIds = [];
    if (doc.groups && doc.groups.length > 0) {
      doc.groups.forEach((group)=>{
        if (group.supplements && group.supplements.length > 0) {
          group.supplements.forEach(item => {
            recipesIds.push(item.ref.id);
          });
        }
      })
    }
    const foodRecipes = await this.fetchRecipes(_.uniq(recipesIds));
    this.setState(o=>({
      doc,
      oldDoc:{...doc},
      foodRecipes:[...o.foodRecipes,...foodRecipes]
    }));
  }
  getMealDoc=async()=>{
    try{
      if(this.isNew && !this.props.scheduleDoc) return;
      let doc,oldDoc,publishedDoc,draftDoc,showingDoc;
      if(this.isNew && this.props.scheduleDoc){
        doc={...this.props.scheduleDoc};
        oldDoc={...this.props.scheduleDoc};
      }
      else if(!this.isNew){
        //get published doc
        const mealDoc = await this.fetchMeal();
        if(!mealDoc.exists){
          this.setState({
            isValidId: false,
            loader: false
          });
          return;
        } 
        publishedDoc = mealDoc.data ? mealDoc.data() : mealDoc;
        //if draft feature is off, treat main collection doc as published
        if(!this.draftFeature){
          publishedDoc['publish_status']=PUBLISHED;
        }
        const publish_status = _.get(publishedDoc, "publish_status", PUBLISHED);
        if (publish_status.includes("draft")) {
          //get draft doc
          const draftWo = await this.fetchMeal(true);
          draftDoc = !!draftWo.data ? draftWo.data() : draftWo;
        }
        if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
          doc = { ...draftDoc };
          showingDoc = DRAFT;
          oldDoc = { ...draftDoc };
        } else {
          doc = { ...publishedDoc };
          showingDoc = PUBLISHED;
          oldDoc = { ...publishedDoc };
        }
      }
      await this.parseCurrentDoc(doc)
      this.setState({
        publishedDoc,
        draftDoc,
        loader: false,
        showingDoc,
      });
    }
    catch(err){
      const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
        this.setState({loader:false})
    }
  }

  componentDidMount() {
    this.setState({ loader: true });
    this.getMealDoc();
    // //TODO: although this is a daily supplement.Meal word is being used here. Not Changing rn because multiple file changes and props changes. FIX ME LATER
    const fetchSuppCategories = () => getTags("supplementCategories");
    const fetchServing = () => getTags("serving");
    Promise.all([fetchSuppCategories(), fetchServing()])
      .then(async ([mealCat, servingData]) => {
        let serving = [], mealCategories = [];
        if (!!mealCat) {
          mealCategories = Object.entries(mealCat).sort(
            (a, b) => a[1].order - b[1].order
          );
        }
        if (!!servingData) {
          serving = Object.entries(servingData);
        }
        this.setState({
          mealCategories,
          serving,
          // loader: false
        });
      })
      .catch(err => {
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
      });
  }

  fetchMeal = (isDraft = false) => {
    return mealRepo(this.context.cid,this.draftFeature).doc(this.docId,null,isDraft);
  };

  fetchRecipes = async ids => {
    const { foodRecipes, loader } = this.state;
    let existIds = [];
    if (foodRecipes && foodRecipes.length) {
      foodRecipes.forEach(ex => {
        existIds.push(ex.id);
      });
    }
    const newIds = ids.filter(d => existIds.indexOf(d) === -1);
    if (!loader) this.props.showLoader();
    const recipesData = await Promise.all(
      newIds.map(async id => {
        const cacheData = this.props.objCache && this.props.objCache[id] && { data: () => this.props.objCache[id] };
        const data = cacheData || await foodRecipesRepo(this.context.cid,this.draftFeature).doc(id);
        if (!!data) {
          const d = data.data();
          if (!cacheData && this.props.objCache) this.props.objCache[id] = d;
          return { ...d, id }
        }
      })
    );
    if (!loader) this.props.hideLoader();
    return recipesData;
  };
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === "new")
    return {
      doc: {
        type: op.type,
        groups: [],
        thumbnail: "",
        title: "",
        ref_name: ""
      },
      loading: s.supplements.loading,
    };
  else
    return { doc: undefined, loading: s.supplements.loading};
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  return { ...suppRdxFns(d), showLoader, hideLoader };
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Info));
