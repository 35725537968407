import React, { useState } from 'react';
import { TextField } from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import { DEFAULT_DEBOUNCE_WAIT, VIMEO_VIDEO_URL_REGEX, YOUTUBE_VIDEO_URL_REGEX, ERROR_VIMEO_URL, ERROR_YOUTUBE_URL } from "fitbud/utils/constants";
import _ from "lodash";
import { uploaderStyles } from './mediaUploader';

export const LinkUploader = (props) => {
  const classes = uploaderStyles();
  const [error, setError] = useState(false);
  const {
    mediaType,
    mediaUrl,
    handleMedia,
    setDirtyOnChange,
    errors = {},
    is_type_specific
  } = props;

  const debMediaLinkChange = _.debounce((v) => {
    setError(false);
    const yt = v.match(YOUTUBE_VIDEO_URL_REGEX);
    if (is_type_specific) {
      handleMedia(mediaType, v); //to handle the case suppose it is only vimeo supported then based on link this method  set media type to you tube and lead to bug:
      return;
    }
    if (!!yt) {
      handleMedia('youtube', `https://www.youtube.com/watch?v=${yt[5]}`);
      return;
    }
    const vm = v.match(VIMEO_VIDEO_URL_REGEX);
    if (!!vm) {
      handleMedia('vimeo', `https://vimeo.com/${vm[4]}`);
      return;
    }
    if(!v || (mediaType !== "youtube" && mediaType !== "vimeo") ){
      return handleMedia(mediaType, v);
    }
    // input has some error at this point, send empty value if some valid link was there in previous state and show error
    if(!!mediaUrl) handleMedia(mediaType, v);
    if(mediaType === "youtube") setError(ERROR_YOUTUBE_URL);
    if(mediaType === "vimeo") setError(ERROR_VIMEO_URL);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, DEFAULT_DEBOUNCE_WAIT * 2);

  const onMediaLinkChange = (e) => {
    return debMediaLinkChange( e.target.value || "");
  };

  return (
    <>
      <FormTextField fullWidth classes={{ control: `${classes.inputBox}` }}>
        <TextField
          key={`${mediaUrl}_media_link_text`}
          id="media_url"
          autoComplete="off"
          defaultValue={String(mediaUrl || "")}
          onChange={(args) => { setDirtyOnChange && setDirtyOnChange(...args); onMediaLinkChange(args) }}
          error={errors.mediaUrl || error}
          helperText={errors.mediaUrl || error}
          placeholder={mediaType ? `${_.capitalize(mediaType)} video link` : "Youtube/Vimeo video link"}
          variant="outlined"
          required
          type="text"
          InputProps={{
            classes: {
              root: "medium",
              input: "size_16_500 medium",
            },
          }}
        />
      </FormTextField>
    </>
  );
};
