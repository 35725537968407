import React, { useState, useRef, useContext } from "react";
import firebase from "fitbud/firebase";
import _ from "lodash";
import {
    Paper,
    InputBase,
    ClickAwayListener,
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Picker } from "emoji-mart";
  import {
    getImageMeta, getVideoMeta  } from "fitbud/utils/services";
import smile from "fitbud/images/smile.svg";
import send_icon from "fitbud/images/send_icon.svg";
import attachment from "fitbud/images/attachment.svg";
import Mic from "@material-ui/icons/Mic";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxHeight: "50%",
    },
    rootPaper: {
        padding: "10px",
        display: "flex",
        alignItems: "end",
        backgroundColor: "#F0F3F5",
        borderRadius: "0",
        border: "none"
    },
    input: {
        // width: "90%",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        padding: "10px 25px 10px 20px"
    },
    emojis: {
        position: "absolute",
        bottom: 70,
        zIndex: 1200,
        left: 10,
        cssFloat: "right",
        "& .emoji-mart-preview": {
            display: "none"
        }
    },
    textareaInput: {
        maxHeight:'100%',
        overflow: "scroll",
        minHeight: "20px"
    },
    attachmentAnchor: {
        color: "#000",
        "&:hover": {
            textDecoration: "none"
        }
    },
    iconButton: {
        backgroundColor: 'red'
    }
}));
const EMPTY_MSG = {
    body: null,
    type: null,
    timestamp: null,
    media: {
        completed: null,
        imageData: null,
        identifier: null,
        aspect: null
    }
}
const ChatInput = ({ sendMessage, setPreviewFile, setShowAudioRecorder }) => {
    const { userProfile } = useContext(FirebaseAuthContext);
    const sendOnEnter = userProfile && userProfile.features && userProfile.features.chatEnterKey;
    const classes = useStyles();
    const inputEl = useRef(null);
    const textInput = useRef(null);
    const [showEmoji, setShowEmoji] = useState(false);
    const [newMsg, setNewMsg] = useState(EMPTY_MSG);

    const addEmoji = e => {
        let emoji = e.native;
        let new_msg_body = newMsg.body ? newMsg.body + emoji : emoji;
        setNewMsg({
            body: new_msg_body,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            type: "text",
        });
    };

    const handleshowEmojis = () => {
        setShowEmoji(true);
        textInput.current.focus();
    };
    const handleClickAway = () => {
        setShowEmoji(false);
        textInput.current.focus();
        textInput.current.setSelectionRange(
            textInput.current.value.length,
            textInput.current.value.length
        );
    };
    const handleKeyPress = e => {
        if (sendOnEnter && e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (!e.target.value.trim()) return;
            setNewMsg({
                body: null,
                type: null,
                timestamp: null,
                media: {
                    completed: null,
                    imageData: null,
                    identifier: null,
                    aspect: null
                }
            })
            onSendClick();
        }
    };
    const onChange = e => {
        setNewMsg({
          body: e.target.value,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          type: "text",
        });
    };
    const onSendClick = async () => {
        let _newMsg = _.clone(newMsg);
        setNewMsg(EMPTY_MSG);
        const sent = await sendMessage(newMsg); 
        if(!sent) setNewMsg({ ..._newMsg })
    }
    const addedFile = async e => {
        e.persist();
        if (!_.get(e, 'currentTarget.files.length')) return;
        const file = e.currentTarget.files[0];
        const parts = file.type.split("/");
        const isImage = parts.length && parts[0] === 'image';
        const isVideo = parts.length && parts[0] === 'video';

        e.currentTarget.value = "";
        if (isImage) {
            const { aspect, width, height } = await getImageMeta(file);
            setPreviewFile({ file, isImage, aspect, width, height });
        } else if(isVideo){
            const { aspect, width, height, duration } = await getVideoMeta(file);
            setPreviewFile({ file, isVideo, aspect, width, height, duration });
        } else {
            setPreviewFile({ file, isImage });
        }
    };


    return (<Paper className={classes.rootPaper}>
        <IconButton
            className="primary fmr-10"
            onClick={() => inputEl.current.click()}
        >
            <img src={attachment} alt="attachmentIcon" />
        </IconButton>
        <IconButton 
            className="fmr-10"
            onClick={() => {
                setShowAudioRecorder(true)
            }}
            onKeyPress={(ev) => {
                ev.preventDefault();
            }}
            >
            <Mic />
            </IconButton>
        <div style={{ width: "90%" }} className="d-flex position-relative flex-1" >
            {showEmoji && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <span className={classes.emojis}>
                        <Picker onSelect={addEmoji} showPreview={false} />
                    </span>
                </ClickAwayListener>
            )}
            <InputBase
                className={` w-100 ${classes.input} `}
                placeholder={sendOnEnter ? 'Type a message. Hit Enter/Return to send' : 'Type a message...'}
                autoFocus={true}
                value={newMsg.body || ""}
                inputRef={textInput}
                id="inputArea"
                startAdornment={
                    <InputAdornment position="start" className="align-self-end h-100">
                        <div
                            onClick={handleshowEmojis}
                            className="cursor-pointer"
                        >
                            <img src={smile} alt="emojiIcon" />
                        </div>
                    </InputAdornment>
                }
                onChange={onChange}
                onKeyPress={handleKeyPress}
                classes={{
                    multiline: classes.textareaInput,
                    inputMultiline: "py-1"
                }}
                multiline={true}
                maxRows={20}
            />
        </div>

        <IconButton
            color="primary"
            className={"fml-20 p-12"}
            classes={{root: "bg-primary"}}
            aria-label="send"
            onClick={onSendClick}
            disabled={!newMsg.body}
        >
            <img src={send_icon} alt="sendIcon" />
        </IconButton>
        <input
            id="file-picker"
            ref={inputEl}
            type="file"
            className="d-none"
            accept="image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/webm, video/ogg, video/quicktime, .doc, .docx, .xls, .xlsx, .pdf"
            onChange={addedFile}
        />
    </Paper>)
}

export default ChatInput
