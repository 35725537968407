import React,{useContext,useMemo} from 'react';
import {
    changeDate,
    getHeightWidthUisngFormula
  } from "fitbud/utils/helpers";
  import moment from "moment";
  import {
    Typography,
    IconButton,
    ListItem,
    ListItemText,
  } from "@material-ui/core";
  import {useStyles,ShowDate,AttachmentList} from './chatView';
import clsx from "clsx";
import { getStaffSettings } from 'fitbud/views/groupChat/helper';
import Linkify from "linkifyjs/react";
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import timer from "fitbud/images/clock.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import _ from "lodash";
import { AvatarImage } from "fitbud/views/users/header";


 
const Message=({msg,propsId,ext,handleOpenMessageOption,index,messages})=>{
    const classes=useStyles();
    const { cid, comp } = useContext(FirebaseAuthContext);
    let msgData = msg.data({ serverTimestamps: "estimate" });
    const staff=useMemo(()=>{
        const staff_authors=_.get(comp.data(),['app_config','staff_authors'],false);
        if(!staff_authors) return {}
        if(!msgData.staffId) return {};
        let out={
            label:getStaffSettings(comp.data(),msgData.staffId,'label'),
            color:getStaffSettings(comp.data(),msgData.staffId,'color'),
            border:getStaffSettings(comp.data(),msgData.staffId,'border'),
            image_url:getStaffSettings(comp.data(),msgData.staffId,'image_url')
        }
        if(!out.label){
            //fallback to comp
            out={
                label:_.get(comp.data(),['profile','name']),
                image_url:_.get(comp.data(),['chat_config','direct_message_profile_url'])
                    ||_.get(comp.data(),['profile','image']),
            }
        }
        return {...out};
    },[msgData,comp.data()]);
    let timestamp_current = msgData.timestamp;
    let timestamp_previous = index !== 0
        ? messages[index - 1].data().timestamp
        : msgData.timestamp;
    let date1 = moment(timestamp_current && timestamp_current.toMillis()).local();
    let date2 = moment(timestamp_previous && timestamp_previous.toMillis()).local();
    let time = moment(date1).format("hh:mm a");
    const view_width =useMemo(()=>{
        return document.getElementById("chatArea") &&
        document.getElementById("chatArea").getBoundingClientRect().width;
    },[document])
    let height, width;
    if (msgData.media) {
        const aspect = msgData.media.aspect || 1;
        let { height_new, width_new } = getHeightWidthUisngFormula(view_width,aspect);
        height = height_new;
        width = width_new;
    }
    const isCompanyMsg=(cid===msgData.authorId)|| !!msgData.staffId;
    let marginLeft = isCompanyMsg ? "auto" : "none";
    const image_base_path = _.get(msgData, 'media.base_path', false);
    const downloadResource = {
        id: msg.id,
        type: 'text',
    };
    if (msgData.media) {
        downloadResource.type = 'image';
        downloadResource.data = {
            identifier: msgData.media.identifier,
            storageFolderRef: !!image_base_path ? `${image_base_path}/chats` : `user_profiles/${propsId}/chats`,
        };
    } else if (msgData.type === 'attachment' || msgData.type === 'audio' || msgData.type === 'video') {
        downloadResource.type = 'other';
        downloadResource.data = msgData;
    }

    return (
        <div key={msg.id} id={msg.id} className={clsx("fmy-20 d-flex flex-column",
            isCompanyMsg ? "align-items-end": "align-items-start")}>
            {changeDate(date1, date2) && <ShowDate date={date1} />}
                <div className="d-flex w-100 pr-30">
                  <div className={classes.bubble} style={{width:_.isEmpty(staff)?'100%':'calc(100% - 50px)'}}>
                    <ListItem divider component="div"
                      className={clsx(
                        msgData.type === "image" && !msgData.deleted
                          ? isCompanyMsg
                            ? classes.mediaRight
                            : classes.mediaLeft
                          : isCompanyMsg
                          ? classes.rightMsg
                          : classes.leftMsg
                      )}
                      style={(!!msgData.staffId && staff.border)? {border:`1px solid ${staff.color}`}:null}
                    >
                      {msgData.deleted ?  (
                        <ListItemText className='font-italic text-muted'>
                          This message has been deleted
                        </ListItemText>
                      ) : (
                            <>
                              {msgData.type === "text" && 
                                <ListItemText>
                                  <Linkify>{msgData.body}</Linkify>
                                </ListItemText>
                              }
                              {(msgData.type === "attachment" || msgData.type === "audio" || msgData.type === "video") && (
                                <AttachmentList id={propsId} attachmentData={msgData} key={msgData.attachment.identifier} />
                              )}
                              {(<div style={{ height: height, width: width, marginLeft: marginLeft }}>
                                  {msgData.media && (
                                    <ImgAspectFill
                                      authorId={msgData.authorId}
                                      authUserId={cid}
                                      targetHeight={height}
                                      targetWidth={width}
                                      identifier={msgData.media.identifier}
                                      completed={msgData.media.completed}
                                      aspect={msgData.media.aspect || 1}
                                      imageData={msgData.media.imageData}
                                      storageFolderRef={!!image_base_path ? `${image_base_path}/chats` :`user_profiles/${propsId}/chats`}
                                      bucketName="chat"
                                      style={{ width: "100%", borderRadius: "10px" }}
                                      alt={"Chat Image"}
                                      ext={ext}
                                    />
                                  )}
                                </div>)}
                            </>)}
                    </ListItem>
                    <div className={clsx("d-flex align-items-center ml-30 flex-row-reverse",!!isCompanyMsg?'':'justify-content-end')}>
                      {!msgData.deleted &&
                        <IconButton size='small' edge='end' onClick={(e) => handleOpenMessageOption(e, downloadResource)} data-id={msg.id}>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      <Typography className={clsx(
                        isCompanyMsg ? (clsx(classes.timeRight) ) : classes.timeLeft, 
                        'd-flex font_11_500',
                        msgData.deleted || cid !== msgData.authorId ? "py-5" : "",
                        
                      )}>
                        <span>{time}</span>
                        {isCompanyMsg && (msg.metadata.hasPendingWrites ? (
                            <img src={timer} className="fml-10" alt="" />
                          ) : "" )}
                      </Typography>
                    {!!msgData.staffId && <Typography className={clsx("d-flex font_11_500",classes.staffName)} style={{color:staff.color}}>{staff.label}</Typography>}
                    </div>
                  </div>
                  {!!staff.label && <StaffAvatar id={msgData.staffId} name={staff.label} src={staff.image_url}/>}
                </div>
        </div>
        )}

export default Message;

const StaffAvatar=({id,name,src=null})=>{
    return(
      <AvatarImage
        src={src}
        name={name}
        base64={false}
        alt={name}
        externalUrl={id}
        style={{ fontSize: 13, fontWeight: 600 }}
        className='ml-10'
      />
    )
  }