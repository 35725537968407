import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

const AORGN = {vertical: 'top', horizontal: 'left'};
const MORGN = {vertical: 'top', horizontal: 'right'};

const ImportBtn = (props) => {
  const { hidden, isPerDay, week, weekId, day = undefined, axn } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const tooltip = useMemo(() => {
    if (!weekId) return undefined;
    if (isPerDay) return 'Import a Workout or Meal Schedule';
    else return 'Import a Workout Schedule';
  }, [isPerDay, weekId]);
  const onIconClick = useCallback((e) => {
    if (isPerDay && !anchorEl) setAnchorEl(e.currentTarget);
    else {
      setAnchorEl(null);
      axn(e);
    }
  }, [anchorEl, isPerDay, axn]);
  const close = () => setAnchorEl(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  if (hidden || !weekId) return null;
  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton className={`${day ? 'my-n2' : ''} ${open ? 'bg-light-primary' : ''}`}
          onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='woSch'>
          {open ? <CloseIcon fontSize={day ? 'small' : 'medium'} color='primary'/>
            : <AddIcon fontSize={day ? 'small' : 'medium'}/>}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={close} anchorOrigin={AORGN} transformOrigin={MORGN}>
        <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='woSch'>Import Workout Schedule</MenuItem>
        <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='mlSch'>Import Meal Schedule</MenuItem>
      </Menu>
    </>
  );
};

export default ImportBtn;
