export const PLAN_FILTER_CONFIG = (filterConfig) => {
  const FILTER_CONFIG = [
    {
      label: 'Plan Status',
      subType: 'status',
      subOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'in_active' },
        // { label: 'Hidden', value: 'hidden' },
      ],
    },
    {
      label: 'Plan Type',
      subType: 'type',
      subOptions: [
        { label: 'Regular', value: 'regular' },
        { label: 'Add On', value: 'add_on' },
        // { label: 'Hidden', value: 'hidden' },
      ],
    },
    {
      label: 'Pricing Option',
      subType: 'mode',
      subOptions: [
        { label: 'One Time', value: 'one_time' },
        { label: 'Subscription', value: 'subscription' },
      ],
    },
    {
      label: 'Automated Scheduling',
      subType: 'scheduling',
      subOptions: [
        { label: 'On', value: 'on' },
        { label: 'Off', value: 'off' },
      ],
    },
    {
      label: 'Video Calling Included',
      subType: 'video_calling',
      subOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
  ];

  const paymentsPlanOption = [];
  const { hasAppleIAP, hasPlayIAP, paymentGateWay } = filterConfig || {};
  if (hasAppleIAP) paymentsPlanOption.push({ label: 'Apple', value: 'apple_in_app' });
  if (hasPlayIAP) paymentsPlanOption.push({ label: 'Google', value: 'google_play' });
  if (!!paymentGateWay) paymentsPlanOption.push({ label: 'None', value: 'others' });
  if ((paymentsPlanOption.length > 1)) {
    FILTER_CONFIG.push({
      label: 'In-App Purchase',
      subType: 'payment',
      subOptions: paymentsPlanOption,
    });
  }

  return FILTER_CONFIG;
};


