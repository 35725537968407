import { TextField } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import { FormTextField } from 'fitbud/components/form-fields';
import ImageFileUpload from "fitbud/components/imageSelector";
import { PUBLISHED,DRAFT } from 'fitbud/utils/constants';
import _ from 'lodash';
import React, { useState } from 'react'
import { getEmptySchedule } from './helper';
import {CustomConfirmation} from "fitbud/components/customConfirmation";

const CreateEditScheduleDialog = (props) => {
  const { cid, scheduleType, doc,title,showCustomConfirmation=false,
    handleDraft,handlePublish,isNew,onClose,draftFeature,isDraftAvailable } = props;
  const [data, setData] = useState(doc || getEmptySchedule(scheduleType));
  const [errors, setErrors] = useState({});
  const [isDirty, setDirty] = useState(false);
  const [isCustomConfrmtn,toggleCustomConfrmtn]=useState(false);

  const validate  = () => {
    let out = true;
    if(!data.title || !data.title.trim()){out=false;setErrors(o=>({...o,title: 'Please enter Display Name'}));}
    if(!data.ref_name || !data.ref_name.trim()){out=false;setErrors(o=>({...o,ref_name: 'Please enter Reference Name'}));}
    if(out) out = _.cloneDeep(data);
    return out;
  };

  const handleChange = (e) => {
    setDirty(true);
    setErrors({});
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };
  const handleSaveClick=()=>{
    if(!isNew && !isDirty && !isDraftAvailable){
      onClose();
      return;
    }
    if(isNew||!draftFeature) handleSave();
    else {
      toggleCustomConfrmtn(true);
    }
  }
  const handleSave = (docType=PUBLISHED) => {
    const finalData = validate();
    if(!finalData) return;
    docType===PUBLISHED?handlePublish(finalData):handleDraft(finalData,true);
  };

  return (
    <Dialog
      {...props} 
      titleFont="h3"
      appBarColor="bg-body"
      buttonColor="primary"
      paperClass={"width-600"}
      dialogContentClassName="d-flex flex-column"
      toolbarClass="height-60"
      title={title}
      fullWidth
      isDirty={isDirty}
      withConfirmation
      onSave={handleSaveClick}
      actionText={isNew ? "Next" : "Done"}
      maxWidth="md"
      showCustomConfirmation={showCustomConfirmation}
      handleDraft={()=>handleSave(DRAFT)}
      handlePublish={()=>handleSave(PUBLISHED)}
      onClose={onClose}
      >
        <div className='w-100 fp-20'>
          <div className='d-flex mb-20'>
            <div className='flex-1 mr-20 d-flex flex-column justify-content-between'>
              <FormTextField labelVariant={'subtitle1'} fullWidth label="Reference Name" required>
                <TextField
                  id="ref_name"
                  name='ref_name'
                  autoComplete="off"
                  placeholder='Enter Reference Name . . .'
                  value={data.ref_name}
                  onChange={handleChange}
                  error={!!errors.ref_name}
                  helperText={errors.ref_name}
                  variant="outlined"
                  type="text"
                  InputProps={{
                    classes: {
                      root: "medium",
                      input: "size_15_500 medium"
                    }
                  }}
                />
              </FormTextField>
              <FormTextField labelVariant={'subtitle1'} fullWidth label="Display Name" required={scheduleType !== 'meal'} classes={{control:"pb-0"}}>
                <TextField
                  id="title_schedule"
                  name='title'
                  autoComplete="off"
                  placeholder='Enter Display Name . . .'
                  value={data.title}
                  onChange={handleChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  variant="outlined"
                  type="text"
                  InputProps={{
                    classes: {
                      root: "medium",
                      input: "size_15_500 medium"
                    }
                  }}
                />
              </FormTextField>
            </div>
              <ImageFileUpload
                name='thumbnail'
                aspectRatio={cid === 'silbe' ? 1.0 : 1.5}
                thumbnail={data.thumbnail}
                onChange={handleChange}
                size={cid === 'silbe' ? 'small' : 'medium'}
              />
          </div>
          <FormTextField labelVariant={'subtitle1'} fullWidth label="Description">
            <TextField
              id="desc"
              name="desc"
              minRows={4}
              maxRows={4}
              multiline
              autoComplete="off"
              placeholder='Enter Description . . .'
              value={data.desc}
              onChange={handleChange}
              error={!!errors.desc}
              helperText={errors.desc}
              variant="outlined"
              type="text"
              InputProps={{
                classes: {
                  input: "size_15_500"
                }
              }}
            />
          </FormTextField>
        </div>
        {isCustomConfrmtn && (
        <CustomConfirmation
            handleClose={() => toggleCustomConfrmtn(false)}
            handleDiscard={onClose}
            handleDraft={()=>handleSave(DRAFT)}
            handlePublish={()=>handleSave(PUBLISHED)}
            dirty={isDirty}
            showDiscard={isDirty}
        />
        )}
    </Dialog>
  )
};

export default CreateEditScheduleDialog;
