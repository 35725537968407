import firebase from 'firebase';
import { daysNameFull } from 'fitbud/utils/scheduling';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { gcCheckScheduleConflicts, gcCreateZoomMeeting } from 'fitbud/api/gcFunctions';
import { getMindBodyClasses } from 'fitbud/api/index';
import React from 'react';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';

export const GC_INDEX_FACTOR = 100;
export const OFFLINE_LOCATION_TEXT = 'Studio Location';
export const ONLINE_LOCATION_TEXT = 'OnLine Location';
export const OFFLINE_MODE_TEXT = 'In Person';
export const ONLINE_MODE_TEXT = 'Online';
export const CLASS_PASSED_TEXT = 'Class has passed.';
export const DEFAULT_SCHEDULE_CONFLICT_MSG = "Schedule is conflicted";
export const DEFAULT_CLASS_CONFLICT_MSG =  "Class is conflicted";

export const GC_STATUS = {
  ACTIVE: 'active',
  PROCESSING: 'processing',
  INACTIVE: 'inactive',
};

export const DEFAULT_GC_SCHEDULE = {
  // start: moment().endOf('hour').toDate(),
  start: undefined, //local state will initialize c
  end: 'x',
  rcr_mode: 'none',
  rcr_interval: 1,
  rcr_time: '1000',
  archive: false,
  rcr_dtow: null,
  rcr_dtom: null,
  common_conf: {
    //All information except buffer and capacity all populated from gc details during saving.
    // trainer_id, location_online, location_offline, mode, tz, ,
  },
};
export const DEFAULT_GC_DETAILS = {
  title: '',
  ref_name: '',
  status: 'inactive',
  archive: false,
  description: '',
  thumbnail: '',
  // access_type: 'all',
  allow_leads: false,
  // access_plans: [],
  type: '',
  sub_type: '',
  intensity: '',
  duration: 15,
  trainer_ids: [],
  locations: [],
  mode: 'offline',
  info_type: 'manually',
  drop_in_rate: {
    enabled: false,
    currency: 'USD',
    price: 0,
  },
};

export const MORNING_10AM = moment().hour(10).minutes(0).toDate();

export const PAYMENT_MODE = [
  { label: 'Specific payment plans', value: 'partial' },
  { label: 'All paying clients except ', value: 'except_all' },
  { label: 'Free for all clients', value: 'free' },
  { label: 'All paying clients', value: 'all' },
];

export const DEFAULT_GAP_IN_NEW_SLOT = 15;

export const createInstanceFromClass = (schedule, meta, isNew = false) => {
  const {
    mode,
    duration,
    location_online,
    location_offline,
    capacity_online,
    capacity_offline,
    buffer_offline,
    buffer_online,
    trainer_id,
    custom_link,
    tz,
  } = _.get(schedule, 'common_conf', {});
  const { rcr_mode, rcr_time, start } = schedule || {};
  const out = {
    mode,
    duration,
    location_online,
    location_offline,
    capacity_online,
    capacity_offline,
    buffer_offline,
    buffer_online,
    class: _.get(schedule, 'class', ''),
    title: _.get(meta, 'title', ''),
    ref_name: _.get(meta, 'ref_name', ''),
    trainer_id,
    info_type: _.get(meta, 'info_type', null),
    workout_id: _.get(meta, 'workout_id', null),
    class_status: _.get(meta, 'status') === GC_STATUS.INACTIVE ? GC_STATUS.INACTIVE : GC_STATUS.ACTIVE,
    custom_link: custom_link || '',
    active: true,
    rcr_mode,
    tz: tz || null,
    users:{},
    slots_online : capacity_online,
    slots_offline : capacity_offline,
  };
  if (isNew && rcr_mode === 'none') {
    let timeStr = start + rcr_time;
    let time = getUtcTime(moment(timeStr, 'YYYYMMDDHHmm'), 'YYYYMMDDHHmm');
    out.time = time;
    out.src = 'auto';
  }
  return out;
};

//Here RcrTime not converted to the moment date only days date
export const convertGclsSlotsToDB = (slotConfig) => {
  const { days, different_slots, same_rcr_time } = slotConfig || {};
  let rcr_dtow = [];
  daysNameFull.forEach((day, dayIndex) => {
    const dayId = day.toLowerCase();
    const daySlot = _.get(days, dayId, false);
    if (!daySlot) return;

    if (!different_slots && same_rcr_time) {
      same_rcr_time.forEach((rcr_time) => {
        rcr_dtow.push(`${dayIndex}${rcr_time}`);
      });
      return;
    }
    daySlot.forEach((time) => {
      const hhmm = moment(time).format('HHmm');
      const timeStr = `${dayIndex}${hhmm}`;
      rcr_dtow.push(timeStr);
    });
  });

  return { rcr_dtow, different_slots };
};
export const convertGclsSlotsFromDB = (schedule) => {
  const { rcr_dtow, different_slots = false, rcr_mode } = schedule || {};
  const same_rcr_time = [];
  const days = {};
  if (rcr_mode === 'none')
    return {
      different_slots,
      days,
      same_rcr_time,
    }; // ie it is one time recurring

  _.forEach(rcr_dtow, (timeStr) => {
    const dayIndex = parseInt(timeStr.charAt(0));
    const hhmm = timeStr.substr(1);
    const dayId = (daysNameFull[dayIndex] || '').toLocaleLowerCase(); // monday, tuesday , and so on..
    days[dayId] = days[dayId] || [];
    days[dayId].push(moment(hhmm, 'HHmm').toDate());
    if (different_slots === false) {
      if (!same_rcr_time.includes(hhmm)) same_rcr_time.push(hhmm);
    }
  });
  return { days, different_slots, same_rcr_time };
};

export const prepareAllClasses = (classes = [], skipPreviousDay) => {
  const activeClass = {}; //store date wise groupClasses:
  const gcInstances = {};
  classes.forEach((data) => {
    const { time } = data || {};
    const local_time = getLocalTimeFromUtc(time, 'YYYYMMDDHHmm');
    if (skipPreviousDay && isPreviousDay(local_time)) return null; //Do not process previous day class.
    gcInstances[data.id] = { _id: data.id, ...prepareClassData(data) }; // prepare map
    const date = moment(local_time).format('YYYYMMDD');
    if (date) {
      const out = { _id: data.id, time, local_time, active: data.active };
      if (!activeClass[date]) activeClass[date] = [];
      activeClass[date].push(out);
    }
    // activeClass[date] = activeClass[date].sort((a, b) => moment(a.local_time).unix() - moment(b.local_time).unix());
  });
  return {
    activeClass,
    gcInstances,
  };
};

export const isPastClass = (startTime, duration) => {
  return isPrevious(moment(startTime).add(duration, 'minutes'));
};

export const prepareClassData = (data) => {
  const { users, time, duration } = data;
  const _users = {};
  Object.keys(users || {}).forEach((uid) => {
    const user = users[uid] || {}; //filter user's only pick yes and waiting users;
    if (['yes', 'waiting'].includes(user.status)) {
      _users[uid] = { ...user };
    }
  });
  const out = { ...data };
  const local_time = getLocalTimeFromUtc(time, 'YYYYMMDDHHmm');
  out.users = _users;
  out.local_time = local_time;
  out.isPast = isPastClass(local_time, duration);
  return out;
};

export const fetchMindBodyClasses = async (cid, classId, offset = 0, limit = 100) => {
  const response = await getMindBodyClasses(cid, {classId, offset, limit});
  const { success, instances } = response.data || {};
  if (!success) return null;
  const { activeClass, gcInstances } = prepareAllClasses(instances) || {};
  return { activeClass, gcInstances };
};

export const fetchClasses = async (cid, classId, skipPreviousDay = true) => {
  const utcStartDate = moment().startOf('day').utc().format('YYYYMMDDHHmm');
  let query = await firebase.firestore().collection(`companies/${cid}/gcInstances`).where('class', '==', classId);
  if (skipPreviousDay) query = query.where('time', '>=', utcStartDate);
  query = query.orderBy('time');
  const snapshot = await query.get();
  if (!snapshot.size) return null;
  const classes = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  const { activeClass, gcInstances } = prepareAllClasses(classes, skipPreviousDay);
  return { gcInstances, activeClass, classes };
};

export const isPreviousDay = (date) => {
  return moment(moment(date).startOf('day')).isBefore(moment().startOf('day'));
};

export const isPrevious = (date) => {
  return moment(date).isBefore();
};

export const dateToHHmm = (date) => {
  return moment(date).format('HHmm');
};

export const getLocalTimeFromUtc = (time, format) => {
  return moment.utc(time, format).local().toDate();
};

export const getUtcTime = (time, format) => {
  return moment.utc(time).format(format || '');
};

export const hhMMtoDate = (HHmm) => {
  return moment(HHmm, 'HHmm').toDate();
};

export const getHHmmAmPm = (time) => {
  const hhMm = moment(time, 'hhmm').format('hh:mm');
  const amPm = moment(time, 'hhmm').format('a');
  return { hhMm, amPm };
};

export const getMode = (mode) => {
  const isOffline = mode === 'offline';
  const isOnLine = mode === 'online';
  const isHybrid = mode === 'hybrid';
  return { isOffline, isOnLine, isHybrid };
};

export const getPaymentModeLabel = (drop_in_rate) => {
  const mode = _.get(drop_in_rate, 'mode', '');
  if (!mode) return '';
  if (mode === 'custom') return 'Drop-in Price or Payment Plan';
  if (mode === 'free') return 'Free';
  if (mode === 'plan') return 'Payment Plan Only';
};

export const getClassBookingStatus = (data, isMindBody = false) => {
  const { capacity_offline, capacity_online, buffer_online, buffer_offline, users, slots_offline, slots_online } = data;
  const total_capacity_offline = capacity_offline || 0;
  const total_capacity_online = capacity_online || 0;
  let total_booked_offline = 0,
    total_waiting_offline = 0,
    total_booked_online = 0,
    total_waiting_online = 0;

  if (!isMindBody) {
    //for normal own classes..
    _.chain(users || {})
      .values()
      .value()
      .forEach((user) => {
        if (user.mode === 'online') {
          if (user.status === 'yes') total_booked_online += 1;
          if (user.status === 'waiting') total_waiting_online += 1;
        } else if (user.mode === 'offline') {
          if (user.status === 'yes') total_booked_offline += 1;
          if (user.status === 'waiting') total_waiting_offline += 1;
        }
      });
  } else {
    //for mind body classes...
    total_booked_offline = Number(capacity_offline || 0) - Number(slots_offline || 0);
    total_booked_online = Number(capacity_online || 0) - Number(slots_online || 0);
    total_waiting_offline = slots_offline < 0 ? -slots_offline : 0;
    total_waiting_online = slots_online < 0 ? -slots_online : 0;
  }

  return {
    online: {
      capacity: total_capacity_online,
      booked: total_booked_online,
      waiting: total_waiting_online,
      buffer: buffer_online || 0,
    },
    offline: {
      capacity: total_capacity_offline,
      booked: total_booked_offline,
      waiting: total_waiting_offline,
      buffer: buffer_offline || 0,
    },
  };
};

export const getClassSlots = ({ capacity_offline, buffer_offline, capacity_online, buffer_online, users = {} }) => {
  const totalCapacityOnline = capacity_online || 0;
  const totalCapacityOffline = capacity_offline || 0;
  const activeUserContOffline =
    _.chain(users)
      .values()
      .filter((user) => user.mode === 'offline')
      .filter((user) => ['yes', 'waiting'].includes(user.status))
      .value().length || 0;
  const activeUserContOnLine =
    _.chain(users)
      .values()
      .filter((user) => user.mode === 'online')
      .filter((user) => ['yes', 'waiting'].includes(user.status))
      .value().length || 0;
  return {
    slots_online: totalCapacityOnline - activeUserContOnLine,
    slots_offline: totalCapacityOffline - activeUserContOffline,
  };
};

export const getMinutesSeconds = (seconds = 0) => {
  return {
    m: String(Math.ceil(seconds / 60)).padStart(2, '0'),
    s: String(seconds % 60).padStart(2, '0'),
  };
};

export const getConflictedSchedule = async (cid, schedule) => {
  const response = await gcCheckScheduleConflicts({
    cid,
    schedule,
  });
  const {
    data: { conflicts, success, message },
  } = response;
  return {conflicts, success, message};
};

export const getPlanIdsFromAccess = (access, id) => {
  const classAccess = _.get(access, 'classes');
  const packsForClass = _.get(classAccess, `${id}.packs`, []); //all access packs
  const except = _.get(classAccess, `${id}.except`, []); //all except packs
  const packsToAllClass = _.get(access, 'packs.all.classes', []); //all packs which have all access.
  const gcIds = [...packsToAllClass, ...packsForClass, ...except];
  return _.filter(gcIds, (id) => id !== 'all');
};

/*
classes:{
  [classId]:{
    packs:[<packsId>],
    except:[],
  }
  ...
  ...
  all:{packs:[<classId>]}
},
packs:{
  [packsId]:{
    classes:[<classId>],
    except:[],
  }
  ...
  ...
  all:{packs:[<packsId>]}
}
*/
export const convertAccessForDb = (access, outAccess, gcId) => {
  //Nothing doing in case of free access.
  const previousAccess = _.get(access, `classes.${gcId}.packs`);
  const previousExceptAccess = _.get(access, `classes.${gcId}.except`);
  access = _.cloneDeep(access); //cloning for new object;
  let classesAccess = _.get(access, 'classes', {});
  let packsAccess = _.get(access, 'packs', {});
  let packAccess = _.get(outAccess, 'access', []);
  let exceptAccess = _.get(outAccess, 'except', []);

  let classAllAccess = _.get(classesAccess, 'all', {});
  let classAccessToAllPacks = _.get(classAllAccess, 'packs', []);
  let classIdAccess = _.get(classesAccess, gcId, {});
  let packsAllAccess = _.get(packsAccess, 'all', {});
  let packAccessToAllClasses = _.get(packsAllAccess, 'classes', []); // containing ids of all packs which have all access to classes

  let isAccessToAllPacks = packAccess.includes('all');
  let isFreeForAllPacks = packAccess.includes('free');

  //if all access then add to allAccess class and if not then removed from the allAccessClass
  const indexInAllAccess = _.findIndex(classAccessToAllPacks, (id) => id === gcId);
  if (isAccessToAllPacks) {
    if (indexInAllAccess < 0) classAccessToAllPacks.push(gcId); //not found then add.
  } else {
    if (indexInAllAccess > -1) {
      classAccessToAllPacks.splice(indexInAllAccess, 1); //if found then removed.
    }
  }
  //assign updated packs to to class
  classIdAccess.packs = [...packAccess];
  classIdAccess.except = [...exceptAccess];
  if (!isAccessToAllPacks) {
    //if not all access and free, ie selected access then set except to  blank array.
    classIdAccess.except = [];
  }

  //preparing all classes access finally
  classesAccess = {
    ...classesAccess,
    all: { ...classAllAccess, packs: classAccessToAllPacks },
    [gcId]: { ...classIdAccess },
  };

  //now working for reverse in packAccess., if selected options then add gcId to all selected packs reverse wise.
  if (!isAccessToAllPacks && !isFreeForAllPacks) {
    packAccess.forEach((packId) => {
      if (packId === 'trial') return;
      const packIdAccess = _.get(access, `packs.${packId}`, {});
      const packClassAccess = _.get(packIdAccess, 'classes', []);
      const isPackAccessToAllClass = packClassAccess.includes('all');
      if (!isPackAccessToAllClass) {
        //if it is selected and not found then add gcId to pack access.
        const gcIndex = _.findIndex(packClassAccess, (id) => id === gcId);
        if (gcIndex < 0) packClassAccess.push(gcId);
      }
      packsAccess = { ...packsAccess, [packId]: { ...packIdAccess, classes: packClassAccess } };
    });

    //now  add except in packs which have all access.... reasons for doing this this will mark in all access.
    packAccessToAllClasses.forEach((packId) => {
      const packIdAccess = _.get(access, `packs.${packId}`, {});
      const except = _.get(packIdAccess, 'except', []);
      if (packAccess.includes(packId)) {
        //if specif pack id is all access, then remove it from except if found..
        const indexInExcept = _.findIndex(except, (id) => id === gcId);
        if (indexInExcept > -1) {
          except.splice(indexInExcept, 1);
        }
      } else {
        //for others all plans
        if (!except.includes(gcId)) except.push(gcId);
      }
      packIdAccess.except = except;
      packsAccess = { ...packsAccess, [packId]: { ...packIdAccess } };
    });
  }

  //now compare previous packIdAccess and current access and which is removed then removed it form the classes.
  const removedPackAccess = _.filter(
    previousAccess,
    (packId) => packId !== 'all' && packId !== 'free' && packId !== 'trial' && !packAccess.includes(packId)
  );
  //remove access in case of access removed;
  if (!!removedPackAccess && removedPackAccess.length) {
    removedPackAccess.forEach((packId) => {
      const packIdAccess = _.get(access, `packs.${packId}`, {});
      const packClassAccess = _.get(packIdAccess, 'classes', []);
      const gcIndex = _.findIndex(packClassAccess, (id) => id === gcId);
      if (gcIndex > -1) packClassAccess.splice(gcIndex, 1);
      packsAccess = { ...packsAccess, [packId]: { ...packIdAccess, classes: packClassAccess } };
    });
  }

  //handling of excepts ;
  (exceptAccess || []).forEach((packId) => {
    const packIdAccess = _.get(access, `packs.${packId}`, {});
    const packClassAccess = _.get(packIdAccess, 'classes', []);
    const except = _.get(packIdAccess, 'except', []);
    const isPackAccessToAllClass = packClassAccess.includes('all');
    if (isPackAccessToAllClass) {
      if (!except.includes(gcId)) {
        except.push(gcId);
      }
    } else {
      //for specif access. //there is no concept of free in packs either all or specific.
      if (packClassAccess.includes(gcId)) {
        const gcIndex = _.findIndex(packClassAccess, (id) => id === gcId);
        if (gcIndex > -1) packClassAccess.splice(gcIndex, 1);
      }
    }
    packsAccess = { ...packsAccess, [packId]: { ...packIdAccess, classes: packClassAccess, except: except } };
  });

  const removeExceptPack = _.filter(previousExceptAccess, (packId) => !exceptAccess.includes(packId));

  removeExceptPack.forEach((packId) => {
    const packIdAccess = _.get(access, `packs.${packId}`, {});
    const packClassAccess = _.get(packIdAccess, 'classes', []);
    const except = _.get(packIdAccess, 'except', []);
    const isPackAccessToAllClass = packClassAccess.includes('all');
    if (isPackAccessToAllClass) {
      const indexInExcept = _.findIndex(except, (id) => id === gcId);
      if (indexInExcept > -1) {
        except.splice(indexInExcept, 1);
      }
    } else {
      if (!packClassAccess.includes(gcId)) {
        packClassAccess.push(gcId);
      }
    }
    packsAccess = { ...packsAccess, [packId]: { ...packIdAccess, classes: packClassAccess, except: except } };
  });

  return {
    ...access,
    packs: packsAccess,
    classes: classesAccess,
  };
};

export const pluralizeLocation = (count) => {
  return `${count > 1 ? 'Locations' : 'Location'} `;
};

export const getLocationName = (data) => {
  let name = '';
  if (!!data.studio_name) {
    name = data.studio_name;
    if (data.ref_name || data.title) {
      name = name + ' - ' + data.ref_name || data.title;
    }
  } else {
    name = data.ref_name || data.title;
  }
  if (data.archive) {
    return (
      <span>
        {name}&nbsp;<span style={{ color: 'red' }}>(archive)</span>
      </span>
    );
  }
  return name;
};

export const checkDuplicateOnDifferentDays = (days) => {
  const dayValues = _.chain(days).values().value();
  for (let day of dayValues) {
    if (typeof day === 'boolean') break;
    let times = day.map((t) => dateToHHmm(t));
    let isSame = hasDuplicate(times);
    if (isSame) return true;
  }
  return false;
};

export const hasDuplicate = (array) => {
  const unique = _.uniq(array);
  return unique.length !== array.length;
};

export const checkForExpiredGroupClass = (out) => {
  const { rcr_mode, start, end, instanceSchedule } = out || {};
  // if (rcr_mode === 'none') { //if it is one time class, then check instanceSchedule ie instance time is past or not
  //   if (isPreviousDay(end)) return true;
  // } //one time
  // else {
  //   //recurring
  //   if (end !== 'x' && isPreviousDay(end)) return true;
  // }
  if (end !== 'x' && isPreviousDay(end)) return true;
  return false;
};

export const prepareGroupClasses = (groupClasses) => {
  const result = (groupClasses || []).map((gcs) => {
    const out = { ...gcs };
    const { rcr_mode, start, end } = _.get(out, 'data', {});
    if (!!rcr_mode && !!start && !!end) {
      //for newly created class, these values will pe available..
      const isExpired = checkForExpiredGroupClass({ rcr_mode, start, end });
      if (isExpired) out.data.status = GC_STATUS.INACTIVE;
    }
    return out;
  });
  return result;
};

export const getClassJoinUrl = (instance, offline, online) => {
  // const _address = "H1478 CR PARK NEw delhi 110019"
  // return `http://maps.google.com/?q=${_address}`
  const { mode, location_online, custom_link } = instance || {};
  const { isOffline, isOnLine, isHybrid } = getMode(mode);
  const offlineLocation = _.get(offline, 'data', {});
  const onlineLocation = _.get(online, 'data', {});
  if (isOnLine || isHybrid) {
    if (location_online === 'custom') return custom_link || '';
    if (_.get(onlineLocation, 'type') === 'custom') return _.get(onlineLocation, 'url', '');
    if (_.get(onlineLocation, 'type') === 'zoom') return _.get(instance, 'url', '');
  }
  if (isOffline) {
    if (!!offlineLocation.gmap) return offlineLocation.gmap;
    return `http://maps.google.com/?q=${offlineLocation.address}`;
  }
  return '';
};

export const getNearestInstance = (instances) => {
  //get nearest instance which is just immediate after current time or last one.
  const now = moment();
  let schedule;
  for (let i = 0; i < instances.length; i++) {
    const instance = instances[i];
    if (moment(instance.local_time).isAfter(moment()) && instance.active) return instance;
    else schedule = instance;
  }
  return schedule;
};

export const getUpcomingBookingOfClass = async (cid, classId) => {
  const now = moment().toDate();
  const query = firebase
    .firestore()
    .collection(`companies/${cid}/bookings/`)
    .where('class', '==', classId)
    .where('time', '>=', now)
    .where('status', '==', 'booked')
    .limit(1);
  const snap = await query.get();
  const docs = snap.docs;
  return docs;
};

export const parseInstance = (instances, isRecurring, status, gcInstances) => {
  //instances, here instances present in map of dates {date:[]}, earlier instances was grouped by date at UI.
  if (!instances) return [];
  let out = _.chain(instances)
    .keys()
    .map((date) => instances[date])
    .flatten()
    .value();
  if (!isRecurring) return out;
  if (!!isRecurring && status === GC_STATUS.ACTIVE) return out;
  if (!!isRecurring && status === GC_STATUS.INACTIVE) {
    //incase of recurring if status is inactive and
    out = _.filter(out, (instance) => {
      const inst = _.get(gcInstances, instance._id);
      const users = _.get(inst, 'users');
      const isActiveUser = _.chain(users)
        .values()
        .some((user) => user.status === 'yes')
        .value();
      return inst.active && isActiveUser;
    });
  }
  return out;
};

export const sortInstances = (instances) => {
  const out = instances.sort((a, b) => moment(a.local_time).unix() - moment(b.local_time).unix());
  return out;
};

export const getRegisterUserErrorText = (code) => {
  switch (code) {
    case 'class.not_found':
      return 'Class not found.';
    case 'class.cancelled':
      return 'Class has been cancelled';
    case 'class.past_event':
      return 'Class has passed.';
    case 'class.no_vacancy':
      return 'No seat available.';
    case 'user.already_registered':
      return 'Client already registered.';
    case 'user.kicked':
      return 'Client cannot be added to this class';
    case 'purchase.required':
      return `Client doesn't have access to this class, purchase required.`;
    default:
      return DEFAULT_ERROR;
  }
};

export const createZoomMeetingUrl = async (cid, args) =>{
  const {trainerId, locationId, instance} = args;
  const response = await gcCreateZoomMeeting({
    cid,
    trainerId,
    locationId,
    instance,
  })
  return response?.data || {};
  console.log(">>>>response",response)
}
