import React, { useContext, useEffect, useState } from 'react';
import _empty from 'lodash/isEmpty';
import { IconButton, Typography, Button } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Dialog from 'fitbud/components/Dialog';
import { DeleteIcon } from 'fitbud/icons/delete';
import { DragIcon } from 'fitbud/icons/drag';
import WorkoutSelector from 'fitbud/views/schedules/workoutSelector';
import { useToggle } from 'fitbud/hooks/form';
import { UserSchContext } from './provider';
import { WOCard } from './editor/cards';

const move = (arr, src, dst) => {
  if (!arr || !arr.length || src === dst) return arr;
  const out = [...arr];
  const removes = out.splice(src, 1);
  out.splice(dst, 0, ...removes);
  return out;
};
const remove = (arr, index) => {
  if (!arr || !arr.length) return arr;
  const out = [...arr];
  out.splice(index, 1);
  return out;
};

const useWeekDayMeta = (showCardDetails) => {
  const [meta, setMeta] = useState({})
  useEffect(() => {
    if (showCardDetails && _empty(meta)) {
      const { weekDayMeta: wdm, editable } = showCardDetails || {};
      setMeta({ weekDayMeta: wdm, future: editable })
    }
  }, [showCardDetails])

  return meta
}

const WorkoutAlts = ({ edit = false }) => {
  const { opmngalt, controller, showCardDetails } = useContext(UserSchContext);
  const { ids, existing } = opmngalt || {};
  const [woids, setWoids] = useState(ids || []);
  const [workouts, setWorkouts] = useState(existing || []);
  const [add, toggleAdd] = useToggle();
  const { weekDayMeta, future } = useWeekDayMeta(showCardDetails)
  const onDragEnd = (event) => {
    const { source, destination } = event;
    if (!source || !destination) return;
    const { index: srcIndex } = source;
    const { index: dstIndex } = destination;
    setWoids(x => move(x, srcIndex, dstIndex));
    setWorkouts(x => move(x, srcIndex, dstIndex));
  };
  const onDelete = (e) => {
    if (woids.length < 2) return;
    const data = e.currentTarget.dataset;
    const index = Number(data.index);
    setWoids(x => remove(x, index));
    setWorkouts(x => remove(x, index));
  };
  const onAdd = (added) => {
    if (!added || !added.length) return toggleAdd();
    const wos = [...workouts], ids = [...woids];
    added.forEach(({data, _id}) => {
      ids.push(_id);
      wos.push({...data, id: _id});
    });
    setWoids(ids);
    setWorkouts(wos);
    toggleAdd();
  }
  const onSave = () => {
    controller.mngAltSave(woids, workouts);
  };
  if (!opmngalt) return null;

  if (!edit || !future) {
    return (
      <Dialog open onClose={controller.mngAltClose} title='Alternatives' fullScreen={false}>
        <div className='px-20 pt-20'>
          {workouts.map((data, n) => (
            <div key={data.id} className='position-relative mb-20'>
              <WOCard id={data.id} data={data} onClick={controller.showDetail} />
              {n === 0 && <Typography className='position-absolute inset-bottom-right text-grey mb-10 mr-10 font_11_600'>PRIMARY</Typography>}
            </div>
          ))}
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog open onClose={controller.mngAltClose} onSave={onSave} title='Alternatives' fullScreen={false} toolbarClass='height-60'>
      <div className='p-20 d-flex flex-column align-items-center'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='workout-alts'>
            {(provided) => (<div {...provided.droppableProps} ref={provided.innerRef}>
              {workouts.map((data, n) => (
                <Draggable key={data.id} draggableId={data.id} index={n}>
                  {(provided) => <div ref={provided.innerRef} {...provided.draggableProps} className='d-flex flex-row align-items-center mb-20'>
                    <div className='position-relative d-flex flex-row align-items-center'>
                      <WOCard id={data.id} data={data} onClick={controller.showDetail} future={future} weekDayMeta={weekDayMeta}/>
                      {n === 0 && <Typography className='position-absolute inset-bottom-right text-grey mb-10 mr-10 font_11_600'>PRIMARY</Typography>}
                      <IconButton component='div' {...provided.dragHandleProps} size='small' className='position-absolute inset-right mr-n15 bg-white border'>
                        <DragIcon />
                      </IconButton>
                    </div>
                    <IconButton size='small' edge='end' className='ml-20' data-index={n} onClick={onDelete}><DeleteIcon /></IconButton>
                  </div>}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>)}
          </Droppable>
        </DragDropContext>
        {future && <Button color='primary' onClick={toggleAdd}>Add Alternatives</Button>}
        {add && <WorkoutSelector open onClose={toggleAdd} moduleName='schedule' handleAdd={onAdd} selectedWOs={workouts} showDraftInt={true}/>}
      </div>
    </Dialog>
  );
};

export default WorkoutAlts;
