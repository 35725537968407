import TagFilter from "./tag-filter";
export const FOOD_RECIPES_FILTER_CONFIG = [
    {
      label: 'Category ',
      subType: 'dtype',
      subOptions: [
        {
          label: 'Food',
          value: 'food',
        },
        {
          label: 'Recipe',
          value: 'recipe',
        },
      ],
    },
    {
      label: 'Source',
      subType: 'source',
      subOptions: [
        {
          label: 'Fitbudd Library',
          value: 'fitbudd',
        },
        {
          label: 'My Library',
          value: 'custom',
        },
        {
          label: 'Fitbudd Customized',
          value: 'fitbudd_custom',
        },
      ],
    },
    {
      label: 'Calorie ',
      subType: 'calories',
      subOptions: [
        {
          label: 'Zero',
          value: 'lte:0',
        },
        {
          label: '< 100',
          value: 'gt:0-lte:100',
        },
        {
          label: '101 - 200',
          value: 'gte:101-lte:201',
        },
        {
          label: '201 - 500',
          value: 'gte:201-lte:500',
        },
        {
          label: '500+',
          value: 'gte:501',
        },
      ],
    },
  ];
export const FOOD_FILTER_CONFIG = [
    {
      label: 'Source',
      subType: 'source',
      subOptions: [
        {
          label: 'Fitbudd Library',
          value: 'fitbudd',
        },
        {
          label: 'My Library',
          value: 'custom',
        },
        {
          label: 'Fitbudd Customized',
          value: 'fitbudd_custom',
        },
      ],
    },
    {
      label: 'Calorie ',
      subType: 'calories',
      subOptions: [
        {
          label: 'Zero',
          value: 'lte:0',
        },
        {
          label: '< 100',
          value: 'gt:0-lte:100',
        },
        {
          label: '101 - 200',
          value: 'gte:101-lte:201',
        },
        {
          label: '201 - 500',
          value: 'gte:201-lte:500',
        },
        {
          label: '500+',
          value: 'gte:501',
        },
      ],
    },
    {
      label:"Tags",
      type:"foods",
      isComponent:true,
      Component:TagFilter,
      tag:"nutrition",
      subType:"systags"
    },
  ];
export const RECIPES_FILTER_CONFIG = [
    // {
    //   label: 'Source',
    //   subType: 'source',
    //   subOptions: [
    //     {
    //       label: 'Fitbudd Library',
    //       value: 'fitbudd',
    //     },
    //     {
    //       label: 'My Library',
    //       value: 'custom',
    //     },
    //     {
    //       label: 'Fitbudd Customized',
    //       value: 'fitbudd_custom',
    //     },
    //   ],
    // },
    {
      label: 'Calorie ',
      subType: 'calories',
      subOptions: [
        {
          label: '< 100',
          value: 'lte:100',
        },
        
        {
          label: '101 - 200',
          value: 'gte:101-lte:201',
        },
        {
          label: '201 - 500',
          value: 'gte:201-lte:500',
        },
        {
          label: '500+',
          value: 'gte:501',
        },
      ],
    },
    {
      label:"Tags",
      type:"recipes",
      isComponent:true,
      Component:TagFilter,
      tag:"nutrition",
      subType:"systags"
    },
  ];
export const MEAL_FILTER_CONFIG = [
    {
      label: 'Meal Type ',
      subType: 'dtype',
      subOptions: [
        {
          label: 'Food Based',
          value: 'food',
        },
        {
          label: 'Macro Based',
          value: 'macros',
        },
      ],
    },
    {
      label: 'Calorie ',
      subType: 'calories',
      subOptions: [
        {
          label: '< 1000',
          value: 'lte:1000',
        },
        
        {
          label: '1001 - 1250',
          value: 'gte:1001-lte:1250',
        },
        {
          label: '1251 - 1500',
          value: 'gte:1251-lte:1500',
        },
        {
          label: '1501 - 1750',
          value: 'gte:1751-lte:1750',
        },
        {
          label: '1751 - 2000',
          value: 'gte:1751-lte:2000',
        },
        {
          label: '2001 - 2500',
          value: 'gte:2001-lte:2500',
        },
        {
          label: '2501 - 3000',
          value: 'gte:2501-lte:3000',
        },
        {
          label: '3000+',
          value: 'gte:3001',
        },
      ],
    },
    {
      label:"Tags",
      type:"meals",
      isComponent:true,
      Component:TagFilter,
      tag:"nutrition",
      subType:"systags"
    },
  ];
export const MEAL_FOOD_FILTER_CONFIG = [
    {
      label: 'Calorie ',
      subType: 'calories',
      subOptions: [
        {
          label: '< 1000',
          value: 'lte:1000',
        },
        
        {
          label: '1001 - 1250',
          value: 'gte:1001-lte:1250',
        },
        {
          label: '1251 - 1500',
          value: 'gte:1251-lte:1500',
        },
        {
          label: '1501 - 1750',
          value: 'gte:1751-lte:1750',
        },
        {
          label: '1751 - 2000',
          value: 'gte:1751-lte:2000',
        },
        {
          label: '2001 - 2500',
          value: 'gte:2001-lte:2500',
        },
        {
          label: '2501 - 3000',
          value: 'gte:2501-lte:3000',
        },
        {
          label: '3000+',
          value: 'gte:3001',
        },
      ],
    },
  ];
  
  
