import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer
} from "react";
import _ from "lodash";
import clsx from "clsx";
import firebase from "fitbud/firebase";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import smile from "fitbud/images/smile.svg";
import send_icon from "fitbud/images/send_icon.svg";
import timer from "fitbud/images/clock.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { WorkerContext } from "fitbud/providers/workerProvider";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import * as Sentry from '@sentry/browser';
import { DEFAULT_ERROR, DISABLED_CHAT_TEXT } from "fitbud/utils/constants";
import {
  changeDate,
  downloadFile,
  getHeightWidthUisngFormula
} from "fitbud/utils/helpers";
import {
  getImageMeta,
  getVideoMeta
} from "fitbud/utils/services";
import moment from "moment";
import "emoji-mart/css/emoji-mart.css";
import { AvatarImage } from "fitbud/views/users/header";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Collapse, Badge, Menu, MenuItem } from "@material-ui/core";
import Mic from '@material-ui/icons/Mic';
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  InputBase,
  ListItem,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import { Picker } from "emoji-mart";
import appRdxFns from "fitbud/redux/app";
import withWidth from "@material-ui/core/withWidth/index";
import ScrollToBottom from "fitbud/views/chats/scrollToBottom";
import PageNotFound from "fitbud/views/pageNotFound";
import PdfIcon from "fitbud/images/pdf.svg";
import DocIcon from "fitbud/images/doc.svg";
import ExcelIcon from "fitbud/images/xls.svg";
import MediaPreview from "./mediaPreview";
import AudioRecorder from "../../components/audio-recorder/audioRecorder";
import AudioPlayer from "fitbud/components/Audio";
import VideoOpener from "fitbud/components/videoOpener";
import { resizeAndUpload } from "../broadcasts/broadcastView";
import { getUserLastSeen } from "../users/helpers";
import { useToggle } from "fitbud/hooks/form";
import Confirmation from "fitbud/components/confirmationDialog";
import Message from './message';

const storageRef = firebase.storage().ref();
const CHAT_FILE_SIZE_DEFAULT = 3; // MB

export const ShowDate = ({ date }) => {
  const classes = useStyles();
  if(!date) return null;
  return (
    <Typography
      variant="caption"
      className={`${classes.dateTab} d-block fpx-20 fpy-10 text-center fmb-20`}
    >
      {moment(date).format("ddd, D MMM YYYY")}
    </Typography>
  );
};

const BoxModeMsgPop = ({ msgs }) => {
  return (
    <Badge
      badgeContent={msgs}
      className="text-danger"
      children={""}
      classes={{ badge: "bg-white fmr-15" }}
    />
  );
};

const getItemSvg = (ext) => {
  let svg = "";
  const fileExt = ext.toLowerCase();
  switch (fileExt) {
    case "pdf":
      svg = PdfIcon;
      break;
    case "doc":
    case "docx":
      svg = DocIcon;
      break;
    case "xls":
    case "xlsx":
      svg = ExcelIcon;
      break;
    default:
      svg = "";
  }
  return svg;
};

export const AttachmentList = ({id, attachmentData}) => {
  const [state, setState] = useState("");
  const [error, setError] = useState({message: 'Loading ...'});
  const {body, attachment: {identifier, completed, duration} = {}, type} = attachmentData;
  const ext = identifier.split(".").pop().toLowerCase();
  const classes = useStyles();
  const base_path = _.get(attachmentData, 'attachment.base_path', false);
  useEffect(() => {
    if (!completed) return;
    const url = base_path ? `${base_path}/chats/attachment/${identifier}` : `user_profiles/${id}/chats/attachment/${identifier}`;
    setTimeout(() => storageRef.child(url)
      .getDownloadURL()
      .then((val) => {
        setState(val);
        setError(false);
      }).catch(e => {
        console.error(e);
        if (e.code === 'storage/object-not-found')
          setError({message: 'File missing ❌'});
        else
          setError(e);
    }), 0);
  }, [id, identifier, completed, attachmentData, base_path]);
  // When attachment is a video
  if(type === 'video'){
    if(!state) return <CircularProgress />; //Show loading while fetching URL
    return (
      <VideoOpener
        url={state}
        attachment={attachmentData.attachment}
      />
    );
  }
  // When attachment is a Audio
  if(type === 'audio'){
    if(!state) return <CircularProgress />; //Show loading while fetching URL
    return (
      <AudioPlayer src={state} duration={duration} minified />
    );
  }
  return (
    <ListItemText>
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      <a href={error || !state ? '#' : state} target={error || !state ? '' : '_blank'}
        rel="noopener noreferrer" className={classes.attachmentAnchor}>
        <img className="fmr-5" width="35px" alt={body} src={getItemSvg(ext)} />{error ? error.message : body}
      </a>
    </ListItemText>
  )
}
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    "& textarea": {
      maxHeight: "100px",
      overflow: "scroll!important",
      minHeight: "20px"
    }
  },
  rootPaper: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    borderRadius: "0",
    border: "none"
  },
  input: {
    // width: "90%",
    borderRadius: "25px",
    backgroundColor: "#ffffff",
    padding: "10px 25px 10px 20px",
  },
  toolbar: {
    borderLeft: "none"
  },
  noMinHeight: {
    minHeight: "unset"
  },
  chatArea: {
    background: "#ffffff",
    flex: "1",
    minHeight: "0",
    overflow: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 187px)"
    }
  },
  bubble: {
    width: 'calc(100% - 50px)',
    whiteSpace: "pre-wrap"
  },
  timeLeft: {
    justifyContent: "start",
    color: "#818181"
  },
  timeRight: {
    justifyContent: "flex-end",
    color: "#818181",
    marginLeft:'8px'
  },
  staffName:{
    marginLeft: "auto",
    justifyContent: "flex-end",
    color: "#818181"
  },

  leftMsg: {
    width: "max-content",
    maxWidth: "50%",
    wordWrap: "break-word",
    marginRight: "auto",
    marginLeft: "30px",
    position: "relative",
    background: "#FFF",
    border: '1px solid #D3D9DB',
    borderRadius: "0px 10px 10px 10px"
  },

  rightMsg: {
    width: "max-content",
    maxWidth: "calc(50% + 50px)",
    wordWrap: "break-word",
    // marginRight: "30px",
    marginLeft: "auto",
    borderRadius: "10px 0px 10px 10px",
    background: "#F0F3F5",
    border: '1px solid #F0F3F5',
  },
  mediaRight: {
    padding: 0,
    // paddingRight: "30px",
    marginLeft: "auto",
    border: "none"
  },
  mediaLeft: {
    padding: 0,
    paddingLeft: "30px",
    marginRight: "auto",
    border: "none"
  },
  dateTab: {
    width: "160px",
    backgroundColor: "#dfecff",
    borderRadius: "16px",
    margin: "auto",
    color: "#4089f7",
    marginTop: "30px",
  },
  emojis: {
    position: "absolute",
    bottom: 70,
    zIndex: 1200,
    left: 10,
    cssFloat: "right",
    "& .emoji-mart-preview": {
      display: "none"
    }
  },
  wrapperInner: {
    display: "flex",
    flexDirection: "column"
  },
  textareaInput: {
    maxHeight: "100px",
    overflow: "scroll",
    minHeight: "20px"
  },
  wrapper: {
    minHeight: "0px",
    width: "100%"
  },
  collapseHeight: {
    maxHeight: "320px"
  },
  attachmentAnchor: {
    color: "#000",
    "&:hover": {
      textDecoration: "none"
    }
  },
  audioPreview:{
    width: "100%",
    minHeight: "60%",
    bottom: 0,
    backgroundColor: "white"
  }
}));

function reducer(state, action) {
  const { type, payload, cid } = action;
  const chatarea = document.getElementById("chatArea");
  const last_msg = document.getElementById("messagesEnd");
  const chatAreaBottom = chatarea.getBoundingClientRect().bottom;
  const lastMsgBottom = last_msg.getBoundingClientRect().bottom;

  let idToScroll = "messagesEnd";
  if (lastMsgBottom - chatAreaBottom > 100 && !!state.messages.length) {
    if (type === "added" && payload[0] && payload[0].data().authorId !== cid) {
      idToScroll = null;
    }
  }
  switch (type) {
    case "added":
      return {
        ...state,
        messages: [...state.messages, ...payload],
        lastFetchedMsg: !!state.messages.length
          ? state.messages[0]
          : payload[0],
        scrollToId: idToScroll,
        seen: false
      };
    case "modified":
      return {
        ...state,
        messages: [
          ...state.messages.map(msg => {
            if (msg.id === payload.id) return payload;
            else return msg;
          })
        ],
        seen: false
      };
    case "metaChanges":
      return {
        ...state,
        messages: state.messages.map(
          obj => payload.find(o => o.id === obj.id) || obj
        )
      };
    case "fetchPrevMsgs":
      return {
        ...state,
        messages: [...payload.reverse(), ...state.messages],
        lastFetchedMsg: !!payload.length ? payload[0] : null,
        scrollToId: state.messages[0].id
      };
    case "setSeen":
      return {
        ...state,
        seen: true
      };
    default:
      return state;
  }
}
const initialState = {
  messages: [],
  lastFetchedMsg: null,
  scrollToId: "messagesEnd",
  seen: false
};
const ChatView = props => {
  const classes = useStyles();
  const isScrolled = useRef(false);
  const [show, setShow] = useState(false);
  const inputEl = useRef(null);
  const textInput = useRef(null);
  const { cid, comp, userProfile:{uid, features = {} } = {} } = useContext(FirebaseAuthContext);
  const sendOnEnter = features && features.chatEnterKey;
  const { markRead } = useContext(WorkerContext);
  const [user, setUser] = useState({});
  const [flagged, setFlag] = useState(false);
  const [flagging, setFlagging] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [previewFile, setPreviewFile] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showEmoji, setShowEmoji] = useState(false);
  const [totalMessages, setTotalMessages] = useState(0);
  const [isChatWinOpen, updateChatWinOpen] = useState(!props.boxMode);
  const [isActiveWindow, setWindowActive] = useState(true);
  const [showAudio, setShowAudio] = useState(false);
  const [newMsg, setNewMsg] = useState({
    authorId: cid,
    body: null,
    type: null,
    timestamp: null,
    media: {
      completed: null,
      imageData: null,
      identifier: null,
      aspect: null
    }
  });
  const [isValidId, setIdStatus] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [focusedMsg, setFocusedMsg] = useState(null);
  const [downloadResource, setDownloadResource] = useState({type: '', data: {}, id: ''});
  const [deleteConfirm, toggleDeleteConfirm] = useToggle();

  const { messages, lastFetchedMsg, scrollToId, seen } = state;
  const latestMsg = messages && messages[messages.length - 1];

  async function beginDownload() {
    showLoader();
    try {
      const { type, data, id } = downloadResource;
      let downloadUrl;
      if (type === 'image') {
        downloadUrl = await getImageDownloadUrl(data);
      } else {
        downloadUrl = await getDownloadUrl(props.id, data);
      }
      if (!downloadUrl) throw new Error('invalid url');
      await downloadFile(downloadUrl, type === 'other' ? data.body : id);
      hideLoader();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  }
  
  useEffect(() => {
    const chatarea = document.getElementById("chatArea");

    chatarea.addEventListener("scroll", decideToShowUnread);
    document.addEventListener("visibilitychange", tabChanged);
    if (!!props.users) {
      const user = props.users.filter(usr => props.id === usr.uid)[0];
      if (!user) {
        setIdStatus(false);
      } else {
        setUser({ ...user });
        markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
      }
    }
    return () => {
      chatarea && chatarea.removeEventListener("scroll", decideToShowUnread);
      document.removeEventListener("visibilitychange", tabChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //on mount update last seen and read count
  useEffect(() => {
    if (!!isChatWinOpen) {
      if (!!props.id && user.total_messages && isActiveWindow) {
        markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.total_messages, isChatWinOpen, isActiveWindow]);

  // listener for changes in chat doc
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("user_profiles")
      .doc(props.id)
      .onSnapshot(async function(doc) {
        setUser({ ...doc.data() });
      });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  // listener for changes in messages docs
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("user_profiles")
      .doc(props.id)
      .collection("messages")
      .orderBy("timestamp", "desc")
      .limit(10)
      .onSnapshot({ includeMetadataChanges: true }, querySnapshot => {
        if (!!querySnapshot.docChanges().length) {
          const newMessages = [];
          querySnapshot.docChanges().forEach(function(change) {
            if (change.type === "added") {
              newMessages.unshift(change.doc);
            }
            if (change.type === "modified") {
              dispatch({ type: "modified", payload: change.doc });
            }
          });
          dispatch({ type: "added", payload: newMessages, cid });
        } else {
          dispatch({ type: "metaChanges", payload: querySnapshot.docs });
        }
      });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);
  const tabChanged = () => {
    if (document.visibilityState === "visible") {
      setWindowActive(true);
    } else {
      setWindowActive(false);
    }
  };
  const decideToShowUnread = () => {
    const chatarea = document.getElementById("chatArea");
    const last_msg = document.getElementById("messagesEnd");
    let chat;
    setUser(prev => {
      chat = prev;
      return prev;
    });
    setShow(
      chatarea &&
        last_msg &&
        chatarea.getBoundingClientRect().bottom <
          last_msg.getBoundingClientRect().bottom
    );
    if (
      chatarea &&
      last_msg &&
      last_msg.getBoundingClientRect().bottom -
        chatarea.getBoundingClientRect().bottom <=
        100
    ) {
      setTotalMessages(chat.total_messages);
      setShow(false);
    }
  };

  const scrollToItem = () => {
    if (!!scrollToId) {
      document
        .getElementById(scrollToId)
        .scrollIntoView({ behaviour: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    setFlagging(false);
    setFlag(!!user && !!user.systags && user.systags.length && user.systags.includes('flagged'));
  }, [user]);
  const toggleFlag = () => {
    setFlagging(true);
    props.toggleFlag();
  };

  useEffect(() => {
    if (
      messages.length &&
      latestMsg.data().authorId === cid &&
      user[user.uid] &&
      user[user.uid].read_count === user.total_messages
    ) {
      dispatch({ type: "setSeen" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, messages.length, cid]);

  // on mount scroll to bottom
  useEffect(scrollToItem, [messages.length, seen]);
  const onChange = e => {
    const { authorId } = newMsg;
    setNewMsg({
      body: e.target.value,
      // timestamp: new Date(),
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type: "text",
      authorId
    });
  };
  const addEmoji = e => {
    let emoji = e.native;
    const { authorId } = newMsg;
    let new_msg_body = newMsg.body ? newMsg.body + emoji : emoji;
    setNewMsg({
      body: new_msg_body,
      // timestamp: new Date(),
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type: "text",
      authorId
    });
  };

  const handleshowEmojis = () => {
    setShowEmoji(true);
    textInput.current.focus();
  };
  const handleClickAway = () => {
    setShowEmoji(false);
    textInput.current.focus();
    textInput.current.setSelectionRange(
      textInput.current.value.length,
      textInput.current.value.length
    );
  };

  useEffect(() => {
    textInput.current.focus();
  },[showAudio])

  const sendMessage = async msg => {
    if(!msg) return;
    if(!msg.authorId) msg.authorId = cid;
    if(!msg.timestamp) msg.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    if (msg.body) msg.body = msg.body.trim();
    if (msg.body === "" && (msg.type === 'text' || msg.type === 'video')) return;
    if(uid)msg.staffId=uid;
    setTotalMessages(prev => (user.total_messages || prev) + 1);
    await firebase
      .firestore()
      .collection("user_profiles")
      .doc(props.id)
      .collection("messages")
      .add({ ...msg });
    markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
  };

  const handleKeyPress = (e, isButtonClick=false) => {
    if(!!isButtonClick){
      e.preventDefault();
      let _newMsg = {...newMsg};
      setNewMsg({});
      sendMessage(_newMsg);
      return;
    }
    if (sendOnEnter && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!e.target.value.trim()) return;
      let _newMsg = {...newMsg};
      setNewMsg({});
      sendMessage(_newMsg);
    }
  };

  const onScroll = e => {
    isScrolled.current =
      e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight;
    if (e.target.scrollTop > 0) return;
    //scroll previous messages
    if (fetching) return;
    setFetching(true);
    fetchPrevMessages();
  };

  const fetchPrevMessages = async () => {
    if (!lastFetchedMsg) {
      setFetching(false);
      return;
    }
    const fetchedMsgs = await firebase
      .firestore()
      .collection("user_profiles")
      .doc(props.id)
      .collection("messages")
      .orderBy("timestamp", "desc")
      .startAfter(lastFetchedMsg)
      .limit(10)
      .get();

    setFetching(false);
    dispatch({ type: "fetchPrevMsgs", payload: fetchedMsgs.docs });
  };

  const addedFile = async e => {
    e.persist();

    if (
      !e ||
      !e.currentTarget ||
      !e.currentTarget.files ||
      e.currentTarget.files.length < 0
    )
      return;
    const file = e.currentTarget.files[0];
    const parts = file.type.split("/");
    const isImage = parts.length && parts[0] === 'image';
    const isVideo = parts.length && parts[0] === 'video';
    e.currentTarget.value = "";
    if (isImage) {
      const { aspect, width, height } = await getImageMeta(file);
      setPreviewFile({ file, isImage, aspect, width, height });
    }else if(isVideo){
      const { aspect, width, height, duration } = await getVideoMeta(file);
      setPreviewFile({ file, isVideo, aspect, width, height, duration });
    } else {
      setPreviewFile({ file, isImage });
    }
  };

  const cancelPreview = () => {
    setPreviewFile({});
    setShowAudio(false)
  };

  const handleCallback = (childData) => {
    setPreviewFile(childData)
  }

  const {hideLoader, showLoader} = props;
  const handleSend = async () => {
    if (!previewFile.file) return;
    showLoader();
    try {
      const path = `user_profiles/${props.id}/chats/${previewFile.isImage ? 'original' : 'attachment'}`
      await resizeAndUpload(previewFile, cid, sendMessage, path, false, null, uid);
      if(previewFile.isAudio) setShowAudio(false);
      setPreviewFile({});
      hideLoader();
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
      hideLoader();
    }
  };
  const {appConfig, enqueueSnackbar} = props;
  useEffect(() => {
    if (!previewFile || !previewFile.file) return;
    let fileSize = (previewFile && previewFile.file.size) || 0;
    const maxSize = (appConfig && appConfig.company_chat_file_max_size) || CHAT_FILE_SIZE_DEFAULT;
    if (!previewFile.isVideo && !previewFile.isAudio && !previewFile.isImage && fileSize > 1024 * 1024 * maxSize) {
      enqueueSnackbar(`Document size should not be more than ${maxSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    const { chat_config } = comp.data() || {};
    const maxVideoSize = (chat_config && chat_config.company_max_video_size) || 16; // in MB
    const maxVideoDuration = (chat_config && chat_config.company_max_video_duration) || 90; // in seconds
    if(previewFile.isVideo && fileSize > 1024 * 1024 * maxVideoSize) {
      enqueueSnackbar(`Video file size should not be more than ${maxVideoSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if(previewFile.isVideo && previewFile.duration >  maxVideoDuration) {
      enqueueSnackbar(`Video file duration should not be more than ${maxVideoDuration} seconds`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if (!previewFile.isImage && !previewFile.isVideo && !previewFile.isAudio) {
      const path = `user_profiles/${props.id}/chats/attachment`
      resizeAndUpload(previewFile, cid, sendMessage, path, false, null, uid);
    }
  }, [previewFile, appConfig, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  let show_date = false;
  let date1;
  if (!!messages  && !!messages.length) {
    show_date = true;
    /*When ChatView is Open and new message arrive then, then messages added to the state two times as a result render two times, first time 
    without timestamp and second time with timestamp.In first time, message come with having no timestamp key so in that case use default new Date() to handle dom fluctuations :: Paritosh
    */
    let timestamp_current = (messages[0].data().timestamp && messages[0].data().timestamp.toMillis())|| new Date();
    date1 = moment(timestamp_current).local();
  }
  let ext = previewFile &&  previewFile.file ? (previewFile.file.name || "").split(".").pop().toLowerCase() : null;
  const unread = user.total_messages - totalMessages || 0;
  const boxModeUnread =
    user.total_messages && user[cid]
      ? user.total_messages - user[cid]["read_count"]
      : 0;
  function toggleChatWindow(val = false) {
    const chatarea = document.getElementById("chatArea");
    if (val) chatarea.scrollTo({ top: (0, chatarea.scrollHeight) });

    updateChatWinOpen(val);
  }
  const handleViewInMode = id => {
    props.boxMode ? props.openChat(id) : props.viewProfile(id);
  };

  const handleOpenMessageOption = (e, resource) => {
    setDownloadResource(resource)
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setFocusedMsg(e.currentTarget.dataset.id);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteMessage = (e) => {
    toggleDeleteConfirm();
    if (!focusedMsg) return;
    e.preventDefault();
    showLoader();
    firebase.firestore()
      .doc(`user_profiles/${props.id}/messages/${focusedMsg}`)
      .update( { deleted: true, _uat: firebase.firestore.FieldValue.serverTimestamp() }).then(() => {
        hideLoader();
      });
  }

  let last_active = getUserLastSeen(user);
  if (!isValidId) return <PageNotFound key="chat" />;
  return (
    <div
      className={`${classes.root} flex-fill d-flex flex-column h-100 position-relative`}
      style={{ flex: "1" }}
    >
      {!!props.boxMode ? (
        <AppBar
          position="static"
          color={props.boxMode ? "primary" : "inherit"}
          className={props.boxMode && " rounded-top"}
          onClick={() => (props.boxMode ? toggleChatWindow(true) : null)}
        >
          <Toolbar
            className={clsx(
              "d-flex justify-content-between align-items-center",
              "border-bottom",
              classes.toolbar,
              props.boxMode && classes.noMinHeight,
              props.boxMode && "fp-10"
            )}
          >
            <div className="d-flex fp-5" style={{ width: "65%" }}>
              <AvatarImage
                src={user && user.profile && user.profile["image_data"]}
                base64={true}
                size={props.boxMode ? "thumbnail" : "small"}
                className="fmr-20 border"
                name={user && user.profile && user.profile["name"]}
                alt={(user && user.profile && user.profile["name"]) || " "}
              />
              <div
                className="d-flex flex-column justify-content-center"
                style={{ flexGrow: "1" }}
              >
                <Typography
                  variant={props.boxMode ? "caption" : "h3"}
                  className={clsx(props.boxMode ? "text-truncate w-75" : "")}
                >
                  {user && user.profile && user.profile.name}
                </Typography>
                {last_active && (
                  <Typography className="font_10_500">
                    Active{" "}
                    {moment(last_active).fromNow() === "a few seconds ago"
                      ? ""
                      : moment(last_active).fromNow()}
                  </Typography>
                )}
              </div>
            </div>

            {props.boxMode && !isChatWinOpen && boxModeUnread > 0 && (
              <BoxModeMsgPop msgs={boxModeUnread} />
            )}
            {isChatWinOpen && (
              <div>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleViewInMode(props.id);
                  }}
                  className={clsx(
                    isChatWinOpen ? "visible" : "invisible",
                    "fmr-5 fp-5"
                  )}
                >
                  <OpenInNewIcon
                    className={clsx(
                      props.boxMode ? "text-white" : "text-black"
                    )}
                  />
                </IconButton>
                {props.boxMode ? (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      toggleChatWindow();
                    }}
                    className={clsx(
                      isChatWinOpen ? "visible" : "invisible",
                      " fp-5"
                    )}
                  >
                    <CloseIcon className="text-white" />
                  </IconButton>
                ) : (
                  <MoreVertIcon />
                )}
              </div>
            )}
          </Toolbar>
        </AppBar>
      ) : (
        <div>
          {last_active && (
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className="py-2 text-center"
              style={{
                top: 0,
                width: "calc( 100% - 40px )",
                marginTop: "1.5px"
              }}
            >
              Active{" "}
              {moment(last_active).fromNow() === "a few seconds ago" ||
              moment(last_active).fromNow() === "in a few seconds"
                ? ""
                : moment(last_active).fromNow()}
            </Typography>
          )}
        </div>
      )}
      <Collapse
        in={isChatWinOpen}
        className={`position-relative w-100 d-flex`}
        classes={{
          container: `flex-grow-1 ${props.boxMode && classes.collapseHeight}`,
          wrapper: `${classes.wrapper} ${props.boxMode &&
            isChatWinOpen &&
            "w-100"}`,
          wrapperInner: classes.wrapperInner
        }}
      >
        <div id="chatArea" className={classes.chatArea} onScroll={onScroll}>
          <div
            className="mx-auto text-center pt-1 "
            style={{
              visibility: lastFetchedMsg && fetching ? "visible" : "collapse"
            }}
          >
            <CircularProgress size={22} />
          </div>
          {show_date && <ShowDate date={date1} />}
          {messages &&
            messages.map((msg, index) => (
             <Message msg={msg} propsId={props.id} ext={ext} 
             handleOpenMessageOption={handleOpenMessageOption}
             messages={messages}
             index={index}
             />
            ))}
          {latestMsg && !latestMsg.metadata.hasPendingWrites && seen && (
            <Typography
              variant="subtitle1"
              id="seen"
              className="text-center mx-auto fp-10 "
              style={{ color: "#70829b" }}
            >
              — Seen —
            </Typography>
          )}
          <div id="messagesEnd" />
          <ScrollToBottom
            unread={unread}
            show={show && !previewFile.file && !!isChatWinOpen && !showAudio}
          />
        </div>
        {!_.isEmpty(user) && !user.onboarding_start_date && (
            <Typography
              variant="subtitle1"
              id="note"
              className="text-center mx-auto fp-10 "
              style={{ color: "#70829b" }}
            >
              Your messages will be delivered after the customer logs in
            </Typography>
        )}
        {!props.enable_chat && (
          <div className="d-flex align-items-center justify-content-center w-100" style={{ backgroundColor: "#70829B", height: "36px"}}>
            <Typography
              variant="caption"
              className="text-white"
            >
              {DISABLED_CHAT_TEXT}
            </Typography>
          </div>
        )}
        <Paper className={classes.rootPaper}>
          <Flag flagged={flagged} flagging={flagging} toggleFlag={toggleFlag}/>
          <IconButton
            className="primary"
            onKeyPress={(ev) => {
              ev.preventDefault();
            }}
            onClick={() => {
              inputEl.current.click();
            }}
          >
            <AttachFileIcon/>
          </IconButton>
          <IconButton 
            onClick={() => {
              setShowAudio(true)
            }}
            onKeyPress={(ev) => {
              ev.preventDefault();
            }}
          >
            <Mic />
          </IconButton>
          <div
            style={{ width: "90%" }}
            className="d-flex position-relative"
          >
            {showEmoji ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <span className={classes.emojis}>
                  <Picker onSelect={addEmoji} showPreview={false} />
                </span>
              </ClickAwayListener>
            ) : (
              ""
            )}
            <InputBase
              className={`ml-10 w-100 ${classes.input} `}
              placeholder={sendOnEnter ? 'Type a message. Hit Enter/Return to send' : 'Type a message...'}
              autoFocus={true}
              value={newMsg.body || ""}
              inputRef={textInput}
              id="inputArea"
              startAdornment={
                <InputAdornment position="start">
                  <div
                    onClick={handleshowEmojis}
                    className="position-absolute cursor-pointer"
                  >
                    <img src={smile} alt="emojiIcon" />
                  </div>
                </InputAdornment>
              }
              onChange={onChange}
              onKeyPress={handleKeyPress}
              classes={{
                inputMultiline: "pl-4 py-1 hide_scroll_bar"
              }}
              multiline={true}
            />
          </div>

          <IconButton
            color="primary"
            className={"fml-20 p-12"}
            classes={{root: "bg-primary"}}
            aria-label="send"
            onClick={(e) => handleKeyPress(e, true)}
            disabled={!newMsg.body}
          >
            <img src={send_icon} alt="sendIcon" />
          </IconButton>
        </Paper>

        {previewFile && previewFile.file && !previewFile.isAudio  &&  (
          <MediaPreview previewFile={previewFile} onSend={handleSend} onCancel={cancelPreview} />
        )}
      </Collapse>
      {showAudio && (
        <AudioRecorder
          onSave={handleSend}
          onChange={(data) => handleCallback(data)}
          open={showAudio}
          onClose={cancelPreview}
          hideAction={!previewFile || !previewFile.file}
        />
      )}
      <input
        id="file-picker"
        ref={inputEl}
        type="file"
        className="d-none"
        accept="image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/webm, video/ogg, video/quicktime, .doc, .docx, .xls, .xlsx, .pdf"
        onChange={addedFile}
      />
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        classes={{
          paper: `fb-border ${classes.zIndex}`,
          list: "p-0"
        }}
        id="delete_pop_up"
        onClick={handleCloseMenu}
      >
        {downloadResource.type !== 'text' && (
          <MenuItem onClick={beginDownload}>
            Download
          </MenuItem>
        )}
        <MenuItem onClick={toggleDeleteConfirm}>
          Delete
        </MenuItem>
      </Menu>
      <Confirmation
        title="Please confirm"
        confirmOption="Yes, Delete"
        msg="Are you sure you want to delete the message?"
        open={!!deleteConfirm}
        handleCancel={toggleDeleteConfirm}
        handleChange={handleDeleteMessage}
      />
    </div>
  );
};

const mapDispatchToProps = d => {
  return {
    ...appRdxFns(d)
  };
};

export default withWidth()(
  withSnackbar(connect(null, mapDispatchToProps)(ChatView))
);

const Flag = ({ flagged, flagging, toggleFlag }) => {
  return (
    <Tooltip title={flagging ? 'Processing...' : (flagged ? 'Starred conversation. Click to remove' : 'Star conversation for quick access later')}>
      <IconButton color={flagged ? 'primary' : 'default'} onClick={toggleFlag} disabled={flagging}>
        {flagging ? <CircularProgress size={24}/> : <i className={`${flagged ? 'fas' : 'far'} fa-star fa-sm`}></i>}
      </IconButton>
    </Tooltip>
  );
};

export const getImageDownloadUrl = async ({ identifier: urlIn, storageFolderRef }) => {
  if (!urlIn) return;
  let url = `${storageFolderRef}/original/${urlIn}`;
  const downloadUrl = await storageRef.child(`${url}`).getDownloadURL();
  return downloadUrl;
};

export const getDownloadUrl = async (id, attachmentData) => {
  const { attachment: { identifier, completed } = {} } = attachmentData;
  const base_path = _.get(attachmentData, 'attachment.base_path', false);
  if (!completed) return;
  const url = base_path
    ? `${base_path}/chats/attachment/${identifier}`
    : `user_profiles/${id}/chats/attachment/${identifier}`;
  const downloadUrl = await storageRef.child(url).getDownloadURL();
  return downloadUrl;
};
