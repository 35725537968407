import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

const AORGN = {vertical: 'top', horizontal: 'left'};
const MORGN = {vertical: 'top', horizontal: 'right'};

const AddBtn = (props) => {
  const { hidden, isPerDay, week, weekId, day, axn } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const tooltip = useMemo(() => {
    if (!weekId || day < 0 || day > 7) return undefined;
    if (isPerDay) return 'Add/Import a Workout or Meal';
    else return 'Add a Workout';
  }, [isPerDay, weekId, day]);
  const onIconClick = useCallback((e) => {
    if (!anchorEl) setAnchorEl(e.currentTarget);
    else {
      setAnchorEl(null);
      axn(e);
    }
  }, [anchorEl, axn]);
  const close = () => setAnchorEl(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  if (hidden || !weekId || day < 0 || day > 7) return null;
  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton className={`my-n2 ${open ? 'bg-light-primary' : ''}`}
          onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='wo'>
          {open ? <CloseIcon fontSize='small' color='primary'/> : <AddIcon fontSize='small'/>}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={close} anchorOrigin={AORGN} transformOrigin={MORGN}>
        <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='woSch'>Import Workout Schedule</MenuItem>
        {isPerDay && <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='mlSch'>Import Meal Schedule</MenuItem>}
        <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='wo'>Add a Workout</MenuItem>
        {isPerDay && <MenuItem onClick={onIconClick} data-week={week} data-weekid={weekId} data-day={day} data-purpose='ml'>Add a Meal</MenuItem>}
      </Menu>
    </>
  );
};

export default AddBtn;
