import React, { useContext, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux';
import SchRdxFns from "fitbud/redux/schedules";
import appRdxFns from "fitbud/redux/app";
import woSchRepo from "fitbud/repo/schedules";
import mlSchRepo from "fitbud/repo/mlSchedules"
import mlSchRdxFns from "fitbud/redux/mlSchedules";
import _ from 'lodash';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR, DRAFT_STATES, PUBLISHED, DRAFT,OFFLINE_ERROR,FETCH_ERROR } from 'fitbud/utils/constants';
import WoMlScheduleEditor from './woMlScheduleEditor';
import { CircularProgress as NonBlockingLoader,Divider, IconButton, Card, CardHeader, CardContent } from "@material-ui/core";
import CreateEditScheduleDialog from './createEditForm';
import { getAllIDsToFetch, getDataKey, MAX_WEEK_VIEW } from './helper';
import WoMlScheduleViewer from './woMlScheduleViewer';
import { bffGetCatalogByIDs } from 'fitbud/api';
import CardDetailDrawer from './cardDetailDrawer';
import { ScheduleDetailHeader } from './header';
import update from "immutability-helper";
import * as Sentry from '@sentry/browser';
import AlternateWoDialog from './alternateWoDialog';
import { isFilterEmpty as checkIfEmpty,isOnline } from "fitbud/utils/helpers";

const WORKOUT='workout';

const fetchScheduleDoc = (_id, _cid, draftFeature, scheduleType,isDraft) => scheduleType === WORKOUT ?
  woSchRepo(_cid,draftFeature).doc(_id,null,isDraft) : mlSchRepo(_cid,draftFeature).doc(_id,null,isDraft);

// const updateScheduleDoc = (_id, _cid, updatedDoc, scheduleType) => scheduleType === WORKOUT ?
//   woSchRepo(_cid).update(_id, updatedDoc) : mlSchRepo(_cid).update(_id, updatedDoc);

const ScheduleDetails = (props) => {
  const { id, scheduleType, pop, onSave, onSelect,
    insertWoSchedule, insertMlSchedule, updateWoSchedule, updateMlSchedule,isEditorMode,isPreview } = props;
  const { cid, comp } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  // LOCAL STATE
  const [editMode, setEditMode] = useState(isEditorMode?"scheduleEdit":null);
  const [doc, setDoc] = useState(null);
  const [draftDoc, setDraftDoc] = useState(null);
  const [publishedDoc, setPublishedDoc] = useState(null);
  const [showingDoc,toggleShowingDoc]=useState(PUBLISHED);
  const [isLoading, setLoading] = useState(false);
  const [selectedRange, updateSelectedRange] = useState([1, MAX_WEEK_VIEW]);
  const [fetchedItems, updateFetchedItems] = useState({});
  const [toggleDrawer, setDrawer] = useState(null);
  const [alternateDialog, setAlternateDialog] = useState(false);
  const draftFeature=useMemo(()=>{
    return !!comp.data().features.draft;
  },[comp]);
  const isNew=useMemo(()=>{
    return id==="new";
  },[id]);
  const isWorkout=useMemo(()=>{
    return scheduleType===WORKOUT;
  },[scheduleType])
  const repo=useMemo(()=>{
    return scheduleType===WORKOUT?woSchRepo:mlSchRepo;
  },[scheduleType]);
  const updateRdx=useMemo(()=>{
    return isWorkout?updateWoSchedule:updateMlSchedule;
  },[scheduleType,id])
  const insert=useMemo(()=>{
    return isWorkout?insertWoSchedule:insertMlSchedule;
  },[scheduleType,id])
  const isDraftAvailable=useMemo(()=>{
    return !!_.get(publishedDoc,"publish_status",PUBLISHED).includes('draft');
  },[id,scheduleType,publishedDoc?.publish_status])
  const isScheduleEmpty=useMemo(()=>{
    return checkIfEmpty(_.get(doc,'data',{}))
  },[_.get(doc,'data',{})]);
  const handleEditClose = () => {
    setEditMode(false);
    //will pop query params 
    onSelect(id);
    if(isNew) (pop && pop());
  };
  const toggleAlternateDialog = (val=true) => {setAlternateDialog(val)};
  const handlePublish = async(data) => {
    //to test if params is 'e' , using param.target
    const isE=!!data.target;
    let _doc={...doc};
    if(isDraftAvailable && showingDoc!==DRAFT){
      _doc={...draftDoc}
    }
    handleSave(isE ? {..._doc}:{...data},PUBLISHED);
  };
  const handleDraft = (data,closeOnSaving=false) => {
    //to test if params is 'e' , using param.target
    const isE=!!data.target;
    handleSave(isE?{...doc}:{...data},DRAFT,closeOnSaving);
  };
  
  const handleSave = (data,docType=PUBLISHED,closeOnSaving=true) => {
    const {showLoader,hideLoader}=props;
    if(checkIfEmpty(data.data) && docType===PUBLISHED){ 
      if(isNew){
        //do nothing
      }
      else if(draftFeature || (!draftFeature && editMode==="scheduleEdit")){
        enqueueSnackbar("Empty schedule can't be published",{variant:"error"});
        return;
      }
    }
    //sanitize code before save
    data['desc']=(data['desc'] || "").trim();
    data['ref_name']=(data['ref_name'] || "").trim();
    data['title']=(data['title'] || "").trim();
    //
    showLoader();
    const fn=isNew?repo(cid,draftFeature).create:repo(cid,draftFeature).update;
     fn(isNew?data:id,data,null,docType)
    .then((_doc)=>{
      const updatedData=_doc.data();
      const newPublishStatus=updatedData.publish_status;
      const reduxDoc = { 
        _id: _doc.id, 
        data: { 
          ...updatedData,
          cid, 
          thumbnail:(newPublishStatus===DRAFT_STATES['DRAFT_ONLY']||docType===PUBLISHED)?
            updatedData.thumbnail
            :publishedDoc.thumbnail
         }
      }
      isNew ? insert({...reduxDoc}) : updateRdx({...reduxDoc});
      setDoc({...updatedData});
      toggleShowingDoc(docType);
      setPublishedDoc(o=>docType===PUBLISHED?{...updatedData}:{...o,publish_status:updatedData.publish_status});
      setDraftDoc(o=>docType===DRAFT?{...updatedData}:{...(o||{})});
      if(isNew && editMode==="metaEdit"){
        onSave(_doc,'editorMode');
      }
      else{
        if(closeOnSaving) setEditMode(false);
        onSelect(_doc.id);
      }
    })
    .catch(err=>{
      console.error(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(err);
    })
    .finally(()=>{
      hideLoader();
    })
  };

  const handleToggleDrawer = (val=true) => {setDrawer(val)};
  const saveWoEdit = (data={},newId=null) => {
    if(!!newId){
      updateFetchedItems(prev=>({...prev,
        [newId]:{
          ..._.cloneDeep({...data,_id:newId})
        }
      }));
      const { weekIndex, dayIndex, woIndex, updateLocalState, localState, setDirty } = toggleDrawer;
      const dayIds = _.get(localState.data, `w${weekIndex}.d${dayIndex}.${getDataKey(scheduleType)}`, []);
      dayIds.splice(woIndex, 1, newId);
      const newState = _.set(localState, `data.w${weekIndex}.d${dayIndex}.${getDataKey(scheduleType)}`, dayIds);
      if(!!updateLocalState){
        updateLocalState(newState);
        setDirty && setDirty(true);
      } else {
        console.error("updateLocalState function is missing!")
        return enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      }
      handleToggleDrawer({
        ...data,
        _id:newId,
        displayEditOption:true,
        weekIndex,
        dayIndex,
        woIndex,
        updateLocalState,
        setDirty,
        localState:{...newState}
      });
    }
    else{
      updateFetchedItems(prev => ({...prev, 
        [toggleDrawer.id || toggleDrawer._id ]: {..._.cloneDeep(data), 
          id: toggleDrawer.id || toggleDrawer._id 
      }}))
    }
  };


  const deleteItem = () => {
    const { onDelete, hideLoader, showLoader, deleteWoSchedule, deleteMlSchedule } = props;
    showLoader();
    repo(cid,draftFeature).delete(id).then((doc)=>{
      if(doc){
        isWorkout? deleteWoSchedule(doc.id):deleteMlSchedule(doc.id);
        if (!!onDelete) onDelete();
        enqueueSnackbar("Schedule deleted successfully.", { variant: "success" });
      }
    })
     .catch((err) => {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }).finally(() => hideLoader());
  };

  const copyItem = async () =>{
    let { showLoader, hideLoader, onSave, clear } = props;
    const oldDoc = doc;
    showLoader();
    try {
      let copyDoc = update(oldDoc,{
        ref_name:{
          $set:`Copy of ${oldDoc.ref_name}`
        },
      });
      let doc = await repo(cid,draftFeature).create(copyDoc);
      if(isWorkout) {
        insertWoSchedule({
          _id: doc.id,
          data: {
            ...doc.data()
          }
        });
      } else {
        insertMlSchedule({
          _id: doc.id,
          data: {
            ...doc.data()
          }
        });
        onSave(doc);
      }
      enqueueSnackbar("Schedule copied successfully.", { variant: "success" });
      hideLoader();
      clear && clear();
      onSave && onSave(doc)
    } catch(err){
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      console.error(err);
      Sentry.captureException(err);
      hideLoader();
    }
  };
  const discardDraft=async ()=>{
    const { showLoader, hideLoader, onDelete ,deleteWoSchedule,deleteMlSchedule} = props;
    showLoader();
    repo(cid,draftFeature).deleteDraft(id,null,publishedDoc.publish_status)
      .then((_doc)=>{
        if(!_doc) return;
        const updatedData = _doc.data();
        const updatedPublishedStatus=_.get(updatedData, "publish_status", "");
        if (updatedPublishedStatus === DRAFT_STATES["DRAFT_ONLY"]) {
          isWorkout? deleteWoSchedule(_doc.id):deleteMlSchedule(_doc.id);
          if (!!onDelete) onDelete();
        }
        else if(updatedPublishedStatus===DRAFT_STATES["PUBLISHED"]){
          setPublishedDoc(o=>({...o,publish_status:PUBLISHED}))
          setDoc(o=>({...o,publish_status:PUBLISHED}));
          toggleShowingDoc(PUBLISHED);
          //TODO: check returned doc.publishedStatus
          setPublishedDoc({...updatedData,publish_status:PUBLISHED});
          updateRdx({
            _id: _doc.id, 
            data:{
              ...updatedData,
              cid
            }
          })
        }
        enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
      })
      .catch((err)=>{
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      })
      .finally(()=>hideLoader())
  }
  const toggleDoc=(docType)=>{
    const doc = docType === DRAFT ? { ...draftDoc } : { ...publishedDoc };
    if (!Object.keys(doc).length) return;
    parseCurrentDoc(doc);
    setDoc({...doc});
    toggleShowingDoc(docType);
  }
  const parseCurrentDoc=async(doc)=>{
    let allIds = getAllIDsToFetch(doc, scheduleType, selectedRange);
    if(!allIds.length || !allIds.length) {
      return;
    }
    const newIds = allIds.filter((d) => !fetchedItems[d]) || [];
    if(!newIds || !newIds.length){
      return;
    }
    const { data: _resData = { } }  = await bffGetCatalogByIDs({cid, [scheduleType+'s']: [ ...newIds ]});
    if(!_resData.success) throw _resData;
    const _items = {};
    _.get(_resData, scheduleType+'s', []).forEach((itemData) => {
        _items[itemData._id] = { ...itemData };
    });
    updateFetchedItems(o=>({...o,..._items  }));
  }
  const getScheduleDoc=async(unmount)=>{
    if(!!unmount) return;
    let doc,publishedDoc,draftDoc,showingDoc;
    try{
      setLoading(true);
      //published doc
      const scheduleDoc=await fetchScheduleDoc(id,cid,draftFeature,scheduleType);
      publishedDoc = { ...scheduleDoc.data() };
      //if draft feature is off, treat main collection doc as published
      if(!draftFeature){
        publishedDoc['publish_status']=PUBLISHED;
      }
      const publish_status=_.get(publishedDoc,'publish_status',PUBLISHED);
      if(publish_status.includes('draft')){
      //get draft doc
      const draftWo=await fetchScheduleDoc(id,cid,draftFeature,scheduleType,true);
      draftDoc=draftWo.data?draftWo.data():draftWo;
      }
      if(publish_status===DRAFT_STATES['DRAFT_ONLY']){
        doc={...draftDoc};
        showingDoc=DRAFT;
      }
      else{
        doc = { ...publishedDoc };
        showingDoc = PUBLISHED;
      }
      await parseCurrentDoc(doc);
      setDoc({...doc});
      setPublishedDoc({...publishedDoc});
      setDraftDoc({...draftDoc});
      toggleShowingDoc(showingDoc);
    }
    catch(err){
      const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
      enqueueSnackbar(msg, { variant : 'error' });
      console.error(err);
    }
    finally{
      setLoading(false)
    }
  }
  const handleEdit=(type)=>{
    setEditMode(type);
    if(isDraftAvailable && showingDoc!==DRAFT){
      toggleDoc(DRAFT);
    }
  }
  
  useEffect(() => {
    let unmount = false;
    if(!id || !cid || !!unmount) return;
    if(isNew && !unmount) return setEditMode('metaEdit');
    getScheduleDoc(unmount);
    return () => unmount = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   let unmount = false;
  //   if(!id || !cid || !!unmount) return;
  //   if(id === 'new' && !unmount) return setEditMode('metaEdit');
  //   setLoading(true);
  //   fetchScheduleDoc(id, cid, scheduleType)
  //     .then(async(doc) => {
  //       if(!!unmount) return;
  //       const data = { ...doc.data() };
  //       let allIds = getAllIDsToFetch(data, scheduleType, selectedRange);
  //       if(!allIds.length || !allIds.length) return setDoc({ ...data });
  //       const { data: _resData = { } }  = await bffGetCatalogByIDs({cid, [scheduleType+'s']: [ ...allIds ]});
  //       if(!_resData.success) throw _resData;
  //       const _items = {};
  //       _.get(_resData, scheduleType+'s', []).forEach((itemData) => {
  //           _items[itemData._id] = { ...itemData };
  //       });
  //       updateFetchedItems({ ..._items  });
  //       setDoc({ ...data });
  //     }).catch((err) =>{console.error(err);enqueueSnackbar(DEFAULT_ERROR, { variant : 'error' })})
  //     .finally(() => setLoading(false));
  //   return () => unmount = true;
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);
  if((isLoading) && !editMode){
    return (
      <div className='w-100 h-100 bg-white'>
        <NonBlockingLoader className="position-absolute" style={{ top: "50%", left: "50%" }} />
      </div>
    );
  };
  return (
    <div className="flex-1 d-flex flex-column">
      {/* // META DATA EDIT ( TITLE, REF NAME etc. ) */}
      {(editMode === 'metaEdit' || editMode === 'metaEditOnly') &&
        <CreateEditScheduleDialog
          cid={cid}
          open={(id === 'new' && editMode === "metaEdit") || editMode === 'metaEditOnly'}
          onClose={handleEditClose}
          handlePublish={handlePublish}
          handleDraft={handleDraft}
          scheduleType={scheduleType}
          doc={doc}
          title={id==="new"?"Add Schedule":"Edit Schedule"}
          showCustomConfirmation={!!draftFeature && !isNew}
          isNew={isNew}
          draftFeature={draftFeature}
          isDraftAvailable={isDraftAvailable}
        />
      }

      {/* // SCHEDULE EDITOR */}
      {editMode === 'scheduleEdit' &&
        <WoMlScheduleEditor {...props}
          open={editMode === 'scheduleEdit'}
          scheduleType={scheduleType}
          id={id}
          onClose={handleEditClose}
          doc={{...doc}}
          fetchScheduleDoc={fetchScheduleDoc}
          fetchedItems={fetchedItems} 
          updateFetchedItems={updateFetchedItems}
          toggleDrawer={handleToggleDrawer}
          selectedRange={selectedRange}
          updateSelectedRange={updateSelectedRange} 
          showCustomConfirmation={!!draftFeature && !isNew}
          handlePublish={handlePublish}
          handleDraft={handleDraft}
          isNew={isNew}
          draftFeature={draftFeature}
          isDraftAvailable={isDraftAvailable}
          isScheduleEmpty={isScheduleEmpty}
        />
      }

      {/* // SCHEDULE VIEWER */}
      <div>
        {editMode !== "metaEdit" && 
          <ScheduleDetailHeader 
            doc={doc}
            deleteItem={deleteItem}
            copyItem={copyItem}
            handleEditMode={()=>handleEdit("metaEditOnly")}
            handleEditSchedule={()=>handleEdit("scheduleEdit")}
            scheduleType={scheduleType}
            isPreview={isPreview}
            isScheduleEmpty={isScheduleEmpty}
            draftBannerProps={{
              toggleDoc:toggleDoc,
              handlePublish,
              discardDraft,
              showingDoc,
              isDraftAvailable
            }}
          />
        }

        {/* View only component */}
        
        {editMode !== "scheduleEdit" && editMode !== "metaEdit" && !isScheduleEmpty &&
          <WoMlScheduleViewer
            onEditClick={()=>handleEdit("scheduleEdit")}
            scheduleType={scheduleType}
            selectedRange={selectedRange}
            updateSelectedRange={updateSelectedRange} 
            doc={{...doc}}
            fetchedItems={fetchedItems}
            toggleDrawer={handleToggleDrawer}
            cid={cid}
            updateFetchedItems={updateFetchedItems}
            toggleAlternateDialog={toggleAlternateDialog}
            isPreview={isPreview}
            isScheduleEmpty={isScheduleEmpty}
          />
        }

        {/* Drawer - Workout/Cardio/Nutrition */}
        <CardDetailDrawer
          anchor="right"
          schName={(doc && doc.ref_name) || ''}
          data={toggleDrawer || {}}
          open={!!toggleDrawer}
          onClose={() => setDrawer(false)}
          onSave={saveWoEdit}
        />

        {/* Alternate Workout Dialog */}
        {isWorkout &&!!alternateDialog && 
          <AlternateWoDialog 
            open={!!alternateDialog}
            viewOnly={true}
            onSave={null} 
            onClose={() => toggleAlternateDialog(false)} 
            ids={_.get(alternateDialog, 'itemId', '')}
            fetchedItems={fetchedItems}
            updateFetchedItems={updateFetchedItems}
            toggleDrawer={handleToggleDrawer}
          />
        }
      </div>
    </div>
  )
};

const mapDispatchToProps = d => {
  const {insert: insertWoSchedule, delete: deleteWoSchedule, update: updateWoSchedule } = SchRdxFns(d);
  const appFns = appRdxFns(d);

  const {insert: insertMlSchedule, delete: deleteMlSchedule, update: updateMlSchedule} = mlSchRdxFns(d);
  return { ...appFns, insertWoSchedule, deleteWoSchedule, updateWoSchedule, 
    insertMlSchedule, deleteMlSchedule, updateMlSchedule };
};
export default connect(null, mapDispatchToProps)(ScheduleDetails);
