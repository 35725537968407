import UserList from 'fitbud/components/userList';
import _ from 'lodash';
import React, { useMemo } from 'react';

export default (props) => {
  const { selectUser, comp, cid, tEnabled, ownerId } = props;
  const { primary_owner_id } = comp.data();
  console.log('>>>>>primary_owner_id',primary_owner_id)

  const isIntroCall = useMemo(()=> {
    const intro_call = _.get(comp ? comp.data() : {}, 'features.intro_call', false);
    return intro_call && intro_call > 0;
  },[comp]);

  return (
    <UserList
      directSearchMode
      cid={cid}
      keyName={isIntroCall ? 'browse' :'onboarded'}
      fullWidth
      hideFilter
      disableTagSearch
      placeholder='Search user by name or email'
      onSelect={selectUser}
      searchPlaceholderText='Search a client to schedule a video call'
      defaultFilters={tEnabled && ownerId !== primary_owner_id ? { trainers:[ownerId] } : {}}
    />
  );
};