import { Divider, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import clsx from "clsx";
import { CroppyContext } from "croppy";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import VideoFeatures from "fitbud/components/videoFeatures";
import videoUploadingIcon from "fitbud/images/videoPlaceholder.svg";
import {
  DEFAULT_MEDIA_TYPE
} from "fitbud/utils/constants";
import { fetchVideoThumbnail } from "fitbud/utils/services";
import _ from "lodash";
import React, { useCallback, useContext, useState } from "react";
import VideoPlayer from "../videoPlayer";
import MultiImageUploader from "./multiImageUploader";
import { LinkUploader } from "./linkUploader";
import { ThumbnailUploader } from "./thumbnailUploader";
import { VideoFileUploader } from "./videoFileUploader";
import { VIMEO_VIDEO_URL_REGEX, YOUTUBE_VIDEO_URL_REGEX } from "fitbud/utils/constants";
import { useAcl, ACL_FEATURES_KEY } from "fitbud/providers/acl-provider";

export const uploaderStyles = makeStyles((theme) => ({
  mediaBlock: {
    display: "grid",
    gridTemplateColumns: (props) =>
      props.isMediaFullWidth || !props.isMediaUrlAvailable
        ? props.withThumbnail ? "390px 1fr 1fr" : "1fr"
        : props.withThumbnail ? "390px 1fr 1fr" : props.showFeature ? "265px 1fr" : "1fr",
    gridAutoRows: (props) => {
      return (props.isUploadCase ?
        (`${props.isMediaFullWidth ? "432px" : props.withThumbnail ? "225px" : "265px"}`)
        : !props.isMediaUrlAvailable ? (`${props.withThumbnail ? "225px" : "auto"}`)
          : props.isMediaFullWidth ? "364px" : props.withThumbnail ? "225px" : "265px")
    },
    gridRowGap: 20,
    gridColumnGap: (props) =>
      props.isMediaFullWidth || !props.isMediaUrlAvailable ? props.withThumbnail ? 20 : 0 : props.withThumbnail ? 20 : 25,
  },
  media: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    overflow: "hidden",
    border: (props) => (props.type === "image" ? "1px solid #d1d1d1" : "none"),
    "& img": {
      width: "100%",
      height: "auto",
    },
    "& .img-square": {
      objectFit: "cover",
      height: "100%",
    },
  },
  inputBox: {
    gridColumn: "1/3",
  },
  closeBtn: {
    top: 0,
    right: "10px",
    zIndex: 1,
  },
  uploadIcon: {
    width: 40,
    height: 40,
    backgroundColor: "#fff",
    borderRadius: "50%",
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thumbnailBox: {
    width: "168px",
    height: '168px'
  },

}));

export const MediaUploader = (props) => {
  const { comp } = useContext(FirebaseAuthContext);
  const ytOnly  = _.get(comp.data(), 'features.yt_only', false);
  const { reupload } = useContext(CroppyContext);
  const {status, checkAccessFeature} = useAcl();
  const {
    label,
    media = {},
    multiImage = false,
    setMedia,
    handleMedia,
    handleChange,
    is_mute_video,
    featureType,
    showFeature,
    className,
    doc = {},
    classes: propClasses = {},//{label:"",mediaBlock:""}
    labelClassName,
    hideRadio = false,
    hideYoutube = false,
    hideVimeo = false,
    required,
    withThumbnail,
    playerProps,
    is_crop_square,
    removeMedia,
    thumbnail,
    thumbaspect = 1,
    imgAspect = 1,
    maxImage,
    isNew,
    children,
    ...rest
  } = props;
  const [isThumbnailLoading, setThumbnailLoad] = useState(false);
  const [canUseVideoThumbnail, allowUseVideoThumbnail] = useState(!!isNew);
  const allowVideoUpload = checkAccessFeature(status, ACL_FEATURES_KEY.CONTENT_UPLOAD);
  const UPLOAD_ORDER_OPTIONS = allowVideoUpload ? ["video", "youtube","vimeo"] :["vimeo", "youtube","video"]; // on basic of allow upload or not, change order.

  function handleRadioChange(e, value) {
    handleMedia(value, "");
  }

  const {
    type: mediaType = ytOnly ? "vimeo" : (allowVideoUpload ? DEFAULT_MEDIA_TYPE : (hideVimeo ? "video" : "vimeo")),
    url: mediaUrl = null,
    videoWidth = null,
    videoHeight = null
  } = _.get(media,"0",{}) || {}; //if video upload not allowed then set default to vimeo for safe case.
  const aspectRatio = (videoWidth && videoHeight) ? (videoWidth / videoHeight).toPrecision(2) : null;
  const isUploadCase = mediaType === "image" || mediaType === "video";
  const isMediaFullWidth = ['workout'].includes(featureType);

  const classes = uploaderStyles({ isUploadCase, isMediaFullWidth, isMediaUrlAvailable: !!mediaUrl, aspectRatio, withThumbnail, showFeature });
  const _removeMedia = () => {
    if (removeMedia) {
      removeMedia(mediaType);
    }
  }

  const _handleMedia = (...args) => {
    handleMedia(...args);
    if (withThumbnail && !thumbnail && !!_.get(args, "1", null)) {
      console.log(args, '>>>122')
      allowUseVideoThumbnail(true);
    }
  };

  const uploadThumbnail = useCallback(async (thumbnail) => {
    if (!withThumbnail) return null;
    if (thumbnail) {
      const uploaded = await reupload(thumbnail);
      handleChange({
        target: {
          name: "thumbnail",
          value: uploaded,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, isThumbnailLoading]);

  const fetchThumbnail = useCallback(async () => {
    try {
      setThumbnailLoad(true);
      const thumbnail = await fetchVideoThumbnail(media);
      if (thumbnail) await uploadThumbnail(thumbnail);
      allowUseVideoThumbnail(false)
    } catch (err) {
      console.log(err, ">>");
    } finally { setThumbnailLoad(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  const whenPlayerReady = useCallback((e) => {
    if(playerProps && playerProps.onReady) playerProps.onReady(e);
    if(withThumbnail && canUseVideoThumbnail) fetchThumbnail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseVideoThumbnail, media]);

  const isValidUrl = isUploadCase || !mediaUrl || mediaUrl.match(YOUTUBE_VIDEO_URL_REGEX) || mediaUrl.match(VIMEO_VIDEO_URL_REGEX);

  if (children) {
    if (Array.isArray(children) || typeof children === 'string') {
      throw new Error('MediaUploader: children should be a single element or a React component');
    }
    if (typeof children === 'function') return children({ mediaType, mediaUrl, handleMedia: _handleMedia, ...rest });

    return React.cloneElement(children, {
      mediaType,
      mediaUrl,
      imgAspect,
      maxImage,
      ytOnly,
      _handleMedia,
      doc,
      setMedia,
      removeMedia,
      playerProps,
      whenPlayerReady,
      ...rest,
    });
  }
  return (
    <>
      <div>
        <Typography variant="h3" className={clsx("fmb-15", propClasses.label)}>
          {label}
          {required && <span aria-hidden="true" class="MuiFormLabel-asterisk"> *</span>}
        </Typography>
        {/*----- Radio -----*/}
        <RadioGroup
          value={mediaType}
          className={`flex-row mb-20 ${hideRadio ? 'd-none' : 'd-block'}`}
          onChange={handleRadioChange}
        >
          {UPLOAD_ORDER_OPTIONS.map((vt)=>{
            if (vt === 'video')
              return (
                <FormControlLabel
                  key={vt}
                  value={!multiImage && isUploadCase ? mediaType : 'video'}
                  disabled={ytOnly}
                  control={<Radio color="primary" className="p-0 mr-10" />}
                  label="Upload"
                  classes={{ label: 'font_16_500', root: 'fm-0 fmr-30' }}
                />
              );
            if (vt === 'youtube' && !hideYoutube)
              return (
                <FormControlLabel
                  key={vt}
                  value="youtube"
                  control={<Radio color="primary" className="p-0 mr-10" />}
                  label="YouTube"
                  classes={{ label: 'font_16_500', root: 'fm-0 fmr-30' }}
                />
              );
            if (vt === "vimeo" && !hideVimeo)
              return (
                <FormControlLabel
                  key={vt}
                  value="vimeo"
                  control={<Radio color="primary" className="p-0 mr-10" />}
                  label="Vimeo"
                  classes={{ label: 'font_16_500', root: 'fm-0 fmr-30' }}
                />
              );
          })}
          {multiImage && <FormControlLabel
            value="image"
            control={<Radio color="primary" className="p-0 mr-10" />}
            label="Images"
            classes={{ label: "font_16_500", root: "fm-0 fmr-30" }}
          />}
        </RadioGroup>
        {/*----- Radio - end-----*/}
      </div>
      {/*----- Uploaders -----*/}
      {!isUploadCase &&
        <LinkUploader
          mediaType={mediaType}
          mediaUrl={mediaUrl}
          handleMedia={_handleMedia}
          is_mute_video={doc.is_mute_video}
          is_crop_square={doc.is_crop_square}
          doc={doc}
          {...rest}
        />
      }
      {!(mediaType === 'image' && multiImage) && <div className={clsx(
        propClasses.mediaBlock, classes.mediaBlock,
        !withThumbnail && mediaUrl && isValidUrl && "mt-20",
        withThumbnail && (mediaUrl || !isUploadCase) && "mt-20",
        withThumbnail && !isUploadCase && !!showFeature && "mb-20",
      )}>
        {isUploadCase && (
          <VideoFileUploader
            ytOnly={ytOnly}
            media={media}
            type={mediaType}
            handleMedia={_handleMedia}
            is_crop_square={doc.is_crop_square}
            is_mute_video={doc.is_mute_video}
            removeMedia={removeMedia}
            playerProps={{ ...playerProps, onReady: whenPlayerReady,id:playerProps.id||'media_bgon' }}
            {...rest}
          />
        )}
        {isUploadCase && withThumbnail && (
          <Divider orientation="vertical" />
        )}
        {withThumbnail && !isUploadCase && (!mediaUrl || !isValidUrl) && (
          <div
            style={{ width: '390px', borderRadius: 6 }}
            className={clsx("bg-grey-new border d-flex flex-column justify-content-center align-items-center position-relative")}
          >
            <div>
              <img src={videoUploadingIcon} alt="" />
            </div>
            <Typography className="text-0d0d0d w-75 font_13_500 text-center mt-10">
              {"Please enter a link above to preview the video"}
            </Typography>
          </div>
        )}
        {!isUploadCase && mediaUrl && isValidUrl &&
          <div className={clsx(classes.media, "bg-black")}>
            <VideoPlayer
              url={mediaUrl}
              playerProps={{ ...playerProps, onReady: whenPlayerReady,id:playerProps.id||'media_bgon'  }}
              muted={is_mute_video}
              onCross={_removeMedia}
              aspectRatio={is_crop_square ? 1 : 0}
            />
          </div>}
        {!isUploadCase && withThumbnail && (
          <Divider orientation="vertical" />
        )}
        {withThumbnail && (
          <ThumbnailUploader
            mediaUrl={mediaUrl}
            media={media}
            isUploadCase={isUploadCase}
            onChange={handleChange}
            thumbnail={thumbnail}
            aspectRatio={thumbaspect}
            uploadThumbnail={uploadThumbnail}
            fetchThumbnail={fetchThumbnail}
            mediaType={mediaType}
            isThumbnailLoading={isThumbnailLoading}
            setThumbnailLoad={setThumbnailLoad}
          />
        )}
        {!withThumbnail && mediaUrl && !!showFeature && (
          <VideoFeatures
            withThumbnail={withThumbnail}
            handleChange={handleChange}
            mediaType={mediaType}
            // setDirtyOnChange={setDirtyOnChange}
            doc={doc}
            featureType={featureType}
            {...rest}
          />
        )}
      </div>}
      {multiImage && mediaType === 'image' && <div>
        <MultiImageUploader imgAspect={imgAspect} maxImage={maxImage} media={media} setMedia={setMedia}/>
      </div>}
      {withThumbnail && mediaUrl && !!showFeature && (
        <div className="mt-20">
          <VideoFeatures
            withThumbnail={withThumbnail}
            handleChange={handleChange}
            mediaType={mediaType}
            doc={doc}
            featureType={featureType}
            {...rest}
          />
        </div>
      )}
      {/*----- Uploaders - end -----*/}
    </>
  );
};
