import React, { useReducer, useState } from "react";
import moment from "moment";
import {
  Card,
  makeStyles,
  Typography,
  IconButton,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DetailEditIcon from "fitbud/icons/detailEdit";
import clsx from "clsx";
import Dialog from "fitbud/components/Dialog";
import { ClrdButton, FormTextField } from "fitbud/components/form-fields";
import AutoSuggest from "fitbud/components/autoSuggest";
import {formatTime} from "./helperfn";
import {DurationWrapper} from "./components/amrapDurationInput";

const styles = makeStyles({
  chips: {
    backgroundColor: "#F2F4F7",
    borderRadius: "40px",
    "& .MuiChip-label": {
      fontSize: "13px",
      fontWeight: "500!important",
    },
  },
});

const SPFEEDBACK = ['hibrido', 'tasdeekraza123', 'hfggx1nkxm'];

export const EditorSummary = ({
  calculated,
  override,
  flags,
  className,
  ...props
}) => {
  const { cid, submitSummary: reset } = props;
  //--renderedValues
  const duration = flags.duration ? override.duration : calculated.duration;
  const bodyPartObj = flags.body_parts
    ? override.bodyPartObj
    : calculated.bodyPartObj;
  const equipmentsObj = flags.equipments
    ? override.equipmentsObj
    : calculated.equipmentsObj;
  //--hideFlags
  const hideEquipments =
    !calculated.equipments.length && !override.equipments.length;
  const hideBodyParts =
    !calculated.body_parts.length && !override.body_parts.length;
  //
  const [isEdit, toggleEdit] = useState(false);
  const classes = styles();
  function openEditForm() {
    toggleEdit(true);
  }
  function closeEditForm() {
    toggleEdit(false);
  }
  function onReset(name) {
    reset({
      data: { [name]: calculated[name] },
      flags: { [name]: false },
    });
  }
  //for view
  const summaryTime={
    hour:moment.utc(duration * 1000).hours(),
    min:moment.utc(duration * 1000).minutes(),
    sec:moment.utc(duration * 1000).seconds()
  }
  return (
    <>
      <Card
        className={clsx("fp-25", className)}
        style={{ borderRadius: "10px" }}
      >
        {/*---- duration--- */}
        <div className="d-flex align-items-start">
        <div className="fmb-25 flex-1">
          <div className="w-100 d-flex justify-content-between align-items-baseline fmb-10">
            <Typography className="font_13_600 text-grey">
              DURATION
              {!!flags.duration && (
                <span className="fml-15 font_13_500">Edited</span>
              )}
            </Typography>
            {!!flags.duration && (
                <ClrdButton
                  type="text"
                  color="primary"
                  onClick={() => onReset("duration")}
                >
                  Reset
                </ClrdButton>
              )}
          </div>
          <Typography className="font_30_700">
            {!!summaryTime.hour && (
              <>
                <span className="mr-4px">{formatTime(summaryTime.hour)}</span>
                <span className="font_16_400 mr-8">h</span>
              </>
            )}
            {!!summaryTime.min && (
              <>
                <span className="mr-4px">{formatTime(summaryTime.min)}</span>
                <span className="font_16_400 mr-8">m</span>
              </>
            )}
            <>
              <span className="mr-4px">{formatTime(summaryTime.sec)}</span>
              <span className="font_16_400">s</span>
            </>
          </Typography>
        </div>
        <IconButton
          aria-label="Edit"
          className="fmtn-10"
          onClick={openEditForm}
        >
          <DetailEditIcon style={{ fill: "none" }} />
        </IconButton>
        </div>
        {/*---- equipments--- */}
        <div className="fmb-25">
          <div className="w-100 d-flex justify-content-between align-items-baseline fmb-10">
            <Typography className="font_13_600 text-grey">
              EQUIPMENT
              {!!flags.equipments && (
                <span className="fml-15 font_13_500">Edited</span>
              )}
            </Typography>
            {!!flags.equipments && (
              <ClrdButton
                type="text"
                color="primary"
                onClick={() => onReset("equipments")}
              >
                Reset
              </ClrdButton>
            )}
          </div>
          <Typography variant="body1" className={hideEquipments && "fmb-15"}>
            {hideEquipments ? (
              "-"
            ) : (
              <>
                {equipmentsObj.map((i) => (
                  <Chip
                    className={clsx(classes.chips, "fmr-10 fmb-10")}
                    key={i}
                    label={i.label}
                  />
                ))}
              </>
            )}
          </Typography>
        </div>
        {/*---- Target areas--- */}
        <div>
          <div className="w-100 d-flex justify-content-between align-items-baseline fmb-10">
            <Typography className="font_13_600 text-grey">
              TARGET AREA
              {!!flags.body_parts && (
                <span className="fml-15 font_13_500">Edited</span>
              )}
            </Typography>
            {!!flags.body_parts && (
              <ClrdButton
                type="text"
                color="primary"
                onClick={() => onReset("body_parts")}
              >
                Reset
              </ClrdButton>
            )}
          </div>
          <Typography variant="body1" className={hideBodyParts && "fmb-10"}>
            {hideBodyParts ? (
              "-"
            ) : (
              <>
                {bodyPartObj.map((i) => (
                  <Chip
                    className={clsx(classes.chips, "fmr-10 fmb-10")}
                    key={i}
                    label={i.label}
                  />
                ))}
              </>
            )}
          </Typography>
        </div>
        {SPFEEDBACK.includes(cid) && <div className='mt-25'>
          <FormControlLabel label='Gather Soreness & Performance metrics' control={<Checkbox color='primary' name='sp_feedback'
          onChange={props.handleMetaChange} checked={props.sp_feedback}/>}/>
        </div>}
        {/* -- */}
      </Card>
      {isEdit && (
        <SummaryEditForm
          onClose={closeEditForm}
          initialState={{
            duration:duration,
            body_parts: bodyPartObj,
            equipments: equipmentsObj,
          }}
          resetState={{
            duration:duration,
            body_parts: calculated.bodyPartObj,
            equipments: calculated.equipmentsObj,
          }}
          initialflags={{ ...flags }}
          {...props}
        />
      )}
    </>
  );
};

const SummaryEditForm = ({
  initialState = {},
  resetState = {},
  initialflags = {},
  onClose,
  ...props
}) => {
  const { equipmentsData, targetAreasData, submitSummary: onSubmit } = props;
  const [flags, updateFlags] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      duration: false,
      equipments: false,
      body_parts: false,
      ...initialflags,
    }
  );
  const [state, updateState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      duration:0,
      equipments: [],
      body_parts: [],
      ...initialState,
    }
  );
  const [error, updateErr] = useState({});
  function handleDropdownChange(e, { name, action, option, removedValue }) {
    if (action === "select-option") {
      handleChange({ target: { name, action, ...option } });
    } else if (action === "remove-value") {
      handleChange({ target: { name, action, ...removedValue } });
    }
  }
  function handleChange(e) {
    if (!e) return;
    const { name, value, label, action } = e.target;
    switch (name) {
      case "duration":{
        updateFlags({ duration: true });
        updateState({ duration: value });
        break;
      }
      case "equipments": {
        updateFlags({ equipments: true });
        if (action === "select-option") {
          updateState({ equipments: [...state.equipments, { label, value }] });
        } else if (action === "remove-value") {
          updateState({
            equipments: state.equipments.filter((i) => i.value !== value),
          });
        }
        break;
      }
      case "body_parts": {
        updateFlags({ body_parts: true });
        if (action === "select-option") {
          updateState({ body_parts: [...state.body_parts, { label, value }] });
        } else if (action === "remove-value") {
          updateState({
            body_parts: state.body_parts.filter((i) => i.value !== value),
          });
        }
        break;
      }
      default:
        return;
    }
  }
  function resetValue(name) {
    if (name === "duration") {
      updateState({
        duration:resetState.duration,
      });
    } else updateState({ [name]: resetState[name] });
    updateFlags({ [name]: false });
  }
  function validate() {
    const { duration } = state;
    const err = {};
    if (duration<=0) {
      err["duration"] = "Please enter valid duration";
    }
    updateErr(err);
    return Object.keys(err).length;
  }
  function handleSubmit() {
    if (!!validate()) {
      return;
    }
    const duration =state.duration;
    const body_parts = state.body_parts.map((i) => i.value);
    const equipments = state.equipments.map((i) => i.value);
    const newState = {
      duration,
      body_parts,
      equipments,
    };
    onSubmit({
      data: { ...newState }, //firebase structure
      override: {
        //state structure
        ...newState,
        bodyPartObj: state.body_parts,
        equipmentsObj: state.equipments,
      },
      flags: { ...flags },
    });
    onClose();
  }
  return (
    <Dialog
      open
      title={"Edit Summary"}
      onSave={handleSubmit}
      onClose={onClose}
      dialogContentClassName="fp-20"
      paperClass="width-600"
    >
        <DurationWrapper 
          label="Duration"
          name="duration"
          value={state.duration}
          onChange={handleChange}
          amrap={false}
          formTextFieldProps={{
            btnProps:{
              children: "Reset",
              className: clsx("font_13_600", !flags.duration && "d-none"),
              color: "primary",
              variant: "text",
              onClick: () => resetValue("duration"),
            }
          }}
          helperText={error["duration"]}
          error={error["duration"]}
        />
      <FormTextField
        fullWidth
        label="Equipment"
        btnProps={{
          children: "Reset",
          className: clsx("font_13_600", !flags.equipments && "d-none"),
          color: "primary",
          variant: "text",
          onClick: () => resetValue("equipments"),
        }}
      >
        <AutoSuggest
          noSort
          name={"equipments"}
          placeholder="Add Equipment …"
          onChange={handleDropdownChange}
          options={equipmentsData}
          value={state.equipments}
        />
      </FormTextField>
      <FormTextField
        fullWidth
        label="Target Area"
        btnProps={{
          children: "Reset",
          className: clsx("font_13_600", !flags.body_parts && "d-none"),
          color: "primary",
          variant: "text",
          onClick: () => resetValue("body_parts"),
        }}
        classes={{ control: "fmb-0" }}
      >
        <AutoSuggest
          noSort
          name={"body_parts"}
          placeholder="Add target area ..."
          onChange={handleDropdownChange}
          options={targetAreasData}
          value={state.body_parts}
        />
      </FormTextField>
    </Dialog>
  );
};
