import _ from "lodash";
import * as React from "react";
import firebase from "fitbud/firebase";
import { ROLE_NAMES as _r } from "fitbud/utils/acl";
import { bffUpdateHubspot } from "fitbud/api";
import { HUBSPOT_PROPS } from "fitbud/utils/constants";
import moment from "moment";

const defaultFbComp = process.env.REACT_APP_ENV === 'production' ? 'silbe' : 'hfggx1nkxm';
const defaultFirebaseContext = {
  authLoading: true,
  authUser: null,
  userProfile: null
};

export const FirebaseAuthContext = React.createContext(defaultFirebaseContext);

const mapClaims = (claims) => {
  if (!claims || !claims.length) return [];
  const out = [];
  claims.forEach(r => {
    const role = _r[r];
    if (!role) return;
    out.push(role);
  });
  return out;
}

class FirebaseAuthProvider extends React.Component {
  constructor (props) {
    super(props);
    this.state = defaultFirebaseContext;
  }

  update = data => {
    if (!data) return;
    this.setState(({ userProfile: u }) => ({ userProfile: { ...u, ...data } }));
  };

  refreshco = () => {
    return this.state.comp.ref.get()
      .then(x => {
        const contract = x.data().contract;
        this.setState({comp: x, contract})
        return x.data();
      }).catch(e => {
        window.alert(e);
        window.location.reload();
      });
  }

  refreshstaff = () => {
    return firebase.firestore().doc(`staff/${this.state.userProfile.uid}`).get()
      .then(doc => {
        const { cids, ...rest } = doc.data();
        this.setState(state => ({userProfile: {...state.userProfile, ...rest}}));
        return doc.data();
      }).catch(e => {
        window.alert(e);
        window.location.reload();
      });
  }

  handleAuth = (user, forceReload = false) => {
    if (!user) {
      // not logged in
      const wasLoggedIn = !!this.state.authUser;
      if (!!window) {
        window.localStorage.removeItem("cid");
      }
      if (!wasLoggedIn) this.setState({ authLoading: false });
      if (!!window) {
        if (window.location.pathname !== '/' && window.location.pathname !== '/sign-up')
          window.history.pushState('', '', '/');
        if (wasLoggedIn)
          window.location.reload();
      }
      return;
    }
    // auth passed -> let's check staff profile
    const uid = user.uid;
    return Promise.all([
      firebase.firestore().doc(`staff/${uid}`).get(),
      user.getIdTokenResult(forceReload)
    ]).then(([doc, token]) => {
      const { cids, ...rest } = doc.data();
      if (_.isEmpty(cids)) throw Error("NOAUTH");
      const claims = token.claims;
      const isFBStaff = !_.isEmpty(claims.fb) && (claims.fb.includes('o') || claims.fb.includes('s') || claims.fb.includes('t') || claims.fb.includes('c'));
      const isAdmin = isFBStaff && claims.fb.includes('o');
      const onlyOps = isFBStaff && _.isEqual(claims.fb, ['s']);
      const out = {
        isFBStaff,
        isAdmin,
        onlyOps,
        acl: {},
        companies: [],
        userProfile: { ...rest, emailVerified: user.emailVerified, uid: user.uid }
      };
      if (isFBStaff && !onlyOps) {
        out.acl.fitbud = mapClaims(claims.fb);
        const cID = window.localStorage.getItem("cid") || null;
        if (!cID) { // a company hasn't been selected yet, nothing more to do
          window.localStorage.setItem("cid", defaultFbComp);
        }
        return Promise.all([Promise.resolve(out),
          firebase.firestore().doc(`companies/${cID||defaultFbComp}`).get()]);
      } else {
        const promises = [Promise.resolve(out)];
        cids.forEach(cid => {
          if (cid === "fitbud") return;
          const cRoles = mapClaims(claims[cid]);
          if (_.isEmpty(cRoles)) return;
          out.acl[cid] = cRoles;
          promises.push(
            firebase
            .firestore()
            .doc(`companies/${cid}`)
            .get()
          );
        });
        if (promises.length === 1)
          // no company, fail
          throw Error("NOAUTH");
        // if (promises.length === 2) // exactly 1 company, no need to fetch
        //   return Promise.all([Promise.resolve(out)]);
        return Promise.all(promises);
      }
    }).then(([state, ...comps]) => {
      const cID = window.localStorage.getItem("cid") || null;
      comps.forEach((comp, n) => {
        if (!comp || !comp.exists) return;
        const cid = comp.id;
        const {_next, profile, contract, features = {}, stripeAccount = {}, explore_config, primary_owner_id } = comp.data();
        const cname = profile.name || "---";
        state.companies.push({ id: cid, name: cname });
        if (cid === cID || n === 0) {
          state.comp = comp;
          state.contract = contract || {};
          state.features = features;
          state.primary_owner_id = primary_owner_id;
          state._next = _next || false;
          state.useNewPurchase = stripeAccount && stripeAccount.stripeAccountId && !['barmas', 'silbe', 'hibrido', 'fitwithmaisa'].includes(cid);
          state.hasClasses = features && features.group_class && features.group_class;
          state.hasExplore = false;
          if (_next && Boolean(explore_config)) {
            _.each(explore_config, (obj) => {
              if (obj.enabled) state.hasExplore = true;
            });
          }
        }
      });
      if (!state.isFBStaff && _.isEmpty(state.companies))
        throw Error("NOAUTH");
      // else state.companies.push(DUMMY, DUMMY2,DUMMY3);

      if(!cID) window.localStorage.setItem("cid",state.companies[0].id)
      this.setState({
        ...state,
        // cid: state.isAdmin ? CID_FITBUD : state.companies[0].id,
        cid: !!cID ? cID : state.companies[0].id,
        authLoading: false,
        authUser: user
      });
      bffUpdateHubspot({
        [HUBSPOT_PROPS.ACCOUNT_ACCESS_TYPE]: state.features.trainers ? "Enterprise" : "Independent",
        [HUBSPOT_PROPS.CONTACT_TYPE]: state.primary_owner_id === user.uid ? "Owner" : "Trainer",
        [HUBSPOT_PROPS.CID]: state.companies[0].id,
        [HUBSPOT_PROPS.LAST_LOGIN]: moment().format("YYYY-MM-DD"),
      });
    }).catch(err => {
      this.setState({
        authLoading: false,
        authUser: null,
        userProfile: null
      });
      firebase.auth().signOut();
      if (!!window) window.location.reload();
    }).catch(err => !!window && window.location.reload());
  };

  refreshAuth = () => this.handleAuth(this.state.authUser, true);

  select = (cid, path = '/') => {
    const { isAdmin, acl } = this.state;
    if (!cid || (!isAdmin && !acl[cid])) return;
    if (!!window) {
      window.localStorage.setItem("cid", cid);
      window.location.href = window.location.origin + path;
    }
  };

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(this.handleAuth);
  }

  componentWillUnmount() {
    this.unregisterAuthObserver && this.unregisterAuthObserver();
  }

  render() {
    return (
      <FirebaseAuthContext.Provider
        value={{ ...this.state, update: this.update, select: this.select,
          refreshco: this.refreshco, refreshAuth: this.refreshAuth,
          refreshstaff: this.refreshstaff, loginAs: this.loginAs, logout2: this.logout2}}>
        {this.props.children}
      </FirebaseAuthContext.Provider>
    );
  }

  loginAs = async (token, cid, {signup = false} = {}) => {
    if (!token || !cid) return false;
    return firebase.auth().signInWithCustomToken(token)
     .then(async (userCredential) => {
        await this.handleAuth(userCredential.user, true);
        window.localStorage.setItem("cid", cid);
        window.location.href = window.location.origin + (signup ? '?welcome' : '');
      });
  };

  logout2 = (goto) => {
    this.unregisterAuthObserver && this.unregisterAuthObserver();
    firebase.auth().signOut().then(res => {
      window.localStorage.removeItem("cid");
    }).finally(() => {
      window.location.href = goto || window.location.origin;
    });
  }
}

export default FirebaseAuthProvider;
