import React, { useMemo } from 'react';
import { Grid, Typography, Avatar, makeStyles } from '@material-ui/core';
import { getEmailFirstLetter } from 'fitbud/utils/helpers';
import { AvatarImage } from 'fitbud/views/users/header';
import peopleSvg from 'fitbud/images/group_appointment.svg';
import useTrainers from 'fitbud/hooks/useTrainers';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  groupAvatar: {
    backgroundColor: '#F0F3F5',
    borderRadius: '50%',
    '& .MuiAvatar-img': {
      height: 24,
      width: 24,
    },
  },
}));

const OPTIONS_CONSTANT = {
  GROUP_CLASS_DETAILS: 'GROUP_CLASS_DETAIL',
  SCHEDULE_VIDEO_CALL: 'SCHEDULE_VIDEO_CALL',
  CANCEL_VIDEO_CALL: 'CANCEL_VIDEO_CALL',
};

export const BookingDetails = (props) => {
  const { user, slot, duration, provider, title, trainer_id, startDate } = props.session;
  const { className } = props;
  const classes = useStyles();
  const { data: trainers } = useTrainers();
  const trainerDetail = useMemo(() => {
    if (trainer_id) return _.find(trainers, (data) => data._id === trainer_id) || {};
    return {};
  }, [trainer_id, trainers]);
  const isGroupClass = !!props.session.class;

  return (
    <React.Fragment>
      {!!isGroupClass && (
        <Grid container item zeroMinWidth wrap="nowrap" className={className} style={{ minWidth: 300 }}>
          <Grid item style={{ marginRight: 16 }}>
            <Avatar className={classes.groupAvatar} src={peopleSvg} />
          </Grid>
          <Grid item className="w-100">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <Typography variant="h6"  noWrap style={{ maxWidth: '200px' }}>
                  {title}
                </Typography>
                <Typography className="font_13_500 text-grey mb-6" noWrap>
                  {_.get(trainerDetail, 'data.name') || ''}
                </Typography>
                <React.Fragment>
                  <Typography color="textSecondary" className={`font_13_500 mt-10`} noWrap>
                    {duration} mins • {slot && slot[0]} - {slot && slot[1]}
                  </Typography>
                </React.Fragment>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      {!isGroupClass && (
        <Grid container item alignItems="center" zeroMinWidth wrap="nowrap" className={className}>
          <Grid item style={{ marginRight: 16 }}>
            <AvatarImage
              src={user && user.image}
              className="border"
              name={(user && user.name) || getEmailFirstLetter(user && user.email)}
              base64={true}
              alt={(user && user.name) || 'USER_IMAGE'}
              size="size40"
            />
          </Grid>
          <Grid item>
            <div className="d-flex align-items-center">
              <div>
                <Typography variant="h6"  noWrap style={{ maxWidth: '200px' }}>
                  {(user && user.name) || ''}
                </Typography>
                <Typography variant="caption" color="textSecondary" className={`font-weight-500 fmt-20`} noWrap>
                  {duration} mins session - {slot && slot[0]} to {slot && slot[1]}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
