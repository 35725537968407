import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CopyIcon } from 'fitbud/icons/copy2';

const AORGN = {vertical: 'top', horizontal: 'left'};
const MORGN = {vertical: 'top', horizontal: 'right'};

const CopyBtn = (props) => {
  const { hidden, isPerDay, hasWOs, hasMLs, weekId, week, day, axn } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const hasOneOnly = !hasWOs || !hasMLs;
  const defaultPurpose = isPerDay && hasOneOnly ? (hasMLs ? 'ml' : 'wo') : 'wo';
  const onIconClick = useCallback((e) => {
    if (isPerDay && !hasOneOnly && !anchorEl) setAnchorEl(e.currentTarget);
    else {
      setAnchorEl(null);
      axn(e);
    }
  }, [axn, isPerDay, hasOneOnly, anchorEl]);
  const close = () => setAnchorEl(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const [copyProps, what] = useMemo(() => {
    if (typeof day === 'number') return [{'data-week': weekId, 'data-day': day}, 'Copy from Day ' + (day + 1)];
    if (typeof week === 'number') return [{'data-week': weekId}, 'Copy from Week ' + (week + 1)];
    return [{}, false];
  }, [day, week, weekId]);
  if (hidden || !weekId || day < 0 || day > 7) return null;
  const nothing2copy = !what || (!hasWOs && !hasMLs);
  return (
    <>
      <Tooltip title={nothing2copy ? 'Nothing to copy ...' : what}>
        <div>
          <IconButton className={`my-n2 ${open ? 'bg-light-primary' : ''}`} onClick={onIconClick}
            {...copyProps} data-purpose={defaultPurpose} disabled={nothing2copy}>
            {open ? <CloseIcon fontSize='small' color='primary'/> : <CopyIcon fontSize='small'/>}
          </IconButton>
        </div>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={close} anchorOrigin={AORGN} transformOrigin={MORGN}>
        <MenuItem onClick={onIconClick} {...copyProps} data-purpose='wo'>Copy Workouts</MenuItem>
        <MenuItem onClick={onIconClick} {...copyProps} data-purpose='ml'>Copy Meal</MenuItem>
        <MenuItem onClick={onIconClick} {...copyProps} data-purpose='both'>Copy Both</MenuItem>
      </Menu>
    </>
  );
};

export default CopyBtn;
