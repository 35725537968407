import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LiveSessionsIcon = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 20 20"
        classes={{
          root: "small"
        }}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5807 12.9486C13.6481 14.4753 12.416 15.7663 10.8288 15.8312C10.7119 15.8361 5.01274 15.8246 5.01274 15.8246C3.43328 15.9445 2.05094 14.8096 1.92636 13.2886C1.91697 13.1753 1.91953 7.06016 1.91953 7.06016C1.84956 5.5318 3.08002 4.23749 4.66801 4.17015C4.78661 4.1644 10.4781 4.17507 10.4781 4.17507C12.0653 4.05681 13.4519 5.20001 13.5747 6.72837C13.5833 6.83842 13.5807 12.9486 13.5807 12.9486Z" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5834 8.31653L16.3275 6.0707C17.0075 5.51403 18.0275 5.99903 18.0267 6.87653L18.0167 13.0007C18.0159 13.8782 16.995 14.359 16.3167 13.8024L13.5834 11.5565" stroke="#8F969F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
);
