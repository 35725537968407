import React, { useState, useContext, useEffect,useMemo } from "react";
import { withSnackbar } from "notistack";
import {get,find,capitalize,sum} from "lodash";
import { Typography, Chip, Drawer, makeStyles, Grid } from "@material-ui/core";
import {Conversion} from "fitbud/providers/conversion";
import { EditorContext } from "fitbud/views/workouts/exerciseEditor";
import { OutlinedCircleIcon } from "./exerciseGroup";
import {Avatar} from "fitbud/components/avatar";
import useChipStyle from "fitbud/hooks/useChipStyle";
import { exTypeHelper, getTrueDuration, resizeEvents } from "fitbud/utils/helpers";
import { RestChip } from "./exerciseGroup";
import DividerDot from '@material-ui/icons/FiberManualRecord';
import ExerciseInfo from "fitbud/views/exercises/info";
import clsx from "clsx";
import {AmrapDurationInput, ValuePopup,ValuePopupNew} from "./components"
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import CircularLoader from "fitbud/components/CircularLoader";
import {DialogAppBar} from "fitbud/components/Dialog";
import {AltPopup} from "./components";
import {ExSecondLine,EditorExerciseOptions} from "./components";
import {ReadOnlyTrackingFields} from "./components";
import { getExIcon } from "fitbud/utils/catalog";
import {durationToColonString} from "fitbud/views/workouts/helperfn";
import {MAX_REP_VALUE} from'fitbud/utils/constants';
// import { UserContext } from "fitbud/views/users/detail";

const useExStyle = makeStyles({
  secondaryColor: {
    color: "#7f8b9b",
  },
  primarySubColor: {
    color: "#0e0e0e",
  },
  dividerOverride: {
    border: "1px solid white",
    position: "absolute",
    height: "calc(100% - 80px)",
    width: 1,
    top: 80,
    left: 14,
  },
  avatar: {
    fontSize: "16px!important",
    fontWeight: "600!important",
    border: "1px solid #D3D9DB",
  },
  exercisePrimary: {
    fontSize: "15px",
    fontWeight: "600",
    color: "#0D0D0D",
  },
  exerciseSecondary: {
    fontSize: "13px",
    marginTop: "5px",
    color: "#6F8099",
    display:"flex",
    flexWrap:'wrap'
  },
  restBwExercise:{
    marginLeft:34,
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginTop:20
  },
  chipStyle:{
    background:"#fff",
    // maxWidth:'75%',
    '& .MuiChip-label':{
      display:'flex',
      alignItems:'baseline',
      gap:"2px"
    },
    '& p':{
      width: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden"
    }
  }
});




const Exercise = props => {
  const classes = useChipStyle();
  const exClasses = useExStyle();
  const {cid}=useContext(FirebaseAuthContext);
  const { exercise ={}, exData ={}, rest_after, group, length, type, isProgress, woType, minified, disableExClick } = props;
  const {
    editorMode: isEditMode,
    handleExTypeValuesChange,
    handleValuesOnSetsChange,
    handleDelete, 
    loading,
    handleRestChange,
    handleGrpAttributes,
    sidesData,
    altExercises,
    handleExerciseAltChange,
  } = useContext(EditorContext);
  const {rest_after_circuit_enable = false, rest_after_circuit = []} = group || {} 
  const isLast = props.index === length - 1;
  const {
    ref_type:refType,
    type:exType,
    values=[],
    weight_type:wtType,
    weights=[],
    tempo
  }=exercise;
  const specifyWeights = !!weights && !!weights.length;
  const [openValuePopover, setValuePopState] = useState(null);
  const [isExDetailOpen,toggleExDetail]=useState(false);
  const [openAltPopup,toggleAltPopup]=useState(null);
  const side = useMemo(()=>{
    return exercise.side===null?exercise.side:(exercise.side || exData.side);
  },[exercise.side,exData.side]);
  useEffect(handleClose, [exercise]);
  const currentSideName=find(sidesData, (d) => d[0] === (exercise.side || exData.side));
  function handleClose() {
    setValuePopState(null);
  }
  function onExerciseValuesSave(e,exerciseChange) {
    handleExTypeValuesChange(e, exerciseChange, () => {
      handleClose();
    });
  }
  let clickableClass = "";
  if (openValuePopover) {
    clickableClass = classes.clickable;
  }
  const { rest } = exercise;
  if(!exData) {
    return null
  }
  const renderNonEditRestChip = () =>{
    if (woType === 'logs') return null;
    const isLast = props.index === length - 1;
    const isRest = !!rest[0];
    const isRestCircuit = rest_after_circuit_enable && !!rest_after_circuit && !!rest_after_circuit[0]
    if (!isLast && isRest) {
      return (
        <div className={exClasses.restBwExercise}>
          <OutlinedCircleIcon />
          <RestChip rest={rest} isEditMode={false} />
        </div>
      );
    }
    if (isLast) {
      if (minified) {
        if (isRest)
          return (
            <div className={exClasses.restBwExercise}>
              <OutlinedCircleIcon />
              <RestChip
                rest={rest}
                isEditMode={false}
                isRestAfterCircuitEnable={rest_after_circuit_enable}
                restType={"set"}
                postLabel={true}
                exLength={length}
                groupType={type}
              />
            </div>
          );
      } else {
        if (isRest || isRestCircuit) {
          return (
            <div className={clsx(exClasses.restBwExercise)}>
              <div className={clsx("h-100 d-flex flex-column",isRest && isRestCircuit?"justify-content-between py-3px":"justify-content-center")} style={{zIndex:5}}>
                <OutlinedCircleIcon/>
                {isRest && isRestCircuit && <OutlinedCircleIcon />}
              </div>
              <div className={clsx("d-flex flex-column")} style={{gap:"15px"}}>
                {(isRest || isRestCircuit) && (
                  <RestChip
                    rest={rest}
                    isEditMode={false}
                    isRestAfterCircuitEnable={rest_after_circuit_enable}
                    restType={"set"}
                    postLabel={true}
                    groupType={type}
                    exLength={length}
                  />
                )}
                {isRestCircuit && (
                  <RestChip
                    isEditMode={false}
                    rest={rest_after_circuit}
                    postLabel={true}
                    groupType={type}
                    exLength={length}
                    restType="circuit"
                    isRestAfterCircuitEnable={rest_after_circuit_enable}
                  />
                )}
              </div>
            </div>
          );
        }
      }
    }
  }
 
  function openExDetail(){
    toggleExDetail(true);
  }
  function closeExDetail(){
    toggleExDetail(false);
  }
  function openManageAlts(){
    if(!(exData.alts||[]).length) return;
    toggleAltPopup(true);
  }
  const icon=getExIcon({type:exData.mediaType});
  // const breakLine=exercise.type!=="range" && exercise.values.length>4;
  return (
    <Grid
      container
      className={clsx(
        "fmt-25",
        !sum(...rest)
          ? rest_after_circuit
            ? !sum(rest_after_circuit) && "fmb-0"
            : "fmb-0"
          : rest_after === "exercise" && isLast
          ? "fmb-0"
          : "fmb-20"
      )}
      justifyContent="space-between"
    >
      {/*----- Left Side -------*/}
      <Grid
        item
        container
        xs={isEditMode ? 7 : 12}
        alignItems={isEditMode ? "center" : "start"}
        onClick={!disableExClick ? openExDetail : null}
        className="cursor-pointer"
      >
        {/*--- avatar container ---*/}
        <div className="position-relative" style={{height:"fit-content"}}>
          <Avatar
            src={get(exData, "thumbnail")}
            name={exData && exData.name}
            key={`testing__${exData.id}`}
            size="large"
            alt={(exData && exData.name) || " "}
            className={exClasses.avatar}
          />
          {icon && (
            <img
              style={{ bottom: "0px", left: "50px", height: "18px" }}
              src={icon}
              alt="Indicator"
              className="position-absolute"
            />
          )}
        </div>
        {/*--- avatar container -end  ---*/}
        <div
          className={clsx(
            "fml-20 flex-1",
            isEditMode && "d-flex flex-column justify-content-center"
          )}
        >
          <Typography className={exClasses.exercisePrimary}>
            {exData && exData.name}
          </Typography>
          <ExSecondLine 
            exercise={exercise}
            exData={exData}
            currentSideName={currentSideName}
            showAlts={true}
            toggleAltPopup={openManageAlts}
            showAllAlts={!!isEditMode}
            className={clsx(
              exClasses.exerciseSecondary,
              !isEditMode && "fmb-15"
            )}
            />
          {!isEditMode && (
            <ReadOnlyTrackingFields
              isProgress={isProgress}
              woType={woType}
              exClasses={exClasses} 
              exercise={exercise}
              exData={exData}
              currentSideName={currentSideName}
            />
          )}
        </div>
      </Grid>
      {/*----- Left Side End -------*/}
      {/*----- Right Side -------*/}
      {isEditMode && (
        <Grid
          item
          container
          xs={5}
          alignItems="center"
          justifyContent="flex-end"
          wrap="nowrap"
        >
          <DisplayValues
            exercise={exercise}
            exData={exData}
            clickableClass={clickableClass}
            onClick={(e) => {
              setValuePopState(e.currentTarget);
            }}
          />
          <EditorExerciseOptions
            className="fml-15"
            title="Exercise"
            onDelete={()=>{
              handleDelete({
                grpIndex: parseInt(props["data-group"]),
                exrcsIndex: props.index,
                type: "exercise",
              })
            }}
            openAlternates={openManageAlts}
            isAlternatesAvailable={!!(exData.alts||[]).length}
          />
        </Grid>
      )}
      {/*----- Right Side End -------*/}
      <RightSide {...props} renderNonEditRestChip={renderNonEditRestChip}/>
      {openValuePopover && (
        <ValuePopupNew
          anchor={openValuePopover}
          handleClose={handleClose}
          onSave={onExerciseValuesSave}
          loading={loading}
          props={props}
          exercise={exercise}
          exData={exData}
        />
      )}
      {openAltPopup && <AltPopup
        onClose={()=>toggleAltPopup(false)}
        onSave={handleExerciseAltChange}
        handlePrimValueSave={onExerciseValuesSave}
        isEditMode={isEditMode}
        altExercises={altExercises}
        exercise={exercise}
        exData={exData}
        varProps={props}
        //--
        currentSideName={currentSideName}
        currentSide={side}
        sidesData={sidesData}
        exClasses={exClasses}
      />}
      {isExDetailOpen && (
        <ExerciseDetailPopup onClose={closeExDetail} {...exData}/>
      )}
    </Grid>
  );
};

export default withSnackbar(Exercise);

export const ShowDurationValue=({value,index=0,valuesLength=0,type,parse=true,convertWRTSystemUnit=""})=>{
  const {convertor}=useContext(Conversion);
  if(!!convertWRTSystemUnit){
    value=convertor.getDisplayValue(convertWRTSystemUnit,value);
  }
  const str=checkMax(parse?durationToColonString(value):value);
  const isRange=type==="range";
  return(
    <span>
      {str}
      {index<(valuesLength-1) && <span className={isRange?"mx-6":"mr-6"}>{isRange?"-":","}</span>}
    </span>
  )
}
//exData required as exercise.ref_type backup
export const DisplayValues=({exercise,exData,alternatePopup=false,...rest})=>{
   const classes=useExStyle();
   const {convertor} = useContext(Conversion);
   const truncateValue=(arr)=>{
     if(!arr) arr=[];
     return arr.join(", ");
   }
   const showValue=(weight=false)=>{
     if(!!weight){
       const weightsData = (exercise.weights||[]).map(v=>convertor.getDisplayValue("equipment-weight", v))
       if(!weightsData.length) return null;

       if(exercise.weight_type==="range") return weightsData[0] + " - " + weightsData[1];
       return truncateValue(weightsData);
     }
    if(exercise.type==="range") return exercise.values[0] + " - " + exercise.values[1];
    return truncateValue(exercise.values);
  }
  let ex_type = get(exercise, "ref_type") || get(exData, "type");
  if(!ex_type) return null;
  if(!!exercise.track){
    return <NewPreview exercise={exercise} alternatePopup={alternatePopup} exData={exData} {...rest}/>
  }
  if(ex_type==="reps"){
    return (
      <ChipComp {...rest} clickableClass={classes.chipStyle}>
        <Typography className="font_16_600">
          {showValue()} 
        </Typography>
        <span className="font_13_500 text-grey">reps</span>
      </ChipComp>
    );
  }
  else if(ex_type==="duration"){
    const values=(exercise.values||[])
    const type=exercise.type;
    return (
      <ChipComp {...rest} clickableClass={classes.chipStyle}>
        <Typography className="font_16_600">
          {values.map((i,index)=>(
            <ShowDurationValue type={type} value={i} index={index} valuesLength={values.length}/>
          ))}
        </Typography>
      </ChipComp>
    );
  }
  else{
    //reps+weight
    return (
      <div className="d-flex flex-wrap justify-content-end overflow-hidden" style={{gap:alternatePopup?10:8}}>
        {showValue(true) && (
          <ChipComp {...rest} clickableClass={classes.chipStyle}>
            <Typography className="font_16_600">
              {showValue(true)}{" "}
            </Typography>
            <span className="font_13_500 text-grey">
                {convertor.getDisplayUnit("equipment-weight")}
              </span>
          </ChipComp>
        )}
        <ChipComp {...rest} clickableClass={classes.chipStyle}>
          <Typography className="font_16_600">
            {showValue()}{" "}
          </Typography>
          <span className="font_13_500 text-grey">reps</span>
        </ChipComp>
      </div>
    );
  }
}
export const ChipComp=({onClick,children,clickableClass})=>{
    const classes = useChipStyle();
    return(
      <Chip className={clickableClass} onClick={onClick} variant="outlined" label={children} classes={{
                    root: classes.root,
                    label:clsx('px-10 py-8')
                  }}/>
    )
  }
export const ExerciseDetailPopup=({onClose,id, hideSection})=>{
  return(
    <Drawer anchor="right" open={true} onClose={onClose}>
      <DialogAppBar  title="Exercise" onClose={onClose} titleFont="h3"/>
      <ExerciseInfo id={id} isPreview={true}/>
    </Drawer>
  )
}
const getTypeValues=(type,values=[],convertor,unit)=>{
  if(!values) values=[];
  if(type==="fixed") {
    return checkMax(values[0],convertor,unit);
  }
  else if(type==="range"){
    return convertor.getDisplayValue(unit,values[0]) + " - " + checkMax(values[1],convertor,unit);
  }
  else{
    return values.map(i=>checkMax(i,convertor,unit)).join(", ");
  }
}

export const checkMax=(n,convertor,unit)=>{
  if(convertor){
    return n===MAX_REP_VALUE?"Max":convertor.getDisplayValue(unit,n)
  }
  return n===MAX_REP_VALUE?"Max":n
};
const NewPreview=({exercise,alternatePopup,exData,...rest})=>{
  const classes=useExStyle();
  const {convertor} = useContext(Conversion);
  const {track,type,type2,male1,male2}=exercise;
  const [prim,sec]=track;
  const isDurationBasedEx = prim==="duration";
  const [primaryValue,primaryUnit]=useMemo(()=>{
    return [getTypeValues(type,male1,convertor,prim),convertor.getDisplayUnit(prim)];
  },[type,male1,prim,convertor]);
  const [secondaryValue,secondaryUnit]=useMemo(()=>{
    if(!sec) return [];
    return [getTypeValues(type2,male2,convertor,sec),convertor.getDisplayUnit(sec)];
  },[type2,male2,sec,convertor])
  return (
    <div
      className="d-flex flex-wrap justify-content-end overflow-hidden"
      style={{ gap: alternatePopup ? 10 : 8 }}
    >
      {male2 && (
        <ChipComp {...rest} clickableClass={classes.chipStyle}>
          <Typography className="font_16_600">
          {sec==="pace"?exercise.male2.map((i,index)=>(
            <ShowDurationValue type={exercise.type2} value={i} index={index} valuesLength={exercise.male2.length}
            convertWRTSystemUnit="pace"
            />
          )):
          secondaryValue
          }
          </Typography>
          <span className="font_13_500 text-grey">
            {secondaryUnit}
          </span>
        </ChipComp>
      )}
      <ChipComp {...rest} clickableClass={classes.chipStyle}>
        <Typography className="font_16_600">
          {isDurationBasedEx?exercise.male1.map((i,index)=>(
            <ShowDurationValue type={exercise.type} value={i} index={index} valuesLength={exercise.male1.length}/>
          )):
          primaryValue
          }
        </Typography>
        {!isDurationBasedEx && <span className="font_13_500 text-grey">
          {primaryUnit}
        </span>}
      </ChipComp>
    </div>
  );
}

const RightSide=({renderNonEditRestChip,...props})=>{
  const {rest_after,group,exercise,woType,index,length} =props;
  const {convertor} = useContext(Conversion);
  const exClasses = useExStyle();
  const {editorMode: isEditMode,handleRestChange}=useContext(EditorContext);
  const {rest}=exercise;
  const {rest_after_circuit_enable = false, rest_after_circuit = []} = group || {} 
  return(
    <>
    {woType === "logs" &&
        exercise.day_w &&
        exercise.day_w.map((key, i) => {
          const dayObject = exercise.day_w[i] || {};
          const refType = exercise.ref_type;
          if("value1" in dayObject){
            const {ref_type,value1,value2}=dayObject;
            const [primTrk,secTrk]=ref_type.split("_w_");
            return <div className="d-flex align-items-center fpt-10" style={{marginLeft:"100px"}}>
              <Typography
                className={clsx("font_13_500", exClasses.secondaryColor)}
              >
                set&nbsp;{i + 1}&nbsp;
              </Typography>
              <Typography className="fml-15 font_16_600">
                {primTrk==="duration"? 
                  !dayObject.value1?"--":durationToColonString(dayObject.value1)
                : <>
                  {convertor.getDisplayValue(primTrk,value1) || "--"}
                  <span className="font_13_500 ml-3px">{convertor.getDisplayUnit(primTrk)}</span>
                </>
                }
              </Typography>
              {!!secTrk && <>
              <>
                &nbsp; <DividerDot className="fmx-10" style={{ fontSize: "5px" }} />
                &nbsp;
              </>
              <Typography className="font_16_600">
                {secTrk==="pace"?
                  !dayObject.value2?"--":durationToColonString(convertor.getDisplayValue("pace",dayObject.value2))
                  :convertor.getDisplayValue(secTrk,value2) || "--"}
                <span className="font_13_500 ml-3px">{convertor.getDisplayUnit(secTrk)}</span>
              </Typography>
              </> }
            </div>
          }
          return (
            <div className="d-flex align-items-center fpt-10" style={{marginLeft:"100px"}}>
              <Typography
                className={clsx("font_13_500", exClasses.secondaryColor)}
              >
                set&nbsp;{i + 1}&nbsp;
              </Typography>
              {refType === 'duration' ? <Typography className="fml-15 font_16_600">
                {durationToColonString(dayObject.duration)||"--"}
              </Typography>
              :<Typography className="fml-15 font_16_600">
                {dayObject.value||"--"}
                <span className="font_13_500">&nbsp;reps</span>
              </Typography>}
              {refType === "reps_w_weight" && (
                <>
                  <div
                    className="rounded-circle fmx-15"
                    style={{
                      backgroundColor: "#000000",
                      width: "4px",
                      height: "4px",
                    }}
                  ></div>
                  <Typography variant="h5">
                    {convertor.getDisplayValue(
                      "user-logged-weight",
                      dayObject.weight
                    ) || "--"}{" "}
                    <span className="font_13_500">
                      {convertor.getDisplayUnit("equipment-weight")}
                    </span>
                  </Typography>
                </>
              )}
            </div>
          );
        })}
      {rest_after === "exercise" && (
        <>
          {isEditMode ? (
            <div className={exClasses.restBwExercise}>
              <div className="d-flex justify-content-start align-items-center">
                <OutlinedCircleIcon />
                <RestChip
                  rest={rest}
                  handleRestChange={handleRestChange}
                  data-group={props["data-group"]}
                  index={index}
                  isRestAfterCircuitEnable={rest_after_circuit_enable}
                  restType="set"
                  postLabel={index === length - 1}
                />
              </div>
            </div>
          ) : (
            renderNonEditRestChip()
          )}
        </>
      )}
      </>
  )
}
