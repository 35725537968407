import React from "react";
import { CircularProgress,makeStyles } from "@material-ui/core";
import clsx from 'clsx';


const styles=makeStyles({
  centerAlign:{
    position:"absolute",
    top:size=>`calc(50% - ${size/2}px)`,
    left:size=>`calc(50% - ${size/2}px)`,
    
    //--transform:translate(x,y)// <--dont use this
  },
  default:{
    top:"50%",
    left:"50%",
    position:"relative"
  }
})

//centerAlign requires parent-container's (position:relative)
export default props => {
  let {className = "",centerAlign=false,size=40,...rest} = props;
  const classes=styles(size);
  return (
    <CircularProgress
      size={size}
      className={clsx(centerAlign?classes.centerAlign:classes.default,className)}
      {...rest}
    />
  );
};
