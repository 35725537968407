import React from "react";
import { withSnackbar } from "notistack";
import LiveVideo from "./liveVideo";
import VideoProvider from "./videoProvider";
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";

const LiveSession = (props) => {
  const { openVideoScreen: book_id } = useLiveSessions();
  if (!book_id) return null;
  return (
    <VideoProvider>
      <LiveVideo />
    </VideoProvider>
  );
};

export default withSnackbar(LiveSession);
