import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { ClrdButton } from 'fitbud/components/form-fields';
import { useLocation } from 'react-router-dom';
import { useAcl, ACL_STATUS } from 'fitbud/providers/acl-provider';
import { useStyles } from './styles';
import { getDaysText } from 'fitbud/utils/helpers';
import { useBillingContext } from 'fitbud/providers/billing-provider';
import { ContractContext } from 'fitbud/providers/contract';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { bffGetContractIssues } from 'fitbud/api';
import { openIntercom } from 'fitbud/utils/intercomm';

const PAYMENT_OVERDUE_CONSTANT = 4;

const getStatusChipTxt = (status, overdueBy) => {
  if (status === ACL_STATUS.TRIAL) return 'In Trial';
  if (status === ACL_STATUS.TRIAL_GRACE) return 'Trial Overdue';
  if (status === ACL_STATUS.TRIAL_LIMITED) return 'Expired';
  if (status === ACL_STATUS.PLAN_GRACE) {
    if(overdueBy < PAYMENT_OVERDUE_CONSTANT) return "Payment Overdue";
    else return "Payment Failed! ⛔️"
  };
  if (status === ACL_STATUS.PLAN_LIMITED) return 'Expired';
  if (status === ACL_STATUS.CANCELLED) return 'Cancelled';
  return '';
};

export const StatusChip = () => {
  const classes = useStyles();
  const { status, checkLimitedAccess } = useAcl();
  const {contract} = useContext(ContractContext)
  const overdueBy = _.get(contract, 'overdue_by', 0);
  const isLimitedAccess = useMemo(() => checkLimitedAccess(), [checkLimitedAccess]);
  const text = getStatusChipTxt(status, overdueBy);
  const getClass = () => {
    if (status === ACL_STATUS.TRIAL || status === ACL_STATUS.PLAN_GRACE) {
      return classes.warningChipContainer;
    }
    if (isLimitedAccess) {
      return classes.dangerChipContainer;
    }
    return classes.warningChipContainer;
  };

  return (
    <div className={clsx(getClass(), classes.statusChipContainer)}>
      <Typography className="font_13_700">{text}</Typography>
    </div>
  );
};

const DEFAULT_TRIAL_MESSAGES = 'Explore our full suite of features during your trial. Upgrade anytime you’re ready!';

const TRIAL_MESSAGE = {
  EXPLORE: 'Test out On-Demand Content during your trial and see how it fits your needs.',
  BROADCAST: 'Trial access includes Broadcast capabilities—give it a try and reach more clients.',
  GROUP_CLASS: 'Access to group classes is included in your trial. Check out a session today!',
  GROUP_CHAT: 'Explore Group Chat during your trial. It’s a great way to connect with clients.',
  CHAT: DEFAULT_TRIAL_MESSAGES,
  IN_APP_NOTIFICATION: DEFAULT_TRIAL_MESSAGES,
  CHECK_IN: DEFAULT_TRIAL_MESSAGES,
  LIVE: 'Video Calls are active during your trial. Connect with your clients live!',
  WEBSITE: DEFAULT_TRIAL_MESSAGES,
  PLAN: 'Plan automation is available now. Start streamlining your schedules during the trial.',
  CATALOG: 'Unlimited content uploads are yours to explore. Upload and organize your materials.',
  APP_THEME: 'Customize your app’s theme during the trial. Tailor the look and feel to your brand.',
  CONFIG: DEFAULT_TRIAL_MESSAGES,
  DEFAULT: DEFAULT_TRIAL_MESSAGES,
};

const getTrailMessage = (pathname, status) => {
  if (pathname.includes('explore')) return TRIAL_MESSAGE.EXPLORE;
  else if (pathname.includes('group-chats')) return TRIAL_MESSAGE.GROUP_CHAT;
  else if (pathname.includes('group-classes')) return TRIAL_MESSAGE.GROUP_CLASS;
  else if (pathname.includes('configuration')) return TRIAL_MESSAGE.DEFAULT;
  else if (pathname.includes('broadcasts')) return TRIAL_MESSAGE.BROADCAST;
  else if (pathname.includes('chats')) return TRIAL_MESSAGE.CHAT;
  else if (pathname.includes('in-app-notifications')) return TRIAL_MESSAGE.IN_APP_NOTIFICATION;
  else if (pathname.includes('checkins')) return TRIAL_MESSAGE.CHECK_IN;
  else if (pathname.includes('live')) return TRIAL_MESSAGE.LIVE;
  else if (pathname.includes('plans')) return TRIAL_MESSAGE.PLAN;
  else if (pathname.includes('config')) return TRIAL_MESSAGE.CONFIG;
  else if (pathname.includes('website')) return TRIAL_MESSAGE.WEBSITE;
  else if (pathname.includes('theme')) return TRIAL_MESSAGE.APP_THEME;
  else if (pathname.includes('fitness') || pathname.includes('nutrition')) return TRIAL_MESSAGE.CATALOG;
  return TRIAL_MESSAGE.DEFAULT;
};

export const StatusText = (props) => {
  const { className, style } = props;
  const { status, contract } = useAcl();
  const overdueBy = _.get(contract, 'overdue_by', 0);
  const location = useLocation();
  const { pathname } = location || {};

  const getTxt = () => {
    if (status === ACL_STATUS.TRIAL) {
      return getTrailMessage(pathname);
    }
    // if (status === ACL_STATUS.TRIAL_GRACE)
    //   return 'Your free trial period is over. Upgrade now to keep enjoying FitBudd premium features.';
    if (status === ACL_STATUS.TRIAL_LIMITED) return 'Your free trial period is over. Upgrade now to continue. ';
    if (status === ACL_STATUS.PLAN_GRACE) {
      if (overdueBy < PAYMENT_OVERDUE_CONSTANT)
        return 'Uh-oh! Your recent payment didn’t go through.';
      return `Warning: Your payment is overdue, and your account's on the brink of deactivation. Let's clear it up before you lose access!`;
    }
    if (status === ACL_STATUS.PLAN_LIMITED) return 'Switch to our premium plans and take your brand to next level.';
    if (status === ACL_STATUS.CANCELLED) return 'Upgrade now to grow your business with FitBudd';

    return '';
  };
  return <Typography className={clsx(className, 'text-0d0d0d')} style={style} >{getTxt()}</Typography>;
};

export const StatusInfo = (props) => {
  const { className } = props;
  const { status, contract, trial_left } = useAcl();
  const overdueBy = _.get(contract, 'overdue_by', 0);

  const getText = () => {
    if (status === ACL_STATUS.TRIAL)
      return (
        <span className="font_15_500">
          Trial Ends in <span className="font_15_700 text-capitalize">{getDaysText(trial_left)}</span>
        </span>
      );
    if ((status === ACL_STATUS.PLAN_GRACE || status === ACL_STATUS.PLAN_LIMITED) && overdueBy) {
      return (
        <span>
          <span className="font_15_500">Overdue by </span>
          <span className="font_15_700 text-capitalize">{getDaysText(overdueBy)}</span>
        </span>
      );
    }
    return null;
  };

  return <Typography className={clsx(className, 'font_15_500 text-0d0d0d ')}>{getText()}</Typography>;
};

const DISABLE_ACTION_BUTTON = ['/config/billing']; //Incase of these route no need to show action button in header

export const AclAction = (props) => {
  const { classes = {}, text, btnStyle = {} } = props;
  const defaultClasses = useStyles();
  const { status, manageSubscriptionUrl } = useAcl();
  const { handleUpgrade } = useBillingContext();
  const { pathname } = useLocation();

  

  const isDisableActionPage = useMemo(() => {
    if (DISABLE_ACTION_BUTTON.includes(pathname)) return true;
    return false;
  }, [pathname]);

  const getActionTxt = () => {
    if (text) return text;
    if ([ACL_STATUS.PLAN_GRACE, ACL_STATUS.PLAN_LIMITED].includes(status)) return 'Manage Subscription';
    return 'Upgrade Now';
  };
  const _btnProps = useMemo(() => {
    if (status === ACL_STATUS.PLAN_GRACE || status === ACL_STATUS.PLAN_LIMITED) {
      if (manageSubscriptionUrl) {
        return {
          component: 'a',
          href: manageSubscriptionUrl,
        };
      } else {
        return {
          onClick: openIntercom,
        };
      }
    } else
      return {
        onClick: () => handleUpgrade(),
      };
  }, [manageSubscriptionUrl, handleUpgrade, status, ACL_STATUS, openIntercom]);

  if (isDisableActionPage) return null;

  return (
    <div className={clsx(classes.container, defaultClasses.actionButton)}>
      <ClrdButton
        style={btnStyle}
        variant="contained"
        className={clsx('f-medium text-center', classes.btn)}
        color="primary"
        {..._btnProps}>
        <span className="font_15_700">{getActionTxt()}</span>
      </ClrdButton>
    </div>
  );
};
