import React from 'react';
import { IconButton, makeStyles } from "@material-ui/core";
import { CrossIcon } from "fitbud/icons/cross";
import { PlayIcon } from 'fitbud/icons/play';
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    top: 0,
    right: "10px",
    zIndex: 1,
  },
}));

const VideoPlayer = props => {
  const {
    url,
    thumbnailUrl,
    aspectRatio,
    onCross,
    playerProps = {},//includes className
    muted = false,
    pause,
    playIconStyle={}
  } = props;
  //--styling--
  const classes = useStyles({ aspectRatio: aspectRatio || 0 });
  //---state--
  //--functions--
  const autoLoad = !!playerProps.onDuration || !!pause;
  const light = !pause && (autoLoad) ? false : (thumbnailUrl || true);
  const _playIconStyle={width:"unset",height:"unset",...playIconStyle};
  if (!url) return null;
  return (
    <>
      {!!onCross && (
        <div className={`position-absolute ${classes.closeBtn}`}>
          <IconButton aria-label="close" onClick={onCross}>
            <CrossIcon />
          </IconButton>
        </div>
      )}
      <ReactPlayer
        playIcon={<PlayIcon style={{..._playIconStyle}} />}
        height={"100%"}
        width={"100%"}
        light={light}
        controls={true}
        url={url}
        playing={!autoLoad}
        muted={muted}
        {...playerProps}
      />
    </>
  );
};


export default VideoPlayer;
