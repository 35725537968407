import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { useAcl } from 'fitbud/providers/acl-provider';
import AclBlocker from 'fitbud/components/acl/acl-blocker';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

// highest priority key, if key is set or unset
// if key is not defined then check plans for which feature is allowed
// pages and components will define which pages and components are covered as part of this feature.
// owner will define if this feature is only available to owner or not
// sudo will define if feature is available only to sudo

const ProtectedRoute = (props) => {
  const { component: Component, type, children, fkey, render, ...restOfProps } = props;
  const { status, checkLimitedAccess, checkAccessFeature } = useAcl();
  const routeParams = _.get(restOfProps, 'computedMatch.params', {}); //special handling for explore
  const { type: routeType } = routeParams;
  const { isBlockedAccess, isAccessFeature } = useMemo(() => {
    const _key = fkey === 'explore' ? (routeType === 'resources' ? 'resources' : fkey) : fkey;
    return { isBlockedAccess: checkLimitedAccess(status), isAccessFeature: checkAccessFeature(status, _key) };
  }, [status, checkAccessFeature, checkLimitedAccess, fkey, routeType]);
  const _fkey = useMemo(() => {
    const _key = fkey === 'explore' ? (routeType === 'resources' ? 'resources' : fkey) : fkey;
    return _key;
  }, [fkey, routeType]);

  if (isBlockedAccess) return null; // if routes is blocked access then nothing show, for security purpose...

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (!isAccessFeature) return <AclBlocker fkey={_fkey} />;
        if (!!Component) return <Component {...props} />;
        if (!!children) return React.cloneElement(children, { ...props });
        if (render) return render(props);
      }}
    />
  );

  // if (!!Component) return <Route type={type} component={isLimitedAccess ? AclBlocker : Component} {...restOfProps} type={type} />;
  // else return <Route type={type} {...restOfProps}>{isLimitedAccess ? <AclBlocker fkey={fkey} /> : children}</Route>; //incase of setting: app config route
};

export default ProtectedRoute;
