import React, { useMemo } from 'react';
import {
  Typography, Tooltip, IconButton,
  Menu, MenuItem,
} from '@material-ui/core';
import LayersIcon from '@material-ui/icons/LayersOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from 'fitbud/icons/detailEdit';
import { cardClrCSS } from 'fitbud/views/users/subComponents';
import { formatNo } from 'fitbud/utils/helpers';
import { useAnchor } from 'fitbud/hooks/form';
import BMRCard from '../bmr';
import {durationToHMString} from "fitbud/views/workouts/helperfn";

const MLSLCard = ({ id, data, className, onClick, future, weekDayMeta, rhs=false, on=false, off=false, sl=false }) => {
  const style = useMemo(() => {
    if (!rhs) return CSSRAND(id, true);
    return {...CSSS[sl ? 'sl' : 'ml'][off ? 'off' : 'on']};
  }, [id, sl, off, rhs]);
  const [l1, l2] = useMemo(() => {
    if (!data) return ['', ''];
    let l2 = '';
    const {ref_name, macros, groups} = data;
    if (sl) {
      if (groups && groups.length)
        l2 = groups.map(x => {
          if (x.supplements && x.supplements.length) return x.supplements.length;
          return 0;
        }).reduce((p, c) => (p + c), 0);
    } else {
      if (macros && macros.calories)
        l2 = formatNo(Math.round(macros.calories), {}, '');
    }
    return [ref_name || '', l2];
  }, [data, sl]);
  const _click = () => {
    if (!onClick) return;
    onClick({ data, id, weekDayMeta, editable: future, rhs, on, off });
  };
  return (
    <div className={`d-flex flex-column justify-content-between pl-10 py-8 pr-30 cursor-pointer ${className || ''}`} style={style} onClick={_click} >
      <Tooltip title={l1}>
        <Typography className='font_14_600 overflow-hidden twoLineEllipsis'>{l1}</Typography>
      </Tooltip>
      <div className='d-flex flex-row align-items-center text-dark-grey'>
        {l2 && <Typography className='font_13_600 text-0d0d0d' color='inherit'>{l2}</Typography>}
        {l2 && <Typography className='font_13_500 text' color='inherit'>&nbsp;{sl ? 'items' : 'cals'}</Typography>}
        {l2 && <div className='flex-grow-1'/>}
        {(on || off) && <Typography className='font_13_500' color='inherit'>{off ? 'OFF' : 'ON'} Day</Typography>}
      </div>
    </div>
  );
};

const AddWoCard = ({ week, weekId, day, axn, className, future = false }) => {
  if (!future) return <div style={CSSS.fake} />;
  return (
    <div className={`d-flex flex-column justify-content-center align-items-center cursor-pointer mr-15 ${className || ''}`}
      data-week={week} data-weekid={weekId} data-day={day} data-purpose='wo' onClick={axn} style={CSSS.addwo}>
      <Typography className='font_13_600 text-0d0d0d lineht_1_5'>No Workouts Added</Typography>
      <Typography className='font_13_600 text-0d0d0d'>Click to Add</Typography>
    </div>
  );
};

const WOCard = ({ id, index, data, alts, className, onClick, future, weekDayMeta, addAlt }) => {
  const style = useMemo(() => CSSRAND(id), [id]);
  const [l1, l2] = useMemo(() => {
    if (!data) return ['', '-'];
    const {ref_name, duration} = data;
    return [ref_name || '',duration];
  }, [data]);
  const handleClick = () => {
    if (alts > 0) {
      addAlt({
        currentTarget: {
          dataset: {
            week: weekDayMeta.week.toString(),
            weekid: weekDayMeta.weekId,
            day: weekDayMeta.day,
            purpose: 'altWo',
            id,
            index
          },
        }
      });
      onClick({ weekDayMeta, editable: future })
    } else {
      const details = { data, id }
      if (weekDayMeta) details.weekDayMeta = weekDayMeta;
      if (future) details.editable = future;
      onClick(details)
    }
  }
  return (
    <div className={`d-flex flex-column justify-content-between px-10 py-8 cursor-pointer ${className || ''}`} style={style} onClick={handleClick}>
      <Tooltip title={l1}>
        <Typography className='font_14_600 overflow-hidden text-0d0d0d mr-20 twoLineEllipsis'>{l1}</Typography>
      </Tooltip>
      <div className='d-flex flex-row align-items-center text-dark-grey'>
        <Typography className='font_13_600' color='inherit'>{durationToHMString(l2)}</Typography>
        {/* <Typography className='font_13_500' color='inherit'>&nbsp;min{l2 > 1 ? 's' : ''}</Typography> */}
        <div className='flex-grow-1'/>
        {alts > 0 && <Typography className='font_13_500' color='inherit'>
          +{alts}&nbsp;Alternative{alts > 1 ? 's' : ''}&nbsp;<LayersIcon fontSize='inherit'/>
        </Typography>}
      </div>
    </div>
  );
};

const ItemCard = ({ id, index, weekId, week, day, dayCopy, objCache, controller, type, ftAltWOs=false, disabled=false, on=false, off=false, future = false, rhs = false, className }) => {
  const [menu, openMenu, closeMenu] = useAnchor();
  const [data, alts] = useMemo(() => {
    if (!id) return [null, false];
    if (id === 'rest') return ['rest', false];
    const parts = id.split('/');
    const x = parts[0];
    if (future) return [((objCache && objCache[x]) || (dayCopy && dayCopy[x])), parts.length - 1];
    return [dayCopy && dayCopy[x], parts.length - 1];
  }, [id, future, dayCopy, objCache]);
  if (!id || id === 'none') return null;
  // if (!data) return null;

  if (id === 'rest') {
    return (
      <div className={`d-flex justify-content-center align-items-center mr-15 ${className || ''}`} style={CSSS.rest}>
        <Typography className='font_13_600 text-0d0d0d'>Rest</Typography>
      </div>
    );
  }

  const removeRHS = () => controller.changeMasters(type, week, {remove: (on ? 'on' : (off ? 'off' : 'single'))});
  const removeMain = () => {
    closeMenu();
    controller.removeFromDay(weekId, week, day, id, type);
  };
  const copyItem = () => {
    closeMenu();
    controller.copySingleItem(weekId, day, type, id);
  };
  const addAlt = (e) => {
    closeMenu();
    controller.importClick(e);
  };

  if (id === 'bmr') {
    if (!rhs) return null;
    if (future) return <BMRCard future={future} data={data} className={className}/>;
    if (!data) return null;
    return <MLSLCard id='bmr' rhs={true} data={data} className={className}/>;
  }

  if (!data) return null;
  const modified = (data.edited || id.startsWith('modified_')) && data.archive;
  return (
    <div className={`position-relative ${rhs ? '' : 'mr-15'}`}>
      {!disabled && rhs && future && <IconButton onClick={removeRHS} size='small' className='position-absolute inset-top-right'><CloseIcon/></IconButton>}
      {!disabled && !rhs && <IconButton size='small' className='position-absolute inset-top-right' onClick={openMenu}><MoreVertIcon/></IconButton>}
      {menu && <Menu open anchorEl={menu} onClose={closeMenu} MenuListProps={{dense: true, disablePadding: true}}>
        <MenuItem onClick={copyItem}>Copy</MenuItem>
        {future && <MenuItem onClick={removeMain}>Delete</MenuItem>}
        {ftAltWOs && future && type !== 'ml' && <MenuItem data-purpose='altWo' data-weekid={weekId} data-week={week} data-day={day}
        data-index={index} data-id={id} onClick={addAlt}>{alts ? 'Manage' : 'Add'} Alternatives</MenuItem>}
      </Menu>}
      {data && !alts && modified && <Tooltip title='Modified for this customer'><div
        className='my-1 mx-2 position-absolute inset-bottom-right'><EditIcon color='#6F8099' fontSize='inherit'/></div></Tooltip>}
      {(type === 'ml' || type === 'sl') ?
        <MLSLCard id={id} rhs={rhs} data={data} on={on} off={off} className={className} sl={type === 'sl'} future={future} onClick={controller.showDetail} weekDayMeta={{ weekId, week, day }}/> :
        <WOCard id={id} index={index} data={data} className={className} alts={ftAltWOs && alts} onClick={controller.showDetail} future={future} weekDayMeta={{ weekId, week, day }} addAlt={addAlt}/>}
    </div>
  );
};

const CSSS = {
  ml: {
    on: { height: 80, backgroundColor: '#E6F7E7', border: '1px solid #D3D9DB', borderLeft: '4px solid #05B715', borderRadius: 3 },
    off: { height: 80, backgroundColor: '#F6F8EB', border: '1px solid #D3D9DB', borderLeft: '4px solid #A7B63A', borderRadius: 3 },
  },
  sl: {
    on: { height: 80, backgroundColor: '#FEF2EE', border: '1px solid #D3D9DB', borderLeft: '4px solid #FF8360', borderRadius: 3 },
    off: { height: 80, backgroundColor: '#FEF6EC', border: '1px solid #D3D9DB', borderLeft: '4px solid #FFB148', borderRadius: 3 },
  },
  rest: { height: 80, width: 210, backgroundColor: '#F0F3F5', border: '1px solid #F0F3F5', borderRadius: 3 },
  addwo: { height: 80, width: 210, backgroundColor: '#FEFAEB', border: '1px solid #FFEA9F', borderRadius: 3 },
  fake: { height: 80, width: 0 },
};
const CSSRAND = (id, meal = false) => {
  const clrs = cardClrCSS(id, meal);
  return { height: 80, width: 210, borderRadius: 3, border: '1px solid #D3D9DB', ...clrs };
};

export {
  MLSLCard, WOCard, ItemCard,
  AddWoCard,
};
