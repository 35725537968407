import React,{useContext} from 'react';
import {DraftTag} from "../draftTag";
import {get} from 'lodash';
import { VIEWS_CONSTANTS,PUBLISHED } from 'fitbud/utils/constants';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

export const SecondaryAction=({keyName,index,doc})=>{
    const {comp}=useContext(FirebaseAuthContext);
    const draftFeature=comp && comp.data() && get(comp.data(),'features.draft',false);
    switch(keyName){
        case VIEWS_CONSTANTS.EXERCISE:
        case VIEWS_CONSTANTS.CARDIO:
        case VIEWS_CONSTANTS.WORKOUT:
        case VIEWS_CONSTANTS.SCHEDULE:
        case VIEWS_CONSTANTS.FOOD:
        case VIEWS_CONSTANTS.RECIPE:
        case VIEWS_CONSTANTS.SUPPLEMENT:
        case VIEWS_CONSTANTS.MEAL_SCHEDULE:
        case VIEWS_CONSTANTS.MEAL:
        case VIEWS_CONSTANTS.SUPPLEMENT:
        case VIEWS_CONSTANTS.SUPPLEMENTS:{
            const publish_status=get(doc,'data.publish_status',PUBLISHED);
            if(publish_status.includes('draft') && draftFeature){
                return (<DraftTag/>)
            }
            return null;
        }
        default:return null;
    }
}