import React, { useContext, useState, useRef, useMemo, useEffect } from "react";
import _ from "lodash";
import * as Sentry from '@sentry/browser';
import uuidv4 from "uuid/v4";
import firebase from "fitbud/firebase";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import appRdxFns from "fitbud/redux/app";
import usrRepo from "fitbud/repo/users";
import Dialog from "fitbud/components/Dialog";
import {DEFAULT_ERROR, oprtnlDateFormat, SUBSCRIPTION_LOOKAHEAD_DAYS } from "fitbud/utils/constants";
import { UserSchContext } from "fitbud/views/schedulev2";
import { DatePicker } from "@material-ui/pickers";
import { DatePickerField } from "fitbud/views/users/createEditForm";
import NumUnitInput from "fitbud/components/numUnitInput";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography} from "@material-ui/core";
import { getConvertedDuration, isSubscriptionUser } from "fitbud/views/users/helpers"
import { durationTypes, durationInTypes } from "fitbud/views/schedulePlanner/helper";

const buildPlanWeeks = async (planDoc, newDuration) => {
  const { weeks = [] } = planDoc;
  const existingDays = 7 * weeks.length;
  const daysToAdd = newDuration - existingDays;
  if (daysToAdd <= 0) return weeks;
  const weeksToAdd = Math.ceil(daysToAdd / 7);
  for (let i = 0; i < weeksToAdd; i++)
    weeks.push(uuidv4());
  return weeks;
};

const ChangePlanDates = (props)=> {
  const { userDoc, planDoc, handleClose, userProfileId, hasUserLoggedProgress, extend = false } = props;
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const { controller } = useContext(UserSchContext);
  const { enqueueSnackbar } = useSnackbar();
  const origEndDate = useRef(moment(userDoc.endDate, oprtnlDateFormat));
  const origDurType = useRef(userDoc.durationType || 'weeks');
  const isSub = useMemo(() => isSubscriptionUser(userDoc), [userDoc]);
  const [state, setState] = useState({
    startDate: moment(userDoc.startDate, oprtnlDateFormat),
    endDate: moment(userDoc.endDate, oprtnlDateFormat).add(extend ? 7 : 0, 'days'),
    totDuration: extend ? userDoc.duration + 7 : userDoc.duration,
    visDuration: extend ? 7 : userDoc.duration,
    totDurType: origDurType.current,
    visDurType: extend ? 'weeks' : origDurType.current,
  })
  const [errors, setErrors] = useState({});
  const [changeType, setChangeType] = useState("duration")
  const { visDuration, totDuration, totDurType, visDurType, startDate, endDate } = state
  function handleDurationChange(e) {
    const {value, unit} = e.target;
    const _val = Number(value);
    const originalDuration = userDoc.duration;
    setState(s => {
      const totDuration = extend ? originalDuration + _val : _val;
      const endDate = startDate.clone().add(totDuration - 1, 'days');
      const totDurType = durationTypes(totDuration, extend ? origDurType.current : unit);
      return {...s, endDate, totDuration, visDuration: value, visDurType: unit, totDurType};
    });
  }
  useEffect(() => {
    validate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visDuration, visDurType])

  function validate() {
    const { visDuration } = state;
    const errors = { }
    let out = true
    if (!visDuration) {
      errors.duration = 'Please provide a valid duration'
      out = false
    }
    setErrors(errors)
    return out
  }
  function handleDateChange(date, type) {
    if (type === "startDate") {
      setState(s => ({ ...s, startDate: date, endDate: date.clone().add(s.totDuration - 1, 'days') }));
    } else {
      const originalDuration = userDoc.duration;
      setState(s => {
        let diff = date.diff(s.startDate, 'days') + 1;
        const totDuration = diff;
        const visDuration = extend ? diff - originalDuration : diff;
        const visDurType = durationTypes(visDuration, origDurType.current);
        const totDurType = durationTypes(totDuration, origDurType.current);
        return { ...s, endDate: date, totDuration, visDuration, visDurType, totDurType }
      });
    }
  }
  const handleChangePlanDates = async () => {
    if(!validate()) return;
    const duration = totDuration;
    const durationType = totDurType;
    if (extend && duration <= userDoc.duration)
      return enqueueSnackbar('Cannot reduce the duration as part of plan extension. Please provide a valid duration', { variant: "error" });
    else if (duration < 7 && !isSub)
      return enqueueSnackbar('Cannot support a plan lesser than 1 week. Please provide a valid duration', { variant: "error" });
    let assigned_till = moment(startDate).add(moment(userDoc.assigned_till, 'YYYYMMDD').diff(moment(userDoc.startDate, 'YYYYMMDD'),'days'), 'days').format('YYYYMMDD')
    let userData = {startDate: startDate.format('YYYYMMDD'), endDate: endDate.format('YYYYMMDD'), assigned_till, durationType, duration, deactivated: false }
    let planData = {startDate: startDate.format('YYYYMMDD'), durationType, duration }
    if(!userDoc.assigned_till) {
      delete userData.assigned_till
    }
    const packKey = userDoc.plan_assign ? "plan_assign" : userDoc.currPurchase ? "currPurchase": null
    //if plan_assign_present then in that case update purchases-> plan_assigns::
    if(!!userDoc.plan_assign && !!userDoc.purchases && !!userDoc.purchases.plan_assign ){
      userData.purchases = userDoc.purchases || {};
      userData.purchases.plan_assign = userDoc.purchases.plan_assign || {};
      userData.purchases.plan_assign.dateExpiry = moment(endDate).toISOString();
      userData.purchases.plan_assign.duration = duration;
      userData.purchases.plan_assign.accessValue = duration;
      userData.purchases.plan_assign.durationType = durationType;
    }
    if(packKey) {
      userData[packKey] = userDoc[packKey]
      if (!userDoc[packKey] || !userDoc[packKey].pack)
        userData[packKey].pack = {} 
      userData[packKey].pack.duration = duration 
      userData[packKey].pack.duration_type = durationType
      planData.purchase = userData[packKey]
    }
    if(!isSub) planData.grace_duration = duration
    if(extend) {
      userData.current_plan_status = userDoc.aplan ? "activated" : "inactive"
      userData.deactivated = false
      planData.plan_state = "activated"
      planData.grace_duration = duration
      planData.purchase = _.get(planDoc, 'purchase') || {pack: {}};
      planData.purchase.pack.duration = duration
      planData.purchase.pack.duration_type = durationType
      userData.plan_assign = planData.purchase
    }
    try {
      showLoader()
      if (userDoc.aplan) {
        const weeks = await firebase.firestore().runTransaction(async t => {
          const userRef = firebase.firestore().doc('user_profiles/' + userProfileId);
          const planRef = firebase.firestore().doc('plans/' + userDoc.aplan);
          let weeks = await buildPlanWeeks(planDoc, duration + (isSub ? SUBSCRIPTION_LOOKAHEAD_DAYS : 0));
          await Promise.all([
            t.update(userRef, {...userData}),
            t.update(planRef, {...planData, weeks}),
          ]);
          return weeks
        });
        planData.weeks = weeks
        controller.changeDates(userData, planData);
      } else {
        await usrRepo.update(userProfileId, userData)
        controller.changeDates(userData);
      }
      handleClose(null, true);
    } catch (error) {
      console.log(error)
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(error);
    }finally {
      hideLoader()
    }
  }

  const isPristine = userDoc.startDate === startDate.format('YYYYMMDD') && userDoc.duration === totDuration;
  const durationStr = useMemo(() => getConvertedDuration(totDuration , totDurType), [totDuration, totDurType]);
  return (
    <Dialog
    toolbarClass="height-60"
    buttonColor="primary"
    open
    onClose={handleClose}
    title={extend ? 'Extend Plan' : 'Change Plan Dates'}
    titleFont="h3"
    
    paperClass="width-400"
    dialogContentClassName="d-flex flex-column"
    onSave={handleChangePlanDates}
    disablePrimaryAction={isPristine}
  >
      <div className="fpx-20 fpy-25">
        {!extend && <DatePicker
        value={startDate.isValid() ? startDate : moment()}
        id="date-picker-dialog"
        inputVariant="outlined"
        onChange={(date) => handleDateChange(date, 'startDate')}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        format="DD MMM YYYY"
        TextFieldComponent={DatePickerField}
        disabled={hasUserLoggedProgress}
        helperText={hasUserLoggedProgress && "Start Date cannot be changed because the user has already logged progress"}
        minDate={moment()}
        minDateMessage=""/>}
        {!isSub && <RadioGroup
        value={changeType}
        name="changeType"
        className="flex-row p-0"
        onChange={e => setChangeType(e.target.value)}>
          <FormControlLabel
          className="p-0 fmr-30"
          value="duration"
          control={<Radio color="primary" />}
          label={extend ? 'By Duration' : 'Duration'}
          classes={{label: "font_15_600"}}/>
          <FormControlLabel
          value="endDate"
          control={<Radio color="primary" />}
          className="p-0"
          label={extend ? 'Till Date' : 'End Date'}
          classes={{label: "font_15_600"}}/>
        </RadioGroup>}
        {isSub ? null : (changeType === "endDate" ? (<>
          <DatePicker
            value={endDate.isValid() ? endDate : moment()}
            id="date-picker-dialog"
            inputVariant="outlined"
            onChange={(date) => handleDateChange(date, 'endDate')}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            format="DD MMM YYYY"
            TextFieldComponent={(props) => <DatePickerField hideLabel {...props}/>}
            minDate={(extend ? origEndDate.current : startDate) || moment()}
            minDateMessage=""/>
          {durationStr && <Typography className="fpb-5" variant="body2">
            <span className="text-muted">{isPristine? 'Current plan duration is ' : 'Plan duration will become '}</span>
            <span>{durationStr}</span>
          </Typography>}
        </>) : (<>
          <NumUnitInput
            id="duration"
            ignoreInitial
            unit={visDurType}
            error={!!errors.duration}
            value={durationInTypes(visDuration, visDurType)}
            helperText={errors.duration || ''}
            decor={2}
            onChange={handleDurationChange}
            variant="outlined"
            InputProps={{classes: {root: "medium",input: "size_16_500 medium"}, inputProps: { min: 1 }}}
            required/>
          <Typography className="fpb-5" variant="body2">
            <span className="text-muted">{isPristine? 'Current plan ends on ' : 'New end date will be '}</span>
            <span>{endDate.format('DD MMM YYYY')}</span>
          </Typography>
        </>))}
      </div>
    </Dialog>
  );
}

export default ChangePlanDates;
