import _ from "lodash";
import React from "react";
import clsx from 'clsx';
import {
    FormControlLabel,
    FormHelperText,
    Typography,
    Checkbox,
    makeStyles,
    Divider,
    Badge
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { QUICK_FILTER_MAP } from 'fitbud/utils/constants'

const styles = makeStyles(theme => ({
    root: {
        '&.Mui-expanded:before': {
            opacity: 'unset'
        },
        '&:before': {
            display: 'none'
        }
    },
    summaryRoot: {
        '&.Mui-expanded': {
            maxHeight: 60,
            minHeight: 60
        },
    },
    summaryContent: {
        minHeight: 36,
        '&.Mui-expanded': {
            maxHeight: '36px',
            margin: '12px 0'
        },
        display: 'flex',
        alignItems: 'center'
    },
    badge: {
        height: '4px',
        minWidth: '4px !important',
    },
}));

const AllClientsQuickFilterCheckbox = (props) => {
    const classes = styles();
    const { componentState: state = [], dispatch, type } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleChange = (e, keyName) => {
        const value = e.target.name;
        const isRoot = keyName === value;
        if (_.includes(state, value)) {
            if (isRoot) {
                dispatch({ type, payload: state.filter(s => !_.startsWith(s, keyName)) })
            } else {
                dispatch({ type, payload: state.filter(s => s !== value) })
            }
        } else {
            if (isRoot) {
                dispatch({ type, payload: [...(state.filter(s => !_.startsWith(s, keyName)) || []), value] })
            } else {
                dispatch({ type, payload: [...(state || []), value] })
            }
        }
    }

    return (
        <div className="fmtn-10 fmln-20 fmrn-20">
            {Object.entries(_.omit(QUICK_FILTER_MAP, ['browse', 'chats', 'groupChats', 'checkins'])).map(([keyName, qfs], index) => {
                let fields = qfs.map(qf => ({ ...qf, value: `${keyName}.${qf.key}`, checked: _.includes(state, keyName) || _.includes(state, `${keyName}.${qf.key}`), disabled: _.includes(state, keyName) }));
                //----TEMPORARY TILL BE SUPPORT FILTERS 
                if(keyName==='active'){
                    fields=fields.filter(i=>i.key!=='manually_added');
                }
                //----
                return (
                    <>
                        <Accordion key={keyName} expanded={expanded === keyName} onChange={handleAccordion(keyName)} elevation={0} className={clsx("border-none fpx-20", classes.root, (expanded === keyName) && 'my-0')} >
                            <AccordionSummary
                                className='px-0'
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                classes={{
                                    root: classes.summaryRoot,
                                    content: classes.summaryContent
                                }}
                            >
                                <Typography variant='h6'>{_.upperFirst(_.find(QUICK_FILTER_MAP.browse, ['key', keyName]).label)} { _.some(state, s => _.startsWith(s, keyName)) && <Badge className="fml-10"  classes={{ badge: classes.badge }} variant="dot" color="primary"/>}</Typography>
                            </AccordionSummary>
                            <AccordionDetails id={keyName} className="d-flex flex-column" classes={{ root: 'p-0' }}>
                                <FormControlLabel
                                    key={keyName}
                                    className="fmb-0"
                                    aria-label={keyName}
                                    control={<Checkbox name={keyName} checked={_.includes(state, keyName)} color="primary"  onClick={e => handleChange(e, keyName)}/>}
                                    label={<Typography className="font_14_600">{`Select All ${_.upperFirst(_.find(QUICK_FILTER_MAP.browse, ['key', keyName]).label)}`}</Typography>}
                                />
                                {fields.map((f, i) => {
                                    return (<>
                                        <FormControlLabel
                                            key={`_check${i}_${keyName}`}
                                            className="fmb-0"
                                            control={
                                                <Checkbox
                                                    name={f.value}
                                                    color="primary"
                                                    checked={!!f.checked}
                                                    onChange={e => handleChange(e, keyName, qfs)}
                                                    value={f.value}
                                                    disabled={f.disabled}
                                                />
                                            }
                                            label={<Typography className="font_14_600">{f.label}</Typography>}
                                        />
                                        {f.helperText && <FormHelperText className={clsx("fml-30 font_12_500", ((i + 1) !== fields.length) && "fmb-8")} style={{ marginTop: -2 }}>{f.helperText}</FormHelperText>}
                                    </>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                        <Divider className={clsx("px-0 w-100", (expanded === keyName) && 'fmt-20')} />
                    </>
                )
            })}
        </div>
    )
}

export default AllClientsQuickFilterCheckbox
