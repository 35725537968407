import axnsBuilder from "./actions";
export default collection => {
  const axns = axnsBuilder(collection);
  return {
    toggleLoader: () => ({ type: axns.toggle }),
    set: (list, count,refresh) => ({ type: axns.set, list, count,refresh }),
    insert: doc => ({ type: axns.insert, doc }),
    update: doc => ({ type: axns.update, doc }),
    delete: id => ({ type: axns.delete, id }),
    request: (refresh, dataLimit, filter, isLimitedAccess) => ({ type: axns.request, refresh, dataLimit, filter, isLimitedAccess }),
    searching: (query, filter) => ({ type: axns.searching, query, filter }),
    searchList: (list, query, searchCount, filter) => ({
      type: axns.search,
      list,
      searchCount,
      query,
      filter
    }),
    resetSearch:() => ({type: axns.resetSearch})
  };
};
