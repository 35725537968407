import React from 'react';
import { AppBar, Dialog, IconButton, Toolbar, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import ProductList from './products';
// import AddOnsList from "./addOns";

const useStyles = makeStyles(() => ({
  toolBar: {
    minHeight: 'unset !important',
    height: '40px',
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
    borderBottom: '2px solid #D8E2EA !important',
  },
  divider: {
    borderBottom: '2px solid #317FF5',
  },
}));

const Header = (props) => {
  const { onClose } = props;
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar className={clsx(classes.toolBar)}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography className="font_15_600 text-0d0d0d flex-grow-1 text-center">Billing</Typography>
      </Toolbar>
    </AppBar>
  );
};

const Separator = (props) => {
  const classes = useStyles();
  const { text } = props;
  return (
    <div className={clsx(props.className, 'd-flex w-100 align-items-center')}>
      <div className={clsx(classes.divider, 'flex-grow-1')} />
      <Typography color="primary" className="font_15_600 mx-10">
        {text}
      </Typography>
      <div className={clsx(classes.divider, 'flex-grow-1')} />
    </div>
  );
};

const PricingDialog = (props) => {
  const { onClose } = props;
  return (
    <Dialog open={true} fullScreen onClose={onClose}>
      <Header {...props} />
      <div className="w-100 d-flex flex-column flex-grow-1 bg-offWhite px-30 py-30">
        <ProductList />
        {/* <AddOnsList /> */}
      </div>
    </Dialog>
  );
};
export default PricingDialog;
