import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import notificationRepo from 'fitbud/repo/inAppNotification';
import Img from './img';
import { Divider, FormControlLabel, IconButton, Switch, Typography } from '@material-ui/core';
import EditIcon from 'fitbud/icons/detailEdit';
import moment from 'moment';
import InAppNotificationDialog from './inAppNotificationDialog';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import * as Sentry from '@sentry/browser';
import { ACTIONS, FREQUENCY, DAY } from './constant';
import { ChevronRightRounded } from '@material-ui/icons';
import Options from './options';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import Confirmation from 'fitbud/components/confirmationDialog';

export default function NotificationInfo({ id, fetchList }) {
  const { cid } = useContext(FirebaseAuthContext);
  const [openEditor, setOpenEditor] = useState(false);
  const [data, setData] = useState(null);
  const [activeConfirmation, setActiveConfirmation] = useState(false);
  const dispatch = useDispatch();
  const { hideLoader, showLoader } = appRdxFns(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { title, title_ref, image, description, _from, _till, _rules, cta1, cta2, active } = data || {};
  const { minutes = DAY } = _rules || {};
  const frequency = useMemo(() => (minutes / DAY), [minutes]);
  const { _action: action1, label: label1, open: open1 } = cta1 || {};
  const { _action: action2, label: label2, open: open2 } = cta2 || {};
  const startDate = moment(_from, 'YYYYMMDDHHmm').format('DD MMM YY, hh:mm a');
  const endDate = moment(_till, 'YYYYMMDDHHmm').format('DD MMM YY, hh:mm a');

  const link1 = (function () {
    if (action1 === 'allPlans') return;
    if (action1 === 'custom') {
      if(open1?.startsWith("http")) return open1
      return "//" + open1
    };
    if (action1 === 'paymentPlan') {
      return `/plans/${open1?.id}`;
    }
    return `/explore/${open1?.id}`;
  })();

  const link2 = (function () {
    if (action2 === 'allPlans') return;
    if (action2 === 'custom') {
      if(open2?.startsWith("http")) return open2
      return "//" + open2
    };
    if (action2 === 'paymentPlan') {
      return `/plans/${open2?.id}`;
    }
    return `/explore/${open2?.id}`;
  })();

  const handleDelete = async () => {
    showLoader();
    try {
      const response = await notificationRepo(cid).delete(id);
      if (response) {
        fetchList();
        history.replace('/messages/in-app-notifications');
        enqueueSnackbar('Notification deleted successfully.', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(error);
    } finally {
      hideLoader();
    }
  };

  const fetchData = () => {
    notificationRepo(cid)
      .doc(id)
      .then((doc) => {
        if (!doc.exists) return;
        setData(doc.data());
      });
  };

  const updateActive = async (value) => {
    const payload = { active: value };
    showLoader();
    try {
      await notificationRepo(cid).update(id, payload);
      setData((prev) => ({ ...prev, active: value }));
      fetchList();
      hideLoader();
    } catch (error) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  };

  const handleActiveToggle = (e) => {
    const checked = e.target.checked;
    if (checked) updateActive(checked);
    else setActiveConfirmation(true);
  };

  const handleConfirmation = async () => {
    await updateActive(false);
    setActiveConfirmation(false);
  };

  useEffect(() => {
    if (!cid || id === 'new') return;
    fetchData();
  }, [cid, id]);

  if (!data) return null;

  return (
    <div className="vh-100 d-flex flex-column bg-white">
      <div className="fp-25">
        <div className="d-flex">
          <Img name={title} src={image} size={360} width={180} aspect={1.5} className="rounded-10 mr-3" />
          <div className="d-flex flex-column align-items-start">
            <Typography variant="h1" className="fmb-10" style={{ fontSize: 20 }}>
              {title}
            </Typography>
            <Typography style={{ fontSize: '14px', lineHeight: 1 }} variant="h4" color="textSecondary">
              {title_ref}
            </Typography>
            <div className="flex-grow-1" />

            <FormControlLabel
              checked={active}
              classes={{ root: 'my-0', disabled: 'text-body' }}
              control={<Switch color="primary" onChange={handleActiveToggle} />}
              label={active ? 'Active' : 'Inactive'}
            />
          </div>
          <div className="flex-grow-1" />
          <div className="d-flex justify-content-center mr-n2 mt-n2 align-items-start">
            <IconButton onClick={() => setOpenEditor(true)}>
              <EditIcon />
            </IconButton>
            <Options onDelete={handleDelete} style={{ marginLeft: 8 }} />
          </div>
        </div>
        <div className="fmt-25">
          {description && <MoreLessTextBtn text={description} className="desc fmb-25" btnClassName="viewMore" />}
        </div>
        <div className="fmt-25 d-flex flex-wrap" style={{ columnGap: 110, rowGap: 20 }}>
          <div className="d-flex flex-column">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              Start Date &amp; Time
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {startDate}
            </Typography>
          </div>
          <div className="d-flex flex-column">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              End Date &amp; Time
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {endDate === 'Invalid date' ? '-' : endDate}
            </Typography>
          </div>
          <div className="d-flex flex-column">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              Frequency
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {FREQUENCY[frequency]}
            </Typography>
          </div>
        </div>
      </div>
      {cta1 && (
        <>
          <Divider className="dense" />
          <div className="fp-25">
            <Typography variant="h5">Primary CTA</Typography>
            <div className="d-flex fmt-25">
              <div className="d-flex flex-column" style={{ maxWidth: 260, width: '100%' }}>
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  CTA Text
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {label1}
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  Navigate to
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {action1 === 'allPlans' ? (
                    ACTIONS[action1]
                  ) : (
                    <>
                      {ACTIONS[action1] || 'Explore'} <ChevronRightRounded style={{ fontSize: 16 }} />
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={link1}
                        className="text-black text-decoration-underline"
                      >
                        {action1 === 'custom' ? open1 : open1?.title}
                      </a>
                    </>
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      {cta2 && (
        <>
          <Divider className="dense" />
          <div className="fp-25">
            <Typography variant="h5">Secondary CTA</Typography>
            <div className="d-flex fmt-25">
              <div className="d-flex flex-column" style={{ maxWidth: 260, width: '100%' }}>
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  CTA Text
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {label2}
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  Navigate to
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {action2 === 'allPlans' ? (
                    ACTIONS[action2]
                  ) : (
                    <>
                      {ACTIONS[action2] || 'Explore'} <ChevronRightRounded style={{ fontSize: 16 }} />
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={link2}
                        className="text-black text-decoration-underline"
                      >
                        {action2 === 'custom' ? open2 : open2?.title}
                      </a>
                    </>
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      {
        <Confirmation
          open={activeConfirmation}
          handleCancel={() => setActiveConfirmation(false)}
          handleChange={handleConfirmation}
          title="Please Confirm"
          msg="Are you sure you want to deactivate notification"
          confirmOption="Yes, Deactivate"
        />
      }
      {openEditor && (
        <InAppNotificationDialog
          open
          onEdit={setData}
          initialData={data}
          onClose={() => setOpenEditor(false)}
          id={id}
          onSave={fetchList}
        />
      )}
    </div>
  );
}
