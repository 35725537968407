import _get from 'lodash/get';
import React, { useContext, useMemo } from 'react';
import {
  IconButton, Button, Hidden,
  Typography, Tooltip,
} from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CloseIcon from '@material-ui/icons/Close';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { UserSchContext } from '../provider';
import ImportBtn from './importBtn';
import CopyBtn from './copyBtn';
import WeekList from './week-list';

const AxnBar = ({ rhs, toggleRHS }) => {
  const { isAdmin } = useContext(FirebaseAuthContext);
	const { local, weekToday, dayToday, currWeek, currWeekId, opcopy, ready, controller } = useContext(UserSchContext);
  const isPerDay = useMemo(() => {
    return _get(local, ['weeks', currWeekId, 'ml', 'mode']) === 'per_day';
  }, [local, currWeekId]);

	const [copying, copyTitle, target] = useMemo(() => {
    if (!opcopy) return [];
    const { week: srcWeek, day, purpose } = opcopy;
    if (day) {
      switch (purpose) {
        case 'wo': return [true, `Copying Workouts from Week ${srcWeek + 1} Day ${day}`];
        case 'ml': return [true, `Copying Meals from Week ${srcWeek + 1} Day ${day}`];
        case 'singleWO': return [true, `Copying a Workout from Week ${srcWeek + 1} Day ${day}`];
        case 'singleML': return [true, `Copying a Meal from Week ${srcWeek + 1} Day ${day}`];
        default: return [true, `Copying from Week ${srcWeek + 1} Day ${day}`];
      }
    } else {
      const canPasteHere = currWeek >= weekToday && srcWeek !== currWeek;
      switch (purpose) {
        case 'wo': return [true, `Copying Workouts from Week ${srcWeek + 1}`, canPasteHere];
        case 'ml': return [true, `Copying Meals from Week ${srcWeek + 1}`, canPasteHere];
        case 'sl': return [true, `Copying Supplements from Week ${srcWeek + 1}`, canPasteHere];
        case 'both': return [true, `Copying from Week ${srcWeek + 1}`, canPasteHere];
        default: return [];
      }
    }
	}, [currWeek, weekToday, opcopy]);

	if (!ready || typeof currWeek === 'undefined') return null;

	if (copying) { // copy operation bar
		return (
			<div className='bar flex-shrink-0 d-flex flex-row border-bottom-2 px-10 pl-md-20 pr-md-16 align-items-center bg-vdark-grey'>
        <Hidden smUp><WeekList select light/></Hidden>
        <Hidden xsDown><Typography className='font_18_600 text-white'>{copyTitle}</Typography></Hidden>
        <div className='flex-grow-1'/>
        {target && <Button variant='contained' className='bg-white text-black' data-weekid={currWeekId} data-week={currWeek} onClick={controller.copyPaste}>
          Paste to Week {currWeek + 1}</Button>}
        <Hidden smUp><IconButton onClick={controller.copyClose} className='text-white ml-2'><CloseIcon/></IconButton></Hidden>
        <Hidden xsDown><Button onClick={controller.copyClose} variant='contained' className='bg-white text-black ml-3'>Done</Button></Hidden>
			</div>
		);
	}

	return ( // standard bar
		<div className='bar flex-shrink-0 d-flex flex-row border-bottom-2 pl-10 pl-md-20 pr-1 pr-md-16 align-items-center'>
			<Hidden smUp><WeekList select/></Hidden>
			<Hidden xsDown><Typography className='font_18_600'>Week {currWeek + 1}</Typography></Hidden>
      {dayToday >= 0 && currWeek !== weekToday && <Tooltip title='Jump to Today'><Button color='primary' onClick={controller.thisWeek} className='ml-12'>Today</Button></Tooltip>}
			<div className='flex-grow-1'/>
      {isAdmin && <Tooltip title='Add Weeks'><IconButton onClick={controller.addWeeksClick}><PlaylistAddIcon/></IconButton></Tooltip>}
      <ImportBtn isPerDay={isPerDay} hidden={rhs || currWeek < weekToday} week={currWeek} weekId={currWeekId} axn={controller.importClick}/>
      <CopyBtn hidden={rhs} isPerDay hasWOs hasMLs week={currWeek} weekId={currWeekId} axn={controller.copyClick}/>
			<Hidden smUp><IconButton onClick={toggleRHS}>{rhs ? <CloseIcon/> : <MenuOpenIcon/>}</IconButton></Hidden>
		</div>
	);
};

export default AxnBar;
