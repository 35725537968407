import _ from 'lodash';
import React, { useContext, useMemo, useEffect } from 'react';
import {
  Typography,
} from '@material-ui/core';
import { AGGRS } from './constants';
import { UserSchContext } from './provider';
import { ItemCard, FakeCard } from './cards';

const IDTODAY = 'today-day-row';
const getWOS = (wa, wb) => {
  const out = wb || wa;
  const isRest = out && Boolean(out.length) && out[0] === 'rest';
  return [out, isRest];
};
const getML = (isRest, ma, mb) => {
  const out = mb || ma;
  if (!out) return null;
  const {onoff, on, off, single} = out;
  return onoff ? (isRest ? off : on) : single;
};
const reorgWOS = ({todo, done, ods}, copy) => {
  const out = [];
  const found = {};

  // first pick the assigned stuff, handling alternates alongwith
  if (todo && todo.length && todo[0] !== 'rest') {
    todo.forEach(str => {
      const ids = str.split('/');
      let primary = null;
      const completed = [];
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        let log = done && done[id];
        let data = {id, log, meta: {}};
        if (copy && copy[id])
          data.meta = copy[id];
        else if (ods && ods[id])
          data = {...data, ...ods[id]};
        if (i === 0) primary = data;
        if (data.meta.srcId && !log) {
          const other = done && done[data.meta.srcId];
          if (other) {
            delete done[data.meta.srcId]
            done[id] = other;
            data.log = other;
            log = other;
          }
        }
        if (log && log.is_complete) {
          completed.push(data);
          found[id] = true;
        }
      }
      if (completed.length) out.push(...completed);
      else out.push(primary);
    });
  }

  // next pick the done stuff which wasn't found within assigned
  _.each(done, (log, id) => {
    if (found[id]) return;
    if (!log || !log.is_complete) return;
    let meta = (ods && ods[id]);
    if (meta) {
      out.push({id, log, ...meta});
    } else {
      meta = (copy && copy[id]);
      out.push({id, log, meta});
    }
  });

  return out;
};

const DayRow = ({week, day, date, dayToday, data, legacyDayId, aggrs}) => {
  const [id, l2, l2css] = useMemo(() => {
    const isToday = (week * 7 + day) === dayToday;
    if (isToday) return [IDTODAY, 'Today, ' + date.format('D MMM'), 'text-primary font_15_500'];
    return ['day' + (day + 1), date.format('ddd, D MMM'), 'font_15_500'];
  }, [week, day, date, dayToday]);
  const {workout, nutrition, copy, isRest, ...wtrAct} = useMemo(() => {
    const { water, activity, dayData } = data || {};
    const { id, oid, wa, wb, ma, mb, copy } = dayData || {};
    const [woids, isRest] = getWOS(wa, wb);
    const mlid = getML(isRest, ma, mb);
    let out = {
      workout: { todo: woids },
      nutrition: { todo: mlid },
      water: { todo: water },
      activity: { todo: activity },
      copy, isRest,
    };
    if (aggrs) {
      AGGRS.forEach(key => {
        const datedLogs = aggrs[key][date.format('YYYYMMDD')] || {};
        const idLogs = aggrs[key][id] || {};
        const legacyLogs = aggrs[key][legacyDayId || oid] || {};
        if (key === 'workout') {
          for (const key in datedLogs) {
            datedLogs[key].id = `${date.format('YYYYMMDD')}:${key}`;
          }
          for (const key in idLogs) {
            idLogs[key].id = `${id}:${key}`;
          }
          for (const key in legacyLogs) {
            legacyLogs[key].id = `${legacyDayId}:${key}`;
          }
        }
        if (key === 'od_workout')
          out.workout.ods = datedLogs;
        else
          out[key].done = {...datedLogs, ...idLogs, ...legacyLogs};
      });
    }
    out.workout = reorgWOS(out.workout, copy);
    return out;
  }, [date, data, aggrs, legacyDayId]);
  return (
    <div className='w-100 bg-white border-bottom' id={id}>
      <div className='pt-20 px-25 d-flex flex-row'>
        <Typography className='font_15_600'>Day&nbsp;{day + 1}&nbsp;&nbsp;•&nbsp;&nbsp;</Typography>
        <Typography className={l2css}>{l2}</Typography>
      </div>
      <div className='py-20 px-25 overflow-x-auto overflow-y-hidden'>
        <div className='d-flex flex-row w-mincont mr-n15'>
          {workout.map((w, n) => <ItemCard key={w.id} wo index={n} week={week} day={day} data={w} date={date}/>)}
          {isRest && !workout.length && <ItemCard rest week={week} day={day}/>}
          <ItemCard ml week={week} day={day} dayCopy={copy} data={nutrition} date={date}/>
          <ItemCard wa week={week} day={day} data={wtrAct}/>
          <FakeCard/>
        </div>
      </div>
    </div>
  );
};

const WeekdaysView = ({ aggrs }) => {
  const { dates, currWeek, currWeekId, weekToday, dayToday, remote } = useContext(UserSchContext);
  const weekData = remote.weeks[currWeekId] || {};
  const { data = {}, days, water, activity } = weekData;
  useEffect(() => {
    if (dayToday >= 0 && currWeek === weekToday)
      window.document.getElementById(IDTODAY).scrollIntoView({behavior: 'smooth', block: 'start'});
    else
      window.document.getElementById('day1').scrollIntoView(true);
  }, [dayToday, currWeek, weekToday]);
  if (!dates || !dates.length) return null;
  return (
    <div className='flex-grow-1 overflow-y-auto overflow-x-hidden'>
      {dates.map((date, n) => <DayRow key={date.format('YYYYMMDD')} weekId={currWeekId} week={currWeek} day={n} date={date} dayToday={dayToday}
        data={{water, activity, dayData: data['d' + (n+1)]}} legacyDayId={days && days.length && days[n].id} aggrs={aggrs} />)}
    </div>
  );
};

export default WeekdaysView;
