import { Card, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { LayersOutlined, MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import EditedCardIcon from 'fitbud/icons/editedCardIcon';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from 'lodash';
import React, { useContext } from 'react';
import { cardClrCSS, getWorkoutIntensityIcon } from '../users/subComponents';
import { CopyOverlay } from './subComponents';
import {durationToHMString} from "fitbud/views/workouts/helperfn";

export const scheduleCardStyles = makeStyles((theme) => ({
  card: {
    minWidth: ({ minified, minWidth }) => minWidth ? minWidth : minified ? 160 : 170,
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'none',
  },
  wtf: {
    height: ({ minified }) => minified ? 72 : 80,
  },
  typeStyling: ({id, type}) => cardClrCSS(id, type !== 'workout'),
  options: { top: 0, right: 0 },
}));
const options = ['edit', 'copy', 'delete'];
const WorkoutCard = ({ woData, id, itemId, toggleAlternateDialog, minWidth, isPrimary, toggleDrawer,
  editable = true, className, minified, onDelete, onCopy, closeCopyMode, isCopied }) => {
  let { ref_name, duration = false, intensity, is_wo_edited } = woData || {};
  const { comp } = useContext(FirebaseAuthContext);
  const isAltWoEnabled = _.get((comp.data() || {}), "app_config.tags.altwo", false);
  const alternateCount = itemId.split('/').length;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMoreClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  
  const handleOptionClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    if(action === 'edit'){
      handleClose(e);
      handleClick(e);
    }
    if (action === 'delete') {
      handleClose(e);
      onDelete();
    }
    if (action === 'copy') {
      handleClose(e);
      return onCopy(e);
    }
    if (action.match('alternat')) {
      handleClose(e);
      return toggleAlternateDialog();
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation()
    if(isAltWoEnabled && editable && alternateCount > 1) toggleAlternateDialog(e);
    else toggleDrawer();
  };

  const classes = scheduleCardStyles({ id: alternateCount > 1 ? itemId.split('/').shift() : itemId, minified, minWidth, type: 'workout' });
  return (
    <Card
      onClick={handleClick}
      className={clsx(classes.card, classes.wtf, classes.typeStyling, 'position-relative shadow-none cursor-pointer', className)}
      id={id} classes={{ root: "border-none" }}>
      <CopyOverlay isCopied={isCopied} type="individualItem" onClose={closeCopyMode} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { minWidth: 130, boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)", borderRadius: '8px' } }}
      >
        {(isAltWoEnabled ? options.concat([`${alternateCount > 1 ? "manage" : 'add' } alternatives`]) : options).map((i, j) => (
          <MenuItem
            key={j}
            onClick={e => handleOptionClick(e, i)}
            className="text-capitalize"
          >
            {i}
          </MenuItem>
        ))}
      </Menu>
      {is_wo_edited && (
        <EditedCardIcon
          style={{
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
      )}
      <div className='h-100 d-flex w-100 flex-column fpx-10 fpy-10 '>
        <div className='flex-1'>
          <Typography className='font_13_600' style={{ maxWidth: '90%' }} noWrap>
            {ref_name}
          </Typography>
        </div>
        <div className='d-flex flex-1 w-100 align-items-end'>
          {isAltWoEnabled && alternateCount > 1 ? editable ? (
            <Typography className='font_11_500 text-dark-grey '>
              +{alternateCount - 1}&nbsp;Alternative{alternateCount > 2 ? 's' : ''}&nbsp;
              <LayersOutlined classes={{root: !!editable ? 'small' : 'x-small'}} />
            </Typography>
          ) : (
            <ClrdButton onClick={toggleAlternateDialog} className='p-0'>
              <Typography className='font_11_500 text-dark-grey '>
                +{alternateCount - 1}&nbsp;Alternative{alternateCount > 2 ? 's' : ''}&nbsp;
                <LayersOutlined classes={{root: !!editable ? 'small' : 'x-small'}} />
              </Typography>
            </ClrdButton>
          ) : (
            <div className={'d-flex w-100 h-100 align-items-end'}>
              <Typography className='font_11_500 text-dark-grey '>
                {durationToHMString(duration)}
              </Typography>
              {editable && <div className="fml-10" style={{ marginBottom: '2px' }}>
                {intensity && getWorkoutIntensityIcon(intensity)}
              </div>}
              <div className='flex-grow-1'/>
              {isPrimary && <Typography className='font_11_600 text-uppercase text-grey mr-10'>
                Primary
              </Typography>}
            </div>
          )}
          {!!editable && (
            <div className={clsx('position-absolute', classes.options)}>
              <IconButton onClick={handleMoreClick} size='small'>
                <MoreVert className='text-dark-grey'/>
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default WorkoutCard;
