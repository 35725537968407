import { makeStyles } from "@material-ui/core";

export const usePlanFlowStyle = makeStyles((theme) => ({
  paper: {
    width: "420px",
    [theme.breakpoints.down("sm")]:{
      width:"100%"
    }
  },
  closeIcon: {
    top: 10,
    left: 10,
  },
  card: {
    cursor:"pointer",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
    border: (isSelected) =>
      isSelected
        ? `1px solid ${theme.palette.primary.main}`
        : "1px solid #D3D9DB",
  },
  selectedIcon: {
    fill: (isSelected) => (isSelected ? theme.palette.primary.main : "#65768C"),
  },
  stripeContainer: {
    "& .StripeElement": {
      marginBottom: "20px",
      background: "#fff",
      padding: "15px",
      borderRadius: "5px",
      border: "1px solid #DBDEEB",
    },
  },
  editIcon:{
    '& path':{
      stroke:"#65768C"
    }
  },
  twoToneCloseIcon:{
    height: "15px",
    width: "15px",
  },
  topInfo:{
    padding:15,
    background: "rgba(242, 153, 0, 0.15)"
  },
  headerDivider:{
    border:"1px solid #D3D9DB"
  }
}));
