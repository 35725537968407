import React, { useEffect, useMemo, useState } from "react";
import Dialog from "fitbud/components/Dialog";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
  IconButton,
  Tooltip,
  Grid
} from "@material-ui/core";
import { Avatar } from "fitbud/components/avatar";
import _ from "lodash";
import { DisplayValues } from "../exercise";
import update from "immutability-helper";
import clsx from "clsx";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import firebase from "fitbud/firebase";
import { ExSecondLine } from "../components/exerciseSecondLine";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIcon } from "fitbud/icons/drag";
import { reorder } from "fitbud/utils/helpers";
import { ValuePopupNew } from "./";
import ExerciseSearch from "fitbud/views/exercises/exercisesSearch";
import { ReadOnlyTrackingFields } from "./";
import { getExIcon } from "fitbud/utils/catalog";
import {TRACKING_FIELDS} from "fitbud/views/workouts/form";

const OVERRIDE_ALT_TRACKING_FIELD_TEXT="Use different tracking fields for alternates";
const OVERRIDE_ALT_TRACKING_FIELD_INFO="You can use different tracking fields (reps/duration/weights) for each alternate exercise by ticking the checkbox.";
const DISABLE_ALTERNATES_TEXT="Disable Alternates";
const DISABLE_ALTERNATES_INFO="If you disable the alternates, the clients on the app won't see the following alternate exercises for this primary exercise.";
const PRIMARY_EX_TRACKING_FIELDS=['ref_type','type','values','track','gender','male1','tempo','type2','male2','female1','female2'];
const styles = makeStyles({
  primary: {
    borderRadius: "20px",
    background: "rgba(64, 137, 247, 0.11)",
    padding: "10px 15px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    marginBottom: "10px",
    "& .icon": {
      position: "absolute",
      bottom: "0px",
      left: "50px",
      height: "18px",
      width:"18px"
    },
  },
  alternate: {
    background: "#fff",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginLeft:"5px",
    "& .icon": {
      position: "absolute",
      bottom: "0px",
      left: "50px",
      height: "18px",
      width:"18px"
    },
    "& .disable": {
      opacity: 0.5,
      pointerEvents: "none",
    },
  },
  alternateReadOnly: {
    display: "flex",
    alignItems: "center",
    padding: "14px 20px",
    "& .icon": {
      position: "absolute",
      bottom: "0px",
      left: "50px",
      height: "18px",
      width:"18px"
    },
    '& .disable':{
        opacity:0.5
    }
  },
});

export const AltPopup = (props) => {
  const {
    onClose,
    exData:primExData= {},
    exercise:primExercise,
    altExercises,//fetched altExercises object
    currentSideName: primSideName,
    currentSide:primSide,
    sidesData,
    varProps = {},
    onSave,
    isEditMode,
    exClasses,
    handlePrimValueSave
  } = props;
  const [overrideAltTracking, toggleOverrideAltTracking] = useState(!!primExercise.overrideAltTracking);//boolean
  const [hideAllAlts, toggleHideAllAlts] = useState(!!primExercise.hideAllAlts);//boolean
  const [altExHash,setAltExHash]=useState({});
  const [overrideAlts,updateOverrideAlts]=useState(primExercise.overrideAlts||{});
  const [altsOrder,updateAltsOrder]=useState(primExercise.altsOrder||[]);
  const [firstMount,setFirstMount]=useState(true);
  useEffect(()=>{
    if(!Object.keys(altExercises).length) return;
    const hash=altExercises.reduce((s,i)=>{
      const {_id,data}=i;
      const obj={[_id]:{...data}};
      return {...s,...obj};
    },{});
    if(!!firstMount){
      const alts = _.get(primExData, "alts", []);//alts from exercise.doc()
      //keep only those alt ids which are present in exercise.doc().alts
      const updatedOverrideAlts=_.pick(overrideAlts,_.intersection(alts,Object.keys(overrideAlts)));
      const updatedAlternates=[];
      (alts || []).forEach((i) => {
        const id = i;
        const found = hash[id];
        let order=-1;
        //check pre-defined order
        if(altsOrder.length){
          const altIndex=altsOrder.findIndex(i=>i===id);
          if(altIndex>-1){
            order=altIndex
          }
        }
        if (found) {
          const obj = {
            title:found.title||"",
            order,
            id
          };
          updatedAlternates.push(obj);
        }
      });
      // order
      const orderedItems=_.sortBy((updatedAlternates||[]).filter(i=>i.order!==-1),['order']);
      const unorderedItems=_.sortBy((updatedAlternates||[]).filter(i=>i.order===-1),(i)=>i.title.toLowerCase())
      const finalOrder=[...orderedItems,...unorderedItems].map(i=>i.id);
      updateAltsOrder(finalOrder);
      setAltExHash(hash);
      updateOverrideAlts(updatedOverrideAlts);
      if(!!firstMount) setFirstMount(false);
    }
  },[altExercises]);
  const handleSave = () => {
    const { "data-group": grpIndex, index: exIndex } = varProps;
    const updatedExercise={
      ...primExercise,
        overrideAlts,
        overrideAltTracking,
        hideAllAlts,
        altsOrder
    }
    onSave({
      exercise: {...updatedExercise},
      grpIndex,
      exIndex,
    });
    onClose();
  };
  const handleOverrideAltSwitch = (e, v) => {
    toggleOverrideAltTracking(v);
    if(!v){
      const newOverrideAlts=_.cloneDeep(overrideAlts);
      for(let i in newOverrideAlts){
        const id=i;
        newOverrideAlts[id]=_.pick(newOverrideAlts[id],['show']);
        if(!Object.keys(newOverrideAlts[id]).length){
          delete newOverrideAlts[id];
        }
      }
      updateOverrideAlts(newOverrideAlts);
    }
    else{
      const primExerciseTrackingFields=_.pick(primExercise,PRIMARY_EX_TRACKING_FIELDS);
      const newOverrideAlts={};
      for(let i in altsOrder){
        const id=altsOrder[i];
        newOverrideAlts[id]={
          ...primExerciseTrackingFields,
          side:altExHash[id].side||'',
          ...(overrideAlts[id]||{})}
      }
      updateOverrideAlts(newOverrideAlts);
    }
  };
  const handleHideAllAltSwitch = (e, v) => {
    toggleHideAllAlts(v);
    if(!!v){
      const newOverrideAlts=_.cloneDeep(overrideAlts);
      for(let i in altsOrder){
          const id=altsOrder[i];
          newOverrideAlts[id]={
            ...(newOverrideAlts[id]||{}),
            show:false
          }
      }
      updateOverrideAlts(newOverrideAlts);
    }
    else{
      const newOverrideAlts=_.cloneDeep(overrideAlts);
      for(let i in altsOrder){
          const id=altsOrder[i];
          const obj=newOverrideAlts[id]||{};
          delete obj.show;
          if(!Object.keys(obj).length){
            delete newOverrideAlts[id];
          }
      }
      updateOverrideAlts(newOverrideAlts);
    }
  };
  const toggleShowAlts = (index, value) => {
    const id=altsOrder[index];
    //update override map
    const newOverrideAlts={
      ...overrideAlts||{},
      [id]:{
        ...overrideAlts[id]||{},
        show:value
      }
    };
    //delete keys if default behaviour
    if(!!value){
      delete newOverrideAlts[id].show;
      if(!Object.keys(newOverrideAlts[id]).length){
        delete newOverrideAlts[id];
      }
    }
    updateOverrideAlts({
      ...newOverrideAlts
    });
    //reset hideAllAlts flag
    if(!!value && !!hideAllAlts){
      toggleHideAllAlts(false);
    }
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedAltsOrder = reorder(
      altsOrder,
      result.source.index,
      result.destination.index
    );
    updateAltsOrder([...updatedAltsOrder]);
  };
  const handleAltValueSave = (
    index,
    exChanges
  ) => {
    const id=altsOrder[index];
    const keysToOmit=TRACKING_FIELDS;
    const overrideAltEx=_.omit(overrideAlts[id],keysToOmit);
    updateOverrideAlts(o=>(
      {...o,
      [id]:{...overrideAltEx,...exChanges}
    }));
  };
  if (isEditMode) {
    return (
      <Dialog
        open={true}
        title="Manage Alternates"
        toolbarClass="height-60"
        buttonColor="primary"
        paperClass="width-840"
        onClose={onClose}
        hideHeaderDivider={true}
        onSave={handleSave}
      >
        <div
          className="bg-grey-new d-flex align-items-center pl-16 sticky-top"
          style={{ height: "50px",borderBottom:"1px solid #D8DCE0" }}
        >
          <FormControlLabel
            control={<Checkbox color="primary" checked={overrideAltTracking} />}
            onChange={handleOverrideAltSwitch}
            label={<Label title={OVERRIDE_ALT_TRACKING_FIELD_TEXT} info={OVERRIDE_ALT_TRACKING_FIELD_INFO}/>}
            className="mr-40 my-0"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={hideAllAlts} />}
            onChange={handleHideAllAltSwitch}
            label={<Label title={DISABLE_ALTERNATES_TEXT} info={DISABLE_ALTERNATES_INFO}/>}
            className="m-0"
          />
        </div>
        <DialogContent className="px-15 pt-12 pb-0">
          <Primary
            primExercise={primExercise}
            primExData={primExData}
            primSideName={primSideName}
            primSide={primSide}
            onSave={handlePrimValueSave}
            varProps={varProps}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {altsOrder.map((id, index) => {
                      return (
                        <Draggable
                          draggableId={`__dragId${index}`}
                          key={`__dragId${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <>
                                <Alternates
                                  index={index}
                                  data={altExHash[id]}
                                  overrideAltTracking={overrideAltTracking}//flag
                                  hideAllAlts={hideAllAlts}//flag
                                  toggleShowAlts={toggleShowAlts}//switch fn
                                  onSave={(v) =>handleAltValueSave(index, v)}
                                  sidesData={sidesData}//all sides fetched
                                  primExercise={primExercise}//workout.doc()[grpIndex].exercise
                                  primExData={primExData}//fetched exercise.doc()
                                  override={overrideAlts[id]||{}} //workout.exercise.alts override
                                  //extra
                                  snapshot={snapshot}
                                  provided={provided}
                                  varProps={varProps}
                                  key={id}
                                />
                              </>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog
      open={true}
      title="Alternate Exercises"
      toolbarClass="height-60"
      paperClass="width-600"
      onClose={onClose}
    >
      {altsOrder.map((i) => {
        return (
            <AlternateReadOnly 
              exClasses={exClasses} 
              sidesData={sidesData} 
              data={{ ...altExHash[i] }} 
              overrideAltTracking={overrideAltTracking}//flag
              primExercise={primExercise}
              primExData={primExData}
              override={overrideAlts[i]||{}}
              />
        )
      })}
    </Dialog>
  );
};
const Primary = ({ primExercise, primExData = {}, primSideName, primSide, onSave, varProps }) => {
  const classes = styles();
  const [showPopup, togglePopup] = useState(null);
  const {mediaType,name,thumbnail,title}=primExData;
  const icon = useMemo(() => getExIcon({type:mediaType}), [mediaType]);
  return (
    <Grid container justifyContent="space-between" className={classes.primary}>
      <Grid item container xs={6} alignItems="center" wrap="nowrap">
    {/* <div className={classes.primary}>
      <div className="d-flex align-items-center" style={{flex:7}}> */}
        <div className="position-relative">
          <Avatar size="large" name={name || title} src={thumbnail} />
          {icon && <img className="icon" src={icon} alt="" />}
        </div>
        <div className="ml-20">
          <Typography className="font_13_700 text-primary">PRIMARY</Typography>
          <Typography className="font_15_600">
            {name || title}
          </Typography>
          <ExSecondLine
            exercise={primExercise}
            exData={primExData}
            showAlts={false}
            currentSideName={primSideName}
            className="font_13_500 text-grey"
          />
        </div>
      </Grid>
      <Grid
        item
        container
        xs={6}
        alignItems="center"
        justifyContent="flex-end"
        wrap="nowrap"
        style={{paddingRight:"42px"}}
      >
        <DisplayValues
          exercise={primExercise}
          exData={primExData}
          onClick={(e) => togglePopup(e.currentTarget)}
          alternatePopup={true}
        />
        {showPopup && (
          <ValuePopupNew
            anchor={showPopup}
            handleClose={() => togglePopup(null)}
            onSave={(e, values) => {
              onSave(e, values);
              togglePopup(null);
            }}
            props={{ ...varProps }}
            exercise={primExercise}
            exData={primExData}
          />
        )}
      </Grid>
    </Grid>
  );
};
const Alternates = (props) => {
  const {
    index,
    data,
    overrideAltTracking,//flag
    hideAllAlts,//flag
    toggleShowAlts,//switch fn
    onSave,
    sidesData,
    primExercise,
    primExData,
    override,
    //extra
    provided,
    varProps,
  } = props;
  const {
    thumbnail,
    media,
    mediaType,
    title,
    side,
    is_crop_square,
    is_mute_video,
  } = data;
  const show="show" in override?override.show:true;
  const classes = styles();
  const [showPopup, togglePopup] = useState(null);
  const icon = useMemo(() =>getExIcon({type:mediaType,media}), [mediaType,media]);
  const _exercise=!overrideAltTracking?{...primExercise,side}:{...override};
  const _exData=!overrideAltTracking?{...primExData, is_crop_square, is_mute_video,side}
    :{...override, is_crop_square, is_mute_video};
  const currentSideName = useMemo(() =>_.find(sidesData, (d) => d[0] === _exData.side), [sidesData, _exData.side]);
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.alternate}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <Grid item container xs={6} alignItems="center" wrap="nowrap">
        <Switch
          color="primary"
          checked={hideAllAlts ? false : !!show}
          onChange={(e, v) => toggleShowAlts(index, v)}
          className="ml-n12"
        />
        <div
          className={clsx(
            "ml-8 d-flex align-items-center ",
            !show && "disable"
          )}
        >
          <div className="position-relative">
            <Avatar size="large" src={thumbnail} name={title} />
            {icon && <img className="icon" src={icon} alt="" />}
          </div>
          <div className="ml-20">
            <Typography className="font_15_600 mb-6">{title}</Typography>
            <ExSecondLine
              exercise={_exercise}
              exData={_exData}
              showAlts={false}
              currentSideName={currentSideName}
              className="font_13_500 text-grey"
            />
          </div>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={6}
        alignItems="center"
        justifyContent="flex-end"
        className="flex-nowrap"
      >
        {overrideAltTracking && (
          <>
            <DisplayValues
              exercise={_exercise}
              exData={_exData}
              onClick={(e) => {
                if(!!show){
                  togglePopup(e.currentTarget);
                }
              }}
              alternatePopup={true}
            />
            {showPopup && (
              <ValuePopupNew
                anchor={showPopup}
                handleClose={() => togglePopup(null)}
                onSave={(e, v) => {
                  onSave(v);
                  togglePopup(null);
                }}
                props={{ ...varProps }}
                exercise={_exercise}
                exData={_exData}
              />
            )}
          </>
        )}
        <IconButton
          component="div"
          style={{ marginLeft: "17px" }}
          {...provided.dragHandleProps}
        >
          <DragIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
const AlternateReadOnly = ({ data, sidesData,exClasses,overrideAltTracking,primExercise,primExData,override }) => {
  const classes = styles();
  const {
    thumbnail,
    media,
    mediaType,
    title,
    side,
    name,
    is_crop_square,
    is_mute_video,
  } = data;
  const show="show" in override?override.show:true;
  const icon = useMemo(() =>getExIcon({type:mediaType,media}), [mediaType,media]);
  const _exercise=!overrideAltTracking?{...primExercise,side}:{...override};
  const _exData=!overrideAltTracking?{...primExData, is_crop_square, is_mute_video,side}
    :{...override, is_crop_square, is_mute_video};
  const currentSideName = useMemo(() =>_.find(sidesData, (d) => d[0] === _exData.side), [sidesData, _exData.side]);

  return (
    <div className={classes.alternateReadOnly} style={{opacity:!!show?1:0.5}}>
      <div className="position-relative">
        <Avatar src={thumbnail} name={name||title} size="large" />
        {icon && <img className="icon" src={icon} alt="" />}
      </div>
      <div className="ml-20" style={{flex:1,minWidth:0}}>
        <Typography className="font_15_600 mb-5">{title}</Typography>
        <ExSecondLine
          exercise={_exercise}
          exData={_exData}
          showAlts={false}
          currentSideName={currentSideName}
          className="fmb-15 font_13_500 text-grey"
        />
        <ReadOnlyTrackingFields
          exercise={_exercise}
          exData={_exData}
          currentSideName={currentSideName}
          exClasses={exClasses}
          truncateWidth={false}
        />
      </div>
    </div>
  );
};

const Label=({title,info})=>{
  return(
    <div className="d-flex">
      <Typography className="font_15_500 mr-2 d-flex align-items-center">
        {title} 
      </Typography>
      <Tooltip title={info}>
        <Typography className="font_15_400">
          ⓘ
        </Typography>
      </Tooltip>
    </div>
  )
}