import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  CardActions,
  MenuItem,
  Menu,
  Divider,
  Checkbox
} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { withSnackbar } from 'notistack';
import { ClrdButton } from 'fitbud/components/form-fields';
import { EditorContext } from './foodItemsForm';
import FoodRecipe from './foodRecipe';
import ReorderDialog from 'fitbud/components/reorderDialog';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Confirmation from 'fitbud/components/confirmationDialog';
import { DetailPgStyling } from 'fitbud/hooks/useDetailPgStyle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { LinearPfc, Calories } from 'fitbud/views/foodRecipes/newUi/pfc';
import TimeIcon from 'fitbud/icons/timeIcon';

const useEditorClasses = makeStyles(()=>({
  root:{
    '& .action_btn':{
      // backgroundColor:"#F2F4F4 !important",
      height:"40px !important ",
      // padding:"9px 15px",
    }
  }

}))

const useClasses = makeStyles(() => ({
  editModeCategoryContainer: {
    border: '1px solid #DDDDDD !important',
    borderRadius: '10px',
    '& .fmr-12': {
      marginRight: 12,
    },
  },
}));

const MealCategoryContainer = (props) => {
  const { storedFoodRecipes, editorMode: isEditMode, handleDelete, handleChange, onSelectImportMeals, checkImportedMealGroup } = useContext(EditorContext);
  const {
    addFoodRecipes,
    meal,
    type,
    handleReorder,
    errors,
    mealCategories,
    index,
    isImport,
    groupsMacros,
  } = props;
  const { notes = '' } = meal || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAction, setActionState] = useState(false);
  const [openReorder, setReorderDialog] = useState(false);
  const [notesEnable, setNotesEnable] = useState(!!notes);
  const [isConfirmationOpen, showConfirmation] = useState(false);
  const classes = useClasses();
  const editorClasses = useEditorClasses();

  useEffect(()=>{
    setNotesEnable(!!notes)
  },[notes])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReorder = (list) => {
    let updatedFoodItems = Array.from(meal.food_items);
    updatedFoodItems = list.map((item) =>
      updatedFoodItems.find((foodItem) => {
        if (foodItem.type === 'alternative') {
          return item.ref.id === foodItem.alternatives[0].ref.id;
        }
        return item.ref.id === foodItem.ref.id;
      })
    );
    handleReorder({
      grpIndex: props.index,
      foodItems: updatedFoodItems,
    });
  };

  const foodItems =
    storedFoodRecipes.length &&
    meal.food_items &&
    meal.food_items.map((item, i) => {
      if (item.type === 'food') {
        const foodRecipeId = item.ref.id;
        const doc = _.find(storedFoodRecipes, (d) => foodRecipeId === d.id);
        return { ref: item.ref, name: doc.ref_name };
      } else {
        const foodRecipeId = item.alternatives[0].ref.id;
        const doc = _.find(storedFoodRecipes, (d) => foodRecipeId === d.id);
        let name = doc.ref_name;
        const length = item.alternatives.length;
        if (length > 2) {
          name = `${name} & ${length - 1} more`;
        } else if (length > 1) {
          const doc = _.find(
            storedFoodRecipes,
            (d) => !!item.alternatives[1].ref && item.alternatives[1].ref.id === d.id
          );
          name = `${name} or ${doc.ref_name}`;
        }
        return {
          ref: item.alternatives[0].ref,
          name,
          type: 'alternatives',
        };
      }
    });

  const mealType = _.find(mealCategories, (d) => d[0] === meal.type);

  if (!!errors && (errors.type || errors.food_item)) {
    const errorMsg = errors.type ? errors.type : errors.food_item;
    if (!!errorMsg)
      props.enqueueSnackbar(errorMsg, {
        variant: 'error',
      });
  }

  const ifTime = _.get(meal, 'iftime', '');
  const handleDeleteNotes = () => {
    let _out = {
      target: {
        dataset: {
          group: props.index,
        },
        id: 'notes',
        value: '',
      },
    };
    handleChange(_out);
    setNotesEnable(false);
    showConfirmation(false);
  };

  const _onDeleteNotes = () => {
    showConfirmation(true);
  };
  
  const groupCalories = !!groupsMacros[index] ? groupsMacros[index].calories : meal.calories;
  //Ui for import meal feature
  if (isImport && type === 'food') {
    return (
      <DetailPgStyling className="w-100">
        <Card elevation={0} square className="fp-20 bg-transparent">
          <CardActions
            className="d-flex justify-content-between align-items-center fpx-0 fpt-0 fpb-15"
          >
            <div className="flex-grow-1 d-flex align-items-center fp-0">
              <Typography variant="h5" className="fmr-10" component="div">
                {mealType && mealType[1].value}
              </Typography>
              {ifTime && (
               <div className="text-grey d-flex align-items-end font_13_500">
                <TimeIcon className="" /> &nbsp;
                <span className="text-grey font_13_500 ">
                  {moment(ifTime, ['HH.mm']).format('hh:mm a')}
                </span>  
                </div>
              )}
              {!notesEnable && <ClrdButton  onClick={()=>setNotesEnable(true)}>
                <Typography color="primary">
                + Add Notes
                </Typography>
              </ClrdButton>}
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {meal.calories > 0 && (
                <>
                  <LinearPfc data={groupsMacros[index]} />
                  <Calories calories={groupCalories} textClassName="font_16_600" className="ml-10" calClassName={`font_13_500`} />
                </>
              )}
              <Checkbox color="primary" className="fp-0 ml-10" onClick={()=>{onSelectImportMeals(meal)}}  />
            </div>
          </CardActions>
          {!!notes && (
              <div className="fmb-20">
                <Typography className="white-space-preline text-dark-grey" variant="body1">
                  {notes}
                </Typography>
              </div>
            )}
          <CardContent className="fp-0">
            <div className="position-relative">
              <FoodRecipesGroup
                addFoodRecipes={addFoodRecipes}
                data-group={props.index}
                meal={meal}
                errors={errors}
                enqueueSnackbar={props.enqueueSnackbar}
              />
            </div>
          </CardContent>
        </Card>
      </DetailPgStyling>
    );
  }

  if (isEditMode && type === 'food') {
    return (
      <DetailPgStyling >
        <Card className={clsx('fmb-20 fpl-30 fpx-20', classes.editModeCategoryContainer, editorClasses.root)}>
          <CardActions className="d-flex justify-content-between align-items-center fpx-0 fpy-0 ">
            <div className="flex-grow-1 d-flex align-items-center fp-0">
              <Typography variant="h5" className="lineht_normal"  component="div">
                {mealType && mealType[1].value}
              </Typography>
              {ifTime && (
               <div className="text-grey d-flex align-items-end font_13_500 ml-10">
                <TimeIcon className="" /> &nbsp;
                <span className="text-grey font_13_500 lineht_normal">
                  {moment(ifTime, ['HH.mm']).format('hh:mm a')}
                </span>  
                </div>
              )}
              {!notesEnable && (
                <ClrdButton  className="ml-10 action_btn"  onClick={() => setNotesEnable(true)}>
                  <Typography color="primary" className="font_15_600">Add Note</Typography>
                </ClrdButton>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {meal.calories > 0 && (
                < >
                  <LinearPfc data={groupsMacros[index]} />
                  <Calories calories={groupCalories} textClassName="font_16_600" className="ml-10" calClassName={`font_13_500`} />
                </>
              )}
              <ClrdButton
                className="ml-10 action_btn"        
                onClick={() => addFoodRecipes('food', 0)}
              >
                <Typography color="primary" className="font_15_600">
                  Add More
                </Typography>
              </ClrdButton>
              <IconButton
                onClick={(e) => {
                  handleClick(e);
                  setActionState(true);
                }}
                className="ml-10"
                component="div"
              >
                <MoreVert />
              </IconButton>
            </div>
          </CardActions>
          <CardContent className="fp-0">
            {notesEnable && (
              <div className="w-100  d-flex align-items-center mt-20">
                <TextField
                  name="notes"
                  fullWidth
                  multiline={true}
                  defaultValue={notes}
                  rows={2}
                  rowsMax={4}
                  variant="outlined"
                  placeholder="Add additional details here..."
                  key={`_notes${props['index']}_${notes}`}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      'data-group': props.index,
                    },
                  }}
                  onBlur={handleChange}
                />
                <IconButton className="fml-10" onClick={_onDeleteNotes}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            <div className={clsx("position-relative", !!_.get(meal,"food_items.length", 0 ) && "fmt-20")}>
              <FoodRecipesGroup
                addFoodRecipes={addFoodRecipes}
                data-group={props.index}
                meal={meal}
                errors={errors}
                enqueueSnackbar={props.enqueueSnackbar}
              />
            </div>
          </CardContent>
        </Card>
        {openAction && (
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            open
            onClose={(e) => {
              handleClose(e);
              setActionState(false);
            }}
          >
            {meal.food_items.length > 1 && (
              <MenuItem
                onClick={(e) => {
                  handleClose(e);
                  setActionState(false);
                  setReorderDialog(true);
                }}
              >
                Reorder
              </MenuItem>
            )}
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                setActionState(false);
                handleDelete({
                  type: 'group',
                  grpIndex: props.index,
                });
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        )}
        {isConfirmationOpen && (
          <Confirmation
            open
            handleClose={() => showConfirmation(false)}
            handleChange={handleDeleteNotes}
            handleCancel={() => showConfirmation(false)}
            title="Delete Note"
            msg="Do you want to delete the note?"
            confirmOption="Continue"
          />
        )}
        {openReorder && (
          <ReorderDialog
            open
            listData={foodItems}
            onClose={() => {
              setActionState(false);
              setReorderDialog(false);
            }}
            onSubmit={(list) => onReorder(list)}
            title="Reorder Food Items"
          />
        )}
      </DetailPgStyling>
    );
  } else {
    return (
      <Card square className={clsx('w-100 pb-0')} elevation={0}>
          <CardContent className="position-relative">
            <div className="d-flex align-items-end mb-20">
              <Typography className="font_16_600 lineht_normal" component="div">
                {mealType && mealType[1].value}
              </Typography>
              {ifTime && (
                  <div className="text-grey d-flex align-items-end font_13_500 ml-10">
                      <TimeIcon className="fmr-5" /> &nbsp;
                      <span className="text-grey font_13_500 lineht_normal ">
                      {moment(ifTime, ['HH.mm']).format('hh:mm a')}
                      </span>  
                  </div>
                )}
              <div className="flex-grow-1" />  
              {meal.calories > 0 && type === 'food' && (
                <LinearPfc className="" data={groupsMacros[index]} />
              )}
              {meal.calories > 0 && <Calories calories={groupCalories} className="ml-10" textClassName={`font_16_600`} calClassName="font_13_500" />}
            </div>
            {!!notes && (
              <div className="fmb-20">
                <Typography className="white-space-preline font_14_500 text-grey" >
                  {notes}
                </Typography>
              </div>
            )}
            {type === 'food' && (
              <FoodRecipesGroup meal={meal} errors={errors} data-group={props.index} addFoodRecipes={addFoodRecipes} />
            )}
          </CardContent>
          <Divider className="content-divider mt-25" />
           {/* {type === "food" && <Divider className="content-divider mt-35" /> }
           {type === "macros" && <Divider className="content-divider mt-25" /> } */}
        </Card>
    );
  }
};

export const FoodRecipesGroup = (props) => {
  const {
    addFoodRecipes,
    meal: { food_items,type:grpType },
    errors,
  } = props;

  const { storedFoodRecipes } = useContext(EditorContext);
  return (
    <div className="container-fluid fm-0 fp-0 position-relative">
      {storedFoodRecipes.length > 0 &&
        food_items &&
        food_items.map((item, i, thisArg) => {
          if (item.type === 'food') {
            const foodRecipeId = item.ref.id;
            const foodRecipeData = _.find(storedFoodRecipes, (d) => foodRecipeId === d.id);
            return (
              <FoodRecipe
                key={`_item${i}`}
                meal={item}
                error={errors && errors.food_items && errors.food_items[i]}
                foodRecipeData={foodRecipeData}
                itemIndex={i}
                data-group={props['data-group']}
                addFoodRecipes={addFoodRecipes}
                length={thisArg.length}
                grpType={grpType}
              />
            );
          } else {
            return item.alternatives.map((alternativeitem, altIndex) => {
              const foodRecipeId = alternativeitem.ref.id;
              const foodRecipeData = _.find(storedFoodRecipes, (d) => foodRecipeId === d.id);
              return (
                <FoodRecipe
                  altFoodRecipes={item.alternatives}
                  alternativeitem={true}
                  altLength={item.alternatives.length}
                  key={`_altItem${altIndex}${i}`}
                  meal={alternativeitem}
                  foodRecipeData={foodRecipeData}
                  altItemIndex={altIndex}
                  itemIndex={i}
                  error={
                    errors &&
                    errors.food_items &&
                    errors.food_items[i] &&
                    errors.food_items[i].alternatives &&
                    errors.food_items[i].alternatives[altIndex]
                  }
                  data-group={props['data-group']}
                  addFoodRecipes={addFoodRecipes}
                  length={thisArg.length}
                  grpType={grpType}
                />
              );
            });
          }
        })}
    </div>
  );
};

export default withSnackbar(MealCategoryContainer);
