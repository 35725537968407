import { spawn, takeEvery } from "redux-saga/effects";
import builder from "./base";
// import { uploadFile } from './file';
import watchFileUpload from "./file";
import fileUploadActionBuilder from 'fitbud/redux/fileUpload/actions';

const watchExercises = cid => builder(cid, "exercises", null, "exercises");
const watchWorkouts = cid => builder(cid, "workouts", ["workout"], "workouts");
const watchCardio = cid => builder(cid, "workouts", ["cardio"], "cardio");
const watchWOCardio = cid => builder(cid, "workouts", null, "wocardio");
const watchSchedule = cid => builder(cid, "schedules", null, "schedules");
const watchFoodRecipes = cid => builder(cid, "foodRecipes", ["food", "recipe", "fdc"], "foodRecipes");
const watchFoods = cid => builder(cid, "foodRecipes", ["food",  "fdc"], "foods");
const watchRecipes = cid => builder(cid, "foodRecipes", ["recipe"], "recipes");
const watchFoodRecipesSupps = cid => builder(cid, "foodRecipes", ["food", "recipe", "fdc"], "foodRecipesSupps");
const watchSupps = cid => builder(cid, "foodRecipes", ["supplement"], "supps");
const watchMeals = cid => builder(cid, "meals", null, "meals");
const watchFoodMeals = cid => builder(cid, "meals", ["food"], "foodMeals");
const watchDailySupplements = cid => builder(cid, "supplements", null, "supplements");
const watchChatList = cid => builder(cid, "user_profiles", null, "chatList");
const watchBrowseUsersList = cid => builder(cid, "user_profiles", null, "browseUsersList");
const watchCheckinsList = cid => builder(cid, "user_profiles", null, "checkinsList");
const watchPendingSignInUsersList = cid => builder(cid, "user_profiles", null, "pendingSignInList");
const watchPlansToAssignUsersList = cid => builder(cid, "user_profiles", null, "plansToAssignList");
const watchActiveUsersList = cid => builder(cid, "user_profiles", null, "activeUsersList");
const watchInactiveUsersList = cid => builder(cid, "user_profiles", null, "inactiveUsersList");
const watchLeads = cid => builder(cid, "user_profiles", null, "leadsList");
const watchPlans = cid => builder(cid, "packs", null, "plans");
const watchMlSchedule = cid => builder(cid, "mlSchedules", null, "mlSchedules");
const watchOnboardedUsersList = cid => builder(cid, "user_profiles", null, "onboardedUsersList");
const fileUploadActions = fileUploadActionBuilder();

export default function* rootSaga(cid) {
  yield spawn(watchExercises(cid));
  yield spawn(watchWorkouts(cid));
  yield spawn(watchCardio(cid));
  yield spawn(watchWOCardio(cid));
  yield spawn(watchFoodRecipes(cid));
  yield spawn(watchFoods(cid));
  yield spawn(watchRecipes(cid));
  yield spawn(watchFoodRecipesSupps(cid));
  yield spawn(watchSupps(cid));
  yield spawn(watchMeals(cid));
  yield spawn(watchFoodMeals(cid));
  yield spawn(watchDailySupplements(cid));
  yield spawn(watchSchedule(cid));
  yield spawn(watchChatList(cid));
  yield spawn(watchBrowseUsersList(cid));
  yield spawn(watchCheckinsList(cid));
  yield spawn(watchPendingSignInUsersList(cid));
  yield spawn(watchPlansToAssignUsersList(cid));
  yield spawn(watchActiveUsersList(cid));
  yield spawn(watchInactiveUsersList(cid));
  yield spawn(watchLeads(cid));
  yield spawn(watchOnboardedUsersList(cid));
  yield spawn(watchPlans(cid));
  yield spawn(watchMlSchedule(cid))
  yield spawn(watchFileUpload)
  // yield takeEvery(fileUploadActions.uploadFile, uploadFile);
}
