import React, { useContext, useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import {
  FLibIcon,
  CustomLibIcon,
  FitbuddCustomIcon,
  InstructionIcon,
  HighIntensityIcon,
  LowIntensityIcon,
  ModerateIntensityIcon,
  WorkoutInstructionIcon,
  WorkoutVideoInstruction,
  OdIcon,
  SupplementLinkIcon,
  MealMacroBasedIcon,
  MealFoodBasedIcon,
  MealBmrBasedIcon
} from 'fitbud/icons/catalogSecondLineIcons';
import { durationInTypes } from 'fitbud/views/schedulePlanner/helper';
import { roundNumber, DUMB_UNITS, GMML_SERVINGS } from 'fitbud/utils/helpers';
import get from 'lodash/get';
import { TagsContext } from 'fitbud/providers/tagsProvider';
import find from 'lodash/find';
import { exCategoryHelper,pluralize } from "fitbud/utils/helpers";
import { Conversion } from 'fitbud/providers/conversion';
import {durationToHMString} from "fitbud/views/workouts/helperfn";
import clsx from "clsx";
import { secondsToDuration } from '../durationInput';
import {getDisplayValue,getPreferredUnit} from "fitbud/views/foodRecipes/newUi/inputDropdown";

//serving values and size are missing
const SupplementSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { dtype, has_link, serving, serving_size } = data || {};
  const { fetchTags } = useContext(TagsContext);
  const renderComponents = [];
  if (!!serving) {
    const servingData = fetchTags('serving');
    const _serving = find(servingData, (d) => d[0] === serving);
    if (!!servingData && !!_serving) {
      renderComponents.push(
        <span>
          <b>1</b>&nbsp;<span className="text-capitalize">{_serving[1].value}</span>
        </span>
      );
    }
  }

  if (!!has_link) {
    renderComponents.push(
      <Tooltip title="Includes a related weblink">
        <SupplementLinkIcon />
      </Tooltip>
    );
  }
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

//resources second line:
const ResourcesSecondLine = (props) =>{
  const { doc } = props;
  const { data } = doc || {};
  const { dtype } = data || {};
  const renderComponents = [];
  if (dtype) {
    renderComponents.push(<span className="toUpperCase">{dtype}</span>);
  }
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
}
const GroupClassSecondLine = (props) =>{
  const { doc } = props;
  const { data } = doc || {};
  const { mode, duration, status } = data || {};
  const active = status === "inactive" ? false : true;
  const renderComponents = [];
  renderComponents.push(<span className={clsx("text-capitalize font_13_500", active ? "text-27AE60":"text-grey" )}>{active ? "Active":"Inactive"}</span>)
  if (mode) {
    renderComponents.push(<span className="text-capitalize font_13_500 font_13_500 text-grey">{mode}</span>);
  }
  if (duration) {
    renderComponents.push(<span className="font_13_500 text-grey"> <span className="font_13_600">{duration}</span> mins</span>);
  }
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
}


//On demand missing
const SupplementPlanSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { num_groups } = data || {};
  const renderComponents=[];
  if(num_groups){
    renderComponents.push(
      <span>
        <b>{num_groups}</b>&nbsp;Supplement{pluralize(num_groups)}
      </span>
    );
  }
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

//On demand is missing,
const MealSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { dtype, macros } = data || {};
  const calories = get(macros, 'calories',0);
  const renderComponents = [];
  // if (calories) {
    renderComponents.push(
      <span>
        <b>{roundNumber(calories||0, 0).toLocaleString()}</b>&nbsp;Cals
      </span>
    );
  // }
  if (dtype) {
    switch(dtype){
      case 'macros':{
        renderComponents.push(
        <Tooltip title="Macro Based Plan">
          <MealMacroBasedIcon/>
        </Tooltip>
        )
        break;
      }
      case 'food':{
        renderComponents.push(
          <Tooltip title="Food Based Plan">
            <MealFoodBasedIcon/>
          </Tooltip>
        )
        break;
      }
      default:{
        //bmr-based
        renderComponents.push(
          <Tooltip title="BMR Based Plan">
            <MealBmrBasedIcon/>
          </Tooltip>
        )
        break;
      }
    }
  };
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

const CustomItem = () => (
  <Tooltip title="Custom created by Trainer">
    <CustomLibIcon />
  </Tooltip>
);
const FitbuddItem = () => (
  <Tooltip title="Sourced from FitBudd library">
    <FLibIcon />
  </Tooltip>
);
const FitbuddCustom = ({type}) => (
  <Tooltip title={`FitBudd ${type} customized by Trainer`}>
    <FitbuddCustomIcon />
  </Tooltip>
);

const SourceIcon = (props) => {
  const { source, type } = props;
  if (!source) return null;
  if (source === 'custom') return <CustomItem />;
  else if (source === 'fitbudd') return <FitbuddItem />;
  else if (source === 'fitbudd_custom') return <FitbuddCustom type={type} />;
  else return null;
};



const FoodSecondaryLine = (props) =>{
  const { unitFactors } = useContext(Conversion);
  const { fetchTags } = useContext(TagsContext);
  const {doc} = props;
  const { data } = doc || {};
  const {serving,macros={},serving_size=0,source}=data;
  const preferred_unit=getPreferredUnit(data);
  const getServingName = (serving) => {
    if (!serving) return null;
    const servingData = fetchTags('serving');
    const _serving = find(servingData, (d) => d[0] === serving);
    if (!!_serving) return _serving[1].value;
  };
  const isDumbUnit=DUMB_UNITS.includes(serving);
  const renderComponents = [];
  if(serving){
    if(isDumbUnit){
      const servingSize=getDisplayValue(unitFactors,serving_size,preferred_unit);
      {!!servingSize && renderComponents.push(<span>{roundNumber(servingSize,2)} {getServingName(preferred_unit)}</span>)}
      renderComponents.push(<span>{roundNumber(macros.calories||0,0)} cals</span>)
    }
    else{
      const servingSize=getDisplayValue(unitFactors,serving_size,preferred_unit);
      renderComponents.push(<span>1 {getServingName(serving)}</span>)
      {!!servingSize && renderComponents.push(<span>{roundNumber(servingSize,2)} {getServingName(preferred_unit)}</span>)}
      renderComponents.push(<span>{roundNumber(macros.calories,0)} cals</span>)
    }
  }
  
  if (source === "fitbudd" || source === "fitbudd_custom") {
    renderComponents.push(<SourceIcon type="food item" source={source} />);
  }
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
}

const RecipeSecondaryLine = (props) =>{
  const { fetchTags } = useContext(TagsContext);
  const {doc} = props;
  const { data } = doc || {};
  const getServingType = (serving) => {
    if (!serving) return null;
    const servingData = fetchTags('serving');
    const _serving = find(servingData, (d) => d[0] === serving);
    if (!!_serving) return _serving[1].value;
  };
  const _servingType = getServingType(get(data, 'serving'));
  const _calories = get(data, 'macros.calories',0);
  const renderComponents = [];
  if(!!_servingType ){
    renderComponents.push(<span>1 {_servingType}</span>)
  }
  // if(!!_calories){
  renderComponents.push(<span>{roundNumber(_calories, 0).toLocaleString()} cals</span>)
  // }
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
}

//source is missing
const FoodRecipeSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { dtype, source } = data || {};
  const _calories = get(data, 'macros.calories',0);
  const renderComponents = [];
  if (dtype) renderComponents.push(<span className="text-capitalize">{dtype === 'fdc' ? 'Food' : dtype}</span>);
  // if(!!_calories){
    renderComponents.push(<span>{roundNumber(_calories, 0).toLocaleString()} cals</span>)
  //}
  if (source === "fitbudd" || source === "fitbudd_custom") {
    renderComponents.push(<SourceIcon type="food item" source={source} />);
  }
  //if source is not available and it is recipes then it will always custom
  // if (!source && dtype === 'recipe') {
  //   renderComponents.push(<CustomItem />);
  // }
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

//On Demands are missing
const ScheduleSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { duration, durationType, weekday_align } = data || {};
  if (!duration || !durationType) return null;
  const calculatedDuration = durationInTypes(duration, durationType);
  const renderComponents = [];
  const _durationType = calculatedDuration === 1 ? (durationType || '').replace('s', '') : durationType;
  const repeating = (duration % 7 === 0) && !!weekday_align;
  renderComponents.push(
    <span>
      {calculatedDuration}&nbsp;<span className="text-capitalize">{_durationType}</span>&nbsp;{repeating ? '• Repeating' : ''}
    </span>
  );
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

const CardioSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { sub_type, duration } = data || {};
  const [ hour, min ] = secondsToDuration(duration || 0).split(" : ").map(i=>Number(i));

  const renderComponents = [];
  if (!!sub_type)
    renderComponents.push(
      <span className="text-capitalize">{sub_type === 'liss' ? 'Low Intensity' : 'High Intensity'}</span>
    );
  if (!!min || !!hour)
    renderComponents.push(
      <span>
        <span>{hour ? `${hour} hr` : ''} {min ? `${min} min` : ''}</span>
      </span>
    );
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

//duration is missing
const WorkoutSecondaryLine = (props, getTagName = undefined) => {
  const { doc } = props;
  const { data } = doc || {};
  const { sub_type = 'workout', duration, intensity, is_single_video } = data || {};

  const getIntensityIcon = () => {
    if (!intensity) return '';
    return (
      <span>
        {intensity === 'high' && (
          <Tooltip title="High intensity">
            <HighIntensityIcon />
          </Tooltip>
        )}
        {intensity === 'moderate' && (
          <Tooltip title="Medium intensity">
            <ModerateIntensityIcon />
          </Tooltip>
        )}
        {intensity === 'low' && (
          <Tooltip title="Low intensity">
            <LowIntensityIcon />
          </Tooltip>
        )}
      </span>
    );
  };
  const getFollowAlongIcon = () => {
    return is_single_video ? (
      <Tooltip title="Includes Follow Along Video">
        <WorkoutVideoInstruction />
      </Tooltip>
    ) : (
      <Tooltip title="Structured Workout">
        <WorkoutInstructionIcon />
      </Tooltip>
    );
  };

  const renderComponents = [];
  if (sub_type)
    renderComponents.push(<span className="text-capitalize"> {getTagName ? getTagName('woSubTypes', sub_type, sub_type) : sub_type}</span>);
  if(duration)
    renderComponents.push(<span>{durationToHMString(duration,true)}</span>);

  // if (intensity) renderComponents.push(getIntensityIcon());
  renderComponents.push(getFollowAlongIcon());
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

//missing subType and itIs Source  custom or not::
const ExerciseSecondaryLine = (props) => {
  const { doc } = props;
  const { data } = doc || {};
  const { category, has_instructions, source,steps=[] } = data || {};
  const renderComponents = [];
  if (category && category!=="none") {
    renderComponents.push(<span className="text-capitalize"> {exCategoryHelper.typeToText(category)}</span>);
  }
  if (source === "fitbudd" || source === "fitbudd_custom") {
    renderComponents.push(<SourceIcon type="exercise" source={source} />);
  }
  if (!!has_instructions || !!steps.length) {
    renderComponents.push(
      <Tooltip title="Includes instructions to perform">
        <InstructionIcon />
      </Tooltip>
    );
  }
  if(!renderComponents.length) return null;
  return (
    <>
      {renderComponents.map((c, index) => {
        return (
          <>
            {index !== 0 && <span className="mx-4px">•</span>}
            {c}
          </>
        );
      })}
    </>
  );
};

const getSecondaryTextListItems = (arg, getTagName) => {
  const { keyName } = arg || {};
  switch(keyName){
    case "exercises":return ExerciseSecondaryLine(arg);
    case "workouts":
    case "wocardio":return WorkoutSecondaryLine(arg, getTagName);
    case "cardio":return CardioSecondaryLine(arg);
    case "schedules":
    case "mlSchedules":return ScheduleSecondaryLine(arg);
    case "foodRecipesSupps":return FoodRecipeSecondaryLine(arg);
    case "foods":return FoodSecondaryLine(arg);
    case "recipes":return RecipeSecondaryLine(arg);
    case "meals":return MealSecondaryLine(arg);
    case "supps":return SupplementSecondaryLine(arg);
    case "supplements":return SupplementPlanSecondaryLine(arg);
    case "exercise-selector":return ExerciseSelectorSecondaryLine(arg);
    case "foodRecipe-selector":return FoodRecipesSelectorSecondLine(arg);
    case "foodRecipes":return FoodRecipesSelectorSecondLine(arg);//explore Nutrition case
    case "resources": return ResourcesSecondLine(arg);
    case "groupClass" : return GroupClassSecondLine(arg);
    default:return '-'
  }
};
export const getExRepTypeSecndryText = (doc) => {
  const exType = get(doc, 'data.extype');
  if (exType === 'reps') {
    return 'Reps';
  } else if (exType === 'reps_w_weight') {
    return 'Reps + Weight';
  } else if (exType === 'duration') {
    return 'Duration';
  }
};

export const ExerciseSelectorSecondaryLine=({doc})=>{
  const exType = get(doc, 'data.extype');
  if (exType === 'reps') {
    return 'Reps';
  } else if (exType === 'reps_w_weight') {
    return 'Reps + Weight';
  } else if (exType === 'duration') {
    return 'Duration';
  }
}

export const FoodRecipesSelectorSecondLine = ({ doc }) => {
  const { fetchTags } = useContext(TagsContext);
  const { data } = doc || {};
  const getServingType = (serving) => {
    if (!serving) return null;
    const servingData = fetchTags('serving');
    const _serving = find(servingData, (d) => d[0] === serving);
    if (!!_serving) return _serving[1].value;
  };

  const _servingType = getServingType(get(data, 'serving'));
  const _servingSize = get(data, 'serving_size');
  const _servingUnit = get(data, 'serving_unit');
  const _calories = get(data, 'macros.calories',0);
  let _secondLine = '';
  if (_servingType && _servingSize && _servingUnit) {
    _secondLine = `1 ${_servingType} (${roundNumber(_servingSize, 2)} ${_servingUnit}) `;
  }

  if (_secondLine) {
    _secondLine = _secondLine + ' • ';
  }

  // if (_calories) {
    _secondLine = _secondLine + `${roundNumber(_calories, 0).toLocaleString()} cals`;
  // }

  return <span>{_secondLine}</span>;
};

export default getSecondaryTextListItems;
