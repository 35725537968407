import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import Dialog from 'fitbud/components/Dialog';
import Confirmation from 'fitbud/components/confirmationDialog';
import ExerciseSearchList from 'fitbud/components/ExerciseSearchList';
import { getExercise } from "fitbud/api";
import appRdxFns from "fitbud/redux/app";
import ArrowIcon from '@material-ui/icons/ArrowForward';

export default function ExerciseSearch(props) {
  const { 
    open,
    setEdcData,
    handleClose,
    handleType
  } = props;
  const history = useHistory();
  const d = useDispatch();
  const { cid } = useContext(FirebaseAuthContext);
  const { showLoader, hideLoader } = appRdxFns(d);
  const [exerciseData, setExerciseData] = useState(null);
  const [existsFdcEx, setExistsFdcEx] = useState(null);
  const clearExistsFdcEx = () => setExistsFdcEx(null);
  const selectFdcEx = () => {
    handleClose();
    history.replace(`/fitness/exercises/${existsFdcEx}`);
  };
  const createNewCopyOfFdcEx = () => {
    setEdcData(exerciseData);
    setExerciseData(null);
    setExistsFdcEx(null);
    handleType();
  };

  const handleAdd = async edcItem => {
    showLoader();
    const exercise = edcItem.data;
    const { success, data: exerciseData, copy } = await getExercise(exercise._id, cid);
    if (!success) return; // FIXME should be handled
    hideLoader();
    if (copy && copy.id) {
      setExistsFdcEx(copy.id);
      setExerciseData({...exerciseData,source:"fitbudd",isDbUsed:true});
    } else {
      setEdcData({...exerciseData,source:"fitbudd", isDbUsed:true});
      handleType();
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  
  return (<>
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      
      open={open}
      onClose={() => {
        handleClose();
      }}
      title={`Select Exercise `}
      titleFont="h3"
      paperClass={isMobile ? "width-600" : "width-600 height-70"}
      dialogContentClassName="d-flex flex-column"
      hideHeaderDivider={true}
    >
      <ExerciseSearchList
        onClick={doc => handleAdd({ data: doc })} 
        ListItemSecondaryAction={()=>
          <ArrowIcon/>
        }
        />
    </Dialog>
    {!!existsFdcEx && <Confirmation open
      handleClose={clearExistsFdcEx}
      showCloseIcon={true}
      title={'Copy Already Exists'}
      msg={'A copy of this exercise already exists in your own library'}
      cancelOption={'Show Existing'}
      handleCancel={selectFdcEx}
      confirmOption="Create New Copy"
      handleChange={createNewCopyOfFdcEx}/>}
  </>);
}
