import React, { useContext, useMemo } from "react";
import { Typography, IconButton, Card, CardHeader, CardContent, Grid, Chip,Divider } from "@material-ui/core";
import { durationInTypes } from "fitbud/views/schedulePlanner/helper";
import CopyAction from "fitbud/components/copyAction";
import DeleteAction from "fitbud/components/deleteAction";
import { RoleContext } from "fitbud/providers/roleProvider";
import { Avatar } from "fitbud/components/avatar";
import DetailEditIcon from "fitbud/icons/detailEdit";
import { DetailPgStyling } from "fitbud/hooks/useDetailPgStyle";
import { MoreLessTextBtn } from "fitbud/components/moreLessText";
import clsx from 'clsx';
import _ from 'lodash';
import {GetDetailSecLine} from "fitbud/components/catalog/detailSecondLine";
import {DraftBanner} from "fitbud/components/draftBanner";
import {DRAFT} from "fitbud/utils/constants";
import addIcon from "fitbud/images/plus.svg";
import {EditorPlaceholder} from "fitbud/views/exercises/editorPlaceholder";
import {durationToHMString} from "fitbud/views/workouts/helperfn";

export const ScheduleDetailHeader = ({
  doc = {},
  copyItem,
  deleteItem,
  handleEditMode,
  handleEditSchedule,
  disableActions,
  scheduleType,
  draftBannerProps={},
  isPreview=false,
  isScheduleEmpty=false
}) => {
  if(!doc) doc={};
  const { duration, weekday_align, durationType } = doc;
  const isDraft=draftBannerProps.showingDoc===DRAFT;
  const calculatedDuration = durationInTypes(doc.duration, doc.durationType);
  const wo_per_week = doc.min_wo_week === doc.max_wo_week ? doc.min_wo_week : 
    `${doc.min_wo_week ? doc.min_wo_week + " -" : ''} ${doc.max_wo_week}`;
  const wo_duration=doc.min_wo_duration === doc.max_wo_duration? durationToHMString(doc.min_wo_duration)
    :(durationToHMString(doc.min_wo_duration) - durationToHMString(doc.max_wo_duration))
  const ml_calories = useMemo(()=>{
    if(!doc.min_ml_calories) return null; //for the previous created ml schedules
    return  doc.min_ml_calories === doc.max_ml_calories ? Math.round(doc.min_ml_calories) : 
    `${doc.min_ml_calories ? (Math.round(doc.min_ml_calories) + " -" ): ''} ${Math.round(doc.max_ml_calories )}`;
  }, [doc])
  const { catalogAdmin } = useContext(RoleContext);
  const _description=_.get(doc,'desc','').trim();
  const secondLine=GetDetailSecLine(`${scheduleType}-schedule`,doc);
  const repeating = duration % 7 === 0 && weekday_align === 2;
  const _durationType = calculatedDuration === 1 ? (durationType || '').replace('s', '') : durationType;

  if (!doc) return null;
  return (
    <DetailPgStyling>
      {draftBannerProps.isDraftAvailable && !isPreview && <DraftBanner doc={doc} {...draftBannerProps}/>}
      <Card square elevation={0} >
        <CardHeader
          className="align-items-start"
          title={doc.title || doc.ref_name}
          subheader={secondLine}
          avatar={
            <Avatar
              src={doc.thumbnail}
              name={doc.title || doc.ref_name}
              alt={doc.title || " "}
              variant='rounded'
            />
          }
          action={
            isPreview?null:(
            <>
              <IconButton onClick={handleEditMode}>
                <DetailEditIcon />
              </IconButton>
              {!isDraft && <CopyAction title="Schedule" disabled={disableActions} onCopy={copyItem} />}
              {catalogAdmin && !isDraft &&
                <DeleteAction title="Schedule" disabled={disableActions} onDelete={deleteItem} />
              }
            </>
            )
          }
          classes={{ root: "main-header-root", title: "main-title" }}
        /> 
        <CardContent>
          {_description && <MoreLessTextBtn text={_description} className="desc fmb-25" btnClassName="viewMore"/>}
          <Grid container>
            {/* Duration */}
            <Grid item xs={4}>
              <Typography className={clsx("fmb-10",scheduleType==='meal'?"field-nutrition-title":"field-title")}>Duration</Typography>
              <Typography className="field-value" display="inline"> {calculatedDuration} </Typography>
              <Typography display="inline">{_durationType} {repeating ? '• Repeating' : ''}</Typography>
            </Grid>
            {/* Wo / Week */}
            {scheduleType === 'workout' && !!wo_per_week &&
              <Grid item xs={4}>
                <Typography className="fmb-10 field-title">Workouts / Week</Typography>
                <Typography className="field-value">{wo_per_week} workout{wo_per_week > 1 && 's' }</Typography>
              </Grid>
            }
            {/* Duration / Wo */}
            {scheduleType === 'workout' && !!wo_duration &&
              <Grid item xs={4}>
                <Typography className="fmb-10 field-title">Duration / Workouts</Typography>
                <Typography className="field-value">{wo_duration}</Typography>
              </Grid>
            }
            {/* calories per meal /Ml schedules */}
            {scheduleType === 'meal' && !!ml_calories &&
              <Grid item xs={4}>
                <Typography className="fmb-10 field-nutrition-title" >Calories / Day</Typography>
                <Typography className="field-value">{ml_calories} cals</Typography>
              </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
      {isScheduleEmpty && <>
        <Divider className="dense" />
        <Card square elevation={0}>
          <CardHeader
            title="Schedule"
            action={
              <IconButton onClick={handleEditSchedule}>
                <img src={addIcon} alt="" />
              </IconButton>
            }
            classes={{
              root: "sub-header-root",
              title: "sub-title",
              action: "margin-top-negative",
            }}
          />
          <CardContent>
            <EditorPlaceholder keyName="schedule-editor-placeholder"/>
          </CardContent>
        </Card>
      </>}
    </DetailPgStyling>
  );
};
