import React, { useEffect } from 'react';
import {
  Typography, Tooltip, Button,
  Select, MenuItem, FormControlLabel, RadioGroup, Radio, Divider
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Dialog from 'fitbud/components/Dialog';
import { useToggle, usePicker } from 'fitbud/hooks/form';

const CLR_DIS = {color: '#90A4AE'};

const Pill = ({weekId, week, day, data, l2, controller}) => {
  const [opts, toggleOpts, setOpts] = useToggle();
  const [masterType, setMasterType] = usePicker(data.wom ? 'wom' : 'mlm');
  const {wb, mb} = data;
  const {name, id, s, d, w, dow, len} = data[masterType] || {};
  const [mWeek, setMWeek] = usePicker(w);
  const [mDOW, setMDOW] = usePicker(dow);
  const ml = masterType === 'mlm';
  const hasBoth = data['wom'] && data['mlm'];
  const overriden = (!ml && Boolean(wb)) || (ml && Boolean(mb));
  useEffect(() => {
    setMWeek(w);
    setMDOW(dow);
  }, [opts, masterType]); // eslint-disable-line react-hooks/exhaustive-deps
  if ((week * 7 + day) < s) return null;
  if (d && (week * 7 + day) >= (s + d)) return null;
  if (7 * (w - 1) + dow > len) return null;
  const mwks = Math.ceil(len / 7);
  const ndslast = len % 7 || 7;
  const restore = () => {
    controller.removeOverride(weekId, week, day, ml)
    setOpts(false);
  };
  const unlink = () => {
    setOpts(false);
    controller.changeMasters(ml ? 'ml' : 'wo', week, null, day);
    setMasterType(curr => curr === 'wom' ? 'mlm' : 'wom');
  };
  const applyOffset = () => {
    const newMaster = {id, o: 7 * (mWeek - 1) + mDOW - 1}; // NOTE d is being ignored
    if (ml) newMaster.mode = 'per_day';
    controller.changeMasters(ml ? 'ml' : 'wo', week, newMaster, day);
    setOpts(false);
  };
  if(!data[masterType]) return null;
  return (
    <>
    <Tooltip title='Master Schedule. Click to know more'>
      <div className='ml-10 d-flex flex-row align-items-center flex-shrink-1 px-2 py-1 cursor-pointer'
        onClick={toggleOpts} style={overriden ? CLR_DIS: {}}>
        {overriden ? <LinkOffIcon color='inherit'/> : <LinkIcon color='inherit'/>}
      </div>
    </Tooltip>
    {opts && <Dialog open onClose={toggleOpts} title='Linked Schedule'>
      <div className='p-20'>
        {hasBoth && <RadioGroup value={masterType} onChange={setMasterType} className='d-flex flex-row fmtn-5 fmb-15'>
          <FormControlLabel value='wom' control={<Radio color='primary' />} label='Workout' className='my-0 lineht_1_2'  classes={{ label : masterType==="wom" ? "font_16_600" : 'font_16_500'}}/>
          <FormControlLabel value='mlm' control={<Radio color='primary' />} label='Nutrition' className='my-0 lineht_1_2'  classes={{ label : masterType==="mlm" ? "font_16_600" : 'font_16_500'}}/>
        </RadioGroup>
        }
        <div className='p-15 bg-offWhite'>
            <div className='d-flex align-items-center' >
              <div className='d-flex flex-column flex-grow-1'>
                <span className='font_17_600 lineht_1_2 fmb-8'>{l2}</span>
                <span className='font_14_500 lineht_1_428'>Client's W{week + 1} D{day + 1} </span>
              </div>
              <div className='fmx-30'>
                {overriden ? <LinkOffIcon color='inherit' /> : <LinkIcon color='inherit' />}
              </div>
              <div className='d-flex flex-column flex-grow-1'>
                <span className='font_17_600 lineht_1_2 fmb-8'>Week {w} Day {dow}</span>
                <span className='font_14_500 lineht_1_428'>of {name}</span>
              </div>
            </div>
        </div>
        <Typography className='font_17_600 pt-25 mb-15 lineht_1_2'>
          {overriden ? 'Restore Link' : `Link a different day of`} { `${ml ? 'nutrition': 'workout'} schedule`}
        </Typography>
        {!overriden && <Typography className='mb-20 font_14_500 lineht_1_428'>
          Select week & day to use for linking client’s schedule from this day onwards.
        </Typography>}
        {!overriden && <div className='d-flex flex-column'>
          <div className='mb-20 d-flex align-items-center'>
          <Select variant='outlined' value={mWeek} className='mr-20' onChange={setMWeek} classes={{root: 'medium font_15_500', select: 'select-medium'}}>
            {Array(mwks).fill().map((x, i) => <MenuItem key={i} value={i + 1}>Week {i + 1}</MenuItem>)}
          </Select>
          <Select variant='outlined' value={mDOW} className='mr-20' onChange={setMDOW} classes={{root: 'medium font_15_500', select: 'select-medium'}}>
            {Array(mWeek === mwks ? ndslast : 7).fill().map((x, i) => <MenuItem key={i} value={i + 1}>Day {i + 1}</MenuItem>)}
          </Select>
          <Button color='primary' variant='contained' className='font_15_700 py-15 fpx-20' onClick={applyOffset}>Apply</Button>
          </div>
          <Divider />
          <div className='flex-grow-1'>
            <Typography className='font_17_600 pt-25 mb-15 lineht_1_2'>
              {'Remove Linked'} {`${ml ? 'nutrition' : 'workout'} schedule`}
            </Typography>
            <Typography className='font_14_500 lineht_1_428 mb-20'>
                Unlinking will remove the linked workouts from this day onwards.
            </Typography>
            <Button color='primary' className='bg-lightPrimary font_15_600 py-15 fpx-20' onClick={unlink}>Unlink</Button>
          </div>
        </div>}
        {overriden && <Typography className='mb-15 font_14_500 lineht_1_428'>
          {`Restoring will remove any modifications to this day and add the ${ml ? 'meals': 'workouts'} from linked schedule to this day.`}
        </Typography>}
        {overriden && <div className='d-flex flex-row'>
          <Button color='primary' variant='contained' className='font_15_700 py-15 fpx-20' onClick={restore}>Restore</Button>
        </div>}
      </div>
    </Dialog>}
    </>
  );
};

const MasterPill = ({week, weekId, day, dayToday, data, l2,  controller}) => {
  if (!data) return null;
  if(!data.wom && !data.mlm) return null;
  if (week * 7 + day < dayToday) return null;
  return (
    <Pill weekId={weekId} week={week} day={day} data={data} l2={l2} controller={controller}/>
  );
};

export default MasterPill;
