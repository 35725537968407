import React, { useContext } from 'react';
import { IconButton, CardHeader } from '@material-ui/core';
import { Avatar } from 'fitbud/components/avatar';
import { RoleContext } from 'fitbud/providers/roleProvider';
import _ from 'lodash';
import DetailEditIcon from 'fitbud/icons/detailEdit';
import CopyAction from 'fitbud/components/copyAction';
import DeleteAction from 'fitbud/components/deleteAction';
import {GetDetailSecLine} from "fitbud/components/catalog/detailSecondLine";

const FoodHeader = (props) => {
  const { doc, handleEditMode, onCopy, onDelete, disabled, isPreview = false,keyName, disableActions, isDraft } = props;
  const { catalogAdmin } = useContext(RoleContext);
  const secondLine=GetDetailSecLine(keyName,doc);
  const _description = _.get(doc,"description",'').trim();
  const confirmationTitle=_.get(doc,'type','')==='fdc'||_.get(doc,'type','')==='food'?"food item":doc.type;
  return (
      <CardHeader
        title={doc.title}
        subheader={secondLine}
        avatar={
          <Avatar
          src={doc.thumbnail}
          name={doc.title}
          size="large"
          alt={doc.title || ' '} 
          />}
        action={
         isPreview ? null : (
          <>
            <IconButton onClick={handleEditMode}>
              <DetailEditIcon/>
            </IconButton>
            {!disableActions && !isDraft && (
              <>
                <CopyAction
                  title={
                    keyName === "supplement-plans"
                      ? "Supplement Plan"
                      : _.capitalize(confirmationTitle)
                  }
                  onCopy={onCopy}
                  isDisabled={disabled}
                />
                {catalogAdmin && !isDraft && (
                  <DeleteAction
                    title={_.capitalize(confirmationTitle)}
                    onDelete={onDelete}
                    disabled={disabled}
                  />
                )}
              </>
            )}
          </>
        )
      }
      classes={{ 
        root: (['recipes','food','supplements'].includes(keyName)||_description)?'main-header-root':"mb-0",
        title: 'main-title',
        subheader:!secondLine && "d-none"}}
    />
  );
};

export default FoodHeader;
