import {
  LinearProgress,
  Typography,
  Switch,
  IconButton,
  CircularProgress,
  // FormControlLabel,
  // Checkbox,
  Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { useBillingContext } from 'fitbud/providers/billing-provider';
import { ContractContext } from 'fitbud/providers/contract';
import _ from 'lodash';
import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { AddOn, SelectedCard, PromoCode, ActiveAddOn } from 'fitbud/views/contract/components';
import {
  applyPromoCode,
  bffContractDueAmount,
  createSetupIntent,
  bffUpdateHubspot,
  bffUpdateHubspotProp,
} from 'fitbud/api';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { ACL_CONFIGURATION, ACL_FEATURES_KEY, PLANS, useAcl } from 'fitbud/providers/acl-provider';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR, HUBSPOT_PROPS } from 'fitbud/utils/constants';
import { usePlanFlowStyle } from 'fitbud/views/contract/usePlanFlowStyle';
import { roundCurrency } from 'fitbud/utils/helpers';
import SuccessDialog from './success-dialog';
import appRdxFns from 'fitbud/redux/app';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ClrdButton } from '../form-fields';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

//SelectedCard : USE OLD COMPONENTS...
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const DEFAULT_PROMO_CODE = {
  msg: '',
  success: false,
};

const PAYMENT_STATUS = {
  Initiated: 'Initiated',
  Successful: 'Successful',
  Failed: 'Failed',
};

const Checkout = () => {
  const { intent, exitCheckout, closePricingOptions } = useBillingContext();
  const { activePlan } = useAcl();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const isUpgradeRoute = useRouteMatch('/upgrade');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { cid, userProfile, comp } = useContext(FirebaseAuthContext);
  const [product, setProduct] = useState({}); //selected plans // like : Initial, Pro, gold and platinum.
  const [addOns, setAddOns] = useState([]); //addOns to show for selection
  const [currentAddOns, setCurrentAddOns] = useState([]); //current active adds on for company..
  const [cardRequired, setCardRequired] = useState(false);
  const [cardErr, updateCardErr] = useState(null);
  const [selectedAddOns, setSelectedAddOns] = useState([]); //state for selected add ons for purchase...
  const [isYearly, setYearly] = useState(false); //check for early of monthly subscription.
  const { products, addOnFeatures, contract, reload, setupFee: setupFeeAmount } = useContext(ContractContext);
  const [promoCodeStatus, updatePCStatus] = useState({ ...DEFAULT_PROMO_CODE }); //promo code status
  const [promoCodeLoading, togglePromoCodeLoading] = useState(false);
  const [addOnUpdated, setAddOnUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradeLoading, toggleUpgrdLoading] = useState(false);
  const [cardLoading, toggleCardLoading] = useState(false);
  const [addOnInfo, setAddOnInfo] = useState(null);
  const [step, updateStep] = useState(1);
  const [dueAmount, setDueAmount] = useState(0);
  const [duns, setDuns] = useState(false);
  const disableAction = loading || promoCodeLoading || upgradeLoading || cardLoading;
  const { stripe: contractStripe, startDate, trial_duration } = contract;
  const cardAdded = _.get(contractStripe, 'setupIntent.status') === 'succeeded';
  const classes = usePlanFlowStyle();
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const firstMountCall = useRef(true);
  const isWhiteLabel = !!_.get(product, 'metadata.whitelabel', 0);
  const company = !comp ? {} : comp.data();
  const isProductSelected = !_.isEmpty(product);
  const isInitiateProduct = [_.get(product, 'metadata.display_name'), _.get(product, 'name')].includes('Initiate');
  const isTrial = activePlan === PLANS.TRIAL;
  const intentType = intent?.type;
  let dueOn = 'today';
  console.log('>>>>>>isWhiteLabel', isWhiteLabel, product);
  // if (!isWhiteLabel && startDate && trial_duration) {
  //   const daysDiff = moment().diff(moment.unix(startDate && startDate._seconds),'days');
  //   const daysLeftInTrial = trial_duration - daysDiff;
  //   dueOn = daysLeftInTrial <= 0 ? 'today' : `on ${moment().add(daysLeftInTrial,'days').format("DD MMM, YYYY")}`;
  // }

  const setupFee = useMemo(() => {
    const no_setup_fee = _.get(company, 'contract.no_setup_fee', false);
    return !no_setup_fee;
  }, [company]);

  const variantHasOffer = useMemo(() => {
    const offer = _.get(product.price?.[isYearly ? 'yearly' : 'monthly'], 'offer', {});
    if (!offer.coupon) return null;
    if (offer.validTill && offer.validTill._seconds && offer.validTill._seconds < new Date()._.getTime() / 1000)
      return null;
    return offer.coupon;
  }, [isYearly, product]);

  //Effects for creating states...
  useEffect(() => {
    const { productId, selectedAddOns, type, isYearly } = intent || {};
    let selected_product;
    if (!!productId) {
      const p = _.find(products, (p) => p.id === productId);
      if (!!p) {
        selected_product = _.get(p, 'metadata.internal_name');
        setProduct(p);
        bffUpdateHubspotProp(HUBSPOT_PROPS.LAST_CHECKOUT_DATE);
      }
    }
    if (isYearly !== undefined) setYearly(isYearly);
    const addOnCurrent = _.get(contract, 'meta.addOnsCurrent', {});
    const activeAddOnKeys = _.chain(addOnCurrent)
      .keys()
      .filter((key) => _.get(addOnCurrent, `${key}.value`, false))
      .value(); //get current activeAddOns..
    const currentPurchasedAddOns = [];
    activeAddOnKeys.forEach((fkey) => {
      const feature = _.find(addOnFeatures, (addOn) => _.get(addOn, 'metadata.fkey') === fkey);
      if (feature) currentPurchasedAddOns.push({ ...feature, ..._.get(contract, `meta.addOnsCurrent.${fkey}`, {}) });
    });
    const addOnsToShow = _.filter(addOnFeatures, (addOn) => {
      const key = _.get(addOn, 'metadata.fkey');
      if (activeAddOnKeys.includes(key)) return false;
      return true;
    }); //filter out adds on to show ...

    if (type === 'upgrade') {
      if (selected_product === PLANS.SILVER) setAddOns([]); // in case of silver product no add on supported....
      else setAddOns(addOnsToShow);
    } else {
      setAddOns(addOnsToShow);
    }
    if (currentPurchasedAddOns && currentPurchasedAddOns.length) setCurrentAddOns(currentPurchasedAddOns);
    if (selectedAddOns && !!selectedAddOns.length) setSelectedAddOns(selectedAddOns);
  }, [intent, products, addOnFeatures, contract]);

  useEffect(() => {
    calculateDueAmount();
  }, [product, selectedAddOns, isYearly, promoCodeStatus]);

  const topInfoMessages = useMemo(() => {
    const type = intent?.type;
    const name = product?.name;
    const isBlockingScreen = intent?.isBlockingScreen;
    if (!isBlockingScreen || type !== 'upgrade' || name !== 'Pro' || !!addOnUpdated) return null;
    let str;
    str = `You need to upgrade your membership to enjoy the benefits of ${_.get(
      ACL_CONFIGURATION,
      `${intent.from}.name`,
      intent.from || 'available features'
    )}.`;
    return str;
  }, [product, intent, selectedAddOns, addOnFeatures, addOnUpdated]);

  const infoMessage = useMemo(() => {
    if (!!isInitiateProduct) return null;
    if (isWhiteLabel && setupFee) {
      return (
        <span>
          {setupFee && (
            <span>
              A ${roundCurrency(setupFeeAmount / 100)} one-time activation fee applies. (gets credited to your FitBudd
              account post app launch).
            </span>
          )}
        </span>
      );
    }
    if (intentType === 'addOn') {
      const { selectedAddOnPrice, dueAmount, nextDueDate } = addOnInfo || {};
      if ((dueAmount || 0) / 100 < selectedAddOnPrice && nextDueDate) {
        return (
          <span className='lineht_1_5'>
            Note: The amount you pay today may differ from the listed price of the add-ons since we're only charging you
            for the days left until your next billing cycle. Your subsequent full payment will be on{' '}
            <b>{moment(nextDueDate).format('MMM D, YYYY')}</b>.
          </span>
        );
      }
    }
  }, [isWhiteLabel, setupFee, isYearly, isInitiateProduct, intentType, addOnInfo]);

  const isSubmitButtonDisabled = useMemo(() => {
    if (disableAction) return true;
    if (!!cardErr) return true;
    if (_.isEmpty(product) && !selectedAddOns.length) return true;
    return false;
  }, [disableAction, product, selectedAddOns, cardErr]);

  const selectAddOn = (id) => {
    if (disableAction) return;
    const index = _.findIndex(selectedAddOns, (addOnId) => addOnId === id);
    const out = [...selectedAddOns];
    if (index < 0) out.push(id);
    else {
      out.splice(index, 1);
    }
    setSelectedAddOns(out);
    setAddOnUpdated(true);
  };

  const getCurrentProduct = () => {
    if (!_.isEmpty(_.get(contract, 'meta.future'))) {
      return { prodId: _.get(contract, 'meta.future.prodId'), priceId: _.get(contract, 'meta.future.priceId') };
    } else if (!_.isEmpty(_.get(contract, 'meta.current'))) {
      return { prodId: _.get(contract, 'meta.current.prodId'), priceId: _.get(contract, 'meta.current.priceId') };
    } else {
      return { prodId: _.get(contract, 'meta.prodId'), priceId: _.get(contract, 'meta.priceId') };
    }
  };

  const getUpgradeV2Params = (dry = true) => {
    //dry : true means for price calculation, false means actual payments.
    const params = {
      cid,
      dry,
    };
    //setting product..
    if (!_.isEmpty(product)) {
      //if product is not empty ie, product selected for checkout.
      params.prodId = product.id;
      params.priceId = isYearly ? _.get(product, 'price.yearly.id') : _.get(product, 'price.monthly.id');
    } else if (_.isEmpty(product)) {
      //ie product not selected, set current packId and priceId..
      const currentProduct = getCurrentProduct();
      params.prodId = currentProduct.prodId;
      params.priceId = currentProduct.priceId;
    }
    params.name = userProfile.name;
    params.setupFee = setupFee;
    params.couponId = promoCodeStatus?.success ? promoCodeStatus.couponId : null;
    if (isWhiteLabel) params.duns = duns;
    let selectedAddOnPrice = 0;
    //setting add ons...
    if (selectedAddOns) {
      params.addOns = selectedAddOns.map((id) => {
        const addOn = _.find(addOnFeatures, (addOn) => addOn.id === id);
        if (!addOn) return null;
        selectedAddOnPrice += _.get(addOn, `price.monthly.cost`, 0);
        const out = {
          priceId: _.get(addOn, 'price.monthly.id'),
          prodId: id,
          key: _.get(addOn, 'metadata.fkey'),
        };
        if (dry) out.deleted = false; //TODO:: check if required or not...
        return out;
      });
    }
    if (dry) params.selectedAddOnPrice = selectedAddOnPrice;
    return params;
  };

  const calculateDueAmount = async () => {
    if (loading) return;
    if (firstMountCall.current) {
      //during first mount: if nothing is selected, product or addOns : no need to call ..
      if (_.isEmpty(product) && !selectedAddOns.length) return;
    }
    firstMountCall.current = false;
    const out = getUpgradeV2Params(true);
    let selectedAddOnPrice = out.selectedAddOnPrice || 0;
    delete out.selectedAddOnPrice;
    try {
      setLoading(true);
      const response = await bffContractDueAmount(out);
      const { dueAmount, success, cardRequired, nextDueDate } = _.get(response, 'data');
      if (!success) throw new Error('Something went wrong');
      if (success) {
        setDueAmount(dueAmount);
        setCardRequired(cardRequired);
        setAddOnInfo({
          nextDueDate,
          dueAmount,
          selectedAddOnPrice,
        });
      }
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      setAddOnInfo(null);
      console.log('>>>> error in get due amount', err);
    } finally {
      setLoading(false);
    }
  };

  const onCardChange = () => {
    updateCardErr(null);
  };

  const handleAddCardErr = (msg) => {
    updateCardErr(msg || 'Something went wrong. Please try again later.');
    toggleCardLoading(false);
    toggleUpgrdLoading(false);
    hideLoader();
  };

  const handleAddCardSuccess = () => {
    const isGold = product && product.metadata && product.metadata.whitelabel === '1';
    //TODO need to handle :::::
    if (isGold && true) {
      let target = 'https://inbound.fitbudd.com/upgraded-to-super-pro?';
      if (activePlan === PLANS.TRIAL) target = 'https://inbound.fitbudd.com/payment-success?';
      if (userProfile.name) target += `name=${encodeURI(userProfile.name)}`;
      if (userProfile.email) target += `email=${encodeURI(userProfile.email)}`;
      window.location.href = target;
    } else {
      hideLoader();
      toggleCardLoading(false);
      toggleUpgrdLoading(false);
      updateStep((i) => i + 1);
    }
  };

  //final payment actions:::
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      toggleUpgrdLoading(true);
      showLoader();
      bffUpdateHubspot({ [HUBSPOT_PROPS.PAYMENT_STATUS]: PAYMENT_STATUS.Initiated });
      const cardAdded = !cardRequired;
      const setupIntPayload = getUpgradeV2Params(false);
      // ----------CARD SETUP --------
      const card = cardAdded ? null : elements.getElement(CardElement);
      if (!cardAdded) {
        if (!stripe || !elements) return;
        if ((card && card._empty) || (card && card._invalid)) {
          updateCardErr('Please enter valid card details');
          toggleUpgrdLoading(false);
          hideLoader();
          return;
        }
        card.blur();
        toggleCardLoading(true);
        //-------create payment api-------
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card,
          billing_details: { name: userProfile.name, email: userProfile.email },
        });
        if (!!error) {
          //err handle
          handleAddCardErr(error.message);
          return;
        } else if (!error && paymentMethod) {
          setupIntPayload['payment_method'] = paymentMethod.id;
        }
      }

      //-------create setup intent---------
      const res = await createSetupIntent({ ...setupIntPayload });
      const { success, payment_intent, subscription } = res.data;
      if (!success) throw new Error('Some thing went wrong');
      if (!!cardAdded && !subscription && !payment_intent) {
        //card already added and subscription and payment_intent not required..
        //success page
        handleAddCardSuccess();
        return;
      }
      if (
        (!subscription || !subscription.latest_invoice.payment_intent.client_secret) &&
        (!payment_intent || !payment_intent.client_secret)
      ) {
        //no card
        //err handle
        handleAddCardErr();
        return;
      }
      const finalize = await (subscription
        ? stripe.confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret)
        : stripe.confirmCardPayment(payment_intent.client_secret, { payment_method: { card } })); //For 3D secure..
      if (!!finalize.error) {
        //err handle
        handleAddCardErr();
        return;
      } else {
        //success page
        handleAddCardSuccess();
        return;
      }
    } catch (err) {
      const msg = _.get(err, 'response.data.message');
      handleAddCardErr(msg);
      Sentry.captureException(err);
    }
  };

  const submitPromoCode = async (code, couponId) => {
    const promoCode = code;
    if (!promoCode && !couponId) return;
    togglePromoCodeLoading(true);
    try {
      const resp = await applyPromoCode({
        cid,
        couponId,
        promoCode,
        prodId: product.id,
        priceId: product.price[isYearly ? 'yearly' : 'monthly'].id,
      });
      updatePCStatus({
        msg: _.get(resp, 'data.data.coupon.name') || 'Congratulations! coupon is applied',
        success: true,
        discountAmt: _.get(resp, 'data.data.discount'),
        couponId: _.get(resp, 'data.data.coupon.id'),
        promoCode,
      });
      togglePromoCodeLoading(false);
    } catch (err) {
      const msg = _.get(err, 'response.data.message', 'This coupon is not applicable');
      updatePCStatus({ msg, errcode: _.get(err, 'response.data.code'), success: false });
      togglePromoCodeLoading(false);
    }
  };

  const onCloseSuccessDialog = () => {
    exitCheckout();
    if (_.get(isUpgradeRoute, 'isExact')) {
      closePricingOptions();
    }
  };

  const resetPMCodeStatus = () => {
    updatePCStatus({ ...DEFAULT_PROMO_CODE });
  };

  // const handleDunsChange = (e) => {
  //   const checked = e.target.checked;
  //   setDuns(checked);
  // };

  return (
    <div className="d-flex flex-column h-100">
      {/* ---------- Header  --------*/}
      <div
        className={clsx('bg-offWhite position-relative d-flex align-items-center px-20', classes.headerDivider)}
        style={{ minHeight: 60 }}>
        <IconButton onClick={exitCheckout} edge="start" disabled={disableAction} id="close-dialogue">
          <CloseIcon className="text-black" />
        </IconButton>
        <Typography className="text-center font_16_600 flex-1">Payment Details</Typography>
        {(loading || upgradeLoading) && (
          <LinearProgress className="position-absolute" style={{ bottom: 0, left: 0, right: 0 }} />
        )}
      </div>
      <div className={clsx('d-flex flex-column flex-grow-1 overflow-auto')}>
        {!!topInfoMessages && (
          <div className={clsx(classes.topInfo)}>
            <Typography className="font_14_500 text-dark-grey">{topInfoMessages}</Typography>
          </div>
        )}
        <div className="p-25">
          {isProductSelected && (
            <div className="w-100">
              <SelectedCard
                control={
                  isTrial ? (
                    <div className="d-flex align-items-center justify-content-center fmb-10 w-100">
                      <Typography
                        variant="body2"
                        className={clsx(
                          'fmr-10',
                          !isYearly ? 'font_13_600 text-0d0d0d' : 'font_13_500 text-dark-grey'
                        )}>
                        MONTHLY
                      </Typography>
                      <Switch
                        id="switch"
                        color="primary"
                        className="fmr-10"
                        checked={isYearly}
                        disabled={disableAction}
                        onChange={() => setYearly((o) => !o)}
                      />
                      <Typography className={clsx(isYearly ? 'font_13_600 text-0d0d0d' : 'font_13_500 text-dark-grey')}>
                        ANNUALLY
                      </Typography>
                    </div>
                  ) : null
                }
                selectedPlan={product}
                isYearly={isYearly}
                hideEdit={true}
              />
              {intent.isBlockingScreen && intent.type === 'upgrade' && (
                <div>
                  <ClrdButton
                    onClick={exitCheckout}
                    className="font_15_600 d-block mx-auto"
                    color="primary"
                    title="View More plans"
                  />
                  <Divider className="my-25" />
                </div>
              )}
            </div>
          )}
          {/* --------- Show Active Add Ons ----------- */}
          {currentAddOns && !!currentAddOns.length && (
            <div className="mb-25">
              <Typography className="font_18_600 mb-15">Active Add-ons</Typography>
              {currentAddOns.map((addOn, index) => {
                const isLast = currentAddOns.length - 1 === index;
                return <ActiveAddOn key={addOn.id} data={addOn} className={isLast ? '' : 'mb-15'} />;
              })}
            </div>
          )}
          {/* ------- Available  Ons  --------   */}
          {!!addOns.length && (
            <div className="mb-25">
              <Typography className="font_18_600 mb-15">
                Select Add-ons &nbsp;&nbsp;<span className="font_13_500 text-dark-grey">Billed monthly</span>
              </Typography>
              <div>
                {addOns.map((addOn, index) => {
                  const isLast = addOns.length - 1 === index;
                  return (
                    <AddOn
                      selected={_.find(selectedAddOns, (id) => id === addOn.id)}
                      onSelect={selectAddOn}
                      className={clsx(!isLast && 'mb-15')}
                      addOn={addOn}
                      key={addOn.id}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {/* ------- Promo code UI ------ */}
          {isProductSelected && !!dueAmount && (
            <>
              <hr className="w-100 mb-25 mt-0" />
              <PromoCode
                loading={disableAction}
                onSubmit={submitPromoCode}
                onReset={resetPMCodeStatus}
                disabled={loading}
                initPromoCode={variantHasOffer}
                isYearly={isYearly}
                className={'mb-25'}
                {...promoCodeStatus}
              />
            </>
          )}
          <hr className="w-100 mb-25 mt-0" />
          {/* --------- INFO MESSAGES ------ */}
          {infoMessage && (
            <div className="info-box mb-25">
              <Typography className="font_14_500 text-dark-grey">{infoMessage}</Typography>
            </div>
          )}

          {/* -----TOTAL PRICING -------- */}
          <div className="d-flex justify-content-between mb-25">
            <Typography className="font_15_500 text-0d0d0d">
              Due <b>{dueOn}</b>
            </Typography>
            {loading ? (
              <Typography className="font_16_700 text-grey"> calculating ...</Typography>
            ) : (
              <Typography className="font_16_700 text-dark-grey">{`$${roundCurrency(dueAmount / 100)}`}</Typography>
            )}
          </div>
          <hr className="w-100 mb-25 mt-0" />
          {/* Card Details */}
          {cardRequired && (
            <div className={clsx(classes.stripeContainer, cardAdded && 'd-none')}>
              <Typography className="font_16_600 text-0d0d0d mb-15"> Add Card Details</Typography>
              <CardElement
                onChange={onCardChange}
                options={{
                  hidePostalCode: true,
                  disabled: disableAction,
                  style: {
                    base: {
                      fontSize: '14px',
                    },
                  },
                }}
              />
              <small className="text-danger" style={{ opacity: !!cardErr ? 1 : 0 }}>
                {cardErr}
              </small>
            </div>
          )}
          {/* {!!isWhiteLabel && (
            <div>
              <FormControlLabel
                label="DUNS confirmation text to be updated"
                style={{ marginBottom: 16, marginTop: '-9px' }}
                classes={{ label: 'font_13_500 text-dark-grey' }}
                control={<Checkbox checked={duns} color="primary" onChange={handleDunsChange} />}
              />
            </div>
          )} */}
          {/*----- Submit Action */}
          <ClrdButton
            disabled={isSubmitButtonDisabled}
            type="submit"
            className="f-xxlarge"
            color="main"
            fullWidth
            onClick={handleSubmit}>
            {loading ? <CircularProgress size={30} className="text-white" /> : 'Confirm Purchase'}
          </ClrdButton>
        </div>
      </div>
      {step === 2 && (
        <SuccessDialog
          handleClose={onCloseSuccessDialog}
          reload={reload}
          isProductPurchase={!_.isEmpty(product)}
          product={product}
          name={!_.isEmpty(product) ? _.get(product, 'metadata.display_name', '') : 'Add On features'}
          isYearly={isYearly}
          selectedAddOns={selectedAddOns}
          addOnFeatures={addOnFeatures}
        />
      )}
    </div>
  );
};

const UpgradeWithStripe = (props) => (
  <Elements stripe={stripePromise}>
    <Checkout {...props} />
  </Elements>
);

export default UpgradeWithStripe;
