/*
IMPORTANT NOTES:
 1.groups.[i].serving_unit_quantity is ui quantity
 2. don't update 'doc' or 'oldDoc' directly , use toggleDoc or parseCurerntDoc.
 3. props.scheduleDoc is saved as doc & oldDoc in initial mounting only, not to be used anywhere again.
*/
import _ from 'lodash';
import React,{useMemo,useContext,useState,useEffect} from "react";
import * as Sentry from "@sentry/browser";
import { DEFAULT_ERROR, DRAFT,DRAFT_STATES, HUBSPOT_PROPS, PUBLISHED, VIEWS_CONSTANTS,EMPTY_MEAL_DISCARD_MSG,DISCARD_MSG } from "fitbud/utils/constants";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import firebase from "fitbud/firebase";
import update from "immutability-helper";
import { Divider } from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import mealRepo from "fitbud/repo/meals";
import mealRdxFns from "fitbud/redux/meals";
import CreateEditForm from "fitbud/views/meals/createEditForm";
// import Details from "fitbud/views/meals/details";
import Details from "fitbud/views/meals/newUI/details";
import { ClrdButton } from "fitbud/components/form-fields";
import MacrosForm from "./macrosForm";
import FoodItemsForm from "./foodItemsForm";
import foodRecipesRepo from "fitbud/repo/foodRecipes";
import {
  calculateMealMacros,
  autoMacrosCal,
  changeCaloriesCalMacros,
  changeCaloriesCalLastField,
  processMetaData,
  migrateDietType,
} from "./helper";
import { getTags, uploadFile } from "fitbud/utils/services.js";
import appRdxFns from "fitbud/redux/app";
import {
  ERROR_TITLE,
  ERROR_REF_NAME,
  MEAL_STORAGE_FILE_PATH,
  OFFLINE_ERROR,
  FETCH_ERROR,
  ERROR_URL
} from "fitbud/utils/constants";
import CircularLoader from "fitbud/components/CircularLoader";
import PageNotFound from "fitbud/views/pageNotFound";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { TagsContext } from "fitbud/providers/tagsProvider";
import { countDecimals,isOnline } from "fitbud/utils/helpers";
import moment from "moment";
import { bffUpdateHubspotProp } from "fitbud/api";
import { DetailPgStyling } from "fitbud/hooks/useDetailPgStyle";
import uuidv4 from "uuid/v4";
import {Title} from "fitbud/views/workouts/form";
import clsx from 'clsx';
import {SaveIcon} from "fitbud/icons/saveIcon";
import { CustomConfirmation } from "fitbud/components/customConfirmation";
import {PublishedDrawer} from "fitbud/views/workouts/publishdedDrawer";
import {calculatePublishStatus} from "fitbud/utils/catalog";
import {EditorPlaceholder} from "fitbud/views/exercises/editorPlaceholder";
import {findStandardUnit} from "fitbud/views/foodRecipes/newUi/inputDropdown";
import {toggleServingType} from "fitbud/views/foodRecipes/newUi/ingredientsEditor"
import {getFactor as _getFactor} from "fitbud/views/foodRecipes/newUi/ingredientsEditor";
import { exportTags } from "../workouts/helperfn";
import { Conversion } from 'fitbud/providers/conversion';
import useServings from 'fitbud/hooks/useServings';
import {getDbValue} from "fitbud/views/foodRecipes/newUi/inputDropdown";
import {uploadPdf} from "fitbud/utils/aws";
import { isValidURL } from "fitbud/utils/helpers";
import LibraryConfirmation from '../workouts/components/libraryConfirmation';

const DEFAULT_NUMBER_OF_ANY_UNIT=1;

const _default = {
  ref_name: "",
  title: "",
  description: "",
  thumbnail: "",
  diet_type: "",
  meal_time: "",
  type: "food",
  groups: [],
  macros: {
    protein: 0,
    fat: 0,
    carbs: 0,
    calories: 0,
  },
  units: "gms",
};
const getFactor=(data,newServingUnit)=>_getFactor({data,serving:newServingUnit,fallback:false});
const getRatio=(num,deno)=>{
  if(!(!!num && _.isFinite(num) && !_.isNaN(num))) return 1;
  if(!(!!deno && _.isFinite(deno) && !_.isNaN(deno))) return 1;
    return num/deno;
}

const DUPLICATE_REF_PREFIX = "Edited copy of";

class MealInfo extends React.Component {
  constructor(props) {
    super(props);
    // let defaultDoc = props.scheduleDoc
    //   ? _.cloneDeep({ ...props.doc, ...props.scheduleDoc })
    //   : props.doc;
    this.state = {
      loader: true,
      isValidId: true,
      doc: {...props.doc},
      oldDoc: {...props.doc},
      draftDoc:{},
      publishedDoc:{},
      foodRecipes: [],
      errors: {},
      editMode: false,
      mealEditorMode: false,
      mealCategories: [],
      serving: [],
      isConfirmationOpen: false,
      dirty: false,
      isImport: props.isImport || false, //props for mealInfo using in import Meal Feature
      showingDoc:PUBLISHED,
      viewOrigDoc:false,
      isMealEmpty:true,
      isAddToLibraryConfirmationOpen: false,
      currentConfirmation: ""
    };
  }

  static contextType = FirebaseAuthContext;
  get draftFeature(){
    const {comp} = this.context;
    if(_.isUndefined(this.props.draftFeature)){
      return !!comp.data().features.draft;
    }
    return this.props.draftFeature;
  }

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get isDraftAvailable() {
    return !!_.get(this.state, "publishedDoc.publish_status",PUBLISHED).includes('draft');
  }

  handleChangeType = (e) => {
    let { doc } = this.state;
    const value = e.target.value;
    if (value === doc.type) return;
    const newState = update(this.state, {
      doc: {
        type: {
          $set: value,
        },
        groups: {
          $set: [],
        },
        macros: {
          $set: {
            protein: 0,
            fat: 0,
            carbs: 0,
            calories: 0,
          },
        },
      },
    });
    this.setState({ ...newState, errors: {} });
  };
  _isValidNo=(v)=>!_.isNaN(v) && !_.isNull(v);

  handleQuantityChange=(e,info,stdUnit)=>{ // info is meal.item
    const {_isValidNo}=this;
    const groupIndex = e.target.dataset
    ? parseInt(e.target.dataset.group)
    : null;
    const mealItemIndex = e.target.dataset
    ? parseInt(e.target.dataset.itemindex)
    : null;
    const altItemIndex = e.target.dataset
      ? parseInt(e.target.dataset.altitemindex)
      : null;
    if(!_isValidNo(groupIndex) || !_isValidNo(mealItemIndex))
      return;
    let chng;
    let _value = Number(e.target.value);
    // if (countDecimals(_value) > 2) return;//removed because of imperial
    const data=this.state.foodRecipes.find(i=>i.id===info.ref.id);
    // if(!data.serving_size){
    //   data.serving_size=1;
    // }
    if(data.type==="food"||data.type==="fdc"){
      if(info.serving_unit===stdUnit.id){ //current serving_unit is gm,ml
        const ratio=getRatio(_value,data.serving_size);
        chng={
          "serving_unit_quantity":_value,
          "quantity":!data.serving_size?_value:ratio//Todo:getratio edge case
        }
      }
      else{ 
        const {serving_options}=this.props.getDataWExtraServingOptions(data);
        const factor=serving_options.find(i=>i.id===info.serving_unit).serving_size;
        chng={
          "serving_unit_quantity":_value,
          "quantity":_value*getRatio(factor,data.serving_size)
        }
      }
    }
    else if(data.type==="recipe"){
      chng = {
        "serving_unit_quantity":_value,
        "quantity":_value
      }
    }
    let newState,errors={};
    //alternate meal item
    if(_isValidNo(altItemIndex)){
      newState=update(this.state,{
        doc:{
          groups:{
            [groupIndex]:{
              food_items:{
                [mealItemIndex]:{
                  alternatives:{
                    [altItemIndex]:{
                      $merge: chng,
                    }
                  }
                }
              }
            }
          }
        }
      });
      if(_value===0){
        _.set(errors,`groups.${groupIndex}.food_items.${mealItemIndex}.alternatives.${altItemIndex}.quantity`,"Please provide a valid value");
      }
    }
    else{
      //normal meal item
      newState=update(this.state,{
        doc:{
          groups:{
            [groupIndex]:{
              food_items:{
                [mealItemIndex]:{
                  $merge:chng
                }
              }
            }
          }
        }
      });
      if(_value===0){
        _.set(errors,`groups.${groupIndex}.food_items.${mealItemIndex}.quantity`,"Please provide a valid value");
      }
    }
    this.setState(
      { ...newState, errors, dirty: true },
      () =>this.updateGrpCalories(groupIndex)
    );
  }
  handleServingChange=(e,v,info,stdUnit,index)=>{
    const {_isValidNo}=this;
    const {foodRecipes}=this.state;
    const groupIndex = index
    ? parseInt(index.groupindex)
    : null;
    const mealItemIndex = index
    ? parseInt(index.itemindex)
    : null;
    const altItemIndex = index
      ? parseInt(index.altitemindex)
      : null;
    if(!_isValidNo(groupIndex) || !_isValidNo(mealItemIndex)) return;
    //------
    const data=foodRecipes.find(i=>i.id===info.ref.id);
    let chng;
      const {serving_size}=data
      const dataWExtraServingOptions=this.props.getDataWExtraServingOptions(data);
      const oldFactor=getFactor(dataWExtraServingOptions,info.serving_unit);
      const newFactor=getFactor(dataWExtraServingOptions,v);
      const valueInGmML=info.serving_unit_quantity * (oldFactor||1);
      const newRatio=getRatio(newFactor,serving_size);//if !newFactor,getRatio fn will handle
      const new_serving_unit_quantity=!(oldFactor && newFactor)? info.serving_unit_quantity 
        : (valueInGmML/(newFactor||1));
      chng={
        'quantity':newRatio*new_serving_unit_quantity,
        'serving_unit':v,
        'serving_unit_quantity':new_serving_unit_quantity,
      }
    let newState,errors={};
     //alternate meal item
     if(_isValidNo(altItemIndex)){
      newState=update(this.state,{
        doc:{
          groups:{
            [groupIndex]:{
              food_items:{
                [mealItemIndex]:{
                  alternatives:{
                    [altItemIndex]:{
                      $merge: chng,
                    }
                  }
                }
              }
            }
          }
        }
      });
     }
     else{
      //normal meal item
      newState=update(this.state,{
        doc:{
          groups:{
            [groupIndex]:{
              food_items:{
                [mealItemIndex]:{
                  $merge:chng
                }
              }
            }
          }
        }
      });
     }
     this.setState(
      { ...newState, errors, dirty: true },
      () =>this.updateGrpCalories(groupIndex)
    );
  }


  handleDocument = (type, file, meta = false) =>{
    const { doc } = this.state;
    let newDoc = doc;
    const  _docName = _.get(doc, "document.0.name", "");
    if (type === 'file') { //incase of file
      let _document = [{ type, name:_docName }];
      if (!!file) { 
          if(meta) _document = [{ type, ...(file || {}) }]; //if meta is true ie only meta fields changes like name
          else  _document = [{ url: file, file_original_name: file.name, size: file.size, type,  isLocal:true, name:_docName }];
      }
      newDoc = update(doc, {
        document: {
          $set: _document,
        },
      });
    } else if (type === 'link') { //incase iof link
      let _document = [{ type,name:_docName, ...(file || {}) }];
      newDoc = update(doc, {
        document: {
          $set: _document,
        },
      });
    }
    this.setState({doc:newDoc, dirty:true});
  }

  handleChange = (e) => {
    if (e.target.value === "select") return;
    const groupIndex = e.target.dataset
      ? parseInt(e.target.dataset.group)
      : null;
    const mealItemIndex = e.target.dataset
      ? parseInt(e.target.dataset.itemindex)
      : null;

    const altItemIndex = e.target.dataset
      ? parseInt(e.target.dataset.altitemindex)
      : null;
    let chng;
    let _value = e.target.value;
    if (e.target.type === "number") {
      if (countDecimals(e.target.value) > 2) return;
      _value = Number(e.target.value);
    } else {
      // _value = (_value || "").trim();
    }
    const key=e.target.id || e.target.name;
    if(key==="systags"){
      _value=(_value||[]).map(val => val.value);
    }
    chng = { [e.target.id || e.target.name]: _value };
    if (
      groupIndex !== null &&
      !isNaN(groupIndex) &&
      mealItemIndex !== null &&
      !isNaN(mealItemIndex)
    ) {
      if (e.target.type === "number") {
        chng = { [e.target.id || e.target.name]: Number(_value) };
      }
      let newState;
      let errors = {};
      if (altItemIndex !== null && !isNaN(altItemIndex)) {
        newState = update(this.state, {
          doc: {
            groups: {
              [groupIndex]: {
                food_items: {
                  [mealItemIndex]: {
                    alternatives: {
                      [altItemIndex]: {
                        $merge: chng,
                      },
                    },
                  },
                },
              },
            },
          },
        });
        if (e.target.name === "quantity" && Number(_value) === 0) {
          errors.groups = [];
          errors.groups[groupIndex] = {};
          errors.groups[groupIndex].food_items = [];
          errors.groups[groupIndex].food_items[mealItemIndex] = {};
          errors.groups[groupIndex].food_items[mealItemIndex].alternatives = [];
          errors.groups[groupIndex].food_items[mealItemIndex].alternatives[
            altItemIndex
          ] = {};
          errors.groups[groupIndex].food_items[mealItemIndex].alternatives[
            altItemIndex
          ].quantity = "Please enter a valid quantity";
        }
      } else {
        if (e.target.type === "number") {
          chng = { [e.target.id || e.target.name]: Number(_value) };
        }
        newState = update(this.state, {
          doc: {
            groups: {
              [groupIndex]: {
                food_items: {
                  [mealItemIndex]: {
                    $merge: chng,
                  },
                },
              },
            },
          },
        });
        if (e.target.name === "quantity" && Number(_value) === 0) {
          errors.groups = [];
          errors.groups[groupIndex] = {};
          errors.groups[groupIndex].food_items = [];
          errors.groups[groupIndex].food_items[mealItemIndex] = {};
          errors.groups[groupIndex].food_items[mealItemIndex].quantity =
            "Please provide a valid value";
        }
      }
      this.setState({ ...newState, errors, dirty: true }, () =>
        this.updateGrpCalories(groupIndex)
      );
    } else if (groupIndex !== null && !isNaN(groupIndex)) {
      let newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              $merge: chng,
            },
          },
        },
      });
      this.setState({ ...newState, dirty: true });
    } else {
      this.setState((s) => ({ doc: { ...s.doc, ...chng },dirty:true }));
    }
  };

  updateGrpCalories = (groupIndex) => {
    const { doc, foodRecipes } = this.state;
    if (doc.type === "macros") return;
    const newDoc = calculateMealMacros(foodRecipes, doc);
    let newState = update(this.state, {
      doc: {
        $set: newDoc,
      },
    });
    this.setState(newState);
  };

  handleEditMode = () => {
    this.setState({ editMode: true});
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };

  updateParent = (params) => {
    this.setState((s) => ({ doc: { ...s.doc, ...params }, dirty: true }));
  };

  updateParentErrors = () => {
    this.setState({ errors: {} });
  };

  handleReorder = ({ grpIndex, itemIndex, foodItems }) => {
    let newState;
    if (itemIndex === undefined) {
      newState = update(this.state, {
        doc: {
          groups: {
            [grpIndex]: {
              food_items: {
                $set: foodItems,
              },
            },
          },
        },
      });
    } else {
      newState = update(this.state, {
        doc: {
          groups: {
            [grpIndex]: {
              food_items: {
                [itemIndex]: {
                  alternatives: {
                    $set: foodItems,
                  },
                },
              },
            },
          },
        },
      });
    }
    this.setState({ ...newState, dirty: true });
  };

  handleMacros = (e, autoCal) => {
    const { doc } = this.state;

    if (e.target.type === "number") {
      e.target.value = Number(e.target.value);
    }

    let value = Number(e.target.value);

    const type = [e.target.id || e.target.name];
    if (doc.units === "percentage" && e.target.id !== "calories") {
      const cals = e.target.id === "fat" ? 9 : 4;
      value = (value * doc.macros.calories) / (cals * 100);
    }

    let newState = update(this.state, {
      doc: {
        macros: {
          [type]: {
            $set: value,
          },
        },
      },
    });

    if (newState.doc.type === "macros") {
      let { calories, fat, protein, carbs } = newState.doc.macros;
      let key = e.target.id || e.target.name;
      let value = 0;
      const macrosArray = ["protein", "fat", "carbs"];
      const newMacros = _.remove(macrosArray, function (n) {
        return n !== autoCal;
      });
      if (newMacros.includes(key)) {
        key = autoCal;
        let carbsValue = 0;
        const usedCals = autoMacrosCal(autoCal, newState.doc.macros).usedCals;
        const macrosV = autoCal === "fat" ? 9 : 4;
        value = (calories - usedCals) / macrosV;

        if (value > 0) {
          carbsValue = value;
        }

        newState = update(newState, {
          doc: {
            macros: {
              [key]: {
                $set: carbsValue,
              },
            },
          },
        });
      }
      // Handle restMacros in case of Calories change
      const previousCals = this.state.doc.macros.calories;
      const macrosKeyArr = ["protein", "fat", "carbs"];
      const newMacrosKey = _.remove(macrosKeyArr, function (o) {
        return o !== autoCal;
      });
      if (newMacrosKey[0] && newMacrosKey[1] && key === "calories") {
        const macrosV = autoCal === "fat" ? 9 : 4;
        let totalAutoMac =
          (calories - previousCals) / macrosV + newState.doc.macros[autoCal];
        totalAutoMac = totalAutoMac > 0 ? totalAutoMac : 0;
        newState = update(newState, {
          doc: {
            macros: {
              [autoCal]: {
                $set: totalAutoMac,
              },
            },
          },
        });

        if (totalAutoMac === 0) {
          const oldMacros = { calories, fat, protein, carbs };
          let { value, macrosKey } = changeCaloriesCalMacros(
            autoCal,
            previousCals,
            oldMacros
          );
          value = value > 0 ? value : 0;
          newState = update(newState, {
            doc: {
              macros: {
                [macrosKey]: {
                  $set: value,
                },
              },
            },
          });

          if (value === 0) {
            let { val, lastFieldKey } = changeCaloriesCalLastField(
              autoCal,
              previousCals,
              oldMacros
            );
            val = val > 0 ? val : 0;
            newState = update(newState, {
              doc: {
                macros: {
                  [lastFieldKey]: {
                    $set: Number(val),
                  },
                },
              },
            });
          }
        }
      }
    }
    this.setState({ ...newState, errors: {}, dirty: true });
  };

  closeEditor= async(action, addToLibrary = false)=>{
    if(action === "done"){
      if(!this.state.dirty && !this.props.isEditorMode && !this.isDraftAvailable){
        //close
      }
      else if(this.draftFeature) {
        this.setState({ isConfirmationOpen: true });
        return;
      }
      else {
        await this.handlePublish(addToLibrary);
        return;
      }
    }
    const newState = update(this.state, {
      doc: {
        $set: {
          ...Object.assign({}, this.state.oldDoc),
        },
      },
      mealEditorMode: {
        $set: false,
      },
      errors: {$set:{}},
      isConfirmationOpen: { $set: false },
      dirty: { $set: false },
      editMode:{$set:false}
    });
    this.setState(newState);
  }


  handleMealIfTime = (grpIndex, date) => {
    const { doc } = this.state;
    if (!date) return;
    let time = moment(date).format("HH:mm");
    let out = update(doc, {
      groups: {
        [grpIndex]: {
          $merge: {
            iftime: time,
          },
        },
      },
    });
    this.setState({ doc: out, dirty: true, errors: {} });
  };

  removeTimePicker = (grpIndex) => {
    const { doc } = this.state;
    let out = update(doc, {
      groups: {
        [grpIndex]: {
          $merge: {
            iftime: null,
          },
        },
      },
    });
    this.setState({ doc: out, dirty: true, errors: {} });
  };

  handleMealType = (grpIndex, value) => {
    const { doc, mealCategories } = this.state;
    if (doc.groups[grpIndex].type === value || value === "select") return;
    let newState = { ...this.state };

    const existCatIndex = doc.groups.findIndex((grp) => grp.type === value);
    if (existCatIndex > -1) {
      let newFoodItems = newState.doc.groups[grpIndex];

      const existedItems = newState.doc.groups[existCatIndex].food_items;
      newFoodItems = newState.doc.groups[grpIndex].food_items.filter(
        (newItem) =>
          !existedItems.find((existItem) => {
            if (existItem.type === "food" && newItem.type === "food") {
              return existItem.ref.id === newItem.ref.id;
            }
            if (existItem.type === "food" && newItem.type === "alternative") {
              return existItem.ref.id === newItem.alternatives[0].ref.id;
            }
            if (existItem.type === "alternative" && newItem.type === "food") {
              return existItem.alternatives[0].ref.id === newItem.ref.id;
            }
            return (
              existItem.alternatives[0].ref.id ===
              newItem.alternatives[0].ref.id
            );
          })
      );

      newState = update(newState, {
        doc: {
          groups: {
            [existCatIndex]: {
              food_items: {
                $push: [...newFoodItems],
              },
            },
          },
        },
      });
      newState = update(newState, {
        doc: {
          groups: {
            $splice: [[grpIndex, 1]],
          },
        },
      });
    } else {
      let order = mealCategories.findIndex((cat) => cat[0] === value);
      order = order === -1 ? 1 : order + 1;
      newState = update(newState, {
        doc: {
          groups: {
            [grpIndex]: {
              type: {
                $set: value,
              },
              order: {
                $set: order,
              },
            },
          },
        },
      });
    }
    // ordering of the meal categories

    const newGroups = newState.doc.groups.sort((a, b) => a.order - b.order);

    newState = update(newState, {
      doc: {
        groups: {
          $set: newGroups,
        },
      },
    });
    this.setState({ ...newState, errors: {}, dirty: true }, () =>
      this.updateGrpCalories(grpIndex)
    );
  };

  handleDelete = (payload) => {
    const { type, grpIndex, itemIndex, altItemIndex } = payload;
    let newState;

    if (
      (this.state.doc.groups[grpIndex].food_items.length === 1 &&
        type === "food") ||
      type === "group"
    ) {
      newState = update(this.state, {
        doc: {
          groups: {
            $splice: [[grpIndex, 1]],
          },
        },
      });
    } else if (type === "food") {
      newState = update(this.state, {
        doc: {
          groups: {
            [grpIndex]: {
              food_items: {
                $splice: [[itemIndex, 1]],
              },
            },
          },
        },
      });
    } else if (itemIndex !== undefined) {
      if (
        this.state.doc.groups[grpIndex].food_items[itemIndex].alternatives
          .length > 2
      ) {
        newState = update(this.state, {
          doc: {
            groups: {
              [grpIndex]: {
                food_items: {
                  [itemIndex]: {
                    alternatives: {
                      $splice: [[altItemIndex, 1]],
                    },
                  },
                },
              },
            },
          },
        });
      } else {
        newState = update(this.state, {
          doc: {
            groups: {
              [grpIndex]: {
                food_items: {
                  [itemIndex]: {
                    alternatives: {
                      $splice: [[payload.altItemIndex, 1]],
                    },
                  },
                },
              },
            },
          },
        });

        const nextItem =
          newState.doc.groups[grpIndex].food_items[itemIndex].alternatives[0];

        const newItem = update(nextItem, {
          type: {
            $set: "food",
          },
        });

        newState = update(this.state, {
          doc: {
            groups: {
              [grpIndex]: {
                food_items: {
                  [itemIndex]: {
                    $set: newItem,
                  },
                },
              },
            },
          },
        });
      }
    }
    this.setState({ ...newState, errors: {}, dirty: true }, () =>
      this.updateGrpCalories(grpIndex)
    );
  };
  // handleFoodRecipesAdd = async (payload) => {
  //   const groupIndex = payload.grpIndex;
  //   const type = payload.type;
  //   let newState;
  //   let recpIds = [];
  //   //if inside a group
  //   if (groupIndex !== null) {
  //     const newItem = payload.data.map((d) => {
  //       if (d.existsData && Object.keys(d.existsData).length) {
  //         return { ...d.existsData }; //if foodItems contain existsData ie, it was already added and have their own data
  //       }
  //       recpIds.push(d._id);
  //       const foodRecipe = {
  //         ref: firebase
  //           .firestore()
  //           .doc(`companies/${this.context.cid}/foodRecipes/${d._id}`),
  //         quantity: 1,
  //         type,
  //       };
  //       return foodRecipe;
  //     });

  //     if (!newItem || !newItem.length) {
  //       //if all added items are empty then delete that group and items
  //       //if type is food then delete that group
  //       if (type === "food") {
  //         newState = update(this.state, {
  //           doc: {
  //             groups: {
  //               $splice: [[groupIndex, 1]],
  //             },
  //           },
  //         });
  //       } else {
  //         const itemIndex = payload.itemIndex;
  //         newState = update(this.state, {
  //           doc: {
  //             groups: {
  //               [groupIndex]: {
  //                 food_items: {
  //                   $splice: [[itemIndex, 1]],
  //                 },
  //               },
  //             },
  //           },
  //         });
  //         if (!newState.doc.groups[groupIndex].food_items.length) {
  //           newState = update(this.state, {
  //             doc: {
  //               groups: {
  //                 $splice: [[groupIndex, 1]],
  //               },
  //             },
  //           });
  //         }
  //       }
  //       this.setState({ ...newState, errors: {}, dirty: true },
  //          () =>this.updateGrpCalories(groupIndex)
  //       );
  //       return;
  //     }

  //     if (type === "food") {
  //       newState = update(this.state, {
  //         doc: {
  //           groups: {
  //             [groupIndex]: {
  //               food_items: {
  //                 // $push: [...newItem]
  //                 $set: [...newItem],
  //               },
  //             },
  //           },
  //         },
  //       });
  //     } else {
  //       let previousItem =
  //         this.state.doc.groups[groupIndex].food_items[payload.itemIndex];
  //       if (previousItem.type === "food") {
  //         let alternatives = [];
  //         // alternatives.push({
  //         //   quantity: previousItem.quantity,
  //         //   ref: previousItem.ref,
  //         //   type: "alternatives"
  //         // });
  //         // alternatives = alternatives.concat(newItem);
  //         newItem[0].type = "alternatives";
  //         alternatives = newItem;

  //         const newItemData = {
  //           type: "alternative",
  //           alternatives: alternatives,
  //         };
  //         newState = update(this.state, {
  //           doc: {
  //             groups: {
  //               [groupIndex]: {
  //                 food_items: {
  //                   [payload.itemIndex]: {
  //                     $set: newItemData,
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         });
  //       } else {
  //         newState = update(this.state, {
  //           doc: {
  //             groups: {
  //               [groupIndex]: {
  //                 food_items: {
  //                   [payload.itemIndex]: {
  //                     alternatives: {
  //                       $set: [...newItem],
  //                     },
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         });
  //       }
  //     }

  //     const foodRecipesData = await this.fetchRecipes(recpIds);
  //     newState = update(newState, {
  //       foodRecipes: {
  //         $push: [...foodRecipesData],
  //       },
  //     });

  //     this.setState({ ...newState, errors: {}, dirty: true }, () =>
  //       this.updateGrpCalories(groupIndex)
  //     );
  //     return;
  //   }
  // };

  handleFoodRecipesAdd = async (payload) => {
    const {_isValidNo}=this;
    const {grpIndex,itemIndex} = payload;
    if (!_isValidNo(grpIndex)) return;
    const type = payload.type;
    let newState=_.cloneDeep(this.state);
    const ids=payload.data.map(i=>i._id);
    //fetch new ids
    const newFoodRecipeData = await this.fetchRecipes(ids);
    const foodServingData=[...(this.state.foodRecipes||[]),...(newFoodRecipeData||[])];
    newState = update(newState, {
      foodRecipes: {
        $push: [...newFoodRecipeData],
      },
    });
    //reformat items
    const items=payload.data.map((d,i)=>{
      //already selected items
      if(d.existsData && Object.keys(d.existsData).length){
        return {...d.existsData};
      }
      //newly added items
      const data=foodServingData.find(i=>i.id===d._id);
      const obj={
        ref: firebase
            .firestore()
            .doc(`companies/${this.context.cid}/foodRecipes/${d._id}`),
      }
      if(data.type==="recipe"){
        obj['quantity']=DEFAULT_NUMBER_OF_ANY_UNIT;
        obj['type']=type;
        obj["serving_unit"]=data.serving;
        obj["serving_unit_quantity"]=DEFAULT_NUMBER_OF_ANY_UNIT;
      }
      else if(data.type==="food"||data.type==="fdc"){
        const serving_options=data.serving_options||[];
        const stdUnitId=findStandardUnit(data.serving_type,this._sortServing).id;
        let ratio=1;
        if(!!serving_options.length){
          const factor=_.get(serving_options,`0.serving_size`);
          ratio=getRatio(factor,data.serving_size);
        }
        obj['quantity']=!serving_options.length ? DEFAULT_NUMBER_OF_ANY_UNIT : ratio;
        obj["serving_unit_quantity"]=!!serving_options.length?1:data.serving_size||DEFAULT_NUMBER_OF_ANY_UNIT;
        obj["serving_unit"]=!!serving_options.length?serving_options[0].id:stdUnitId;
        obj['type']=type;
      }
      return {...obj}
    });
    if(!items||!items.length){
      //if all added items are empty then delete that group and items
      if(type==="food"){
        //food,fdc or recipes , not alts
        newState=update(newState,{
          doc:{
            groups:{
              $splice:[[grpIndex, 1]]
            }
          }
        });
      }
      else{
        //alts
        newState = update(newState, {
          doc: {
            groups: {
              [grpIndex]: {
                food_items: {
                  $splice: [[itemIndex, 1]],
                },
              },
            },
          },
        });
        if(!_.get(newState,`doc.groups.${grpIndex}.food_items`,[]).length){
          newState = update(newState, {
            doc: {
              groups: {
                $splice: [[grpIndex, 1]],
              },
            },
          });
        }
      }
    }
    else{
      //items are not empty
      //food,fdc or recipes , not alts
      if (type === "food") {
        newState = update(newState, {
          doc: {
            groups: {
              [grpIndex]: {
                food_items: {
                  $set: [...items]
                },
              },
            },
          },
        });
      }
      else{
        //alts
        let previousItem =_.get(this.state.doc,`groups.${grpIndex}.food_items.${itemIndex}`);
        if(previousItem.type === "food"){
          items[0].type="alternatives";
          const newItemData = {
            type: "alternative",
            alternatives: [...items],
          };
          newState = update(newState, {
            doc: {
              groups: {
                [grpIndex]: {
                  food_items: {
                    [itemIndex]: {
                      $set: newItemData,
                    },
                  },
                },
              },
            },
          });
        }
        else{
          newState = update(newState, {
            doc: {
              groups: {
                [grpIndex]: {
                  food_items: {
                    [itemIndex]: {
                      alternatives: {
                        $set: [...items],
                      },
                    },
                  },
                },
              },
            },
          });
        }
      }
    }
    this.setState({ ...newState, errors: {}, dirty: true },
      () =>this.updateGrpCalories(grpIndex)
    );
  };

  valid = (isPublish) => {
    let out = true;
    let errors = {};
    const {
      description,
      ref_name="",
      title="",
      macros,
      groups,
      type,
      sort_by,
      auto_calculate = "carbs",
      document,
    } = this.state.doc;

    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }

    if (!title.trim()) {
      errors.title = ERROR_TITLE("display name");
      out = false;
    }
    //document validation
    const _document = document && document[0] ||  {}
    const {type:documentType, url } = _document;
    if(documentType === "link" && !!url){
      if(!isValidURL(url)){
        out = false;
        errors.document = {url:ERROR_URL};
      }
    }
    //full screen editor in edit mode
    if (!this.isNew && !this.state.editMode) {
      if (type === "macros") {
        //remove validation for macros field(P, F, C) discussed with @tasdeek
        errors.macros = {};
        Object.entries(macros).forEach((item, i) => {
          if (item[0] === "calories" && !item[1]) {
            errors.macros[item[0]] = `Please provide valid ${item[0]} value`;
            out = false;
          }
        });
      }
      if (!groups.length && isPublish) {
        this.props.enqueueSnackbar(
          "Please add category to complete the meal plan",
          { variant: "error" }
        );
        errors.category = `Please add category to complete the meal plan`;
        out = false;
      }
    }

    errors.groups = [];
    groups.forEach((cat, i) => {
      errors.groups[i] = {};
      if (!cat.type) {
        errors.groups[i].type = `Please select category for each meal`;
        out = false;
      }
      if (!cat.iftime && sort_by === "time") {
        errors.groups[i].iftime = `Please select date for each meal`;
        out = false;
      }
      if (type === "food") {
        errors.groups[i].food_items = [];
        cat.food_items.forEach((foodItem, mealIndex) => {
          errors.groups[i].food_items[mealIndex] = {};
          if (foodItem.type === "food" && !foodItem.serving_unit_quantity) {
            errors.groups[i].food_items[mealIndex].quantity =
              "Please enter a valid quantity";
            out = false;
          } else if (foodItem.type === "alternative") {
            errors.groups[i].food_items[mealIndex].alternatives = [];
            foodItem.alternatives.forEach((item, altIndex) => {
              errors.groups[i].food_items[mealIndex].alternatives[altIndex] =
                {};
              if (!item.quantity) {
                errors.groups[i].food_items[mealIndex].alternatives[
                  altIndex
                ].quantity = "Please enter a valid quantity";
                this.props.enqueueSnackbar("Please enter a valid quantity", {
                  variant: "error",
                });
                out = false;
              }
            });
          }
        });
      }

      if (!!cat.food_items && !cat.food_items.length && type !== "macros") {
        errors.groups[
          i
        ].food_item = `Please add food items in each category to complete the meal plan`;
        out = false;
      }
    });
    this.setState({ errors });
    return out;
  };

  deleteItem = () => {
    const { onDelete, enqueueSnackbar, hideLoader, showLoader } = this.props;
    showLoader();
    mealRepo(this.context.cid,this.draftFeature)
      .delete(this.docId)
      .then((doc) => {
        hideLoader();
        if (doc) {
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Meal deleted successfully.", { variant: "success" });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };


  handleRemoveDocument = async () => {
    const { showLoader, hideLoader, enqueueSnackbar } = this.props;
    const { doc } = this.state;
    try {
      showLoader();
      let updatedDoc = update(doc, {
        document: {
          $set: [],
        },
      });
      await mealRepo(this.context.cid,this.draftFeature).update(this.docId, updatedDoc);
      this.setState({ doc: updatedDoc, oldDoc: updatedDoc });
      hideLoader();
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      hideLoader();
    }
  };

  copyItem = () => {
    const { insert, onSave, enqueueSnackbar, hideLoader, showLoader } =
      this.props;
    const { doc } = this.state;
    showLoader();
    let copyDoc = update(doc, {
      ref_name: {
        $set: `Copy of ${doc.ref_name}`,
      },
    });
    mealRepo(this.context.cid,this.draftFeature)
      .create(copyDoc)
      .then((doc) => {
        hideLoader();
        if(doc){
          insert({
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: doc.data().ref_name,
              type: doc.data().type,
              dtype: doc.data().type,
              macros: _.get(doc.data(), "macros", {}),
              thumbnail: doc.data().thumbnail,
              publish_status:doc.data().publish_status
            },
          });
        }
        if (!!onSave) onSave(doc);
        enqueueSnackbar("Meal copy successfully.", { variant: "success" });
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
 
  handlePublish=async(addToLibrary)=>{
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      //TODO: check this flow with Draft Feature
      await this.toggleDoc(DRAFT);
    }
    await this.onSubmit(PUBLISHED, true, addToLibrary);
  }
  handleDraft=async(close=true)=>{
    this.onSubmit(DRAFT,close);
  }

  closeLibraryConfirmation = () => {
    this.setState({ isAddToLibraryConfirmationOpen: false, invokeCloseEditor: false });
  }

  handleMealSave = async (addToLibrary = false) => {
    const {currentConfirmation} = this.state;
    if(currentConfirmation === "save") await this.closeEditor("done", addToLibrary);
    else if(currentConfirmation === "publish") this.handlePublish(addToLibrary);
    else this.onSubmit(PUBLISHED, true, addToLibrary)
    this.closeLibraryConfirmation()
  }
  
  handleConfirmation = (type) => {
    if(this.props.createNew && this.state.dirty) {
      this.setState({isAddToLibraryConfirmationOpen: true, currentConfirmation: type});
    } else {
      if(type === "save") this.closeEditor("done")
      else if(type === "publish") this.handlePublish();
      this.onSubmit();
    }
  }
  
  onSubmit = async (docType = PUBLISHED, closeOnSaving = true, addToLibrary = false) => {
    const { insert, onSave, enqueueSnackbar, hideLoader, showLoader } =
      this.props;
    const { doc } = this.state;
    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED'];
    if (!this.valid(isPublish)) {
      this.setState({isConfirmationOpen:false});
      return;
    }
    showLoader();
    const { thumbnail } = doc;
    let updatedDoc = { ...doc };

    if (thumbnail && thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: MEAL_STORAGE_FILE_PATH,
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original", "240"),
        },
      });
    }
    //preparation for documents::
    const {document} = updatedDoc;
    const _document = document && document[0] || {};
    const _document_type = _.get(_document, "type", "");
    const _isLocalFile = _.get(_document, "isLocal", false);
    if(_document_type === "file" && _isLocalFile &&  _document.url){ //ie raw pdf save to doc state for showing progress
      try {
        const _docResponse = await uploadPdf({ cid: this.context.cid, file: _document.url });
        updatedDoc = update(updatedDoc, {
          document: {
            $set: [_.assign(_docResponse,{name:_.get(_document,"name")})], //assign extra filed here like name
          },
        });
      } catch (err) { //If document upload failed then show error and return::
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
        return;
      }
    }
    if(updatedDoc.type === 'food'){
      // let foodItems = [];
      // make new foodItems store as food items are not deleted from cache when they are deleted from any group
      // updatedDoc.groups.forEach((grp) => {
      //   const { food_items: grpFoods } = grp;
      //   grpFoods.forEach((item) => {
      //     if(item.type === 'food'){
      //       if(_.find(foodItems, (i) => i.id === item.ref.id)) return;
      //       foodItems.push(_.find(this.state.foodRecipes, (i) => i.id === item.ref.id));
      //     } else item.alternatives.forEach((altItem) => {
      //       if(_.find(foodItems, (i) => i.id === altItem.ref.id)) return;
      //       foodItems.push(_.find(this.state.foodRecipes, (i) => i.id === altItem.ref.id));
      //     });
      //   });
      // });
      // updatedDoc = processMetaData(updatedDoc, foodItems);
      // const updatedDoc2 = processMetaData2(updatedDoc, this.state.foodRecipes);
      updatedDoc=processMetaData(updatedDoc, this.state.foodRecipes);
    };
    const _rdxState = {
      cid: this.context.cid,
      ref_name: updatedDoc.ref_name,
      dtype: updatedDoc.type,
      macros: _.get(updatedDoc, "macros", {}),
      thumbnail:(calculatedDocType===DRAFT_STATES['DRAFT_ONLY']||calculatedDocType===DRAFT_STATES["PUBLISHED"])?updatedDoc.thumbnail:_.get(this.state.publishedDoc,'thumbnail'),
      publish_status: calculatedDocType,
    };
    if (this.isNew) {
      mealRepo(this.context.cid,this.draftFeature)
        .create(updatedDoc)
        .then((doc) => {
          bffUpdateHubspotProp(HUBSPOT_PROPS.MEAL_PLAN_CREATED);
          hideLoader();
          if(doc){
            insert({
              _id: doc.id,
              data: _rdxState,
            });
            if (!!onSave) onSave(doc);
            if (this.props.scheduleSaveMealEdit) {
              this.props.scheduleSaveMealEdit(updatedDoc);
            }
          }
        })
        .catch((err) => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    } else {
      let request = () =>
        mealRepo(this.context.cid,this.draftFeature).update(
          this.docId,
          updatedDoc,
          null,
          docType
        );
      if (this.props.createNew) {
        updatedDoc.srcId = this.docId;
        updatedDoc.userProfileId = this.props.userProfileId;
        if(addToLibrary){
          updatedDoc.archive = false;
          updatedDoc.ref_name = `${DUPLICATE_REF_PREFIX} ${updatedDoc.ref_name}`;
        } else {
          updatedDoc.archive = true;
        }
        const docRef = await firebase
          .firestore()
          .collection(`companies/${this.context.cid}/meals`)
          .doc(`modified_${uuidv4()}`);
        await docRef.set(updatedDoc);
        request = () => docRef.get();
      }
      request()
        .then((doc) => {
          hideLoader();
          if (doc) {
            this.props.update({
              _id: doc.id,
              data: _rdxState,
            });
            if (this.props.scheduleSaveMealEdit) {
              this.props.scheduleSaveMealEdit(updatedDoc, doc.id);
            }
            this.setState(o=>({
              editMode: false,
              mealEditorMode: !closeOnSaving,
              doc: {...updatedDoc,publish_status: doc.data().publish_status},
              oldDoc: {
                ...updatedDoc,
                publish_status: doc.data().publish_status,
              },
              publishedDoc:docType===PUBLISHED?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
              draftDoc:docType === DRAFT ? doc.data() : { ...(o.draftDoc || {}) },
              dirty: false,
              showingDoc:docType,
              isConfirmationOpen:false
            }));
          }
        })
        .catch((err) => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    }
  };

  handleMedia = (file) => {
    let newState;
    if (file) {
      newState = update(this.state, {
        doc: {
          thumbnail: {
            $set: {
              file: file,
              url: URL.createObjectURL(file),
            },
          },
        },
      });
    } else {
      newState = update(this.state, {
        doc: {
          thumbnail: {
            $set: "",
          },
        },
      });
    }
    this.setState({ ...newState, errors: {} });
  };

  addMealCategory = () => {
    let data = {
      calories: 0,
      type: "",
      food_items: [],
      iftime: null, //TODO :: need to decide date according to the meal type
    };
    const newState = update(this.state, {
      doc: {
        groups: {
          $push: [data],
        },
      },
    });
    this.setState({ ...newState, dirty: true });
  };

  handleImportedMealGroups = async (mealGroups, cb) => {
    const { doc } = this.state;
    const { groups } = doc || {};
    let newState = { ...this.state };
    let recpIds = [];

    mealGroups.forEach((_mg) => {
      const _gIndex = groups.findIndex((g) => g.type === _mg.type);
      if (_gIndex === -1) {
        newState = update(newState, {
          doc: {
            groups: {
              $push: [_mg],
            },
          },
        });
      } else {
        newState = update(newState, {
          doc: {
            groups: {
              $splice: [[_gIndex, 1, _mg]],
            },
          },
        });
      }
    });
    //TODO ::need to do sort
    //fetch newly added food & recipes::
    _.forEach(newState.doc.groups, (g) => {
      let fItems = g.food_items || [];
      _.forEach(fItems, (f) => {
        if (f.type === "food") recpIds.push(f.ref.id);
        else if (f.type === "alternative") {
          _.forEach(f.alternatives, (af) => {
            recpIds.push(af.ref.id);
          });
        }
      });
    })
    const foodRecipesData = await this.fetchRecipes(recpIds);
    newState = update(newState, {
      foodRecipes: {
        $push: [...foodRecipesData],
      },
    });
    this.setState({...newState, dirty:true }, this.updateDocMacros) //callback for updating macros
    cb && cb();
  }

  updateDocMacros = () => {
    const { doc, foodRecipes } = this.state;
    const newDoc = calculateMealMacros(foodRecipes, doc);
    let newState = update(this.state, {
      doc: {
        $set: newDoc,
      },
    });
    this.setState({...newState})
  }
  toggleDoc = async (docType,fullSanitize=false) => {
    const { draftDoc, publishedDoc } = this.state;
    const doc = docType === DRAFT ? { ...draftDoc } : { ...publishedDoc };
    if (!Object.keys(doc).length) return;
    const respDoc=await this.parseCurrentDoc(doc,fullSanitize);
    this.setState(o=>({ 
      showingDoc: docType,
      doc:{...respDoc},
      oldDoc:fullSanitize?{...o.oldDoc}:{...respDoc}
      /* Note: oldDoc doesn't have full-sanitize code(meant for food-editor only),
        so that when foodEditor exists, doc can copy oldDoc.
       */
    }));
  };
  discardDraft = () => {
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    mealRepo(this.context.cid,this.draftFeature)
      .deleteDraft(this.docId, null, this.state.doc.publish_status)
      .then((doc) => {
        hideLoader();
        if (!doc) return;
        const updatedDoc = doc.data();
        if (
          _.get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]
        ) {
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
        } else if (
          _.get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]
        ) {
          this.setState((o) => ({
            oldDoc: { ...updatedDoc },
            doc: { ...updatedDoc },
            publishedDoc:{...o.publishedDoc,publish_status:PUBLISHED},
            showingDoc:PUBLISHED
          }));
          this.props.update({
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: updatedDoc.ref_name,
              dtype: updatedDoc.type,
              macros: _.get(updatedDoc, "macros", {}),
              thumbnail: updatedDoc.thumbnail,
              publish_status: updatedDoc.publish_status,
            },
          });
        }
        enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
  toggleOrigDocPopup = () => {
    this.setState((o) => ({
      viewOrigDoc: !o.viewOrigDoc,
    }));
  };
  openEditor=()=>{
    this.setState({ mealEditorMode: true });
    const MODE=this.isDraftAvailable && this.state.showingDoc !== DRAFT?DRAFT:PUBLISHED;
    // if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
    //   this.toggleDoc(DRAFT,true);
    // }
    this.toggleDoc(MODE,true);
  }
  isMealEmpty=()=>{
    return !_.get(this.state.doc,"groups",[]).length;
  }
  render() {
    const {
      loader,
      doc,
      oldDoc,
      errors,
      editMode,
      mealCategories,
      serving,
      isConfirmationOpen,
      isValidId,
      isImport = false,
      showingDoc,
      viewOrigDoc,
      dirty,
      isMealEmpty,
      nutritionTags,
      loadingTags,
      isAddToLibraryConfirmationOpen
    } = this.state;
    const { loading, scheduleDoc = {}, isPreview,showOnlyEdit, disableActions } = this.props;
    const mergeOldDoc = {
      ...oldDoc,
      ...scheduleDoc,
    };
    const noGroups = !_.get(mergeOldDoc, "groups", []).length;
    if (!isValidId) return <PageNotFound keyName="meal" />;
    if (this.isNew)
      return (
        <CreateEditForm
          loading={loading||loadingTags}
          isNew
          doc={doc}
          errors={errors}
          handleChange={this.handleChange}
          handleChangeType={this.handleChangeType}
          onCancel={this.props.onCancel}
          onSubmit={this.onSubmit}
          handleMedia={this.handleMedia}
          nutritionTags={nutritionTags}
          handleDocument={this.handleDocument}
        />
      );
    if (isImport) {
      return (
        <>
          {loader && (
            <CircularLoader centerAlign={true} style={{ zIndex: 2 }} />
          )}
          <FoodItemsForm
            doc={mergeOldDoc}
            disableItemClick={this.props.disableItemClick}
            storedFoodRecipes={this.state.foodRecipes}
            mealCategories={mealCategories}
            serving={serving}
            isImport={true}
            onSelectImportMeals={this.props.onSelectImportMeals}
            checkImportedMealGroup={this.props.checkImportedMealGroup}
            handleImportedMealGroups={this.handleImportedMealGroups}
          />
        </>
      );
    }
    return (
      <DetailPgStyling className="overflow-x-hidden h-100 position-relative d-flex flex-column flex-grow-1">
        {loader && <CircularLoader className="position-absolute" />}
        {doc && !loader && (
          <>
            <div>
              <Details
                doc={{ ...oldDoc, ...scheduleDoc }}
                handleEditMode={this.handleEditMode}
                storedFoodRecipes={this.state.foodRecipes}
                handleMealEditMode={this.openEditor}
                mealCategories={mealCategories}
                isPreview={isPreview}
                showOnlyEdit={showOnlyEdit}
                copyItem={this.copyItem}
                deleteItem={this.deleteItem}
                disableActions={disableActions}
                nutritionTags={nutritionTags}
                draftBannerProps={{
                  toggleDoc:this.toggleDoc,
                  isDraftAvailable:this.isDraftAvailable,
                  handlePublish:this.handlePublish,
                  discardDraft:this.discardDraft,
                  showingDoc:showingDoc,
                }}
              />
              {editMode && (
                <CreateEditForm
                  loading={loading}
                  doc={doc}
                  errors={errors}
                  handleChangeType={this.handleChangeType}
                  handleChange={this.handleChange}
                  handleDocument={this.handleDocument}
                  onCancel={this.closeEditor}
                  onSubmit={() => this.handleConfirmation("submit")}
                  handleMedia={this.handleMedia}
                  handleDraft={this.handleDraft}
                  handlePublish={this.handlePublish}
                  draftFeature={this.draftFeature}
                  isDraftAvailable={this.isDraftAvailable}
                  nutritionTags={nutritionTags}
                />
              )}
            </div>
            {!isPreview && noGroups && mergeOldDoc.type !== "macros" && (
              <>
                <Divider className="mb-20"/>
                <EditorPlaceholder
                  className="mb-20"
                  keyName={"meal-macro-detail-placeholder"}
                  cta={() => (
                    <ClrdButton
                      className="f-h40w150 mt-20"
                      variant="contained"
                      color="primary"
                      onClick={() => this.setState({ mealEditorMode: true })}
                    >
                      Add Meals
                    </ClrdButton>
                  )}
                />
              </>
            )}
            {!noGroups && (
              <Divider
                className="position-relative"
                style={{ left: 20, width: "calc(100% - 40px)" }}
              />
            )}
            <FoodItemsForm
              doc={mergeOldDoc}
              storedFoodRecipes={this.state.foodRecipes}
              mealCategories={mealCategories}
              serving={serving}
              onSelectImportMeals={this.props.onSelectImportMeals}
              checkImportedMealGroup={this.props.checkImportedMealGroup}
              handleImportedMealGroups={this.handleImportedMealGroups}
              disableItemClick={this.props.disableItemClick}
            />
          </>
        )}
        {this.state.mealEditorMode && doc.type === "macros" && (
          <MacrosForm
            doc={doc}
            handleChange={this.handleChange}
            errors={errors}
            loading={loading}
            onCancel={this.closeEditor}
            onSubmit={() => this.handleConfirmation("submit")}
            updateParent={this.updateParent}
            handleMacros={this.handleMacros}
            mealCats={mealCategories}
            handleDraft={this.handleDraft}
            handlePublish={() => this.handleConfirmation("publish")}
            draftFeature={this.draftFeature}
            isNew={this.isNew}
            dirty={dirty}
            isDraftAvailable={this.isDraftAvailable}
          />
        )}

        {this.state.mealEditorMode && doc.type === "food" && (
          <Dialog
            open
            fullScreen
            withConfirmation
            isDirty={this.state.dirty||isMealEmpty}
            showCustomConfirmation={this.draftFeature && !this.isNew && !isMealEmpty}
            confirmationProps={{
              msg:isMealEmpty?EMPTY_MEAL_DISCARD_MSG:DISCARD_MSG
            }}
            appBarColor="bg-white"
            paperClass="bg-offWhite"
            titleFont="h3"
            buttonColor="primary"
            toolbarClass="height-40"
            actionText="Save"
            title={
              <Title
                doc={doc}
                toggleOrigDocPopup={this.toggleOrigDocPopup}
                viewOrigDoc={viewOrigDoc}
                isDraftAvailable={this.isDraftAvailable}
              />
            }
            onClose={() => this.closeEditor("close")}
            onSave={() => this.handleConfirmation("save")} //Close Button on UI
            additionalActions={
              <ClrdButton
                className={clsx(
                  "f-medium",
                  !this.draftFeature && "d-none"
                )}
                onClick={() => this.handleDraft(false)}
                disableElevation
                color={"invert"}
                variant="contained"
              >
                <SaveIcon
                  className={"mr-5"}
                  color={ "primary"}
                />
                Save
              </ClrdButton>
            }
          >
            <FoodItemsForm
              editorMode
              doc={doc}
              handleChange={this.handleChange}
              handleQuantityChange={this.handleQuantityChange}
              handleServingChange={this.handleServingChange}
              errors={errors}
              loading={loading}
              storedFoodRecipes={this.state.foodRecipes}
              onSubmit={() => this.handleConfirmation("submit")}
              handleFoodRecipesAdd={this.handleFoodRecipesAdd}
              addMealCategory={this.addMealCategory}
              handleMealType={this.handleMealType}
              handleMealIfTime={this.handleMealIfTime}
              handleDelete={this.handleDelete}
              handleReorder={this.handleReorder}
              mealCategories={mealCategories}
              serving={serving}
              removeTimePicker={this.removeTimePicker}
              updateParent={this.updateParent}
              updateParentErrors={this.updateParentErrors}
              handleImportedMealGroups={this.handleImportedMealGroups}
              onSelectImportMeals={this.props.onSelectImportMeals}
              checkImportedMealGroup={this.props.checkImportedMealGroup}
            />
            {viewOrigDoc && (
              <PublishedDrawer
                onClose={this.toggleOrigDocPopup}
                keyName={VIEWS_CONSTANTS.MEAL}
              />
            )}
          </Dialog>
        )}
        {isConfirmationOpen && (
          <CustomConfirmation
            open
            handleClose={() => this.setState({ isConfirmationOpen: false })}
            handleDiscard={() => this.closeEditor()}
            handleDraft={this.handleDraft}
            handlePublish={this.handlePublish}
            dirty={dirty}
            draftFeature={this.draftFeature}
          />
        )}
        <LibraryConfirmation 
            open={isAddToLibraryConfirmationOpen} 
            docType='meal'
            onClose={this.closeLibraryConfirmation} 
            action1={() => this.handleMealSave(false)}
            action2={() => this.handleMealSave(true)}
          />
      </DetailPgStyling>
    );
  }

  get authUser() {
    return this.context.authUser;
  }
  get _sortServing(){
    return this.state.serving.map(i=>({
      value:i[1].value,
      id:i[0]
    }))
  }
  parseCurrentDoc=async(doc,fullSanitize=false)=>{
    let ids = [],foodRecipes;
    if (doc.groups && !!doc.groups.length) {
      doc.groups.forEach((group) => {
        if (group.food_items && !!group.food_items.length) {
          group.food_items.forEach((item) => {
            if (item.type === "food") {
              ids.push(item.ref.id);
            } else {
              item.alternatives.forEach((altItem) => {
                ids.push(altItem.ref.id);
              });
            }
          });
        }
      });
    }
    let newDoc=_.cloneDeep(doc);
    foodRecipes = await this.fetchRecipes(_.uniq(ids));
    const fetchedItems=[...this.state.foodRecipes,...foodRecipes];
    //can only sanitize after all fetch is complete
    if(doc.type==="food"){//food based meal
      let servingItems=this._sortServing;
      if(!servingItems.length){
        servingItems=await this.fetchServing();
        servingItems=exportTags(servingItems,'id','value');
      }
      doc.groups.forEach((g,gIndex)=>{
        g.food_items.forEach((i,fIndex)=>{
          if(i.type==="food"){
            const item={...this.sanitizeEachItem(fullSanitize,fetchedItems,i,servingItems,g.type,doc.meta)};
            newDoc=update(newDoc,{
              groups:{
                [gIndex]:{
                  food_items:{
                    [fIndex]:{
                      $merge:{
                        ...item
                      }
                    }
                  }
                }
              }
            });
          }
          else{
            //alts
            i.alternatives.forEach((a,aIndex)=>{
              const item=this.sanitizeEachItem(fullSanitize,fetchedItems,a,servingItems,g.type,doc.meta);
              newDoc=update(newDoc,{
                groups:{
                  [gIndex]:{
                    food_items:{
                      [fIndex]:{
                        alternatives:{
                          [aIndex]:{
                            $merge:{
                              ...item
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            })
          }
      });
    });
    }
    this.setState((o) => ({
      foodRecipes: [...fetchedItems],
      // doc:{...newDoc},
      // oldDoc: { ...newDoc },
    }));
    return {...newDoc};
  };

  sanitizeEachItem=(fullSanitize,fetchedItems,item,servingItems,grpId,meta={})=>{
    const data=fetchedItems.find(i=>i.id===item.ref.id);
    //RECIPE:
    if(data.type==="recipe"){
      return {
        ...item,
        serving_unit:data.serving,
        serving_unit_quantity:item.quantity
      };
    } 
    //FOOD:
    const standardUnit=findStandardUnit(data.serving_type,servingItems);
    if(!item.serving_unit){//Old structure only
      item["serving_unit"]=standardUnit.id;
      item["serving_unit_quantity"]=item.quantity*data.serving_size
    }
    else{
      //new structure
      const serving_options=this.props.getDataWExtraServingOptions(data).serving_options;
      const isServingUnitValid=(serving_options).find(i=>i.id===item.serving_unit);
      if(!isServingUnitValid){
        //check if std Unit or toggleStdUnit is used
        const toggleStandardUnit=findStandardUnit(toggleServingType(data.serving_type),servingItems);
        if(item.serving_unit===standardUnit.id){//gm
          //update quantity
          const ratio=getRatio(item.serving_unit_quantity,data.serving_size);
          item['quantity']=!data.serving_size?item.serving_unit_quantity:ratio; //Todo:getratio edge case
        }
        else if(item.serving_unit===toggleStandardUnit.id){//ml
          //update quantity and serving_unit
          item["serving_unit"]=standardUnit.id;
          const ratio=getRatio(item.serving_unit_quantity,data.serving_size); 
          item['quantity']=!data.serving_size?item.serving_unit_quantity:ratio; //Todo:getratio edge case
        }
        else{
          //serving_option deleted from food
          //update all keys
            const metaQuantity=_.get(meta,[item.ref.id,"groups",grpId,'quantity'],1);
            const factor=(metaQuantity*data.serving_size)/item.serving_unit_quantity;
            item["quantity"]=metaQuantity;
            if(fullSanitize){//only meant for Food-Editor
              item['serving_unit']=standardUnit.id;
              item['serving_unit_quantity']=item['serving_unit_quantity']*(factor||1)
           }
        }
      }
      else{
        //update quantity
        item['quantity']=getRatio(isServingUnitValid.serving_size,data.serving_size)*item.serving_unit_quantity;
      }
    }
    return item;
  }
  getMeal=async()=>{
    try{
      const scheduleDoc= this.props.scheduleDoc
        ? _.cloneDeep({ ...this.props.doc, ...this.props.scheduleDoc })
        : null;
      this.setState({ loader: true });
      let doc, oldDoc, publishedDoc, draftDoc, showingDoc;
      //get publishgeted doc
      const mealDoc= scheduleDoc||await this.fetchMeal();
      if (!(mealDoc.exists || scheduleDoc)) {
        this.setState({
          isValidId: false,
          // loader: false,
        });
        return;
      }
      publishedDoc = mealDoc.data ? mealDoc.data() : {...mealDoc};
      //-------- one time migration for diet_types in published doc---------
      //------- No need to migrate from user module--------
      if(!!_.get(publishedDoc, 'diet_type', "") && !publishedDoc.systags && !scheduleDoc){
        const migratedDietType = migrateDietType(publishedDoc);
        publishedDoc=await mealRepo(this.context.cid,this.draftFeature).update(this.docId,migratedDietType,null);
        publishedDoc=publishedDoc.data?publishedDoc.data():publishedDoc;
      }
      //------------------------------------------------------------
      //if draft feature is off, treat main collection doc as published
      if (!this.draftFeature) {
        publishedDoc["publish_status"] =DRAFT_STATES['PUBLISHED'];
      }
      const publish_status = _.get(publishedDoc, "publish_status", PUBLISHED);
      if (publish_status.includes("draft")) {
        //get draft doc
        const draftWo = await this.fetchMeal(true);
        draftDoc = !!draftWo.data ? draftWo.data() : draftWo;
      }
      if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
        doc={...draftDoc};
        oldDoc={...draftDoc};
        showingDoc=DRAFT;
      } else {
        doc={...publishedDoc};
        oldDoc={...publishedDoc};
        showingDoc=PUBLISHED;
      }
      const respDoc=await this.parseCurrentDoc(doc);
      this.setState({
        draftDoc,
        publishedDoc,
        showingDoc,
        loader: false,
        doc:{...respDoc},
        oldDoc:{...respDoc}
      });
    }
    catch(err){
      const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
      this.setState({loader:false});
      Sentry.captureException(err);
    }
  };
  fetchServing = () => getTags("serving", undefined, this.context.comp);
  componentDidMount() {
    if (!this.isNew) this.getMeal();
    this.setState({ loadingTags: true });
    const fetchNutritionTags = () => getTags("nutrition", "meals", this.context.comp);
    const fetchMealCategories = () => getTags("mealCategories", undefined, this.context.comp);
    Promise.all([fetchMealCategories(), this.fetchServing(), fetchNutritionTags()])
      .then(async ([mealCat, servingData, nutritionTags]) => {
        this.setState({
          mealCategories:Object.entries(mealCat).sort(
            (a, b) => a[1].order - b[1].order
          ),
          serving: Object.entries(servingData),
          nutritionTags: exportTags(nutritionTags),
          loadingTags: false,
        });
      })
      .catch((err) => {
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
      });
  }
  componentDidUpdate(prevProps,prevState) {
    //---------------------drawer copy & edit--------------------------
    if (
      prevProps.showOnlyEdit !== "copy" &&
      this.props.showOnlyEdit === "copy" &&
      this.props.scheduleSaveMealEdit
    ) {
      //send back the doc;
      this.props.scheduleSaveMealEdit(this.state.doc);
    }
    //-----------------------isMealEmpty check------------------------
    if (prevState.doc !== this.state.doc) {
      const isMealEmpty=this.isMealEmpty();
      if(isMealEmpty!==this.state.isMealEmpty){
        this.setState({
          isMealEmpty
        });
      }
    }
  }

  fetchMeal = (isDraft = false) => {
    return mealRepo(this.context.cid, this.draftFeature).doc(
      this.docId,
      null,
      isDraft
    );
  };

  fetchRecipes = async (ids) => {
    const { foodRecipes, loader } = this.state;
    let existIds = [];
    if (foodRecipes && foodRecipes.length) {
      foodRecipes.forEach((ex) => {
        existIds.push(ex.id);
      });
    }
    const newIds = ids.filter((d) => existIds.indexOf(d) === -1);
    if (!loader) this.props.showLoader();
    const recipesData = await Promise.all(
      newIds.map(async (id) => {
        const cacheData = this.props.objCache &&
          this.props.objCache[id] && { data: () => this.props.objCache[id] };
        const data =
          cacheData ||
          (await foodRecipesRepo(this.context.cid, this.draftFeature).doc(id));
        if (!!data) {
          const d = data.data();
          if (!cacheData && this.props.objCache) this.props.objCache[id] = d;
          return { ...d, id };
        }
      })
    );
    if (!loader) this.props.hideLoader();
    //only new ids data is returned;
    return recipesData;
  };
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === 'new')
    return {
      doc: { ..._default },
      loading: s.meals.loading,
    };
  else return { doc: undefined, loading: s.meals.loading };
};

const mapDispatchToProps = (d) => {
  const { showLoader, hideLoader } = appRdxFns(d);
  return { ...mealRdxFns(d), showLoader, hideLoader };
};


const MealInfoWrapper=(props)=>{
  const { serving: servingData, fetchTags } = useContext(TagsContext);
  const { unitFactors } = useContext(Conversion);
  const [_sortServing,n,n2,dumbUnits] = useServings({servingData:servingData||[]});
  const additionalServingOptions=useMemo(()=>{
    //creating dumbunits factors
    if(!!unitFactors && !!dumbUnits){
      return dumbUnits.map(i=>({
        id:i.id,
        label:i.value,
        serving_size:getDbValue(unitFactors,1,i.id)
      }));
    }
    return [];
  },[unitFactors,dumbUnits]);
  useEffect(() => {
    fetchTags('serving');
  }, [fetchTags]); 
  const getDataWExtraServingOptions=(data={})=>{
    let _additionalServingOptions=[...additionalServingOptions];
    const {serving_size}=data;
    if(!serving_size){
      _additionalServingOptions=_additionalServingOptions.map(i=>({...i,serving_size:1}));
    }
    return {
      ...data,
      serving_options:[
        ...(data.serving_options||[]),
        ...(_additionalServingOptions||[])
      ]
    }
  }
  if (!servingData) return null;
  return(
    <MealInfo {...props} getDataWExtraServingOptions={getDataWExtraServingOptions}/>
  )
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(MealInfoWrapper));
