import { oprtnlDateFormat } from 'fitbud/utils/constants';
import moment from 'moment';
import React from 'react';
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
const { Hidden, IconButton, MenuItem, Select, Typography, makeStyles } = require("@material-ui/core");

export const dateRangeToDisplay = (startDate, endDate, format1='D MMM', format2='D MMM') => {
  if(moment().startOf('day').isSame(moment(startDate), 'day')) return `${moment(startDate).format(format2)}`;
  return `${moment(startDate).format(format1)} - ${moment(endDate).format(format2)}`;
}

const TimeLineNav = ({handleViewLimitSelect, viewLimit, navigateDates, selectedPeriod, planStartDate, planDuration}) => {
  const handleSelect = (e) => {
    const { value } = e.target;
    if(viewLimit === value) return;
    handleViewLimitSelect(value);
  };

  // Disable navigation conditions
  const disableNext = moment(selectedPeriod.endDate).isSameOrAfter(moment(planStartDate, oprtnlDateFormat).add(planDuration - 1, 'days'), 'day') || 
    moment(selectedPeriod.endDate).isSameOrAfter(moment(), 'day');
  const disablePrevious = moment(selectedPeriod.startDate).isSameOrBefore(moment(planStartDate, oprtnlDateFormat), 'day');

  const { selectField } = styles();
  return (
    <div className='bg-white w-100 d-flex fpx-20 fpy-5 border-bottom'>
      <div className='d-flex align-items-center ml-md-auto'>
        <IconButton edge='start'
          className="fp-5"
          disabled={disablePrevious}
          onClick={navigateDates('prev')}
        >
          <LeftIcon  />
        </IconButton>
          <div className='d-flex flex-column flex-md-row align-items-center justify-content-center fmx-15'>
            {viewLimit === 'weekly' ? (
              <Typography variant='h5' className='font-weight-bold'>
                {selectedPeriod.name}
              </Typography>
            ) : null}
            {viewLimit === 'weekly' ? <Hidden mdDown>
              <Typography variant='h5' className='mx-5 font-weight-bold'>
                &nbsp;&bull;&nbsp;
              </Typography>
            </Hidden> : null}
            <div className='d-flex align-items-center justify-content-center'>
              {viewLimit=== 'weekly' ? (
                <Typography variant='h5' className='font-weight-bold'>
                  {dateRangeToDisplay(selectedPeriod.startDate, selectedPeriod.endDate)}
                </Typography>
              ) : (
                <Typography variant='h5' className='font-weight-bold'>
                  {dateRangeToDisplay(selectedPeriod.startDate, selectedPeriod.endDate)}
                </Typography>
              )}
            </div>
          </div>
        <IconButton edge='end'
          className="fp-5"
          disabled={disableNext}
          onClick={navigateDates('next')}
        >
          <RightIcon />
        </IconButton>
      </div>
      <Select
        value={viewLimit}
        variant='outlined'
        onChange={handleSelect}
        className={'ml-auto cursor-pointer ' + selectField}
        classes={{root: 'fpx-15 fpy-10 font_14_500 font-weight-bolder'}}
      >
        <MenuItem value={'weekly'}>WEEKLY</MenuItem>
        <MenuItem value={'monthly'}>MONTHLY</MenuItem>
      </Select>
    </div>
  )
}
const styles = makeStyles(theme => ({
  selectField:{
    borderRadius: '7px',
  }
}))
export default TimeLineNav;
