import React, { useContext } from 'react';
import ScheduleSelector from 'fitbud/views/schedulePlanner/scheduleSelector';
import WorkoutSelector from 'fitbud/views/schedules/workoutSelector';
import MealPlanSelector from 'fitbud/views/users/mealSelector';
import SupplementSelector from 'fitbud/views/users/supplementSelector';
import { UserSchContext } from '../provider';

const Picker = () => {
  const { opimport, controller } = useContext(UserSchContext);
  if (!opimport) return null;
  const onSelect = (args) => {
    controller.importSave(opimport, args);
  };
  const { existing, purpose } = opimport;
  switch (purpose) {
    case 'woSch':
      return <ScheduleSelector open onClose={controller.importClose} importType='woImport' handleAdd={onSelect}/>;
    case 'altWo':
    case 'wo':
      return <WorkoutSelector open onClose={controller.importClose} moduleName='schedule' handleAdd={onSelect} selectedWOs={existing} showDraftInd={true}/>;
    case 'mlSch':
      return <ScheduleSelector open onClose={controller.importClose} importType='mlImport' handleAdd={onSelect}/>;
    case 'ml':
    case 'mlms-single':
    case 'mlms-on':
    case 'mlms-off':
      return <MealPlanSelector open onClose={controller.importClose} handleAdd={onSelect}/>;
    case 'sl':
    case 'slms-single':
    case 'slms-on':
    case 'slms-off':
      return <SupplementSelector open onClose={controller.importClose} handleAdd={onSelect}/>;
    default: return null;
  }
};

export default Picker;
