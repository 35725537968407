import firebase from "firebase";
import _ from "lodash";
import update from 'immutability-helper';

export function multiplier(type) {
  switch (type) {
    case "days":
      return 1;
    case "months":
      return 30;
    case "weeks":
      return 7;
    default:
      return 1;
  }
}
export function durationInTypes(duration, type) {
  switch (type) {
    case "days":
      return duration;
    case "months":
      return duration / 30;
    case "weeks":
      return duration / 7;
    default:
      return duration;
  }
}

export function durationTypes(duration, type) {
  if (type === "months") {
    if (duration % 30 === 0) {
      return "months"
    } else if (duration % 7 === 0) {
      return "weeks"
    }
  } else if (type === "weeks") {
    if (duration % 7 === 0) {
      return "weeks"
    } else if (duration % 30 === 0) {
      return "months"
    }
  }
  return "days";
}

export const getEmptySchedule = (type) => {
  const key = type === 'workout' ? 'wo' : 'ml';
  return ({
    duration: 7,
    durationType: "days",
    ref_name: "",
    title:'',
    thumbnail: '',
    data: {
      w1: {
        d1: { [key]: [] },
        d2: { [key]: [] },
        d3: { [key]: [] },
        d4: { [key]: [] },
        d5: { [key]: [] },
        d6: { [key]: [] },
        d7: { [key]: [] },
      },
    },
  })
};
export const getAllIDsToFetch = (data, scheduleType, range=false) => {
  let out= [];
  Object.keys(data.data)
  .filter((w)=> Math.ceil(data.duration / 7) >= Number(w.replace('w', ''))) // remove weeks outside of duration
  .forEach((weekKey) => {
    const weekIdx = Number(weekKey.replace('w', ''));
    const weekData = _.get(data.data, weekKey, {});
    const [start, end] = range || [];
    if(range && (weekIdx < start || weekIdx > end)) return;
    Object.keys(weekData)
    .forEach((dayKey) => {
      if((((weekIdx - 1) * 7) + Number(dayKey.replace('d', ''))) > data.duration) return;
      let dayIds = _.get(weekData, `${dayKey}.${getDataKey(scheduleType)}`, []);
      dayIds.forEach((id) => {
        let itemsArr = id.split('/');
        itemsArr = itemsArr.filter((o) => !out.includes(o) && o !== 'rest');
        out.push(...itemsArr);
      })
    })
  });
  return out;
};

export const getWoMlFirebase = async (cid, ids, { type='workout' } = {}) => {
  let out = {};
  const getAllWos = ids.map((id) => firebase.firestore().doc(`companies/${cid}/${type === 'workout' ? 'workouts' : 'meals'}/${id}`).get());
  const list = await Promise.all(getAllWos);
  if(!list) throw new Error('Unable to fetch, please try again later');
  list.forEach((i) => !!i.exists && (out[i.id] = { ...i.data(), id: i.id }));
  return out;
};
export const getWoMlEmptyWeek = (type) => (
  {
    d1: { [getDataKey(type)]: [] },
    d2: { [getDataKey(type)]: [] },
    d3: { [getDataKey(type)]: [] },
    d4: { [getDataKey(type)]: [] },
    d5: { [getDataKey(type)]: [] },
    d6: { [getDataKey(type)]: [] },
    d7: { [getDataKey(type)]: [] },
  }
);
export const getDataKey = (type) => type === 'workout' ? 'wo' : 'ml';
export const updateDayInWeekInData = (data, weekKey, dayKey, dayData) => {
  // Short form for mutating a day
  let weekData  = _.get(data.data, weekKey, {});
  weekData = update(weekData, {
    [dayKey]:{$set: { ...dayData }}
  });
  const newData = update(data, {
    data: {
      [weekKey]: {
        $set: { ...weekData },
      },
    },
  });
  return newData;
};

export const addIdsToDay = (ids, dayData, type='workout') => {
  let dayIds = dayData[getDataKey(type)] || [];
  if(dayIds && dayIds[0] === 'rest') return {[getDataKey(type)] :[ ...ids ]};
  return { [getDataKey(type)] :[...dayIds, ...ids] };
};

export const updateWeekInData = (data, weekKey, weekData) => {
  // Short form for mutating a week 
  return update(data, {
    data: {
      [weekKey]: {
        $set: { ...weekData },
      },
    },
  });
};

export const processMlScheduleMeta = (doc) =>{
  let out = _.cloneDeep(doc);
  let { data, meta={}, ml_calories_overridden } = doc || {};
  let weekKeys = Object.keys(data || {});
  if(!weekKeys || !weekKeys.length) return out;
  if(!ml_calories_overridden){
    const mealCalories = _.values(meta).map(i=>i.calories).filter((o)=>_.isNumber(o));
    const min_ml_calories = !mealCalories.length ? 0 : Math.min(...mealCalories);
    const max_ml_calories = !mealCalories.length ? 0 : Math.max(...mealCalories);
    out.min_ml_calories = min_ml_calories;
    out.max_ml_calories = max_ml_calories;
  }
  return out;
};

export const processScheduleMeta = (doc) => {
  let out = _.cloneDeep(doc);
  let { data, duration, meta={}, wo_week_overridden, wo_duration_overridden } = doc || {};
  let weekKeys = Object.keys(data || {});
  const weeksCount = Math.ceil(duration / 7);
  if(!weekKeys || !weekKeys.length) return out;
  if(!wo_duration_overridden){
    const woDurations = Object.values(meta).filter((o) => (Number.isInteger(o) && o > 0));
    let min_wo_duration = !woDurations.length ? 0 : Math.min(...woDurations);
    let max_wo_duration = !woDurations.length ? 0 : Math.max(...woDurations);
    out.min_wo_duration = min_wo_duration;
    out.max_wo_duration = max_wo_duration;
  };
  if(!wo_week_overridden){
    let noWorkouts = [];
    weekKeys = weekKeys.sort((a,b) => Number(a.replace('w', '')) - Number(b.replace('w', '')))
    weekKeys.forEach((weekKey, i) => {
      const weekData = _.get(data, weekKey, {});
      const dayKeys = Object.keys(weekData) || [];
      if(weeksCount < (i+1) || ((weeksCount < (i+1)) && dayKeys.length < 7)) return;
      let totalWos = 0;
      dayKeys.forEach((dayKey) => {
        const dayData = dayKey ? _.get(weekData || {}, `${dayKey}.wo`, []) : [];
        totalWos += dayData.filter((o) => o !== "rest").length;
      });
      if(totalWos > 0) noWorkouts.push(totalWos);
    });
    if(noWorkouts.length){
      out.min_wo_week = Math.min(...noWorkouts);
      out.max_wo_week = Math.max(...noWorkouts);
    } else {
      out.min_wo_week = 0;
      out.max_wo_week = 0;
    }
  }
  return out;
};
export const MAX_WEEK_VIEW = 12;
export const getNavigateRange = (currentRange, totalWeeks, direction) => {
  const [start, end] = [...currentRange];
  let newEnd = end;
  let newStart = start;
  if(direction === 'prev'){
    if(end && ((end - MAX_WEEK_VIEW) < MAX_WEEK_VIEW)) newEnd = end - (end - MAX_WEEK_VIEW);
    else newEnd = start - 1;
    newStart -= MAX_WEEK_VIEW;
  } else {
    if(end && ((end + MAX_WEEK_VIEW) >= totalWeeks)) newEnd = totalWeeks;
    else newEnd = end + MAX_WEEK_VIEW;
    newStart += MAX_WEEK_VIEW;
  };
  return [newStart, newEnd];
}
export const validateWoMlSchedule = (doc, type) => {
  let out = _.cloneDeep(doc || {});
  const lastWeek = Math.ceil(doc.duration / 7);
  let errors = {};
  const weeksArr = Array.from(Array(lastWeek).keys());
  weeksArr.forEach((i) => {
    const weekKey = `w${i+1}`;
    if(i+1 > lastWeek) return;
    const weekData = _.get(out.data, weekKey, {});
    Array.from(Array(7).keys()).forEach((j) => {
      if(((7*i)+j+1) > doc.duration) return; //if its out of duration
      const dayKey = `d${j+1}`;
      const items = _.get(weekData[dayKey], getDataKey(type), []);
      if(!items[0]) errors = _.set(errors, [weekKey, dayKey], true);
    });
  });
  return [ errors, out ];
};
export const generateMetaMap = (data, scheduleType, fetchedItems) => {
  const _ids = getAllIDsToFetch(data, scheduleType);
  if(!_ids.length) return {};
  return _ids.reduce((prev, itemId) => {
    const data = _.get(fetchedItems, itemId, false);
    const { duration, intro } = data;
    if(scheduleType === 'workout') prev[itemId] = (intro ? 0 : duration) || 0;
    if(scheduleType === "meal") prev[itemId] = {calories:_.get(data, "macros.calories", 0)};
    return prev;
  }, {});
}

export const getWeekTitle = (isWeekIndexAllowed, weekIndex, weekCustomTitle) =>{
  isWeekIndexAllowed = isWeekIndexAllowed === undefined ? true : isWeekIndexAllowed;
  const customTitle = _.get(weekCustomTitle, 'value', '');
  let primary = customTitle || `Week ${weekIndex}`;
  let secondary = '';
  if (isWeekIndexAllowed && customTitle) secondary = `Week ${weekIndex}`;
  return { primary, secondary };
};
