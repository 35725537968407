import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import play_icon from "fitbud/images/play_icon.svg";
import Dialog from "@material-ui/core/Dialog";
import ReactPlayer from "react-player";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  videoRoot: {
    height: props => "320px",
    width: "320px !important",
    cursor: "pointer",
    position: "relative"
  },
  playIcon: {
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  videoWrapper:{
    position:'relative',
    paddingBottom: `56.25%`,
    backgroundColor:'black'
  },
  videoPlayer: {
    position:'absolute',
    top:0,
    left:0,
  },
  closeButton:{
    position:'absolute',
    top:0,
    right:10,
    color:'white',
    zIndex: 1,
  }
}));

const VideoContainer = ({imageData, aspect, fileUrl}) => {
  const classes = useStyles({ aspectRatio: aspect || 1 });
  return(
    <div className={classes.videoRoot}>
      <div className={classes.playIcon}>
        <img src={play_icon} alt="PlayIcon" />
      </div>
      <div 
        style={{
          width: "100%",
          flex: "1",
          backgroundImage: `url(${fileUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100%",
          backgroundColor: "#666"
        }}
      >
      </div>
    </div>
  )
}

const VideoOpener = (props) => {
  const {attachment={}, url='' } = props;
  const {aspect, imageData, } = attachment;
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  const fileUrl = imageData && `data:image/png;base64,${imageData.replace(/\s/g, '')}`;
  const classes = useStyles();
  return(
    <div className='position-relative'>
      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <IconButton onClick={handleClose} className={classes.closeButton} size='medium'>
          <Close />
        </IconButton>
        <div className={classes.videoWrapper}>
          <ReactPlayer
            className={classes.videoPlayer}
            height={"100%"}
            width={"100%"}
            controls={true}
            playing={true}
            url={url}
            config={{ file: { attributes: { disablepictureinpicture: 'true' }}}} //disabled PIP Option now
          />
      </div>
      </Dialog>
      <div onClick={handleOpen}>
        <VideoContainer aspect={aspect} imageData={imageData} fileUrl={fileUrl} />
      </div>
    </div>
  )
}
export default VideoOpener;
