import React, { useContext, useMemo, useState } from 'react';
import { ContractContext } from 'fitbud/providers/contract';
import { PLANS, SHOW_ADDONS, useAcl } from 'fitbud/providers/acl-provider';
import clsx from 'clsx';
import { Typography, Grid, makeStyles, Collapse } from '@material-ui/core';
import _ from 'lodash';
import { ClrdButton } from '../form-fields';
import collapseSvg from 'fitbud/images/collapse.svg';
import { useBillingContext } from 'fitbud/providers/billing-provider';
import arrowSvg from 'fitbud/images/billing/arrow.svg';
import AddIcon from '@material-ui/icons/Add';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { roundCurrency } from 'fitbud/utils/helpers';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import Confirmation from '../confirmationDialog';

const PRODUCT_PREFERENCE = {
  [PLANS.TRIAL]: 0,
  [PLANS.SILVER]: 1, //initiate
  [PLANS.BASIC_GOLD]: 2, // pro
  [PLANS.GOLD]: 3, // super pro
  [PLANS.PLATINUM]: 4, //Elite
};

const PRODUCTS_NAME = {
  Initiate: 'Initiate',
  Pro: 'Pro',
  'Super Pro': 'Super Pro',
  Elite: 'Elite',
};

const useStyles = makeStyles(() => ({
  card: {
    // minHeight: 475,
    border: '1px solid #D3D9DB',
    borderRadius: 10,
    backgroundColor: 'white',
    padding: '25px 20px',
    '& .title_box': {
      minHeight: 110,
    },
    '& .lineHeight20': {
      lineHeight: '20px',
    },
    '& .custom_title_container': {
      paddingTop: 25,
      height: 98,
    },
    '& .font_34_700': {
      fontSize: 34,
      fontWeight: 700,
    },
  },
  highlightCard: {
    backgroundColor: '#317FF5',
    padding: '8px 12px',
    position: 'absolute',
    right: '20px',
    top: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  highlightBorder: {
    border: '1px solid #317FF5 !important',
  },
  pricing: {
    minHeight: 63,
  },
  upgradeButton: {
    height: 44,
    width: '100%',
  },
  featureCollapseButton: {
    '& .collapseIcon': {
      transition: 'rotate 0.2s',
    },
    '& .collapseIn': {
      rotate: '0deg',
    },
    '& .collapseOut': {
      rotate: '180deg',
    },
  },
  downgradeButton: {
    border: '1px solid #D3D9DB !important',
    color: '#6F8099',
  },
}));

const Pricing = (props) => {
  const { data } = props;
  const classes = useStyles();
  const { price, metadata } = data;
  const isCustomPrice = _.isEmpty(price);
  const [basicPrice, seatPrice, freeSeats] = useMemo(() => {
    //calculation based on monthly
    return [_.get(price, 'monthly.cost'), _.get(metadata, 'seat_cost'), _.get(metadata, 'free_seats')];
  }, [price]);
  return (
    <div className={classes.pricing}>
      {!isCustomPrice && (
        <Typography className="font_13_500 text-grey mb-10" style={{ letterSpacing: 1 }}>
          BILLED MONTHLY
        </Typography>
      )}
      <Typography className={clsx('font_34_700 text-0d0d0d', isCustomPrice && 'custom_title_container')}>
        {isCustomPrice && <span>Custom</span>}
        {!isCustomPrice && (
          <span>
            ${basicPrice} &nbsp;
            <span className="font_13_500 text-dark-grey">{`for ${freeSeats} clients`} </span>
          </span>
        )}
      </Typography>
      {!isCustomPrice && !!seatPrice && (
        <Typography className="font_13_500 text-65768C mt-15">{`+ $${seatPrice} / client / month for over ${freeSeats} clients`}</Typography>
      )}
    </div>
  );
};

const ELITE_CALENDLY_URL = 'https://calendly.com/fitbudd_celebrity_calls/fitbudd-celebrity-trainer-app-demo';

const UpgradeButton = (props) => {
  const { data } = props;
  const { price, id, metadata } = data;
  const { userProfile } = useContext(FirebaseAuthContext);
  const { initCheckout, closePricingOptions } = useBillingContext();
  const { contract } = useContext(ContractContext);
  const [downgradePopup, setDowngradePopup] = useState(false);
  const classes = useStyles();
  const isCustomPrice = _.isEmpty(price);
  const highlight = !!_.get(data, 'metadata.highlight');
  const { activePlan } = useAcl();
  const plan = metadata.internal_name;
  const isCurrentPlan = activePlan === plan;
  const isDowngrade = PRODUCT_PREFERENCE[plan] < PRODUCT_PREFERENCE[activePlan];
  const isYearly = _.get(contract, 'meta.recurring_interval') === 'year';
  const isElite = _.get(data, 'name') === 'Elite';
  const openDowngradePopup = () => setDowngradePopup(true);
  const closeDowngradePopup = () => setDowngradePopup(false);
  const onClickUpgrade = () => {
    initCheckout({
      type: 'upgrade',
      from: 'billing',
      selectedAddOns: [],
      productId: id,
      isYearly: isYearly,
    });
  };
  //special handling for elite product.
  if (isElite) {
    return (
      <ClrdButton
        component={'a'}
        target="_blank"
        // onClick={closePricingOptions} //just for safety..
        href={`${ELITE_CALENDLY_URL}?invitee_email=${encodeURI(userProfile.email)}&invitee_full_name=${encodeURI(
          userProfile.name
        )}`}
        className={clsx(classes.upgradeButton, props.className, isDowngrade && classes.downgradeButton)}
        color="primary"
        title={isDowngrade ? `Downgrade to ${data.name}` : 'Contact Sales'}
        variant="outlined"
        disabled={isCurrentPlan}
      />
    );
  }
  //in case of extra link...
  if (isDowngrade)
    return (
      <>
        <ClrdButton
          className={clsx(classes.upgradeButton, props.className, isDowngrade && classes.downgradeButton)}
          color="primary"
          title={isDowngrade ? `Downgrade to ${data.name}` : 'Contact Sales'}
          variant="outlined"
          onClick={openDowngradePopup}
        />
        {downgradePopup && (
          <Confirmation
            open
            title="Hold Up! Are You Sure About Downgrading?"
            handleClose={closeDowngradePopup}
            confirmOption="Close"
            showCloseIcon
            handleChange={closeDowngradePopup}
            msg={
              <span className='lineht_1_5'>
                Downgrading might seem like a step back as you'll lose access to features crucial for your business's
                success. Why not let us support you in making the best choice for your needs?Still wish to downgrade?
                Email us at <a href='mailto:support@fitbudd.com'>support@fitbudd.com</a>.
              </span>
            }
          />
        )}
      </>
    );
  //in case of normal actions...
  else
    return (
      <ClrdButton
        className={clsx(classes.upgradeButton, props.className)}
        color="primary"
        variant={highlight ? 'contained' : 'outlined'}
        title={isCurrentPlan ? 'Current Plan' : `Upgrade to ${data.name}`}
        onClick={onClickUpgrade}
        disabled={isCurrentPlan}
      />
    );
};

const lessFeature = ['lorem ipsum'];
const notes =
  " Quick note: Apple and Google charge a small annual fee of $99 and $25, respectively, for app launches. We'll help you navigate through this simple process.";

const AddOns = () => {
  const { addOnFeatures } = useContext(ContractContext);
  if (!addOnFeatures || !addOnFeatures.length) return null;
  return (
    <div className="pt-15">
      <Typography className="font_15_600 mb-20">Available Add Ons</Typography>
      {addOnFeatures.map((addOn, index) => {
        const isLast = index === addOnFeatures.length - 1;
        return (
          <div key={addOn.id} className={clsx('d-flex mb-10')}>
            <AddIcon color="primary" fontSize="small" />
            <Typography className="ml-15 font_13_500 text-dark-grey ">{addOn.name}</Typography>
          </div>
        );
      })}
    </div>
  );
};

const Feature = (props) => {
  const { data, collapse, toggleCollapse } = props;
  const classes = useStyles();
  const _features = data.features || [];
  const metadata = data?.metadata || {};
  const plan = _.get(metadata, 'internal_name');
  const isAddOnAvailable = !!SHOW_ADDONS[plan];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const featureHeading = useMemo(() => {
    if (plan === PLANS.BASIC_GOLD) return 'Everything in Initiate, plus:';
    if (plan === PLANS.GOLD) return 'Everything in Pro, plus:';
    if (plan === PLANS.PLATINUM) return 'Everything in Super Pro, plus:';
  }, [plan]);

  return (
    <div className={clsx(classes.featureCollapseButton, 'flex-grow-1 d-flex flex-column justify-content-between')}>
      <Collapse in={collapse}>
        {featureHeading && <Typography className="font_15_600 mb-20">{featureHeading}</Typography>}
        <div className="">
          {_features.map((feature, index) => {
            const isLast = index === _features.length - 1;
            return (
              <div key={'_feature__' + index} className={clsx('d-flex', 'mb-10')}>
                <img src={arrowSvg} />
                <Typography className="font_13_500 text-dark-grey ml-15">{feature.name || ''}</Typography>
              </div>
            );
          })}
        </div>
        {isAddOnAvailable && <AddOns />}
      </Collapse>
      {!!isMobile && (
        <div>
          <Typography
            onClick={toggleCollapse}
            className={clsx('cursor-pointer text-center font_13_600 text-dark-grey pt-15')}>
            {!collapse ? <span>View All Features</span> : <span>Collapse All Features</span>}{' '}
            <img className={clsx(collapse ? 'collapseOut' : 'collapseIn', 'collapseIcon')} src={collapseSvg} />
          </Typography>
        </div>
      )}
    </div>
  );
};

const Product = (props) => {
  const { data } = props;
  const classes = useStyles();
  const { price, metadata, name } = data;
  const isSuperPro = name === 'Super Pro';
  const isHighlight = !!_.get(data, 'metadata.highlight');
  const { setupFee: setupFeeAmount } = useContext(ContractContext);

  return (
    <div
      className={clsx(
        classes.card,
        'w-100 d-flex flex-column position-relative',
        isHighlight && classes.highlightBorder
      )}>
      {isHighlight && (
        <div className={clsx(classes.highlightCard)}>
          <Typography className="font_11_600 text-white">MOST POPULAR</Typography>
        </div>
      )}
      <div className="title_box">
        <div className="d-flex align-items-center mb-15">
          <Typography className="font_18_600 ">
            {metadata.display_name ? PRODUCTS_NAME[metadata.display_name] : data.name}
          </Typography>
        </div>
        <Typography className={clsx('font_13_500 text-dark-grey lineHeight20')}>{data.description}</Typography>
      </div>
      <Pricing data={data} />
      <UpgradeButton data={data} className="my-25" />
      {isSuperPro && (
        <Typography className="font_13_500 text-65768C lineHeight20 mb-25">
          <i>
            * A ${roundCurrency(setupFeeAmount / 100)} one-time setup fee applies.
          </i>
        </Typography>
      )}
      <Feature {...props} />
    </div>
  );
};

const Products = (props) => {
  const { products = [] } = useContext(ContractContext);
  const { activePlan } = useAcl();
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse((v) => !v);

  const _products = useMemo(() => {
    return products;
  }, [products, activePlan]);
  return (
    <div className={clsx('w-full')}>
      <Grid container spacing={3}>
        {_products.map((product) => {
          return (
            <Grid container key={product.id} item xs={12} sm={6} md={3}>
              <Product collapse={collapse} toggleCollapse={toggleCollapse} data={product} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Products;
