import _ from 'lodash';
import React, {useMemo} from 'react';
import {
  Button,
  Typography,
  IconButton
} from '@material-ui/core';
import UpDown from "@material-ui/icons/UnfoldMore";

export const ToggleButton = (props) => {
  const {options, value, onChange, ...rest} = props;
  const _onClick = (e) => {
    if (!onChange || !options || !value) return;
    let index = _.indexOf(options, value);
    if (index < 0) return onChange(options[0]);
    onChange(options[(index + 1) % options.length]);
  }
  return (<IconButton onClick={_onClick} {...rest}>
    <UpDown />
  </IconButton>);
}

export const ToggleLabel = (props) => {
  const {options, value, onChange, event=false, className, hideBtn=false,...rest} = props;
  const mapping = useMemo(() => _.chain(options).keyBy('value').mapValues('label').value(), [options]);
  const _opts = useMemo(() => _.map(options, 'value'), [options]);
  const _onClick = (e) => {
    if (!onChange || !options || !value) return;
    let index = _.indexOf(_opts, value);
    if (index < 0) return onChange(_opts[0]);
    const out = _opts[(index + 1) % _opts.length];
    if (event) {
      let { name, id, dataset } = e.currentTarget;
      onChange({target: { name, id, dataset, value: out }, currentTarget: { name, id, dataset, value: out }});
    } else onChange(out);
  }
  if (!_opts.length || hideBtn)
      return <Typography noWrap className={`no-select ${className}`}>{mapping[value] || ''}</Typography>;
  return (
    <Button endIcon={<UpDown/>} onClick={_onClick}
      disableRipple disableFocusRipple disableElevation
      classes={{endIcon: 'ml-0'}}
      className={className} {...rest}>
      {mapping[value] || ''}
    </Button>
  );
}

