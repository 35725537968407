import _ from "lodash";
import update from "immutability-helper";
import axnBuilder from "./actions";

export const DEFAULT_STATE = {
  loading: false,
  docs: undefined,
  searchResult: undefined,
  nextPage: undefined,
  searchNextPage: undefined,
  count: undefined,
  searchCount: undefined,
  query: undefined,
  filter: {}
};

export default (collection, _default = DEFAULT_STATE) => {
  const axns = axnBuilder(collection);
  return (state = _default, action) => {
    switch (action.type) {
      case axns.toggle:
        return { ...state, loading: !state.loading, timestamp: Date.now() };
      case axns.resetSearch:
        const {
          searchCount,
          searchResult,
          searchNextPage,
          query,
          filter
        } = DEFAULT_STATE;
        return {
          ...state,
          searchCount,
          searchResult,
          searchNextPage,
          query,
          filter,
          nextPage: undefined,
          timestamp: Date.now()
        };
      case axns.set:
        if (action.refresh) {
          return {
            ...state,
            docs: action.list,
            loading: false,
            timestamp: Date.now(),
            nextPage: 1,
            count: action.count
          };
        } else {
          if (!!state.nextPage) {
            return {
              ...state,
              docs: [...state.docs, ...action.list],
              loading: false,
              timestamp: Date.now(),
              nextPage: state.nextPage + 1,
              count: action.count
            };
          } else
            return {
              docs: action.list,
              loading: false,
              timestamp: Date.now(),
              nextPage: 1,
              count: action.count
            };
        }
      case axns.insert:{
        if (!!state.docs){
          return {
            ...state,
            docs: [action.doc, ...state.docs],
            loading: false,
            timestamp: Date.now()
          };
        }
        else
          return {
            ...state,
            docs: [action.doc],
            loading: false,
            timestamp: Date.now()
          };
      }
      case axns.update:
        let index = _.findIndex(state.docs, d => d._id === action.doc._id);
        let searchIndex = (state.searchResult || []).findIndex(d => d._id === action.doc._id);
        if (index >= 0 || searchIndex >= 0) {
          let name = !!action.doc["data"] && action.doc["data"]["profile"]
            ? !!action.doc["data"] && action.doc["data"]["profile"]["name"]
            : !!action.doc["data"] && action.doc["data"]["name"];
          return update(state, {
            docs: { [index]: { $set: action.doc } },
            loading: { $set: false },
            timestamp: { $set: Date.now() },
            searchResult:
              (searchIndex >= 0)
                ? {
                    [searchIndex]: {
                      data: {
                        $set: {
                          ...state.searchResult[searchIndex] && {...state.searchResult[searchIndex]}["data"],
                          name: name,
                          name_lowercase: name && name.toLowerCase()
                        }
                      }
                    }
                  }
                : { $set: state.searchResult }
          });
        } else return state;
      case axns.searchList:
        if (state.query !== action.query || !_.isEqual(state.filter, action.filter)) { //check for if query or filter any one has changed
          return {
            ...state,
            searchResult: action.list,
            loading: false,
            timestamp: Date.now(),
            // searchNextPage: (!!action.query || !_.isEqual(state.filter, action.filter)) ? 1 : undefined,
            searchNextPage: 1, //if any one of query and filter updated then value should be 1
            searchCount: action.searchCount,
            query: action.query,
            filter: action.filter
          };
        } else {
          if (!!state.searchNextPage) {
            return {
              ...state,
              searchResult: [...state.searchResult, ...action.list],
              loading: false,
              timestamp: Date.now(),
              searchNextPage: state.searchNextPage + 1,
              searchCount: action.searchCount
            };
          } else
            return {
              ...state,
              searchResult: action.list,
              loading: false,
              timestamp: Date.now(),
              searchNextPage: 1,
              searchCount: action.searchCount
            };
        }

      case axns.request:
        return {
          ...state,
          loading: true
        };
      case axns.delete: {
        let index = _.findIndex(state.docs, d => d._id === action.id);
        let searchIndex = -1;
        searchIndex =
          state.searchResult &&
          state.searchResult.length &&
          state.searchResult.findIndex(d => d._id === action.id);
        if (searchIndex === undefined) searchIndex = -1;
        if (index === undefined) index = -1;
        if (index >= 0 || searchIndex >= 0) {
          let newState = update(state, {
            docs: index >= 0 ? { $splice: [[index, 1]] } : { $set: state.docs },
            loading: { $set: false },
            timestamp: { $set: Date.now() },
            searchResult:
              searchIndex >= 0
                ? { $splice: [[searchIndex, 1]] }
                : { $set: state.searchResult }
          });
          return { ...newState };
        } else return state;
      }
      
      default:
        return state;
    }
  };
};
