import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import { parseImgUrl } from "croppy";
import { getText as getMediaText } from "fitbud/components/mediaPlaceholder";
import { CrossIcon } from "fitbud/icons/cross";
import { UpArrowIcon } from "fitbud/icons/upArrow";
import videoUploadingIcon from "fitbud/images/videoPlaceholder.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { upload } from "fitbud/utils/aws";
import {
 FILE_STATUS
} from "fitbud/utils/constants";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import uuidv4 from "uuid/v4";
import { uploaderStyles } from "./mediaUploader";
import VideoPlayer from "../videoPlayer";
import { ACL_FEATURES_KEY, useAcl } from 'fitbud/providers/acl-provider';
import { LockIcon } from "fitbud/icons/lock-unlock-icon";


export const VideoFileUploader = (props) => {
 const { status, checkAccessFeature } = useAcl();
 const { cid } = useContext(FirebaseAuthContext);
 const [error, setError] = useState('');
 const [uploading, setUploading] = useState(false);
 const [mediaUrl,setMediaUrl]=useState(null);
 const allowVideoUpload = checkAccessFeature(status, ACL_FEATURES_KEY.CONTENT_UPLOAD);
 let { 
   ytOnly=false,
   media, 
   type,
   errors={},
   handleMedia,
   removeMedia,
   playerProps,
   allowGifs,
   setDirtyOnChange,
   is_crop_square,
   is_mute_video,
   videoStatusRefresh,
   videoUploadError,
   pause
 } = props;
 const classes = uploaderStyles({type});
 const _removeMedia=()=>{
   if(removeMedia){
     removeMedia('video');
   }
 }
 const _m =_.get(media,"0",{});
 useEffect(()=>{
 let _mediaUrl = _m && (_m.s3Url || _m.url) ? _m.s3Url || _m.url : "";
 if (_m && _m.url && _m.url.name) {
   _mediaUrl = URL.createObjectURL(_m.url);
 }
 if(mediaUrl!==_mediaUrl){
   setMediaUrl(
     _mediaUrl
   )
 }
 },[media])

 let accept = 'video/*';
 if (allowGifs) accept = 'video/*, image/gif';
 const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
   accept, multiple: false, disabled: !!mediaUrl
 });
 useEffect(() => {
   if (acceptedFiles && acceptedFiles.length > 0) {
     const file = acceptedFiles[0];
     if (file && file.type) { 
       if (file.type.startsWith("video")) {
         setDirtyOnChange && setDirtyOnChange();
         handleMedia && handleMedia("video", file);
         URL.revokeObjectURL(file);
       } else if (allowGifs && file.type === 'image/gif') {
         setUploading(true);
         const path = `${process.env.REACT_APP_FB_PROJECT}/${cid}/${uuidv4()}.gif`;
         upload({file, path, baseWidth: '-'}).then(url => new Promise(resolve => {
           const { parsed } = parseImgUrl(url, { raw: true });
           const out = parsed.href;
           setTimeout(() => resolve(out), 10);
         })).then(url => {
           setUploading(false);
           handleMedia && handleMedia("image", url);
         }).catch(e => {
           setError("Unable to upload GIF, please try again");
         }).finally(() => {
           URL.revokeObjectURL(file);
         });
       } else {
         setError("File type no supported");
       }
     }
   }
 }, [acceptedFiles]); // eslint-disable-line react-hooks/exhaustive-deps
 const refreshMedia=(e)=>{
   e.stopPropagation();
   videoStatusRefresh();
 }
 const thumbnailUrl = _m && _m.thumbnailUrl;
 const fileRejectionError = error || _.chain(..._.chain(fileRejections).map('errors').value()).map('message').uniq().join().value();
 const isMediaUploading=_m && _m.status && [FILE_STATUS.processing,FILE_STATUS.uploading].includes(_m.status);
 const msg=useMemo(()=>{
   if(!allowVideoUpload) return "Video upload is a premium feature of our Pro Plan. Upgrade now to access this and other advanced features.";
   if(!isMediaUploading){
     return allowGifs?"Drag 'n' drop a video or GIF here, or click to select one":"Drag 'n' drop a video here, or click to select a video";
   }
   else{
     return getMediaText(_m.status,videoUploadError);
   }
 },[_m,allowGifs,isMediaUploading,videoUploadError, status, allowVideoUpload])
 if (!mediaUrl) {
   if (uploading) {
     return (
       <div className={clsx( "bg-grey-new border rounded d-flex flex-column justify-content-center align-items-center")} style={{ borderRadius: 6 }}>
         <CircularProgress />
       </div>
     );
   }
   else if(isMediaUploading){
     return(
       <div
         {...getRootProps({
           className:clsx("bg-grey-new border d-flex flex-column justify-content-center align-items-center position-relative"),
           style: { borderRadius: 6 },
         })}
       >
       <div className={`position-absolute ${classes.closeBtn}`}>
         <IconButton aria-label="close" onClick={_removeMedia}>
           <CrossIcon />
         </IconButton>
       </div>
       <div className={clsx(classes.uploadIcon)}>
         <img src={videoUploadingIcon} alt=""/>
       </div>
       <Typography className="text-0d0d0d w-75 text-center">{msg}</Typography>
       <Typography className="text-danger" variant="caption">
         {fileRejectionError || errors.mediaUpload || " "}
       </Typography>
       {videoStatusRefresh && isMediaUploading && !videoUploadError && (
         <Typography onClick={refreshMedia} className="mt-15 text-primary font_15_600">
           Click here to refresh status
         </Typography>
       )}
     </div>
     )
   }
   else return(
     <div
       {...getRootProps({
         className:clsx("cursor-pointer bg-grey-new border d-flex flex-column justify-content-center align-items-center" ),
         style: { borderRadius: 6, backdropFilter: allowVideoUpload ? "none" : "blur(5px)" },
       })}
     >
       {allowVideoUpload ? <input {...getInputProps()} /> : <></>}
       <div className={classes.uploadIcon}>
         {allowVideoUpload ? <UpArrowIcon style={{ fill: "none" }} /> : <LockIcon style={{ fill: "none" }} />}
       </div>
       {!allowVideoUpload && <Typography className="font_15_600 text-0d0d0d mb-5">Unlock Video Upload with Pro Plan! </Typography>}
       <Typography className="text-dark-grey font_13_500 text-center lineht_1_5 px-20">{msg}</Typography>
       <Typography className="text-danger" variant="caption">
         {fileRejectionError || errors.mediaUpload || " "}
       </Typography>
     </div>
   )
 } else if (type === 'video') {
   return (
     <div className={clsx(classes.media,"bg-black")}>
       <VideoPlayer
         url={mediaUrl}
         thumbnailUrl={thumbnailUrl}
         aspectRatio={is_crop_square?1: (
           !!parseInt(_m.videoHeight) &&
             !!parseInt(_m.videoWidth)
           ? parseInt(_m.videoWidth) / parseInt(_m.videoHeight)
           : 0
         )}
         muted={is_mute_video}
         playerProps={playerProps}
         pause={pause}
       />
       {!ytOnly && <div className={`position-absolute ${classes.closeBtn}`}>
         <IconButton aria-label="close" onClick={_removeMedia}>
           <CrossIcon />
         </IconButton>
       </div>}
     </div>
   )
 } else if (type === 'image') {
   return (
     <div className={clsx(classes.media)}>
       <img alt="" src={mediaUrl} className={is_crop_square?"img-square":"img"}/>
       <div className={`position-absolute ${classes.closeBtn}`}>
         <IconButton aria-label="close" onClick={_removeMedia}>
           <CrossIcon />
         </IconButton>
       </div>
     </div>
   );
 }
};
