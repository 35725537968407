import React, { useState, useContext, useEffect } from 'react';
import firebase from "fitbud/firebase";
import _ from 'lodash';
import { Drawer } from '@material-ui/core';
import { UserSchContext } from './provider';
import { WorkoutTracker, MealTracker } from "../users/schedulePlanTracker";

const UserTracker = () => {
    const { aplan, showCardDetails, controller, objCache, } = useContext(UserSchContext);
    const [data, setData] = useState(showCardDetails);
    const [aggrs, setAggrs] = useState(null);
    const [dayWiseData, setDayWiseData] = useState({});
    const closeDrawer = controller.hideDetail;
    const isWorkout = _.get(showCardDetails, 'meta.type') === 'workout' || _.get(showCardDetails, 'meta.type') === 'cardio';
    const [cid, uid] = aplan.split(':')
    const daywiseRef = firebase.firestore().collection(`user_profiles/${cid}:${uid}/daywise`);
    const type = isWorkout ? 'workout' : 'nutrition';
    useEffect(() => {
        if (showCardDetails) {
            const { past, meta, todo } = showCardDetails;
            const aggregate = isWorkout ? showCardDetails.log : showCardDetails.done;
            if (!aggregate) {
                if (!past) {
                    const id = isWorkout ? showCardDetails.id : todo && todo.id;
                    if (id) {
                        firebase.firestore().doc(`companies/${cid}/${isWorkout ? 'workouts' : 'meals'}/${id}`).get().then(doc => {
                            setData(doc.data());
                        }).catch(e => {
                            console.log(e);
                        });
                    } 
                } else {
                    setData((isWorkout ? meta : todo));
                }

            } else {
                const key = isWorkout ? showCardDetails.log.id : showCardDetails.date.format('YYYYMMDD');
                daywiseRef.doc(`${key}:${type}:${aplan}`).get()
                    .then(snap => {
                        const dayWiseData = snap.data();
                        setDayWiseData(dayWiseData);
                        if (isWorkout)
                            setData(dayWiseData.meta || showCardDetails.meta);
                        else
                            setData(todo || dayWiseData);
                    })
            }
            setAggrs(aggregate);
        } else {
            if (data) setData(null);
            if (dayWiseData) setDayWiseData({});
            if (aggrs) setAggrs(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCardDetails])

    if (!data) return null;
    const { date: dayDate } = showCardDetails || dayWiseData || {};
    return <Drawer open={!!showCardDetails} anchor="right" onClose={closeDrawer}>
        {
            !isWorkout && <MealTracker isUser
                data={data}
                dayWiseData={dayWiseData}
                dayDate={dayDate}
                objCache={objCache}
                closeDrawer={closeDrawer}
                docId={data && data.id}
            />
        }
        {isWorkout && <WorkoutTracker isUser
            data={data}
            dayDate={dayDate}
            aggregate={aggrs}
            dayWiseData={dayWiseData}
            closeDrawer={closeDrawer}
            objCache={objCache}
        />}
    </Drawer>
        ;
};

export default UserTracker;
