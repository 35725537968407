import React, { useEffect, useState, useMemo, useContext} from "react";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { connect } from "react-redux";
import {
  Drawer,
  makeStyles,
} from "@material-ui/core";
import { Line } from "rc-progress";
import {
  TrendingUpTwoTone,
} from "@material-ui/icons";
import _ from "lodash";
import {
  DrawerHeaderInScheduleTracker,
  DrawerSubHeaderInScheduleTracker,
  RatingInScheduleTracker,
  SwitchbuttonInScheduleTracker,
  DrawerSubHeaderInNutritionTracker,
  NutritionTracker,
} from "./subComponents";
import Image from "fitbud/components/placeholderImg"
import WoDetails from "fitbud/views/workouts/form";
import CardioDetails from "fitbud/views/cardio/info";
import ExerciseEditor from "fitbud/views/workouts/exerciseEditor";
import CircularLoader from "fitbud/components/CircularLoader";
import Details from "fitbud/views/cardio/details";
import backgroundIcon from "fitbud/images/div.svg";
import {
  DEFAULT_UNIT_SYSTEM,
} from "fitbud/utils/constants";
import { EditorContext as FoodItemContext } from "fitbud/views/meals/foodItemsForm";
import {DialogAppBar} from 'fitbud/components/Dialog'
import { TagsContext } from 'fitbud/providers/tagsProvider';
import exRepo from "fitbud/repo/exercises";
import MealInfo from "fitbud/views/meals/mealInfo";

export const ProgressBar = ({ aggregateDoc }) => {
  const progress = !!aggregateDoc
    ? (aggregateDoc.completed / aggregateDoc.exCount) * 100
    : "0";
  return (
    <Line
      percent={progress}
      strokeWidth="2"
      trailWidth="1"
      trailColor="#e2e7ee"
      strokeColor="#4089f7"
    />
  );
};

export const WorkoutPlan = (props) => {
  let { data, closeDrawer, loader, wid } = props;
  const isWorkout = _.get(data, "type") === "workout";
  const isCardio = _.get(data, "type") === "cardio";
  const title = _.capitalize(_.get(data, "type"));
  if (loader) return <CircularLoader />;
  if (!data) return null;
  const renderContent = () => {
    if (isWorkout)
      return (
        <WoDetails
          id={wid}
          scheduleDoc={data}
          minified={true}
          isPreview={true}
        />
      );
    else if (isCardio)
      return (
        <CardioDetails
          id={wid}
          scheduleDoc={data}
          isPreview={true}
        />
      );
      else return null;
  };
  return (
    <>
      <DialogAppBar title={title} onClose={closeDrawer} titleFont="h3"/>
      {renderContent()}
    </>
  );
};


const useWorkoutStyles = makeStyles({
  img:{
    borderRadius:12,
    objectFit:"cover",
    width:"100%",
    height:"380px !important"
  }
})

//In case of user workout progress,in reps-weights section ,only reps and reps-weights exercise need to show
const getFilteredGroups = (doc = {}) =>{
  doc = {...doc};
  doc.groups = doc.groups || [];
  return {
    doc,
    showSwitch: !!doc.groups.length
  };
}


const initializeState = (data) =>{
  let out = {
    "exercises":data
  }
  let {doc, showSwitch } = getFilteredGroups(data);
  out["logs"] = {...doc};
  out.showSwitch = showSwitch;
  return out;
}

export const WorkoutTracker = (props) => {
  let {
    data,
    dayDate,
    aggregate,
    dayWiseData,
    closeDrawer,
    objCache,
  } = props;
  const classes = useWorkoutStyles();
  const [loading, setLoading] = useState(false);
  const [state, updateState] = useState(initializeState(data));
  const { cid } = useContext(FirebaseAuthContext);
  const { targetAreas, sides: sidesData, fetchTags } = useContext(TagsContext);
  const [woType, setWoType] = useState("exercises");
  const [exData, setExData] = useState([]);
  useEffect(() => {
    updateState(initializeState(data))
  }, [data, woType])
  useEffect(() => {
    fetchTags('targetAreas');
    fetchTags('sides');
  }, [targetAreas, sidesData, fetchTags]); // eslint-disable-line react-hooks/exhaustive-deps

  const targetArea = useMemo(() => {
    if (!targetAreas || !targetAreas.length) return null;
    return targetAreas.map(item => {
      return { value: item[0], label: item[1].value };
    });
  } , [targetAreas]);

  async function fetchExs() {
    //TODO-draft flag is required?
    setLoading(true);
    let exids = [];
    if (!!data) {
      if (data.groups && data.groups.length > 0) {
        data.groups.forEach(group => {
          if (group.exercises && group.exercises.length > 0) {
            group.exercises.forEach(ex => {
              exids.push(ex.ref.id);
            });
          }
        });
      }
    }
    exids = _.uniq(exids);
    const exData = await Promise.all(
      exids.map(async id => {
        const data = objCache[id] ? objCache[id] : await exRepo(cid).doc(id);
        if (!!data) {
          const d = objCache[id] ? objCache[id] : data.data();
          if(!objCache[id]) objCache[id] = d;
          return {
            id,
            name: d.ref_name,
            thumbnail: d.thumbnail,
            type: d.type,
            side: d.side
          };
        }
      })
    );
    setExData(exData);
    setLoading(false);
  };
  useEffect(() => {
    fetchExs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (loading) return <CircularLoader />;
  if (!data) return null;
  const getType = () => data.type;
  const isWorkout = getType() === "workout";
  const isCardio = getType() === "cardio";
  const title = _.get(data, "type");
  const isSingleVideoWorkout = !!_.get(data, "is_single_video");
  const thumbnail = _.get(data, "thumbnail");
  const switchTitle = ["Exercises", "Client's Logs"];
  function handleSwitchChange(isChecked) {
    const type = !isChecked ? "exercises" : "logs"
    setWoType(type)
  }
  const renderDayWiseTracker = () => {
    if (!dayWiseData || _.isEmpty(dayWiseData)) return null;
    return (
      <>
        <RatingInScheduleTracker
          dayWiseData={dayWiseData}
          aggregateDoc={aggregate}
        />
        {!!isWorkout &&  !isSingleVideoWorkout && !!state.showSwitch && (
          <SwitchbuttonInScheduleTracker
            title={switchTitle}
            hanldeSwitchChange={handleSwitchChange}
          />
        )}
      </>
    );
  };
  const renderContent = () => {
    if (!!isWorkout) {
      if (isSingleVideoWorkout)
        return (
          <div className="fmy-20">
            <Image src={thumbnail} imgClassName={classes.img} />
          </div>
        );
      return (
        <div className={!state.showSwitch ? "fmy-20" : ''}>
          <ExerciseEditor
            editorMode={false}
            doc={state[woType]}
            isProgress={true}
            storedExrcs={exData}
            sidesData={sidesData}
            dayWiseWo={dayWiseData}
            woType={woType}
            cid={cid}
            isPreview={true}
          />
        </div>
      );
    } else if (!!isCardio)
      return <Details doc={data} isProgress={TrendingUpTwoTone} hideHeader />;
    else return null;
  };
  return (
    <>
      <DrawerHeaderInScheduleTracker
        closeDrawer={closeDrawer}
        title={title}
        date={dayDate}
      />
      <div className="fpx-30 fpb-20 rounded-0">
        <DrawerSubHeaderInScheduleTracker
          scheduleDoc={data}
          targetAreasData={targetArea}
        />
        {(!_.isEmpty(dayWiseData)) &&<ProgressBar aggregateDoc={aggregate} />}
        <div className="fp-0 fm-0 fpb-20">
          {renderDayWiseTracker()}
          {renderContent()}
        </div>
      </div>
    </>
  );
};

const useMealStyle = makeStyles({
  headerBg: {
    backgroundImage: `url(${backgroundIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#ffffff",
  },
});

const ConnectedMealTracker = (props) => {
  let {
    isUser,
    data,
    closeDrawer,
    dayDate,
    dayWiseData,
    docId,
  } = props;
  const classes = useMealStyle();
  const [showMealInfo, setShowMealInfo] = useState(false);
  const { mealCategories, serving: servingData, fetchTags } = useContext(TagsContext);
  const getServingUnit = (serving_type) => {
    let { unitSystem, unitDetails = {}, measurableUnits } = props;
    unitDetails = unitDetails || {};
    unitSystem = unitSystem || DEFAULT_UNIT_SYSTEM;
    measurableUnits = measurableUnits || {};
    let unit = _.get(
      measurableUnits,
      `${serving_type}.${unitDetails[serving_type] || unitSystem}`
    );
    return unit || {};
  };
  useEffect(() => {
    fetchTags('mealCategories');
    fetchTags('serving');
  }, [mealCategories, servingData, fetchTags]); 
  const _mealCategories = useMemo(()=>{
    const {groups : mealGroups =[]} = data || {};
    const {groups: dayWiseMGroups = []} = dayWiseData || {}
    const assignedGroups = (mealGroups || []).map((g)=>{ //assigned groups full details
      return _.find(mealCategories, (mc)=>{
        return mc[0] === g.type;
      } )
    }).filter(Boolean);

    const customAddedGroupKeys = (dayWiseMGroups || []).filter((g)=>{ //keys of user added groups which is not assigned
      const index =  _.findIndex(mealGroups,(mG)=>{
        return mG.type === g.type;
      })
      return index === -1;
    })
    
    const customAddedGroups = (customAddedGroupKeys || []).map((g)=>{ //user added group full details
      return _.find(mealCategories, (mc)=>{
        return mc[0] === g.type;
      } )
    }).filter(Boolean);

    customAddedGroups.forEach((c)=>{  //insert user own added group just before less order in assigned groups
      const order = c[1].order;
      let index;
      for(let i = 0; i < assignedGroups.length; i++ ){
        let _order = assignedGroups[i][1].order;
        if(order < _order){
          index = i;
          break;
        }
      }
      if(index !== undefined){
        assignedGroups.splice(index,0,c);
      }else assignedGroups.push(c);
    })

    return assignedGroups;

  },[data, dayWiseData, mealCategories])

  if (!data) return null;
  return (
    <FoodItemContext.Provider
      value={{
        serving: servingData,
        getServingUnit,
      }}
    >
      <div className={classes.headerBg}>
        <DrawerHeaderInScheduleTracker
          closeDrawer={closeDrawer}
          title="nutrition"
          date={dayDate}
          daywiseNutrition={dayWiseData}
          docId={docId}
          btnTitle={docId && "View Original Meal"}
          btnClick={() => setShowMealInfo(true)}
        />
        <div className="fpx-30 fpb-20 rounded-0">
          <DrawerSubHeaderInNutritionTracker
            nutritionDoc={data}
            daywiseNutrition={dayWiseData}
          />
        </div>
      </div>
      {_mealCategories &&
        _mealCategories.map((mealCate, index) => {
          return (
            <NutritionTracker isUser={isUser}
              daywiseNutrition={dayWiseData}
              mealCategories={mealCate}
              servingData={servingData}
              meal={data}
              index={index}
            />
          );
        })}
      <Drawer open={showMealInfo} anchor="right" onClose={() => setShowMealInfo(false)}>
        <DialogAppBar title="Meal" onClose={() => setShowMealInfo(false)} titleFont="h3" />
        <MealInfo
          id={docId}
          scheduleDoc={data}
          edit={false}
          displayEditOption={false}
          isPreview
        />
      </Drawer>
    </FoodItemContext.Provider>
  );
};

const mapStateToProps = (s, op) => {
  let commonProps = {
    unitSystem:
      (s.staff && s.staff.doc && s.staff.doc.unit_system) ||
      DEFAULT_UNIT_SYSTEM,
    unitDetails: (s.staff && s.staff.doc && s.staff.doc.unit_details) || {},
    measurableUnits: (s.config && s.config.docs && s.config.docs[0]) || {},
  };
  return { ...commonProps };
};

export const MealTracker = connect(mapStateToProps, null)(ConnectedMealTracker);
