import { Divider, Typography } from '@material-ui/core';
import { roundNumber, secondsToMinuteFormatted } from 'fitbud/utils/helpers';
import moment from 'moment';
import React, {  useMemo, useContext } from 'react';
import { DifferenceIcon } from '.';
import _ from "lodash";
import AreaGraph from 'fitbud/components/areaGraph';
import { graphDateFormat, oprtnlDateFormat } from 'fitbud/utils/constants';
import { getDetailValueText, Navbar } from '../progress/statView';
import clsx from "clsx";

const convertDates = (data) => 
  data.map((item) => ({...item, date: moment(item.date, oprtnlDateFormat).format(graphDateFormat)}));

const convertDuration = (data=[]) => {
  // Convert Duration if any value is greater than 120 seconds
  let pass = true;
  data.some((item) => {
    if(item.value > 120){ pass = false; return true};
    return false;
  });
  if(!pass) return data.map((item) => ({ ...item, unit:'mins', value: item.value / 60 }));
  return [...data];
};

const MeasurementStats = (props) => {
  const { data: rawData, view, type } = props;
  const data=useMemo(()=>{
    return rawData[view];
  },[rawData,view]);
  return (
    <div className='bg-white fpy-25'>
      <PrimaryStats data={data} type={type} className="mb-40"/>
      <AreaGraph
          type={(data && data.primary)==="weight"?"reps":(data.primary || '')}
          data={(data && data.primary) === 'duration' ? convertDates(convertDuration((data && data.logs) || [])) 
            : convertDates(data.logs || [])}
          color={data.graphColor}
          goal={(!!data && data.target) || false}
          tooltipConfig={{unit: (data && data.primary) === 'duration' ? data.logs.some(({ value }) => value > 120) ? 'mins'  : (data.unit || '') : (data.unit || '') }}
          secondary={(data && data.primary)==="weight"?"weight":null}
        />
      <Logs className='fmt-20' data={data} />
    </div>
  )
};

export const calculateTimePeriod = (lastLogged, firstLogged) => {
  const diff = moment(lastLogged.date).diff(moment(firstLogged.date), 'days');
  if(diff < 31) return `${diff} days`;
  if(diff >= 31 && diff < 92) return `${Math.round(diff / 7)} ${Math.round(diff / 7) === 1 ? 'week' : 'weeks'}`;
  if(diff >= 92) return `${Math.round(diff / 30, 1)} ${Math.round(diff / 30) === 1 ? 'month' : 'months'}`;
  return '';
}
export const renderTime = (value, showZero, options={}) => {
  if(value === 0 && showZero) return (
    <>
      {'0'}{' '}
      <span className={!!options.small ? 'font_14_500'  :'font_18_500'}>
        s
      </span>
    </>
  )
  const [mm, ss] = secondsToMinuteFormatted(value, { toString: false });
  return (
    <>
      {mm || ''}
      {' '}
      {!!mm ? (
        <span className={!!options.small ? 'font_14_500'  :'font_18_500'}>
          m
        </span>
      ): null}
      {' '}
      {ss || ''}
      {' '}
      {!!ss ? (
        <span className={!!options.small ? 'font_14_500'  :'font_18_500'}>
          s
        </span>
      ): null}
    </>
  )
};
const PrimaryStats = ({data:{logs, unit, target, primary} = {}, type,className}) => {
  const lastLogged = logs[0];
  const firstLogged = logs[logs.length - 1];
  const period = logs.length === 1 ? 'a day' : calculateTimePeriod(lastLogged, firstLogged);
  const difference = (firstLogged && lastLogged) ? roundNumber(lastLogged.value, 1) - roundNumber(firstLogged.value, 1) : 0;
  return (
    <div className={clsx('d-flex justify-content-between fpx-20',className)}>
      <div>
        <Typography variant='h1'>
          {primary==="duration"? renderTime(lastLogged.value)
            :roundNumber(lastLogged.value, 1)
          } {" "} 
          {primary!=="duration" && <span className='font-weight-normal' style={{fontSize: '16px'}}>
              {unit}
          </span>}
        </Typography>
        {!!lastLogged && (
          <Typography variant='caption' color='textSecondary' className='font-weight-normal fmt-5'>
            {moment(lastLogged.date, oprtnlDateFormat).format('DD MMM YYYY')}
          </Typography>
        )}
      </div>
      {logs.length > 1 && <div className='text-right'>
        <div className='d-flex align-items-center justify-content-end'>
          <DifferenceIcon
            difference={lastLogged.value - firstLogged.value}
            type={type}
            target={target}
            initialValue={firstLogged.value}
            lastValue={lastLogged.value} 
          />
          <Typography variant='h1' className='font-weight-bolder fml-15' align='right'>
          {primary==="duration"? renderTime(Math.abs(difference), true) : Math.abs(roundNumber(difference, 1))} {' '}
            {primary!=="duration" && (
              <span className='font_16_500'>
                {unit}
              </span>
            )}
            {!!target &&
              <span className='font_16_500'>
               {' '} / {Math.abs(roundNumber(target - firstLogged.value, 1))}
               {' '} {unit}
              </span>
            }
          </Typography>
        </div>
        {!!period && (
          <Typography variant='caption' color='textSecondary' className='font-weight-normal fmt-5' align='right'>
            in {period}
          </Typography>
        )}
      </div>}
    </div>  
  );
}
const Logs = (props) => {
  let {
    data={logs:[]},
    ...restProps
  } = props;
  if(!data || _.isEmpty(data) || !data.logs.length) return null;
  const logs=_.get(data,'logs',[]);
  return (
    <div {...restProps}>
      <Divider className='fmb-15'/>
      <div className='fpx-20'>
        <Typography className={'font_15_600 fmb-15'}>
          Logs
        </Typography>
        <Divider/>
        <div className='bg-white fpt-5'>
          {(logs || []).map(({ value, date, value2, unit, unit2,secondary }, index) => {
            const dateString = moment(date, ).format("D MMM YYYY");
            return (
              <div key={`${index}${value}${dateString}`}>
                <div className='d-flex justify-content-between fpy-20'>
                  <Typography color='textSecondary' className="w-40 font_15_500">
                    {moment(date).format("D MMM YYYY")}
                  </Typography>
                  {!!value2 && <Typography className='w-30 text-right font_15_500 text-black'>
                    {getDetailValueText({value:value2,unit: unit2,config:{ inSeconds: true, decimals: 1 },trackingField:secondary})}
                  </Typography>}
                  <Typography className={clsx(!value2?"w-70":"w-30",'text-right font_15_500 text-black')}>
                    {getDetailValueText({value:value, unit:unit||data.unit, config:{ inSeconds: true, decimals: 1 }})}
                  </Typography>
                </div>
                <Divider/>
              </div>
            )
          })}  
        </div>
      </div>
    </div>
  );
}
export default MeasurementStats;