import _ from 'lodash';
import React, { useState, useContext, useEffect, useMemo } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  Chip,
  FormControlLabel,
  Checkbox,
  makeStyles,
  CardActions,
  InputBase,
  Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { EditorContext } from "fitbud/views/workouts/exerciseEditor";
import Exercise from "fitbud/views/workouts/exercise";
import Stepper from "fitbud/views/workouts/stepper";
import { withSnackbar } from "notistack";
import { ClrdButton } from "fitbud/components/form-fields";
import RestPopover from "./restPopover";
import ReorderDialog from "fitbud/components/reorderDialog";
import useChipStyle from "fitbud/hooks/useChipStyle";
import { ToggleLabel } from "fitbud/components/toggles";
import clsx from "clsx";
import {RightControls} from "./segmentControls";
import {SegmentDuration} from "./segmentDuration";
import {AmrapDurationInput} from "./components";
import {ShowDurationValue,ChipComp} from "./exercise";
import {SECONDARY_TRACKING_OPTIONS,OMIT_SECONDARY_TRACKING_FIELDS} from "fitbud/views/workouts/components/valuePopupNew";
import {GrpHeaderPrevw} from "./grpHeader";

const REST_SET = 'set';
const REST_EXERCISE = 'exercise';
const EMOMTYPES = [{ value: 'ex', label: 'sec/ex' }, { value: 'set', label: 'sec/set'}];

const styles = makeStyles(theme=>({
  card: {
    borderRadius: "10px",
    overflow: "unset",
    padding: "25px 30px",
    position: "relative",
    width: "100%",
  },
  cardContent: {
    display: "flex",
    padding: "0px!important",
    position: "relative",
    width:"100%"
  },
  topControlsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between",
    marginLeft: 66,
    color: "#0D0D0D",
  },
  stepperContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    left: "34px", //calculated not hardcoded
    padding:(props)=>props.isEditMode?"15px 0px":"4px 0px"// hardcoded wrt UI
  },
  restAfterSet: {
    marginLeft: "34px",//calculated
    marginTop:30,
    // '& .restChipsAtEnd':{
    //   display:"flex",
    //   flexDirection:"column",
    //   [theme.breakpoints.up("sm")]:{
    //     flexDirection:"row",
    //   }
    // }
  },
  // restAfterSetNEdit:{
    // alignItems:"flex-start",
    // [theme.breakpoints.up('sm')]:{
    //   alignItems:"flex-center",
    // }
  // },
  cardAction: {
    padding: 0,
    justifyContent: "space-between",
    marginTop:20,
    marginLeft:29,
    alignItems:'flex-start'
  },
  checkBoxContainer: {
    padding: "0px 20px 0px 28px",
    marginBottom: 20,
  },
  restChipContainer: {  
    marginTop: 16,
    marginBottom: 10,
  },
  groupInput: {
    "& fieldset": {
      border: "1px solid #DDDDDD",
    },
    "&.emom": {
      width: 116,
    },
  },
  durationInput: {
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    border: (props) =>props.durationErr ? "1px solid #f44336" : "1px solid #DDDDDD",
    height: 36,
    width: 90,
    padding: "0px 8px",
    "& .endAdornment": {
      color: "#6F8099",
      fontSize: 13,
      fontWeight: 500,
    },
    "& input": {
      fontSize: 16,
      fontWeight: 600,
      color: "#0D0D0D",
      padding: 0,
      display: "flex",
      textAlign: "right",
      marginRight: 2,
      width: 21,
    },
  },
  notes:{
    lineHeight:"20px",
    fontSize:14,
    fontWeight:500,
    color:'#6F8099',
    margin:'12px 0px 20px 66px'
  }
}));

export const RestChip = (props) => {
  const classes = useChipStyle();
  const { rest = [], handleRestChange, type, postLabel = '', index, isEditMode = true, groupType, restType, isRestAfterCircuitEnable,exLength,className   } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRest, setRestState] = useState(false);

  const _postLabel = useMemo(()=>{
   if (!postLabel) return '';
   if (typeof postLabel === 'string') return postLabel;
   if(!isRestAfterCircuitEnable) return "";
   let _restType = groupType === 'circuit' ? 'Round' : 'Set';
   if(groupType === "amrap"){
     _restType = exLength > 1 ? "Round":"Rep"
    }
   
   if (restType === 'circuit') {
     return `after last ${_restType}`;
   }
   if (restType === 'set') {
     return `in between ${_restType}s`;
   }

  },[postLabel, groupType,restType,isRestAfterCircuitEnable,exLength])


  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (!isEditMode) {
    if (!rest[0]) return null;
    return (
      <div className="d-flex align-items-center fp-0 fpl-20 text-nowrap">
        <Typography className="fpr-10 text-grey font_14_500">
          Rest
        </Typography>
        <Typography className="font_16_700 text-0d0d0d">
          {rest.map((i,index)=>(<ShowDurationValue type={rest.length===2?"range":""} valuesLength={rest.length} index={index} value={i} isPreview={true}/>))}
        </Typography>
        {_postLabel && (
          <Typography className="font_14_500 text-grey fml-5">
            {_postLabel}
          </Typography>
        )}
      </div>
    );
  }
  return (
    <div
      className={clsx("d-flex align-items-center", clsx(className || "fpl-20"))}
    >
      <Typography className="fpr-10 text-grey font_14_500">Rest</Typography>
      <ChipComp
        onClick={(e) => {
          handleClick(e);
          setRestState(true);
        }}
        clickableClass={openRest ? classes.clickable : ""}
      >
        <Typography className="font_16_600">
          {rest.map((i, index) => (
            <ShowDurationValue
              type={rest.length === 2 ? "range" : ""}
              valuesLength={rest.length}
              index={index}
              value={i}
            />
          ))}
        </Typography>
      </ChipComp>
      {_postLabel && (
        <Typography className="font_14_500 fpl-8">{_postLabel}</Typography>
      )}
      {openRest && (
        <RestPopover
          anchorEl={anchorEl}
          onClose={(e) => {
            handleClose(e);
            setRestState(false);
          }}
          values={rest}
          handleRestChange={handleRestChange}
          data-group={props['data-group']}
          type={type}
          index={index}
        />
      )}
    </div>
  );
};

export const OutlinedCircleIcon = (props) => (
  <div
    className="rounded-circle"
    style={{
      zIndex: "1",
      // marginLeft:"19px",
      border: `solid 2.4px #4089f7`,
      // marginLeft: '9px',
      backgroundColor: '#ffffff',
      width: '12px',
      height: '12px',
    }}
  >
    {'\u00A0'}
  </div>
);

const ExerciseGrpContainer = (props) => {
  const classes=styles();
  //-----props
  const { index,addExercise, isProgress, dayWiseWo, woType, cid, group, minified,
    group: { exercises, rest_after, type, rest_after_circuit_enable = false, rest_after_circuit = [], rest:rest_after_set,title,description } = {}
  } = props;
  //----context
  const {
    editorMode: isEditMode,
    storedExrcs,
    handleGrpAttributes,
    handleRestChange,
    grpData,
    totalGrps,
    selectorMode
  } = useContext(EditorContext);
  //----null condition
  if (!totalGrps) return null;
  //----functions
  const handleRestAfterChange = (e) => {
    const checked = e.target.checked;
    const _value = checked ? REST_EXERCISE : REST_SET;
    const cb = ()=>{
      handleGrpAttributes([
        {
          index: index,
          key: 'rest_after',
          value: _value,
        },
      ])
    };
    if(rest_after === REST_EXERCISE){ //if rest after exercise to set changes then last rest copies to rest of set and vice versa::
      const _lastEx =  exercises && exercises.length && exercises[exercises.length - 1];
      const _lastExRest = !!_lastEx ? _lastEx.rest : [0];
      //update group rest:
      const _e = {
        currentTarget:{
          dataset:{
            group:index
          }
        }
      }
      handleRestChange(_e,_lastExRest, cb);
    }else if(rest_after === REST_SET){
      const _restAfterSet = rest_after_set || [0];
      //update last ex
      const _e = {
        currentTarget:{
          dataset:{
            group:index,
            exercise: exercises &&  !!exercises.length  ? exercises.length -1 : 0
          }
        }
      }
      handleRestChange(_e, _restAfterSet, cb);
    }
  };

  const handleEnableRestAfterCircuit = (e) => {
    const checked = e.target.checked;
    handleGrpAttributes([
      {
        index: index,
        key: 'rest_after_circuit_enable',
        value: checked,
      },
    ]);
  };
 const totalEx = (exercises || []).length;
  const dayW = exercises.length && exercises[0] && exercises[0].day_w;
  let _differentSetRestLabel = type === "circuit" ? "Different rest after end of circuit" :" Different rest after last set"
  if(type === "amrap"){
    _differentSetRestLabel = totalEx >1 ?"Different rest after last Round":"Different rest after last Rep"
  }
  const className =index < totalGrps - 1 && (isEditMode ? "mb-20" :(selectorMode?"mb-0":"mb-40"));
  if (isEditMode) {
    return (
      <>
        <GrpHeaderPrevw title={title} description={description} isEditMode={isEditMode} grpIndex={index}/>
        <Card className={clsx(classes.card, className)}>
          <SegmentDuration durationInSecs={props.segmentDuration} />
          <CardContent className={classes.cardContent}>
            <StepperContainer isEditMode={isEditMode}/>
            <ExerciseGroup
              addExercise={addExercise}
              storedExrcs={storedExrcs}
              group={props.group}
              data-group={index}
              enqueueSnackbar={props.enqueueSnackbar}
              minified={minified}
              isProgress={isProgress}
              dayWiseWo={dayWiseWo}
              woType={woType}
              cid={cid}
            />
          </CardContent>
          <CardActions className={classes.cardAction}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    className="fp-0 fmr-10"
                    name="rest_after_circuit_enable"
                    color="primary"
                    checked={rest_after_circuit_enable}
                    onChange={handleEnableRestAfterCircuit}
                  />
                }
                classes={{
                  root: "fm-0",
                }}
                label={
                  <Typography className="font_14_600 text-0d0d0d">
                    {_differentSetRestLabel}
                  </Typography>
                }
              />
              {rest_after_circuit_enable && (
                <div className={classes.restChipContainer}>
                  <RestChip
                    data-group={index}
                    handleRestChange={handleRestChange}
                    rest={rest_after_circuit}
                    type="rest_after_circuit"
                    postLabel={true}
                    groupType={type}
                    restType="circuit"
                    exLength={totalEx}
                    isRestAfterCircuitEnable={rest_after_circuit_enable}
                    className="fpl-0"
                  />
                </div>
              )}
            </div>
            {/*--- Rest after each exercise ---*/}
            {(totalEx > 1 && !["superset","triset"].includes(type)) && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name=""
                      color="primary"
                      onChange={handleRestAfterChange}
                      checked={rest_after === REST_EXERCISE}
                      className="fp-0 fmr-10"
                    />
                  }
                  classes={{
                    root: "fm-0",
                  }}
                  label={
                    <Typography className="text-0d0d0d font_14_600">
                      Enable rest after each exercise
                    </Typography>
                  }
                />
              </div>
            )}
            {/*--- Rest after each exercise ---*/}
          </CardActions>
          {/* <div className={`d-flex justify-content-between ${classes.checkBoxContainer}`}>
            <div>
            <FormControlLabel
                control={
                  <Checkbox
                    className='fp-0 fmr-10'
                    name="rest_after_circuit_enable"
                    color="primary"
                    checked={rest_after_circuit_enable}
                    onChange={handleEnableRestAfterCircuit}
                  />
                }
                classes={{
                  root: 'fm-0',
                }}
                label={<Typography className="font_14_600">{_differentSetRestLabel}</Typography>}
            />
            {rest_after_circuit_enable && (
                <div className={classes.restChipContainer}>
                  <RestChip
                    data-group={index}
                    handleRestChange={handleRestChange}
                    rest={rest_after_circuit}
                    type="rest_after_circuit"
                    postLabel={true}
                    groupType={type}
                    restType="circuit"
                    exLength={totalEx}
                    isRestAfterCircuitEnable={rest_after_circuit_enable}
                    className="fpl-0"
                />
              </div>
              )}
            </div>
            {totalEx > 1 &&  !['superset'].includes(type) && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name=""
                      color="primary"
                      onChange={handleRestAfterChange}
                      checked={rest_after === REST_EXERCISE}
                      className="fp-0 fmr-10"
                    />
                  }
                  classes={{
                    root: 'fm-0',
                  }}
                  label={<Typography className="font_14_600">Enable rest after each exercise</Typography>}
                />
              </div>
            )}
          </div> */}
        </Card>
      </>
    );
  } else {
    return (
      <>
        <GrpHeaderPrevw
          title={title}
          description={description}
          isEditMode={isEditMode}
          grpIndex={index}
        />
        <Card
          elevation={0}
          className={clsx(className, "w-100 bg-transparent w-100 p-0")}
        >
          <CardContent className={classes.cardContent}>
            <StepperContainer isEditMode={isEditMode} />
            <ExerciseGroup
              addExercise={addExercise}
              storedExrcs={storedExrcs}
              group={props.group}
              data-group={index}
              enqueueSnackbar={props.enqueueSnackbar}
              minified={minified}
              isProgress={isProgress}
              dayWiseWo={dayWiseWo}
              woType={woType}
              cid={cid}
            />
          </CardContent>
        </Card>
      </>
    );
  }
};
export const ExerciseGroup = (props) => {
  const classes=styles();
  //----props
  const {
    group: {
      exercises,
      sets,
      duration_per_exercise,//emom only
      emom_type = 'ex',
      duration,
      rest,
      rest_after,
      notes,
      type,
      editNotes,
      rest_after_circuit_enable = false,
      rest_after_circuit = [],
    },
    addExercise,
    isProgress,
    dayWiseWo,
    woType,
    cid,
    minified
  } = props;
  //----context
  const {
    editorMode: isEditMode,
    storedExrcs,
    handleChange,
    errors = {},
    loading,
    handleRestChange,
    handleDelete,
    totalGrps,
    handleGrpAttributes,
    handleReorder,
    setNotesState,
    doc,
    grpData,
    selectedGps,
    selectorMode,
    disableExClick
  } = useContext(EditorContext);
  //---state
  // const [openRest, setRestState] = useState(false);
  const [openReorder, setReorderDialog] = useState(false);
  const [setsText, setSetsText] = useState('Sets');
  const totalEx = exercises.length;
  //----functions
  function toggleReorderDialog(){
    setReorderDialog(o=>!o);
  }
  const updateSetsText = () => {
    if (type === 'circuit'){
      setSetsText('Rounds');
    } else if (type === 'amrap') {
      setSetsText('Duration');
    } else {
      setSetsText('Sets');
    }
  };

  useEffect(updateSetsText, [type]);
  const dupExMapper = useMemo(() => { // build indexes for handling duplicate ex in same group
    const out = [], tmp = {};
    if (!exercises || !exercises.length) return out;
    exercises.forEach(ex => {
      if (!ex || !ex.ref || !ex.ref.id) return out.push(0);
      const exId = ex.ref.id;
      if (!tmp.hasOwnProperty(exId)) tmp[exId] = 0;
      else tmp[exId] += 1;
      out.push(tmp[exId]);
    });
    return out;
  }, [exercises]);

 
  // if (!!errors.groups && errors.groups[props['data-group']] && errors.groups[props['data-group']].sets) {
  //   props.enqueueSnackbar(errors.groups[props['data-group']].sets, {
  //     variant: 'error',
  //   });
  // }

  // if (!!errors.groups && errors.groups[props['data-group']] && errors.groups[props['data-group']].duration) {
  //   props.enqueueSnackbar(errors.groups[props['data-group']].duration, {
  //     variant: 'error',
  //   });
  // }
  // if (
  //   !!errors.groups &&
  //   errors.groups[props["data-group"]] &&
  //   errors.groups[props["data-group"]].duration_per_exercise
  // ) {
  //   props.enqueueSnackbar(
  //     errors.groups[props["data-group"]].duration_per_exercise,
  //     {
  //       variant: "error",
  //     }
  //   );
  // }
  // if (!!errors.groups && errors.groups[props['data-group']] && errors.groups[props['data-group']].type) {
  //    props.enqueueSnackbar(errors.groups[props['data-group']].type, {
  //     variant: 'error',
  //   });
  // }
  const onReorder = (list) => {
    let updatedExercises = [...exercises];
    updatedExercises = list.map((item) => updatedExercises.find((exercise) => item.ref.id === exercise.ref.id));
    handleReorder(parseInt(props['data-group']), updatedExercises);
  };

  const exListData = exercises.map((exercise, i) => {
    const exId = exercise.ref.id;
    const doc = storedExrcs && storedExrcs.find((d) => exId === d.id);
    if (doc) return { ref: exercise.ref, name: doc.name };
    else return {};
  });

  //____WIP________________
  const newTrackingLogic=(ex,grpType)=>{
    if(grpType==="amrap"||grpType==="emom"){
      const [prim,sec]=ex.track;
      if(ex.track[0]==="duration"){//amrap doesnt allow duration
        ex.track[0]="reps";
        if(sec){
          const isSecCompatibleWReps=SECONDARY_TRACKING_OPTIONS['reps'].find(i=>i.value===sec);
          if(isSecCompatibleWReps){
            if(sec==="weight"){
              ex.ref_type="reps_w_weight";
              if(!!_.get(ex,"male2",[]).length){
                ex.weights=[...ex.male2];
                ex.weight_type=ex.type2;
              }
            }
          }
          else{
            ex.track=["reps"];
            ex=_.omit(ex,OMIT_SECONDARY_TRACKING_FIELDS);
          }
        }
      }
      else{
        //for new fields-dist,meter,cals
        ex.values=ex.values.map(i=>10);
      }
    }
    else if(grpType==="tabata"){
      const [prim,sec]=ex.track;
      ex.track[0]="duration";
      ex.male1=[...ex.values];
      if(ex.gender){
        ex.female1=[...ex.values];
      }
      const isSecCompatibleWReps=SECONDARY_TRACKING_OPTIONS['duration'].find(i=>i.value===sec);
      if(!isSecCompatibleWReps){
        ex=_.omit(ex,OMIT_SECONDARY_TRACKING_FIELDS);
        ex.track=['duration'];
      }
    }
    return {...ex};
  }
  //____WIP________________
  const handleType = (e) => {
    const currentType=type;
    const newType = e.target.value;
    let payloads = [{ index: props['data-group'], key: 'type', value: newType }];
    if(currentType==="amrap" && newType!=='tabata'){
      const _exOut=sanitizeAmrapEx([...exercises]);
      payloads.push({
        index: props['data-group'],
         key:'exercises',
        value: _exOut
      });
    }
    if (['circuit', 'superset','triset'].includes(newType)) {
      payloads.push({
        index: props['data-group'],
        key: 'rest_after',
        value: 'set',
      });
    }
    if(newType ==="superset"||newType==='triset'){
      payloads.push({
        index: props['data-group'],
        key: 'rest_after_circuit_enable',
        value: false,
      });
    }
    if(newType === "amrap"){
      payloads.push({
        index: props['data-group'],
        key:'duration',
        value:10 * 60
      })
      payloads.push({
        index: props['data-group'],
        key:'rest_after',
        value:"set"
      })
      payloads.push({
        index: props['data-group'],
        key:'rest',
        value:[0]
      })
      payloads.push({
        index: props['data-group'],
        key: 'rest_after_circuit_enable',
        value: false,
      });
      const _exOut=sanitizeAmrapEx([...exercises]);
      payloads.push({
        index: props['data-group'],
         key:'exercises',
        value: _exOut
      })
    }
    if(newType==='emom'){
        payloads.push({
          index: props['data-group'],
          key:'rest_after',
          value:"set"
        })
        payloads.push({
          index: props['data-group'],
          key:'rest',
          value:[0]
        })
        payloads.push({
          index: props['data-group'],
          key: 'rest_after_circuit_enable',
          value: false,
        });
        payloads.push({
          index: props['data-group'],
          key: 'duration_per_exercise',
          value: 60,
        });
    }
    if(newType ==="tabata"){
      props.enqueueSnackbar(`All exercises of type "Reps" or "Reps & Weights" have been converted to type "Duration"`, {
        variant: 'warning',
      });
      payloads.push({
        index: props['data-group'],
        key:"rest",
        value:[10]
      })
      payloads.push({
        index: props['data-group'],
        key:"rest_after",
        value:"exercise"
      })
      payloads.push({
        index: props['data-group'],
        key:"rest_after_circuit",
        value:[60]
      })
      payloads.push({
        index: props['data-group'],
        key:"rest_after_circuit_enable",
        value:true
      })
      const _ex = [...exercises] //group type is tabata then convert reps|reps_w_weight type exercises to the duration type:
      const _exOut = _ex.map((ex)=>{
        if(ex.ref_type!=='duration'||(ex.track && ex.track[0]!=="duration")){//DONT CHANGE
          ex.ref_type = 'duration';
          ex.rest = [10];
          ex.values =[20];
          ex.type="fixed";
          ex=_.omit(ex,['weights','weight_type'])//duration with weight not supported in old apps
          //---NEW TRACKING FIELDS LOGIC
          if(!!ex.track){
            ex={...newTrackingLogic(ex,'tabata')};
          }
        } 
        if(ex.overrideAlts){
          for(let i in ex.overrideAlts){
            const alt_ref_type=ex.overrideAlts[i].ref_type;
            if(alt_ref_type!=="duration"){
              ex.overrideAlts[i].ref_type="duration";
              ex.overrideAlts[i].type="fixed";
              ex.overrideAlts[i].values=[20];
              ex.overrideAlts[i]=_.omit(ex.overrideAlts[i],['weights','weight_type']);//duration with weight not supported in old apps
              //---NEW TRACKING FIELDS LOGIC
              if(!!ex.overrideAlts[i].track){
                ex.overrideAlts[i]={...newTrackingLogic(ex.overrideAlts[i],'tabata')};
              }
            }
          }
        }
        return {...ex};
      })
      payloads.push({
        index: props['data-group'],
        key:'exercises',
        value: _exOut
       })
    }
    handleGrpAttributes(payloads);
  };
  return (
    <>
      <div className="w-100 overflow-hidden">
        {/*--- segment top controls -----*/}
        <div className={classes.topControlsContainer}>
          {isEditMode ? (
            <>
              <div className="d-flex align-items-center">
                <Typography component="div" className="mr-10 font_16_500">
                  {setsText}
                </Typography>
                <GroupInputField
                  type={type}
                  duration={duration}
                  duration_per_exercise={duration_per_exercise}
                  emom_type={emom_type}
                  sets={sets}
                  errors={errors}
                  loading={loading}
                  handleChange={handleChange}
                  {...props}
                />
                {!editNotes && (
                  <ClrdButton
                    variant="text"
                    color="primary"
                    className="font_14_600 fml-10"
                    onClick={() => setNotesState(props["data-group"], true)}
                  >
                    Add Note
                  </ClrdButton>
                )}
              </div>
              <div className="d-flex align-items-center">
                <RightControls
                  addExercise={addExercise}
                  typeValue={type}
                  handleTypeChange={handleType}
                  totalGrps={totalGrps}
                  totalEx={totalEx}
                  openReorderDialog={toggleReorderDialog}
                  className={classes.groupInput}
                  errors={errors}
                  {...props}
                />
              </div>
            </>
          ) : (
            // NON EDIT MODE
            <div className="col fpx-0">
              <Typography>
                {type === "amrap" ? (
                  <span className="font_16_600">
                    <ShowDurationValue value={duration}/>
                    &nbsp;&nbsp;
                  </span>
                ) : (
                  <span className="font_16_600">{sets}</span>
                )}
                {setsText && type!=="amrap" && <span className="fmr-15 font_16_500">&nbsp;&nbsp;{setsText}</span>}
                <span className="font_15_600 text-primary">
                  {_.upperCase(type === "default" ? "Regular" : type)}
                </span>
              </Typography>
            </div>
          )}
            {selectorMode && <IconButton onClick={() => {
                selectedGps(props["group"], doc.groups);
              }}>
              <Checkbox
                color="primary"
                className="fp-0"
                checked={!!_.find(grpData, props["group"])}
              />  
            </IconButton>}
        </div>
        {/*--- segment top controls- end -----*/}
        <Notes
          isEditMode={isEditMode}
          editNotes={editNotes}
          handleDelete={handleDelete}
          handleChange={handleChange}
          disabled={loading}
          errors={errors.notes}
          {...props}
        />
        {/*--- exercises ---*/}
        {storedExrcs &&
          exercises &&
          exercises.map((exercise, i) => {
            const exId = exercise.ref.id;
            const exData = _.find(storedExrcs, (d) => exId === d.id);
            const exIndx = dupExMapper[i];
            let groupId = `companies/${cid}/exercises/${exId}:group${props["data-group"]}${!exIndx ? '' : `:${exIndx}`}`;
            let day_w = _.get(dayWiseWo, `log.${groupId}`);
            exercise.day_w = day_w;
            if (typeof exercise.day_w === "undefined") {
              exercise.day_w = null;
            }
            return (
              <Exercise
                type={type}
                key={exercise.ref.id+"_"+i}
                group={props.group}
                exercise={exercise}
                exData={exData}
                rest_after={rest_after}
                index={i}
                length={totalEx}
                data-group={props["data-group"]}
                isProgress={isProgress}
                dayWiseWo={dayWiseWo}
                woType={woType}
                minified={minified}
                disableExClick={disableExClick}
              />
            );
          })}
        {/*--- exercises end ---*/}
        {rest_after === "set" && (
          <RestAfterSet
            isEditMode={isEditMode}
            handleRestChange={handleRestChange}
            rest={rest}
            woType={woType}
            rest_after_circuit_enable={rest_after_circuit_enable}
            rest_after_circuit={rest_after_circuit}
            isRestAfterExercise={false}
            isProgress={isProgress}
            type={type}
            totalEx={totalEx}
            minified={minified}
            sets={sets}
            {...props}
          />
        )}
      </div>
      {openReorder && (
        <ReorderDialog
          open
          listData={exListData}
          onClose={toggleReorderDialog}
          onSubmit={(list) => onReorder(list)}
          title="Reorder Exercises"
        />
      )}
    </>
  );
};

export default withSnackbar(ExerciseGrpContainer);



const StepperContainer = ({ isEditMode }) => {
  const classes = styles({ isEditMode });
  return (
    <div className={classes.stepperContainer}>
      <Stepper />
    </div>
  );
};

const Notes=({disabled,isEditMode,editNotes,errors,handleChange,handleDelete,group,...props})=>{
  const classes=styles();
  if(isEditMode && editNotes){
    return (
      <div
        className="d-flex align-items-center fmt-15"
        style={{ marginLeft: "70px" }}
      >
        <TextField
          className="flexw-1"
          name="notes"
          key={`_notes${props['data-group']}_${group.notes}`}
          multiline={true}
          rows={2}
          rowsMax={4}
          variant="outlined"
          placeholder="Add additional details here..."
          defaultValue={group.notes}
          autoComplete="off"
          error={!!errors}
          helperText={errors}
          disabled={disabled}
          required
          InputProps={{
            inputProps: {
              "data-group": props["data-group"],
            },
            classes: {
              root: "large",
              input: "size_14_normal large",
            },
          }}
          onBlur={handleChange}
        />
        <IconButton
          className="fp-5 fml-10"
          onClick={() => {
            handleDelete({
              grpIndex: parseInt(props["data-group"]),
              exrcsIndex: 0,
              type: "delete_note",
            });
          }}
        >
          <CloseIcon className="text-0d0d0d" />
        </IconButton>
      </div>
    );
  }
  else if(!isEditMode && group.notes){
    return (
      <Typography
        className={clsx(classes.notes)}
      >
        {group.notes}
      </Typography>
    );
  }
  return null;
}

const RestAfterSet=({isEditMode,handleRestChange,rest,woType,rest_after_circuit_enable,rest_after_circuit,
  isRestAfterExercise,isProgress,type,totalEx,minified,sets,...props})=>{
  const classes=styles();
  if(isEditMode)
    return(
      <div className={clsx("d-flex align-items-center",classes.restAfterSet)}>
        <OutlinedCircleIcon />
        <RestChip
          data-group={props['data-group']}
          handleRestChange={handleRestChange}
          rest={rest}
          postLabel={true}
          groupType={type} 
          restType="set"
          sets={sets}
          exLength={totalEx}
          isRestAfterCircuitEnable={rest_after_circuit_enable}
        />
      </div>
    )
  else{
    if (woType === 'logs') return null;
    const isRest = !!rest[0];
    const isRestAfterCircuit = !!rest_after_circuit_enable && !!rest_after_circuit && !!rest_after_circuit[0];
    // if (isRestAfterExercise) {
    //   if (isRestAfterCircuit) {
    //     return (
    //       <div className="fmb-25 fmr-30 fpr-20 d-flex align-items-center">
    //         <OutlinedCircleIcon isProgress={isProgress} />
    //         <RestChip 
    //           isEditMode={isEditMode}
    //           rest={rest_after_circuit} 
    //           postLabel={true} 
    //           groupType={type} 
    //           restType="circuit"
    //           exLength={totalEx}
    //           isRestAfterCircuitEnable={rest_after_circuit_enable}
    //         />
    //       </div>
    //     );
    //   }
    // }
    // else 
    if (!minified) {
      // const extraCircle= rest_after_circuit_enable && (isPreview||under1000Width);
      if (isRest || isRestAfterCircuit) {
        return (
          //TODO:h-100 find alternative-might be buggy
          <div className={clsx("d-flex ",classes.restAfterSet)}>
              {/* <div className="bg-white h-100 mt-2px" style={{zIndex:5}}>
                <OutlinedCircleIcon isProgress={isProgress} />
              </div> */}
              <div className={clsx("d-flex flex-column",rest_after_circuit_enable ? "justify-content-between py-3px":"justify-content-center")} style={{zIndex:5}}>
                {!!_.get(rest,'0',null) && <OutlinedCircleIcon isProgress={isProgress} />}
                {rest_after_circuit_enable && !!_.get(rest_after_circuit,'0',null) && <OutlinedCircleIcon isProgress={isProgress} />}
              </div>
              <div className={clsx("d-flex flex-column")} style={{gap:"15px"}}>
                <RestChip
                rest={rest} 
                isEditMode={isEditMode} 
                postLabel={true} 
                groupType={type} 
                restType="set"
                exLength={totalEx}
                isRestAfterCircuitEnable={rest_after_circuit_enable} 
              />
              {rest_after_circuit_enable && (
                <RestChip
                 isEditMode={isEditMode} 
                 rest={rest_after_circuit} 
                 postLabel={true} 
                 groupType={type} 
                 restType="circuit"
                 exLength={totalEx}
                 isRestAfterCircuitEnable={rest_after_circuit_enable}
                />
              )}
            </div>
          </div>
        );
      }
    } else {
      //schedule case
      return (
        <>
          {isRest && (
            <div className="fmb-25 fmr-30 fpr-20 d-flex align-items-center">
              <OutlinedCircleIcon isProgress={isProgress} />
              <RestChip 
                rest={rest} 
                isEditMode={isEditMode} 
                postLabel={true} 
                groupType={type} 
                restType="set" 
                exLength={totalEx}
                isRestAfterCircuitEnable={rest_after_circuit_enable}
              />
            </div>
          )}
          {isRestAfterCircuit && (
            <div className="fmb-25 fmr-30 fpr-20 d-flex align-items-center">
              <OutlinedCircleIcon isProgress={isProgress} />
              <RestChip 
                isEditMode={isEditMode} 
                rest={rest_after_circuit} 
                postLabel={true} 
                groupType={type} 
                restType="circuit"
                exLength={totalEx}
                isRestAfterCircuitEnable={rest_after_circuit_enable} 
              />
            </div>
          )}
        </>
      );
    }
    return null;
  }
}

const GroupInputField = ({
  type,
  duration,
  sets,
  duration_per_exercise,
  emom_type,
  errors,
  loading,
  handleChange,
  ...props
}) => {
  const classes = styles({
    durationErr: !!_.get(
      errors,
      `groups.${props["data-group"]}.duration`,
      false
    ),
  });
  if (type === "amrap") {
      const _handleChange=(e)=>{
        const _e={
          ...e,
          target:{
            ...e.target,
              dataset:{
              group:props['data-group']
            }
          }
        }
        handleChange(_e);
      }
      return <AmrapDurationInput
        name="duration"
        type="amrap"
        value={duration}
        onChange={_handleChange}
        loading={loading}
        hideHr={true}
        error={_.get(
          errors,
          `groups.${props["data-group"]}.duration`,
          false
        )}
       />
  } else if(type==='emom'){
    return (
      <>
        <TextField
          name="sets"
          type="number"
          value={(sets || 0).toString()}
          max={10}
          min={1}
          classes={{
            root: clsx("w-small", classes.groupInput),
          }}
          autoComplete="off"
          error={
            !!errors.groups &&
            !!errors.groups[props["data-group"]] &&
            !!errors.groups[props["data-group"]].sets
          }
          disabled={loading}
          variant="outlined"
          required
          onChange={handleChange}
          InputProps={{
            inputProps: {
              "data-group": props["data-group"],
            },
            classes: {
              root: "small mr-10",
              input: "size_16_600  small text-center",
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleChange(e);
            }
          }}
        />
        <TextField
          name="duration_per_exercise"
          type="number"
          value={(duration_per_exercise||0).toString()}
          classes={{
            root: clsx("emom", classes.groupInput),
          }}
          autoComplete="off"
          error={
            !!errors.groups &&
            !!errors.groups[props["data-group"]] &&
            !!errors.groups[props["data-group"]].duration_per_exercise
          }
          disabled={loading}
          variant="outlined"
          required
          onChange={handleChange}
          InputProps={{
            inputProps: {
              "data-group": props["data-group"],
            },
            classes: {
              root: "small",
              input: "size_16_600  small text-center pl-10",
              adornedEnd:"pr-10"
            },
            endAdornment: (<ToggleLabel className='font_13_500 text-grey text-nowrap' size='small' options={EMOMTYPES} event
              onChange={handleChange} value={emom_type || 'ex'} edge='end' name='emom_type' data-group={props['data-group']}/>)
          }}
        />
      </>
    );
  } 
  else {
    return (
      <TextField
        name="sets"
        type="number"
        value={(sets || 0).toString()}
        max={10}
        min={1}
        classes={{
          root: clsx("w-small", classes.groupInput),
        }}
        autoComplete="off"
        error={
          !!errors.groups &&
          !!errors.groups[props["data-group"]] &&
          !!errors.groups[props["data-group"]].sets
        }
        disabled={loading}
        variant="outlined"
        required
        onChange={handleChange}
        InputProps={{
          inputProps: {
            "data-group": props["data-group"],
          },
          classes: {
            root: "small",
            input: "size_16_600  small text-center",
          },
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleChange(e);
          }
        }}
      />
    );
  }
};

const sanitizeAmrapEx=(exercises)=>{
  return [...exercises].map(ex=>{
    //type
    if(ex.type==="perset"){
      ex.type="fixed";
      ex.values=[...ex.values].slice(0,1);
      if(!!(ex.male1||[]).length){
        ex.male1=[...ex.male1].slice(0,1);
      }
      if(!!(ex.female1||[]).length){
        ex.female1=[...ex.female1].slice(0,1);
      }
    }
    if(_.get(ex,'type2','')==="perset"){
      ex.type2="fixed";
      if(!!(ex.male2||[]).length){
        ex.male2=[...ex.male2].slice(0,1);
      }
      if(!!(ex.female2||[]).length){
        ex.female2=[...ex.female2].slice(0,1);
      }
    }
    if(ex.overrideAlts){
      for(let i in ex.overrideAlts){
        if(_.get(ex,['overrideAlts',i])){
          const [parsed,...rest]=[...sanitizeAmrapEx([ex.overrideAlts[i]])];
          ex.overrideAlts[i]=parsed;
        }
      }
    }
    return {...ex};
  });
}