import React, { useContext, useMemo } from "react";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { Typography, IconButton, ListItem, ListItemText } from "@material-ui/core";
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { MoreVert } from '@material-ui/icons';
import Linkify from "linkifyjs/react";
import { AvatarImage } from '../users/header';
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import { getStaffSettings } from './helper';
import { AttachmentList, ShowDate } from 'fitbud/views/chats/chatView';
import { getHeightWidthUisngFormula, changeDate } from "fitbud/utils/helpers";



const GrpMessage = ({ msg, messages, index, groupId,view_width,classes,profileCache,id,onOptionClick }) => {
  const { cid, authUser,comp } = useContext(FirebaseAuthContext);
  const msgData = msg.data({ serverTimestamps: "estimate" });
  const [height, width] = useMemo(() => {
    if (msgData.media) {
      const aspect = msgData.media.aspect || 1;
      let { height_new, width_new } = getHeightWidthUisngFormula(
        view_width,
        aspect
      );
      return [height_new, width_new];
    }
    return [0, 0];
  }, [msgData]);
  const downloadResource = useMemo(() => {
    let out = { id: msg.id, type: "text" };
    if (msgData.media) {
      out.type = "image";
      out.data = {
        identifier: msgData.media.identifier,
        storageFolderRef: `companies/${groupId}/groupChats/chats`,
      };
    } else if (
      msgData.type === "attachment" ||
      msgData.type === "audio" ||
      msgData.type === "video"
    ) {
      out.type = "other";
      out.data = msgData;
    }
    return { ...out };
  }, [msg, msgData]);
  const timestamp_previous = useMemo(() => {
    return index !== 0
      ? messages[index - 1].data().timestamp
      : msgData.timestamp;
  }, [index, messages, msgData]);
  const isCompanyMsg=cid===msgData.authorId|| !!msgData.staffId;
  const isClientMsg=!isCompanyMsg;
  const {name,border,color,image}=useMemo(()=>{
    if(isClientMsg){
        const id=msgData.staffId ? msgData.staffId : `${cid}:${msgData.authorId}`;
        const data=_.get(profileCache,[id,'data'],{});
        return {
            ...data,
            image:`user_profiles/${id}/profile/original/${data.image}`
        };
    }
    else{
        let out={
            name:getStaffSettings(comp.data(),msgData.staffId,'label'),
            color:getStaffSettings(comp.data(),msgData.staffId,'color'),
            border:getStaffSettings(comp.data(),msgData.staffId,'border'),
            image:getStaffSettings(comp.data(),msgData.staffId,'image_url')
        }
        if(!out.name){
            //fallback to comp
            out={
                name:_.get(comp.data(),['profile','name']),
                image:_.get(comp.data(),['chat_config','direct_message_profile_url'])
                    ||_.get(comp.data(),['profile','image']),
            }
        }
        return {...out};
    }
  },[isCompanyMsg,isClientMsg,msgData,comp,profileCache])
  const isLeftMsg = useMemo(() => {
    return msgData.staffId
      ? authUser.uid !== msgData.staffId
      : cid !== msgData.authorId;
  }, [msgData, authUser, cid]);
  const timestamp_current = msgData.timestamp;
  const date1 = moment(
    timestamp_current && timestamp_current.toMillis()
  ).local();
  const date2 = moment(
    timestamp_previous && timestamp_previous.toMillis()
  ).local();
  const handleOptionClick=(e)=>{
    onOptionClick(
        e,
        msg.id,
        msgData.authorId,
        downloadResource
    )
  }
  return (
    <div
      key={msg.id}
      id={msg.id}
      className={clsx(
        "mt-25 mb-25 d-flex flex-column w-100 position-relative",
        !isLeftMsg && "text-right align-items-end"
      )}
      style={{ paddingLeft: "50px" }}
    >
      {!!date1 && !!date2 && changeDate(date1, date2) && (
        <ShowDate date={date1} />
      )}
      <div
        className={clsx(isLeftMsg ? classes.ltContainer : classes.rtContainer)}
      >
        <ListItem
          divider
          id={`message_${msg.id}`}
          className={clsx(
            (msgData.type === "image" || msgData.type === "video") &&
              !msgData.deleted
              ? !isLeftMsg
                ? classes.mediaRight
                : classes.mediaLeft
              : !isLeftMsg
              ? classes.rightMsg
              : classes.leftMsg
          )}
          component="div"
          style={{
            border:(msgData.type === "text" && !!border && !!color) ? `1px solid ${color}`
                :(!isLeftMsg?'1px solid #D3D9DB':'unset')
            }}
        >
          {msgData.deleted ? (
            <ListItemText className="font-italic text-muted">
              This message has been deleted
            </ListItemText>
          ) : (
            <>
              {msgData.type === "text" && (
                <ListItemText>
                  <Linkify>{msgData.body}</Linkify>
                </ListItemText>
              )}
              {(msgData.type === "attachment" ||
                msgData.type === "audio" ||
                msgData.type === "video") && (
                <AttachmentList
                  id={id}
                  attachmentData={msgData}
                  key={msgData.attachment.identifier}
                />
              )}
              {msgData.type === "image" && (
                <div
                  style={{
                    height,
                    width,
                  }}
                >
                  {msgData.media && (
                    <ImgAspectFill
                      authorId={cid}
                      authUserId={cid}
                      targetHeight={height}
                      targetWidth={width}
                      identifier={msgData.media.identifier}
                      completed={msgData.media.completed}
                      aspect={msgData.media.aspect || 1}
                      imageData={msgData.media.imageData}
                      storageFolderRef={`companies/${groupId}/groupChats/chats`}
                      bucketName="chat"
                      style={{ borderRadius: "10px 10px 10px 0px" }}
                      alt={"Group Image"}
                      ext={"jpg"}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </ListItem>
      </div>

      {/* Timestamp and user info */}
      <div
        className={clsx(
          "d-flex align-items-end",
          msgData.deleted ? classes.normalMargin : classes.negMargin
        )}
        style={{ maxWidth: "70%" }}
      >
        {!!isLeftMsg && (
          <div
            className="d-flex align-items-baseline"
            style={{ width: "max-content" }}
          >
            <div className={classes.messageAvatar}>
              <AvatarImage
                src={image}
                name={name}
                base64={false}
                alt={name}
                externalUrl={isLeftMsg && msgData.staffId}
                style={{ fontSize: 13, fontWeight: 600 }}
              />
            </div>
            <Typography
              className={clsx("font_13_500")}
              style={ color ? { color} : {}}
              noWrap
            >
              {_.truncate(name,{ length: 50 })}
              <span className="mx-5 text-muted">&bull;</span>
            </Typography>
          </div>
        )}
        {((isLeftMsg && msgData.timestamp) ||
          (!isLeftMsg && !msg.metadata.hasPendingWrites) ||
          (!isLeftMsg && msgData.deleted)) && (
          <Typography className={clsx("font_11_500 text-muted m-0")}>
            {moment(
              msgData.timestamp.toMillis
                ? msgData.timestamp.toMillis()
                : msgData.ts
            ).format("LT")}
          </Typography>
        )}
        {!isLeftMsg && msg.metadata.hasPendingWrites && !msgData.deleted && (
          <Typography className={clsx("font_11_500 text-muted m-0")}>
            Sending...
          </Typography>
        )}
        {!msgData.deleted && (
          <IconButton
            size="small"
            onClick={handleOptionClick}
            style={{ marginBottom: "-6px" }}
          >
            <MoreVert />
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default GrpMessage;
