import React from 'react';
import { withStyles } from "@material-ui/core";
import clsx from "clsx";
const MEDIA_SIZE=250;
export const DetailPgStyling=withStyles(theme=>({
  detailPg:{
      '& .MuiCard-root':{
          padding:"25px 20px",
          border:"unset",
          '& .main-header-root':{
              marginBottom:"25px",
          },
          '& .sub-header-root':{
              marginBottom:"15px",
              display:"flex",
              alignItems:'start'
          },
          '& .main-title':{
            fontSize:"20px",
            fontWeight:600,
          },
          '& .sub-title':{
            //heading of second section (NOT secondary text)
            fontSize:"18px",
            fontWeight:600
          },
          '& .MuiCardHeader-root':{
              padding:"0px",
              '& .MuiCardHeader-avatar':{
                marginRight:20,
                '& .MuiAvatar-root':{
                  border: "1px solid #D3D9DB",
                  background: "#F2F4F7",
                  fontSize:20,
                  fontWeight:600,
                  color:"#6F8099"
                },
                '& .MuiAvatar-rounded':{
                  width: '135px',
                  height: '90px',
                  borderRadius: "6px",
                },
              },
              '& .MuiCardHeader-content':{
                flex:1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                lineClamp: 2,
                WebkitBoxOrient: "vertical"
              },
              '& .MuiCardHeader-title':{
                color:'#0D0D0D'
              },
              '& .MuiCardHeader-subheader':{
                fontSize:"15px",
                fontWeight:500,
                color:"#6F8099",
                marginTop:"10px"
              },
              '& .MuiCardHeader-action':{
                display:"flex",
                gap:'8px',
                margin:0,
              },
              '& .margin-top-negative':{
                marginTop:'-8px'
              },
              // '& .detail-icon-unfill':{
              //   fill:'none!important'
              // },
              // '& .detail-icon-fill':{
              //   '& svg':{
              //     fill:'#65768C!important'
              //   }
              // }
          },
          '& .MuiCardContent-root':{
            padding:"0px",
            '& .desc':{
              lineHeight:"20px!important",
              fontSize:"14px!important",
              fontWeight:"500!important",
              color:"#37404D"
            },
            '& .viewMore':{
              lineHeight:"20px!important",
              fontSize:"14px!important",
              fontWeight:"500!important",
            },
            '& .field-title':{
              fontSize:12,
              fontWeight:600,
              color:theme.palette.primary.main,
              textTransform:'uppercase'
            },
            '& .field-nutrition-title':{
              fontSize:12,
              fontWeight:600,
              color:'#27AE60',
              textTransform:'uppercase'
            },
            '& .field-value':{
              fontSize:15,
              fontWeight:600,
              color:'#0D0D0D'
            },
            // '& .rounded-videoPlayer':{
            //   borderRadius:"6px",
            //   overflow:"hidden"
            // },
            '& .chips':{
              backgroundColor:"#F0F3F5",
              borderRadius:"40px",
              '& .MuiChip-label':{
                fontSize:"13px",
                fontWeight:"600!important",
                color:"#0D0D0D"
              }
            },
            '& .img':{
              width: MEDIA_SIZE,
              height: 'auto',
            },
            '& .img-square':{
              width: MEDIA_SIZE,
              height: MEDIA_SIZE,
            }
          },
      },
      '& .content-divider':{
            height:"1px",
            backgroundColor:"#D8DCE0"
          },
       '& .media-container':{
              height:MEDIA_SIZE,
              width:MEDIA_SIZE,
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              borderRadius:6,
              overflow:'hidden',
            },  
        '& .media-img':{
          border:'1px solid #d1d1d1',
          '& img':{
            objectFit:"cover",
            height:"100%",
            width:"100%"
          }
        },
        '& .media-video':{
          background:"#000",
       }
  }
  
}))(({children,className,classes})=>(
  <div className={clsx(classes.detailPg, className )}>
    {children}
  </div>
))
