import React, { useEffect, useContext, useMemo } from 'react';
import {
  List, ListItem, Typography, makeStyles,
  NativeSelect, Tooltip, IconButton,
} from '@material-ui/core';
import { PasteIcon } from 'fitbud/icons/paste2';
import { UserSchContext } from '../provider';

const styles = makeStyles(theme => ({
  list: {
    backgroundColor: '#fff',
    '& .MuiListItem-root': { height: 70 },
    '& .Mui-selected': { backgroundColor: '#F0F6FE' },
    '& .Mui-selected .MuiTypography-root': { color: theme.palette.primary.main, fontWeight: '600 !important' },
    '& .curr-week-dot': {
      position: 'absolute', top: 30, right: 10,
      backgroundColor: theme.palette.primary.main,
      height: 10, width: 10, borderRadius: 5,
    },
    '& .pasteico': {
      position: 'absolute', top: 17, right: 0,
    },
  },
}));

const WeekList = ({ select = false, light = false }) => {
  const { opcopy, halt, currWeek, local, weekToday, dayToday, controller } = useContext(UserSchContext);
  const classes = styles();
  const { weeks } = local.plan;
  const l2s = useMemo(() => {
    return weeks.map((x, n) => controller.weekL2(n));
  }, [weeks, controller]);
  const [showPaste, excludeWeek] = useMemo(() => {
    if (!opcopy) return [];
    const { week: srcWeek, day, purpose } = opcopy;
    if (!day && purpose) return [true, srcWeek];
    return [];
	}, [opcopy]);

  const onClick = (e) => {
    const data = e.currentTarget.dataset;
    controller.gotoWeek(Number(data.x));
  };
  const onSelect = (e) => {
    controller.gotoWeek(Number(e.target.value));
  };
  const onPaste = (e) => {
    e.preventDefault();
    e.stopPropagation();
    controller.copyPaste(e);
  };

  useEffect(() => { // we only want scroll to happen initially
    const x = window.document.getElementById('week-' + currWeek);
    if (x) x.scrollIntoView({behavior: 'smooth', block: 'start'});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (select) {
    return (
      <NativeSelect value={currWeek} variant='outlined' onChange={onSelect} className={light ? 'text-white' : ''}>
        {weeks.map((weekId, n) => <option value={n}>Week {n + 1} • {l2s[n]}</option>)}
      </NativeSelect>
    );
  }

  return (
    <List disablePadding className={`border-bottom ${classes.list}`}>
      {weeks.map((weekId, n) => {
        const _paste = showPaste && n >= weekToday && n !== excludeWeek;
        const _dot = !_paste && dayToday >= 0 && n === weekToday;
        return (
          <ListItem id={'week-' + n} button key={weekId} selected={n === currWeek} data-x={n}
            onClick={onClick} className='d-flex flex-column align-items-start justify-content-center position-relative'>
            <Typography className='font_15_500'>Week {n + 1}</Typography>
            <Typography className='font_13_500 text-grey'>{l2s[n]}</Typography>
            {_paste && <Tooltip title={`Paste to Week ${n + 1}`} placement='right'>
              <IconButton className='pasteico' disabled={halt} onClick={onPaste} data-weekid={weekId} data-week={n}>
                <PasteIcon/></IconButton></Tooltip>}
            {_dot && <Tooltip title='Current Week'><div className='curr-week-dot'/></Tooltip>}
          </ListItem>
        );
      })}
    </List>
  );
};

export default WeekList;
