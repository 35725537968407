import React, { useMemo } from 'react';
import { Typography, Button, makeStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { ClrdButton } from 'fitbud/components/form-fields';
import { ACL_CONFIGURATION, ACL_FEATURES_KEY, PLANS } from 'fitbud/providers/acl-provider';
import broadcast from 'fitbud/images/acl-images/brodcast_scrren_new.png';
import groupChat from 'fitbud/images/acl-images/groupchat_screen.png';
import payment from 'fitbud/images/acl-images/payment_screen.png';
import videoCall from 'fitbud/images/acl-images/videocall_screen.png';
import website from 'fitbud/images/acl-images/website_screen.png';
import explore from 'fitbud/images/acl-images/explore_screen.png';
import automation from 'fitbud/images/acl-images/automation.svg';
import groupClass from 'fitbud/images/acl-images/groupClass.png';
import trainer from 'fitbud/images/acl-images/trainer.png';
import appTheme from 'fitbud/images/acl-images/app_theme.png';
import resources from "fitbud/images/acl-images/resources.png";
import { useBillingContext } from 'fitbud/providers/billing-provider';

const getImageSrc = (fkey) => {
  if (fkey === ACL_FEATURES_KEY.WEBSITE) return website;
  if (fkey === ACL_FEATURES_KEY.BROADCAST) return broadcast;
  if (fkey === ACL_FEATURES_KEY.GROUP_CHAT) return groupChat;
  if (fkey === ACL_FEATURES_KEY.PAYMENTS_PLAN) return payment;
  if (fkey === ACL_FEATURES_KEY.VIDEO_CALLING) return videoCall;
  if (fkey === ACL_FEATURES_KEY.EXPLORE) return explore;
  if (fkey === ACL_FEATURES_KEY.AUTOMATION) return automation;
  if (fkey === ACL_FEATURES_KEY.GROUP_CLASS) return groupClass;//
  if (fkey === ACL_FEATURES_KEY.TRAINERS) return trainer;
  if (fkey === ACL_FEATURES_KEY.APP_THEME) return appTheme;
  if(fkey === ACL_FEATURES_KEY.RESOURCES) return resources;
  else return website;
};

const DEFAULT_HELP_LINK = 'https://help.fitbudd.com/en/';

const getInfo = (fkey) => {
  const out = { title: '', subTitle: '', btn: '', url: '' };
  if (fkey === ACL_FEATURES_KEY.AUTOMATION) {
    out.title = 'Unlock Automation with Pro Plan! ';
    out.subTitle =
      'Looking to streamline and automate your fitness business? Upgrade to the Pro Plan and unlock our powerful automation features to save time and boost efficiency!';
    out.btn = 'Purchase Plan';
    out.url = 'https://help.fitbudd.com/en/articles/6474318-payment-plan-automation';
  }
  if (fkey === ACL_FEATURES_KEY.WEBSITE) {
    out.title = 'Launch Your Custom Branded Website';
    out.subTitle =
      'Customize & effortlessly launch your own website within no time. Use this website to promote your business or to market & sell your services.';
    out.btn = 'Get Custom Website';
    out.url = '';
  }
  if (fkey === ACL_FEATURES_KEY.BROADCAST) {
    out.title = 'Send messages in bulk with FitBudd Broadcast';
    out.subTitle =
      'Save time with our Broadcast messaging feature. Send messages or other vital information to your clients in bulk. Use smart filters to create the audience.';
    out.btn = 'Get Broadcast';
    out.url = 'https://help.fitbudd.com/en/articles/6432372-broadcast-messages';
  }
  if (fkey === ACL_FEATURES_KEY.GROUP_CHAT) {
    out.title = 'Group Chat - Fast, Simple & Better';
    out.subTitle =
      'Build digital communities with our premium Group Chat and engage with multiple clients at the same time. Share files, images, plans, promotions & much more.';
    out.btn = 'Get Group Chat';
    out.url = 'https://help.fitbudd.com/en/articles/8878457-group-chat-messaging';
  }
  if (fkey === ACL_FEATURES_KEY.PAYMENTS_PLAN) {
    out.title = 'Sell your services digitally with Payment Plans';
    out.subTitle =
      'Set up one-time or recurring subscription packages to sell your services. Seamlessly collect global payments directly into your account.';
    out.btn = 'Setup Payment Plans';
    out.url = '';
  }
  if (fkey === ACL_FEATURES_KEY.VIDEO_CALLING) {
    out.title = 'Do more with 1-to-1 Video Calls';
    out.subTitle =
      'Meet your clients across the globe virtually & save time with Video Calls. Book calls in advance or call instantly, take notes & establish meaningful relationships with your clients.';
    out.btn = 'Setup Video Calls';
    out.url = 'https://help.fitbudd.com/en/articles/5677882-video-calls-schedule-booking-more';
  }
  if (fkey === ACL_FEATURES_KEY.EXPLORE) {
    out.title = 'Create and Sell On-Demand Content Your Clients Will Love';
    out.subTitle =
      'Let your clients dive in whenever they want, giving them the freedom to train according to their own schedule. Make it perfect for anyone looking to fit workouts into their busy lives!';
    out.btn = 'Get Explore';
    out.url = 'https://help.fitbudd.com/en/articles/8694562-explore-on-demand';
  }
  if (fkey === ACL_FEATURES_KEY.RESOURCES) {
    out.title = 'Unlock Resource Hub: Your Essential Toolkit';
    out.subTitle =
      'With Resources, you can craft valuable resources with links, documents, and videos for your clients, like gym rules, workout videos, and nutrition tips. Keep clients motivated with all necessary information centralized in one place.';
      out.btn = 'Get Resources';
    out.url = 'https://help.fitbudd.com/en/articles/6967167-setting-up-resources-and-collections';
  }
  if (fkey === ACL_FEATURES_KEY.IN_APP_NOTIFICATIONS) {
    out.title = 'With In App Notification, Create Messages for application';
    out.subTitle =
      'Create and sell on-demand content for your clients that they can flexibly follow along with at their own pace without you requiring to do anything';
    out.btn = 'Get In App Notification';
    out.url = '';
  }
  if (fkey === ACL_FEATURES_KEY.TRAINERS) {
    out.title = 'Do More With Your Team by Your Side!';
    out.subTitle =
      'With Team Logins, you can add an unlimited number of trainers to manage your client. You can allocate clients to suitable trainers and review their performance';
    out.btn = 'Get Trainer Feature';
    out.url = ' https://help.fitbudd.com/en/articles/8410103-trainers-add-assign-manage-other-coaches-on-your-dashboard';
  }
  if (fkey === ACL_FEATURES_KEY.GROUP_CLASS) {
    out.title = 'Discover the benefits of GroupClass!';
    out.subTitle =
      'Ready to enhance your fitness offerings? Spice things up by organising group classes. Give it a try!';
    out.btn = 'Get Group Class';
    out.url = 'https://help.fitbudd.com/en/articles/9242601-group-classes-session-packs-more';
  }
  if (fkey === ACL_FEATURES_KEY.APP_THEME) {
    out.title = 'Design Your Client App!';
    out.subTitle =
      'With app theming, you control the appearance of your client app. Choose from pre-curated templates or design it yourself. Showcase your logo and select brand-aligned colors for a cohesive client experience.';
    out.btn = 'Get Teams';
    out.url = 'https://help.fitbudd.com/en/articles/9248217-app-theming';
  }
  return out;
};

const useClasses = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  container: {
    padding: 20,
    // [theme.breakpoints.up('sm')]: {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   height: '100%',
    // },
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      // width: '90%',
    },
    [theme.breakpoints.up('xl')]: {
      // width: '75%',
    },
  },
  leftContainer: {
    backgroundColor: '#FFF',
  },
  leftContent: {
    maxWidth: 420,
    // marginBottom: 30,
    // maxWidth: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: 420,
    //   marginRight: 40,
    //   flex: 1,
    //   marginBottom: 0,
    // },
  },
  rightContent: {
    backgroundColor: '#F0F3F5',
  },
  linkButton: {
    minWidth: 200,
    height: 40,
    '&:hover': {
      color: 'white !important',
    },
  },
  linkUrl: {
    marginLeft: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 50,
    },
  },
  imageStyle: {
    height: 'auto',
    width: '80%',
    // height: 360,
    // width: 360,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: '100%',
      maxWidth: 360,
    },
  },
  automationContainer: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  automationImage: {
    height: 80,
    width: 80,
  },
}));

const AclBlocker = (props) => {
  const { fkey } = props;
  const classes = useClasses();
  const { handleUpgrade } = useBillingContext();
  const info = getInfo(fkey);
  const handleClick = () => {
    handleUpgrade(fkey);
  };
  const containMessage = useMemo(() => {
    const feature = ACL_CONFIGURATION[fkey];
    if (!feature) return null;
    //check for includes in Pro
    if (feature.plans.includes(PLANS.GOLD)) return 'Available in Pro, Super Pro & Elite.';
    if (feature.addOn.length) return `Available as add-on with Pro & Super Pro. Included in Elite.`;
    return null;
  }, [fkey, ACL_CONFIGURATION]);

  if (fkey === ACL_FEATURES_KEY.AUTOMATION) return <AutomationBlocker containMessage={containMessage} {...props} />; //different layout for automation...
  return (
    <div className="h-100">
      <Grid container className={'h-100'}>
        <Grid
          item
          xs={12}
          md={6}
          className={clsx(classes.leftContainer, 'd-flex flex-column justify-content-center align-items-center')}>
          <div className={clsx(classes.leftContent)}>
            <Typography className="font_20_700 mb-20 text-0d0d0d">{info.title}</Typography>
            <Typography className="font_15_500 text-dark-grey mb-20">{info.subTitle}</Typography>
            <div className="">
              <ClrdButton
                // to={'/config/billing'}
                className={clsx('', classes.linkButton)}
                color="primary"
                variant="contained"
                onClick={handleClick}>
                {info.btn}
              </ClrdButton>
              {true && (
                <Button
                  target="_blank"
                  className={classes.linkUrl}
                  href={info.url || DEFAULT_HELP_LINK}
                  color="primary">
                  Learn More
                </Button>
              )}
            </div>
            {!!containMessage && (
              <Typography className="font_13_500 text-dark-grey mt-20">
                <i>{containMessage}</i>
              </Typography>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={clsx(clsx(classes.rightContent, 'd-flex justify-content-center align-items-center'))}>
          <img className={clsx(classes.imageStyle)} src={getImageSrc(fkey)} />
        </Grid>
      </Grid>
    </div>
  );
};

export const AutomationBlocker = (props) => {
  const { fkey, containMessage } = props;
  const info = getInfo(fkey);
  const classes = useClasses();
  const { handleUpgrade } = useBillingContext();
  return (
    <div className={clsx('d-flex flex-column justify-content-center align-items-center h-100 bg-white')}>
      <div className={clsx(classes.automationContainer, 'd-flex flex-column align-items-center')}>
        <img src={getImageSrc(fkey)} className={clsx(classes.automationImage)} />
        <Typography className="font_20_700 mb-20 text-0d0d0d mt-20 w-50 text-center">{info.title}</Typography>
        <Typography className="font_15_500 text-dark-grey mb-20 text-center">{info.subTitle}</Typography>
        <ClrdButton
          onClick={() => handleUpgrade(ACL_FEATURES_KEY.AUTOMATION)}
          className={clsx('mb-20', classes.linkButton)}
          color="primary"
          variant="contained">
          {info.btn}
        </ClrdButton>
        {!!containMessage && (
          <Typography className="font_13_500 text-dark-grey mt-20">
            <i>{containMessage}</i>
          </Typography>
        )}{' '}
      </div>
    </div>
  );
};

export default AclBlocker;
