import React, { useContext } from 'react';
import {
  Dialog, DialogTitle, DialogContent,
  IconButton, makeStyles, Button, Hidden,
  LinearProgress, CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useToggle } from 'fitbud/hooks/form';
import Confirmation from 'fitbud/components/confirmationDialog';
import { UserSchContext } from '../provider';
import WorkoutAlts from '../workoutAlts';
import AxnBar from './axnBar';
import WeekList from './week-list';
import WeekDays from './weekdays';
import AddWeeksDialog from './addWeeksDialog';
import Picker from './picker';
import RHS from './rhs';
import { CardEditor } from './card-editor';

const styles = makeStyles(theme => ({
  root: {
    '& .header': { height: 80 },
    '& .left': { width: 200, height: 'calc(100vh - 80px)' },
    '& .bar': { height: 60 },
    '& .daybar': { height: 40 },
    '& .rhsbar': { height: 20 },
    '& .srcsch': { maxWidth: 190 },
    '& .right': { width: '25%', height: 'calc(100vh - 140px)', minWidth: 280 },
    '& #main': { width: 'calc(min((100vw - 200px)*0.75, 100vw - 480px))', height: 'calc(100vh - 140px)' },
    [theme.breakpoints.down('sm')]: {
      '& #main': { width: 'calc(min((100vw - 160px)*0.75, 100vw - 400px))' },
      '& .left': { width: 160 },
      '& .right': { width: '25%', minWidth: 240 },
    },
    [theme.breakpoints.down('xs')]: {
      // '& #main': { width: '100vw', height: 'calc(100vh - 140px)' },
      '& .right': { width: '25%', minWidth: 280 },
    },
  },
}));

const Editor = () => {
  const { ready, currWeek, weekReady, block, halt, opmngalt, opconfirm, controller } = useContext(UserSchContext);
  const { confirmation } = opconfirm || {};
  const [rhs, toggleRHS] = useToggle(false);
  const classes = styles();

  if (!ready || typeof currWeek === 'undefined') return null;

  return (
    <Dialog open fullScreen disableEscapeKeyDown disableBackdropClick onClose={controller.closeEdit} scroll='paper'
      className={classes.root}>
      <DialogTitle disableTypography className='p-0'>
        <div className='header d-flex flex-row p-10 pr-sm-20 py-sm-15 border-bottom-2 align-items-center'>
          <IconButton disabled={!ready || block || halt} onClick={controller.closeEdit}><CloseIcon/></IconButton>
          <div className='flex-grow-1'/>
          <Button color='primary' variant='contained' className='py-10 px-30'
            disabled={!ready || !weekReady || block || halt} onClick={controller.saveme}>Save &amp; Close</Button>
        </div>
      </DialogTitle>
      <DialogContent className='bg-grey-new d-flex flex-row h-100 p-0'>
        <Hidden xsDown>
          <div className='left flex-shrink-0 border-right-3 overflow-y-auto'>
            <WeekList/>
          </div>
        </Hidden>
        <div className='flex-grow-1 position-relative d-flex flex-column'>
          <AxnBar rhs={rhs} toggleRHS={toggleRHS}/>
          <div className='w-100 flex-grow-1 d-flex flex-row position-relative justify-content-between'>
            <div id='main' className='overflow-y-auto overflow-x-hidden flex-grow-1'>
              <WeekDays/>
            </div>
            <Hidden xsDown>
              <div className='right flex-shrink-0 overflow-y-auto overflow-x-hidden bg-white border-left-3'>
                <RHS/>
              </div>
            </Hidden>
            <Hidden smUp>
              {rhs && <div className='position-absolute inset-0 d-flex flex-row justify-content-end bg-transluscent-black'>
                <div className='right overflow-y-auto overflow-x-hidden bg-white border-left-3'>
                  <RHS/>
                </div>
              </div>}
            </Hidden>
          </div>
          {!weekReady && <LoadingOverlay/>}
          <AddWeeksDialog/>
          {opmngalt && <WorkoutAlts edit/>}
          <Picker/>
          {confirmation && <Confirmation open
            handleClose={controller.closeConfirm} handleCancel={controller.closeConfirm}
            handleChange={controller.applyConfirm} {...confirmation}/>}
          {block && <BlockingLoader/>}
          <CardEditor />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const LoadingOverlay = () => {
  return (
    <div className='position-absolute inset-0 white-overlay'>
      <LinearProgress className='w-100'/>
    </div>
  );
};
const blocker = makeStyles({
  root: {
    zIndex: '1301!important',
  },
  dialogPaper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  dialogContentRoot: {
    backgroundColor: 'transparent',
  },
  CircularProgress: {
    color: 'white',
  },
});
const BlockingLoader = (props) => {
  const classes = blocker();
  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown
    classes={{ root: classes.root, paper: classes.dialogPaper }}>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <CircularProgress classes={{colorPrimary: classes.CircularProgress}}/>
      </DialogContent>
    </Dialog>
  );
};
export default Editor;
