import React, { useState, createContext, useEffect } from "react";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ClrdButton } from "fitbud/components/form-fields";
import MealCategoryContainer from "./mealCategoryContainer";
import SupplementsSelector from "./supplementsSelector";

export const EditorContext = createContext({});

const useStyles = makeStyles(theme => ({
  root: {
    width: "1160px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  paper: {
    width: "900px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

const SupplementsForm = props => {
  const classes = useStyles();
  const {
    editorMode,
    errors,
    doc = {},
    handleSupplementsAdd,
    storedSupplements,
    loading,
    handleChange,
    addMealCategory,
    handleMealType,
    handleDelete,
    handleReorder,
    mealCategories,
    serving,
    isImport = false,
    onSelectImportSupplements,
    checkImportSupplementGroup
  } = props;

  const [open, setOpen] = useState(false);
  const [grpIndex, setGrpIndex] = useState(null);
  const [type, setMealType] = useState(doc.type);
  const [itemIndex, setItemIndex] = useState(null);

  const selectSupplements = (grpIndex, type, index) => {
    setOpen(true);
    setGrpIndex(grpIndex);
    setMealType(type);
    setItemIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [doc]);

  return (
    <EditorContext.Provider
      value={{
        editorMode,
        storedSupplements,
        loading,
        errors,
        handleChange,
        handleDelete,
        handleReorder,
        serving,
        isImport,
        onSelectImportSupplements,
        checkImportSupplementGroup
      }}
    >
      <div
        className={` ${editorMode ? classes.root : ""} text-black`}
        style={{
          minHeight: "60vh"
        }}
      >
        <div className="d-flex justify-content-center ">
          <div className={`${classes.paper} ${editorMode ? "fmr-20 fmt-30" : ""} `}>
            {doc.groups.map((meal, index,thisArg) => {
              return (
                <MealCategoryContainer
                  handleMealType={handleMealType}
                  error={errors && errors.groups && errors.groups[index]}
                  key={`group-${index + 1}`}
                  index={index}
                  type={type}
                  meal={meal}
                  addSupplements={(type, itemIndex) =>
                    selectSupplements(index, type, itemIndex)
                  }
                  handleReorder={handleReorder}
                  mealCategories={mealCategories}
                  length={thisArg.length}
                />
              );
            })}
            {editorMode && doc.type !== "macros" && (
                <ClrdButton
                  classes={{
                    root: 'f-xxxlarge',
                  }}
                  style={{ backgroundColor: '#ffff', border: '1px solid #DDDDDD', borderRadius: '10px' }}
                  variant="outlined"
                  component="div"
                  className="shadow-none fmb-30"  
                  onClick={addMealCategory}
                >
                  <Typography color="primary" className="font_15_600">Add Supplement Timing Segments</Typography>
                </ClrdButton>
            )}
          </div>
        </div>

        <SupplementsSelector
          doc={doc}
          handleClose={handleClose}
          open={open}
          handleAdd={payload => handleSupplementsAdd(payload)}
          grpIndex={grpIndex}
          type={type}
          itemIndex={itemIndex}
          storedSupplements={storedSupplements}
        />
      </div>
    </EditorContext.Provider>
  );
};

export default withSnackbar(SupplementsForm);
