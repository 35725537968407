import _ from "lodash";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  InputBase,
  IconButton,
  Checkbox,
  InputAdornment,
  Typography,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Clear from "@material-ui/icons/CloseRounded";
import Refresh from "@material-ui/icons/Refresh";
import useListItemStyle from "fitbud/hooks/useListItemStyle";
import { calculateHeight } from "fitbud/utils/helpers";
import SearchIcon from "@material-ui/icons/Search";
import cardioRdxFns from "fitbud/redux/cardio";
import exRdxFns from "fitbud/redux/exercises";
import mealRdxFns from "fitbud/redux/meals";
import foodMealRdxFns from "fitbud/redux/foodMeals";
import planRdxFns from "fitbud/redux/plans";
import frsRdxFns from "fitbud/redux/foodRecipesSupps";
import frRdxFns from "fitbud/redux/foodRecipes";
import suppsRdxFns from "fitbud/redux/supps";
import dailySuppRdxFns from "fitbud/redux/supplements";
import woRdxFns from "fitbud/redux/workouts";
import woCardioRdxFns from "fitbud/redux/wocardio";
import schRdxFns from "fitbud/redux/schedules";
import mlSchRdxFns from "fitbud/redux/mlSchedules";
import NoMeal from "fitbud/images/no_meal.svg";
import NoCardio from "fitbud/images/no_cardio.svg";
import NoExercise from "fitbud/images/no_exercise.svg";
import NoSchedule from "fitbud/images/no_schedule.svg";
import NoPlans from "fitbud/images/no_plans.svg";
import { NavIcon } from "fitbud/partials/appBar";

const ListInner = props => {
  const classes = useListItemStyle();
  const { docs, onSelect, selected, filter, multiSelect, doc, onItemClick, renderRow } = props;
  let checked = props.checked||[];
  let onClick = null;
  if (!!onSelect)
    if (!multiSelect) {
      onClick = doc => {
        onSelect(doc._id);
      };
    } else {
      onClick = doc => {
        onSelect(doc);
      };
    }
  if(onItemClick){
    onClick = onItemClick
  }   
  if (!docs) return null;

  let existItems = [];
  if (doc) {
    existItems = _.filter(
      docs,
      d =>
        !!d &&
        _.find(doc, d2 => {
          if (!!d2.alternatives && d2.alternatives.length > 0) {
            return d2.alternatives[0].ref.id === d._id;
          }
          return d2.ref && d2.ref.id ? d2.ref.id === d._id : d2.id === d._id;
        })
    );
    checked = [...checked, ...existItems];
  }

  return (
    <>
      {docs.map((doc, index) => {
        const _onClick = () => onClick(doc);

        if (renderRow) {
          return renderRow(doc, index, _onClick);
        }

        return (
          <ListItem
            key={doc._id}
            id={doc._id}
            selected={selected === doc._id}
            className="fpy-10 fpx-20"
            divider
            disabled={existItems.findIndex((item) => item._id === doc._id) !== -1}
            button
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
            onClick={_onClick}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className={multiSelect ? 'col-11' : 'w-100'}>
                <ListItemText
                  classes={{
                    multiline: 'fm-0',
                  }}
                  primary={
                    <Typography
                      classes={{
                        root: 'text-truncate',
                      }}
                      variant={
                        (checked && checked.findIndex((item) => item._id === doc._id) !== -1) || selected === doc._id
                          ? 'h6'
                          : 'body1'
                      }
                    >
                      {doc.data.ref_name}
                    </Typography>
                  }
                />
              </div>
              {multiSelect && (
                <Checkbox
                  color="primary"
                  className="fp-0 fm-0"
                  edge="end"
                  checked={checked.findIndex((item) => item._id === doc._id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': doc._id }}
                />
              )}
            </div>
          </ListItem>
        );
      })}
    </>
  );
};

const useStyles = makeStyles((t) => ({
  inputBase: {
    borderRadius: "20px",
    height: 40,
    border: "solid 0.6px #d7d7d7",
  },
  clearIconButton:{
    padding: 8,
    '&:hover': {
      background: "none",
    }
  },
  refreshIconButton:{
    padding:8,
    margin:"0px 2px" //margin horizontally for hover effect not touches with searchInput
  }
}));

const ListItems = props => {
  let {
    docs,
    count,
    loading,
    keyName,
    searchCount,
    searchResult,
    nav = false,
    query:propsQuery,
    searchOff= false,
    filters: filter,
    noResultFound,
    initialNoResultFound,
    ...restProps
  } = props;
  const classes  = useStyles();
  const [searching, setSearching] = useState(!nav);
  const toggleSearching = () => {
    if (!!searching) {
      if (!!nav) setSearching(false);
      if (!!q) {
        setQ("");
        currentKeyRdxFns.searching("");
      }
    } else setSearching(true);
  }
  const [q, setQ] = useState("");
  const currentKeyRdxFns = props[keyName];
  
  const updateRef = useRef({ searchResult, docs, searchCount, count });
  const dataLimit = useRef(0);
  const listRef = useCallback(node => {
    if (node !== null) {
      dataLimit.current = calculateHeight(node)
    }
  }, []);

  const handleChange = e => {
    currentKeyRdxFns.searching(e.target.value, filter);
    setQ(e.target.value);
  };

  useEffect(() => {
    currentKeyRdxFns.searching(propsQuery);
    setQ(propsQuery);
  }, [propsQuery]);

  const fetchList = () => {
    if ((!loading && !docs) || (docs && docs.length < 10)) fetch();
    let list = document.getElementById(props.keyName);
    list.addEventListener("scroll", handleScroll);
    return () => {
      let list = document.getElementById(props.keyName);
      list.removeEventListener("scroll", handleScroll);
      if (currentKeyRdxFns) currentKeyRdxFns.resetSearch();
    };
  };

  useEffect(fetchList, []);

  useEffect(() => {
    updateRef.current = {
      searchResult,
      docs,
      searchCount,
      count
    };
  }, [searchResult, docs, searchCount, count]);

  const handleScroll = _.debounce(e => {
    let latestQ;
    let { searchResult, docs, searchCount, count } = updateRef.current;
    setQ(oldVal => (latestQ = oldVal));

    if (e.target.scrollTop + e.target.clientHeight >= (e.target.scrollHeight - 100)) {
      if (!!latestQ) {
        if (!!searchResult)
          if (searchCount > searchResult.length) {
            currentKeyRdxFns.searching(latestQ, filter);
          }
      } else {
        if (!!docs)
          if (count > docs.length) {
            currentKeyRdxFns.request();
          }
      }
    }
  }, 500);

  const fetch = (refresh = false) => {
    if (loading) return;
    currentKeyRdxFns.toggleLoader();
    currentKeyRdxFns.request(refresh, dataLimit.current, filter);
  };

  const handleRefresh = () => {
    if(!!q) {
      currentKeyRdxFns.searching(q, filter);
      if (currentKeyRdxFns) currentKeyRdxFns.resetSearch();
    }else{
      fetch(true)
    }
  }

  //Follow Along Video Workout filter from workout docs
  //The workout list loads for the first time and data stores in the redux.
  //And when we need data we directly fetch it from the redux.
  // So for import from workout we need filtered data from redux.
  if(!!filter) {
    docs = _.filter(docs, doc => doc.data.isEmpty === false);
  }

 return (
    <div className="h-100 d-flex flex-column" ref={listRef}>
        {!searchOff && 
          <div>
          <div className="d-flex bg-light-grey align-items-center" style={{ minHeight: "60px" }}>
            <div className="d-flex flex-grow-1 align-items-center h-100">
              {!searching && <>
                <NavIcon />
                <Typography variant='h2' className='flex-grow-1 pl-0 pl-md-3'>{getTitleText(props.keyName)}</Typography>
                <IconButton onClick={toggleSearching}><SearchIcon/></IconButton>
              </>}
              {searching && <InputBase fullWidth value={q}
                className={`bg-white fpl-10 fml-10 ${classes.inputBase}`}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={toggleSearching}
                      disableRipple disableFocusRipple className={classes.clearIconButton}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={`Search ${getPlaceHolderName(props.keyName)}`}
              />}
            </div>
            <IconButton onClick={handleRefresh} className={classes.refreshIconButton}><Refresh/></IconButton>
          </div>
          <Divider />
        </div>
      }
      {!!loading && (
          <div className="white-overlay bg-transparent position-relative">
            <LinearProgress />
          </div>
       )}
      {!!q && searchResult && searchResult.length === 0 && (
        <Typography
          variant="h2"
          className="fmt-30 d-flex align-items-center justify-content-center"
        >
          No results found
        </Typography>
      )}
      {docs && !docs.length && <Placeholder keyName={keyName}/>}
      <List
        disablePadding
        className="position-relative overflow-auto"
        style={{ flex: 1, display: "table-row" }}
        id={props.keyName}
      >
        <ListInner docs={!!q ? searchResult : docs} {...restProps} />
      </List>
    </div>
  );
};

const mapStateToProps = (s, op) => {
  return { ...s[op.keyName], query: op.query }; // for updated query not passed as props to components
};

const mapDispatchToProps = d => {
  return {
    supps: { ...suppsRdxFns(d) },
    foodRecipes: { ...frRdxFns(d) },
    foodRecipesSupps: { ...frsRdxFns(d) },
    exercises: { ...exRdxFns(d) },
    cardio: { ...cardioRdxFns(d) },
    meals: { ...mealRdxFns(d) },
    foodMeals: { ...foodMealRdxFns(d) },
    supplements: { ...dailySuppRdxFns(d) },
    workouts: { ...woRdxFns(d) },
    wocardio: { ...woCardioRdxFns(d) },
    schedules: { ...schRdxFns(d) },
    mlSchedules: { ...mlSchRdxFns(d) },
    plans: { ...planRdxFns(d) },
  };
};

const getNoItemSvg = (key) => {
  let svg = "";
  switch (key) {
    case "foodRecipesSupps":
    case "foodRecipes":
    case "supps":
    case "meals":
    case "foodMeals":  
    case "supplements":
      svg = NoMeal;
      break;
    case "workouts":
    case "exercises":
      svg = NoExercise;
      break;
    case "cardio":
      svg = NoCardio;
      break;
    case "schedules":
    case "mlSchedules":
      svg = NoSchedule;
      break;
    case "plans":
      svg = NoPlans;
      break;
    default:
      svg = "";
  }
  return svg;
};

const getPlaceHolderName = (key) => {
  switch (key) {
    case "foodRecipes":
    case "foodRecipesSupps":
      return "food & recipes";
    case "supps":
      return "supplements";
    case "wocardio":
      return "workouts";
    case "meals":
    case "foodMeals":  
      return "meal plans";
    case "supplements":
      return "supplement plans";
    case "mlSchedules":
      return "schedules"
    case "workouts":
    case "exercises":
    case "cardio":
    case "schedules":
    case "plans":
    default:
      return key;
  }
};

const getTitleText = (key) => {
  return _.capitalize(getPlaceHolderName(key));
}

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ListItems)
);


const Placeholder=({keyName})=>{
  return(
    <div
      className="text-center d-flex align-items-center justify-content-center flex-column position-absolute"
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
      }}
    >
      <div className="fmt-30 fmb-30 ">
        <img alt="noitem" src={getNoItemSvg(keyName)} />
      </div>
      <Typography className="text-capitalize fmb-10 font_18_600">
        No {keyName} added
      </Typography>
      <Typography className="font_14_500 text-nowrap">
        Click on ‘+’ to add {keyName}
      </Typography>
  </div>
  )
}
