import firebase from 'firebase';
import React, { useContext, useState, useCallback, useMemo } from 'react';
import { FirebaseAuthContext } from './firebase-auth';
import { snapshotDocsArrayToDataArray } from 'fitbud/utils/helpers';
import find from 'lodash/find';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import update from 'immutability-helper';
const LocationsContext = React.createContext({});

const getStudioLocations = (locations) => {
  //this function will return studio locations, using physical location, eg, [{meta:{}, rooms:[], mode: ,id : as source}]
  const studioLocationMap = (locations || []).reduce((acc, curr) => {
    const { _id, data } = curr;
    const { studio } = data;
    if (!studio) return acc; //studio is important, other wise, will skipp.
    acc[studio] = acc[studio] || [];
    acc[studio].push({ _id: _id, ...data });
    return acc;
  }, {});
  const out = [];
  Object.values(studioLocationMap).forEach((locations) => {
    const firstLocation = locations[0] || {};
    const { gmap, address, studio_name, studio } = firstLocation;
    const meta = { gmap, address, studio_name, studio };
    const rooms = locations;
    out.push({ data: { meta, rooms, mode: 'offline' }, id: studio });
  });
  return out;
};

export const LocationProvider = (props) => {
  const { cid } = useContext(FirebaseAuthContext);
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadLocations = async () => {
    setLoading(true);
    const locationDocs = await firebase.firestore().collection(`companies/${cid}/gcLocations`).get();
    setLocations(snapshotDocsArrayToDataArray(locationDocs));
    setLoading(false);
  };

  const fetchLocations = async () => {
    if (locations || (locations && locations.length === 0)) return;
    loadLocations();
  };

  const refreshLocations = async () => {
    loadLocations();
  };

  const addLocation = (_id, data) => {
    setLocations((l) => [...l, { _id, data }]);
  };

  const updateLocation = (id, obj) => {
    const index = findIndex(locations, (location) =>location._id === id);
    if (index < 0) return;
    const out = update(locations, {
      [index]: {
        data: {
          $merge: { ...obj },
        },
      },
    });
    setLocations(out);
  };

  const [offline_locations, online_locations, studio_locations] = useMemo(() => {
    const _locations = filter(locations, ({ data } = {}) => !data.archive) || []; //filter out archive true locations, there values to show locations for app.
    const offline_locations = filter(_locations, ({ data } = {}) => data.mode === 'offline') || [];
    const online_locations = filter(_locations, ({ data } = {}) => data.mode === 'online') || [];
    //pushing custom options
    online_locations.push({ _id: 'custom', data: { title: 'Custom', ref_name: 'Custom' } });
    //getting studio locations
    const studio_locations = getStudioLocations(offline_locations);
    return [offline_locations, online_locations, studio_locations];
  }, [locations]);

  const getLocation = useCallback(
    (location_id) => {
      if(!location_id) return null;
      return find(locations, (i) => i._id === location_id);
    },
    [locations]
  );

  return (
    <LocationsContext.Provider
      value={{
        fetchLocations,
        locations,
        getLocation,
        offline_locations,
        online_locations,
        studio_locations,
        refreshLocations,
        locationLoading: loading,
        addLocation,
        updateLocation,
      }}>
      {props.children}
    </LocationsContext.Provider>
  );
};

export const useLocations = () => useContext(LocationsContext);
