import { InputAdornment, InputBase, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Dialog from './Dialog';
import searchIcon from 'fitbud/images/searchIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import appRdxFns from "../redux/app/";
import planRdxFns from "fitbud/redux/plans";
import { PlanContext } from 'fitbud/views/plan/planProvider';
import { parsePacks } from 'fitbud/views/plan/helper';
import firebase from 'firebase';
import Img from 'fitbud/views/plan/img';
import { SeconderyText } from 'fitbud/views/plan/list';
import clsx from 'clsx';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { debounce } from 'lodash';
import DefaultInfo from "fitbud/components/defaultInfo";
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from "lodash";


export const useStyles = makeStyles((theme) => {
  return {
    input: {
      borderRadius: '20px',
      height: 40,
      border: '1px solid #D3D9DB',
      background: '#fff',
      padding: '0px 10px',
      marginBottom:15,
    },
    root: {
      minHeight: "80px",
      position: 'relative',
      "& .MuiListItemText-primary": {
        marginBottom: "2px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: "600"
      },
      "& .MuiListItemText-secondary": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "&.Mui-selected": {
        backgroundColor: "#F6F9FE",
        "&:hover": {
          backgroundColor: "#F6F9FE"
        }
      },
      "&:hover": {
        backgroundColor: "#F6F9FE"
      },
      '& .icon':{
        position:"absolute",
        bottom:0,
        right:5,
        height:14,
        width:14
      }
    },
  }
});

const SearchBar = ({ onChange, value }) => {
  const classes = useStyles();
  return (
    <InputBase 
      fullWidth
      value={value}
      className={clsx(classes.input, 'font_13_500')}
      onChange={onChange}
      startAdornment={
        <InputAdornment position="start">
          <img alt="search-icon" src={searchIcon} style={{ width: '20px' }} />
        </InputAdornment>
      }
        placeholder={"Search by payment plan name"}
    />
  )
};

const PaymentPlanSelector = (props) => {
  const {type = "all"} = props;
  const [plans, setPlans] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const d = useDispatch();
  const { cid, hasVidCall, parsePacksForIapIds } = useContext(PlanContext);
  const {comp} = useContext(FirebaseAuthContext);
  const  { showLoader, hideLoader } = appRdxFns(d);
  const { set } = planRdxFns(d);
  const { docs: planRdxDocs } = useSelector((s) => s.plans);
  const company = comp ? comp.data() : {};
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));

  const _plans = useMemo(()=>{
    if(!type) return plans;
    else  if(type === "all") return plans;
    else  if(type === "regular"){
      return _.filter(plans, (plan)=>_.get(plan, "data.type") !== "add_on");
    } else if(type === "add_on"){
      return _.filter(plans, (plan)=>_.get(plan, "data.type") === "add_on");
    }else return plans;
  },[type, plans])

  const fetchList = async() => {
    if(planRdxDocs && planRdxDocs.length){
      setPlans(planRdxDocs);
      return setLoading(false);
    }

    try {
      const docs = await firebase.firestore().collection(`companies/${cid}/packs`).get();
      set(parsePacks(docs, isGroupClass), 0, true);
      parsePacksForIapIds(docs);
      setPlans(parsePacks(docs, isGroupClass));
      setLoading(false)
    } catch(err){
      console.log(err);
      setLoading(false);
    };
  };

  useEffect(() => {
    showLoader();
    fetchList().then(() => hideLoader());
  }, []);

  const debouncedChange = debounce((value= '') => {
    if(!value) return setPlans(planRdxDocs);
    const results = plans.filter(({ data }) => data.ref_name.toLowerCase().includes(value.toLowerCase()));
    return setPlans(results);
  }, 300);

  const handleChange = (e) => {
    const { value } = e.target;
    debouncedChange(value);
  };

  const handleItemClick = (doc) => {
    props.onSubmit(doc);
  };

  const classes = useStyles();
  if(isLoading) return null;
  return (
    <Dialog
      open  
      title={"Select Payment Plan"}
      paperClass={"width-600 height-70"}
      appBarColor="bg-grey-new"
      toolbarClass={"height-60"}
      dialogContentClassName="d-flex flex-column"
      hideHeaderDivider
      // actionText="Next"
      {...props}
    >
      <div className='px-20 bg-grey-new'>
        <SearchBar onChange={handleChange} />
      </div>
      <div className="position-relative d-flex flex-column h-100 overflow-hidden">
        {!_plans || !_plans.length ? (
          <div className='flex-1 d-flex flex-column align-items-center justify-content-center'>
            <DefaultInfo keyName="plan" label="No results found" />
          </div>
        )  : (
          <List disablePadding className='flex-1 overflow-auto'>
            {_plans.map((doc, i) => (
              <ListItem
                key={i}
                id={i}
                className="fpy-10 fpx-20"
                button
                onClick={() => handleItemClick(doc)}
                classes={{
                  root: classes.root,
                }}
                
              >
                <ListItemAvatar>
                  <Img name={doc.data.title} src={doc.data.image} size={360} width={70} aspect={1.5} className='rounded mr-3'/>
                </ListItemAvatar>
                <ListItemText
                  primary={doc.data.ref_name}
                  secondary={<SeconderyText ftCalls={hasVidCall} data={doc.data} />} 
                />
                <div>
                  <ArrowForward />
                </div>
              </ListItem>
            ))}
          </List>
        ) 
        }
      </div>
    </Dialog>
  );
}
export default PaymentPlanSelector;