import React, { useEffect, useState } from "react";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import {
  MenuItem,
  Select,
  OutlinedInput,
  FormControlLabel, Typography, Checkbox, FormHelperText, Switch
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import NumUnitInput from "fitbud/components/numUnitInput";

const duration = [{ label: "15 mins", value: 15 }, { label: "30 mins", value: 30 }, { label: "45 mins", value: 45 }, { label: "60 mins", value: 60 }];

const OPTS = [
  {value: 'week', label: 'Per Week'},
  {value: 'month', label: 'Per Month'},
  {value: 'plan', label: 'Total'},
];

const DEFAULT = {
  duration: 0,
  frequency: "week",
  includes_calls: false,
  num_sessions: 0
};

const VideoCalling = (props) => {
  const { handleChange, vid_call, errors, src = 'custom', standalone = false, isStepper=false, heading="Include Video Calling" } = props;
  const [state, setState] = useState(vid_call || DEFAULT);
  const handleChangeWrapper = (e) => {
    const key = e.target.id || e.target.name;
    let chng = {...state};
    switch (key) {
      case "includes_calls":
        chng.includes_calls = e.target.checked;
        break;
      case "duration":
        chng.duration = Number(e.target.value);
        break
      case "num_sessions":
        const {display, unit} = e.target;
        chng.num_sessions = Number(display);
        chng.frequency = unit;
        break;
      default:
        return;
    }
    setState(chng);
    handleChange({ vid_call: chng });
  }

  useEffect(()=>{setState(vid_call || DEFAULT)}, [vid_call, src]);
  return (
    <>
      <FormControlLabel
        label={<Typography>{heading}</Typography>}
        className="mb-8"
        onChange={handleChangeWrapper}
        checked={state.includes_calls}
        control={<Switch color="primary" id="includes_calls" name="includes_calls" />}
      />
      {(standalone || state.includes_calls) &&
        <div className={(!standalone || isStepper) && "d-flex"}>
          <FormTextField fullWidth label="Duration">
            <Select
              IconComponent={DropDownIcon}
              disabled={standalone && !state.includes_calls}
              value={!!state.duration ? state.duration : "select"}
              error={!!errors.call_duration}
              input={
                <OutlinedInput
                  classes={{
                    root: `medium ${isStepper && "fmr-20"}`,
                    input: "size_16_500 select-medium",
                  }}
                  name="duration"
                />
              }
              onChange={handleChangeWrapper}
              required
            >
              <MenuItem value="select">
                Select
              </MenuItem>
              {duration && duration.map((item, index) => {
                return (
                  <MenuItem key={`${index}`} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText style={{color: "#f44336"}}>{errors.call_duration}</FormHelperText>
          </FormTextField>
          <NumUnitInput
            key={src}
            id="num_sessions"
            label="Number of Calls"
            opts={OPTS} noFloat
            unit={state.frequency}
            value={state.num_sessions}
            error={!!errors.num_sessions}
            helperText={errors.num_sessions}
            onChange={handleChangeWrapper}
            variant="outlined"
            disabled={standalone && !state.includes_calls}
            LabelProps={{classes: {control: `fmb-20 ${!standalone && "fml-20"}`}}}
            InputProps={{classes: {root: "medium", input: "size_16_500 medium"}}}
            required />
        </div>}
    </>
  )
}

export default VideoCalling;
